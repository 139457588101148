import React, { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewSubAreasComponent from "../components/areas/ViewSubAreas";
const SubAreasPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Sub Areas By Area");
  }, [setTitle]);

  return (
    <>
      <ViewSubAreasComponent />
    </>
  );
};
export default SubAreasPage;
