import React, { useEffect } from "react";
import NetwalaModal from "../Items/Modal/Index";
import { Row, Typography } from "antd";
import "./styles/RequestIPTVModal.css";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import WarningIcon from "./../../assets/svgs/WarningIcon.svg";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";

const DeleteTransactionModal: React.FC<DeleteTransactionModalProps> = ({
  show,
  handleClose,
  transaction,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();

  const [
    postConnectionRechargeApi,
    rechargeApiResponse,
    rechargeApiError,
    rechargeApiLoading,
    rechargeApiMessage,
    rechargeApiErrorCount,
  ] = useAxiosPost<DeleteUserTransactionApiProp>(
    "transactions/deleteUserTransaction",
    true
  );
  useEffect(() => {
    if (rechargeApiError !== null && rechargeApiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", rechargeApiError);
    }
  }, [rechargeApiError, rechargeApiErrorCount]);
  useEffect(() => {
    if (rechargeApiResponse !== null) {
      const { message } = rechargeApiResponse;
      if (message) {
        openNotification(`success`, "Success", message);

        setTimeout(() => {
          handleClose(true);
        }, 2500);
      }
    }
  }, [rechargeApiResponse]);
  return (
    <>
      <NetwalaModal
        title="Delete Transaction"
        headerStyle={{
          backgroundColor: "#292D8E",
          color: "#FFFFFF",
          fontSize: "16px",
          fontWeight: "400",
          height: "50px",
        }}
        open={show}
        onCancel={() => {
          handleClose(false);
        }}
      >
        {contextHolder}

        <Row className="service-remove-confirmation-container">
          <img src={WarningIcon} />
          <Typography className="remove-message">
            Are you Sure You want to delete this transaction?
          </Typography>
          <div className="remove-buttons-container">
            <NetwalaButton
              backgroundColor={ButtonProps.background.STANDARD}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.ONE24}
              textColor={ButtonProps.color.STANDARD}
              onClick={() => {
                const data: DeleteUserTransactionApiProp = {
                  intPkTransactionHeadID: transaction.intPkTransactionHeadID,
                };
                postConnectionRechargeApi(data);
              }}
              text="Confirm"
              disabled={rechargeApiLoading}
            />
            <NetwalaButton
              backgroundColor={ButtonProps.background.SECONDARY}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.ONE24}
              textColor={ButtonProps.color.BLACK}
              onClick={() => handleClose(false)}
              text="Cancel"
              //   icon={<img src={PlusIcon} />}
              borderColor="#D7D7D7"
              disabled={rechargeApiLoading}
            />
          </div>
        </Row>
      </NetwalaModal>
    </>
  );
};

export default DeleteTransactionModal;
