import { useEffect, useState } from "react";
import { Row, Col, Typography, Form, Input, Card, Select, Button } from "antd";
import useAxiosPost from "../../hooks/useAxiosPost";
import { useNavigate, useParams } from "react-router-dom";
import TransactionDetailsTable from "../Items/Table/TransactionDetailsTable";
import useMultiNotification from "../../hooks/useNotification";
const TransactionDetailsComponent = () => {
  const navigate = useNavigate();

  const { openNotification, contextHolder } = useMultiNotification();

  const [transactions, setTransactions] = useState<TransactionDetailsApiProp[]>(
    []
  );
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<TransactionDetailsByTransactionHeadApiProp>(
    "transactions/getSingleTransactionDetails"
  );

  const [
    postRefundTransactionApi,
    refundTransactionApiResponse,
    refundTransactionApiError,
    refundTransactionApiLoading,
    refundTransactionApiMessage,
    refundTransactionApiErrorCount,
  ] = useAxiosPost<RefundTransactionApiProp>("transactions/refundTransaction");
  const { intPkTransactionHeadID, isRefunded } = useParams();
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setTransactions(data || []);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({ intPkTransactionHeadID: Number(intPkTransactionHeadID) });
  }, []);
  const handleRefundTransaction = (isRefund: boolean) => {
    console.log({ isRefund });
    if (isRefund === true) {
      postRefundTransactionApi({
        intPkTransactionHeadID: Number(intPkTransactionHeadID),
      });
    }
  };
  useEffect(() => {
    if (refundTransactionApiResponse !== null) {
      const { message } = refundTransactionApiResponse;
      if (message) {
        openNotification(`success`, "Success", message);
        setTimeout(() => {
          navigate(-1);
        }, 2500);
      }
    }
  }, [refundTransactionApiResponse]);
  useEffect(() => {
    if (
      refundTransactionApiError !== null &&
      refundTransactionApiErrorCount !== 0
    ) {
      openNotification(`error`, "Operation Failed", refundTransactionApiError);
    }
  }, [refundTransactionApiError, refundTransactionApiErrorCount]);
  return (
    <>
      {contextHolder}
      <Row gutter={30}>
        <Col span={24}>
          <TransactionDetailsTable
            transactions={transactions}
            handleRefundTransaction={handleRefundTransaction}
            isRefunded={Number(isRefunded)}
          />
        </Col>
      </Row>
    </>
  );
};
export default TransactionDetailsComponent;
