import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import SyncMikroNocIspComponent from "../components/isps/SingleIspProfile/components/SyncMikroNocIsp";

const MikroNoCIspSyncPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Sync Data");
  }, [setTitle]);
  return (
    <>
      <SyncMikroNocIspComponent />
    </>
  );
};
export default MikroNoCIspSyncPage;
