import React, { useState } from "react";
import { Row, Col, Typography, Dropdown } from "antd";
import "./transactions.css";
import { formatDate } from "../../../../helpers/date";
import ViewReceiptIconBlue from "./../../../../assets/svgs/ViewReceiptIconBlue.svg";
import ViewSoaIcon from "./../../../../assets/svgs/ViewSoaIcon.svg";
import ThreeDotsVertical from "./../../../../assets/svgs/ThreeDotsVertical.svg";
import PromiseIcon from "./../../../../assets/svgs/PromiseIcon.svg";
import PromisedTransactionIcon from "./../../../../assets/svgs/PromisedTransactionIcon.svg";
import { useNavigate } from "react-router-dom";
import type { MenuProps } from "antd";
import UpdateOrCreatePromiseModal from "../../../Modals/UpdateOrCreatePromiseModal";
import { getUserType } from "../../../../helpers/userDetails";
import { DeleteOutlined } from "@ant-design/icons";
import DeleteTransactionModal from "../../../Modals/DeleteTransactionModal";

interface TransactionRowItemProp {
  label: string;
  value: string;
  valueClass: string;
}
const DetailsItem: React.FC<TransactionRowItemProp> = ({
  label,
  value,
  valueClass,
}) => {
  return (
    <div className="transaction-single-item-col">
      <Typography className="item-label">{label}</Typography>
      <Typography className={`${valueClass}`}>{value}</Typography>
    </div>
  );
};
const SingleTransactionCard: React.FC<TransactionsPageCardProp> = ({
  transaction,
  staffHeadId,
  reloadApi,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const navigate = useNavigate();
  const items: MenuProps["items"] = [
    {
      key: "PROMISE_ON_TRANSACTION",
      label: (
        <Typography className="profile-drop-down-item">
          <img src={PromiseIcon} />
          {transaction.promiseCreationDate !== null
            ? "Update Promise"
            : "Create Promise"}
        </Typography>
      ),
    },
  ];
  if (getUserType() === "CEO") {
    items.push({
      key: "DELETE_TRANSACTION",
      label: (
        <Typography className="profile-drop-down-item">
          <DeleteOutlined />
          Delete Transaction
        </Typography>
      ),
    });
  }
  const handleMenuClick = (e: any) => {
    switch (e.key) {
      case "PROMISE_ON_TRANSACTION":
        setShow(true);
        break;
      case "DELETE_TRANSACTION":
        setShowDelete(true);
        break;
    }
  };
  const handleClose = (isReload: boolean) => {
    setShow(false);
    setShowDelete(false);
    if (isReload === true) {
      //lets reload the main api
      reloadApi();
    }
  };

  return (
    <>
      {show === true && (
        <UpdateOrCreatePromiseModal
          handleClose={handleClose}
          show={show}
          transaction={transaction}
        />
      )}
      {showDelete === true && (
        <DeleteTransactionModal
          handleClose={handleClose}
          show={showDelete}
          transaction={transaction}
        />
      )}
      <Row style={{ height: "100%" }} className="transaction-container">
        {/* {transaction.promiseCreationDate && (
          <Col span={1}>
            <img src={PromisedTransactionIcon} />
          </Col>
        )} */}
        <Col
          span={6}
          className={`${
            transaction.promiseCreationDate
              ? "promise-created-image-container-transaction"
              : ""
          }`}
        >
          {transaction.promiseCreationDate &&
            Number(transaction.isPromiseFulfilled) !== 1 && (
              <img
                src={PromisedTransactionIcon}
                className="promise-created-icon-in-transaction"
              />
            )}

          <DetailsItem
            label="Transaction type"
            value={transaction.transactionMessage}
            valueClass="payment-purpose"
          />
        </Col>
        <Col span={4}>
          <DetailsItem
            label="Connection Type"
            value={`${transaction.connectionType}`}
            valueClass="user-name-label"
          />
        </Col>
        <Col span={2}>
          <DetailsItem
            label="Amount"
            value={`+${transaction.inAmount}`}
            valueClass="in-amount-label"
          />
        </Col>
        <Col span={4}>
          <DetailsItem
            label="Transaction Date"
            value={`${formatDate(new Date(transaction.dateCreated))}`}
            valueClass="recharge-date-value"
          />
        </Col>
        <Col span={3}>
          <DetailsItem
            label="User Name"
            value={transaction.username ? transaction.username : "-"}
            valueClass="user-name-label"
          />
        </Col>

        <Col span={3}>
          <DetailsItem
            label="Staff"
            value={`${transaction.firstName} ${transaction.lastName} (${transaction.headType})`}
            valueClass="user-name-label"
          />
        </Col>

        <Col className="view-icons-container" span={2}>
          <img
            className="cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              navigate(
                `/transactions/transaction-details/${transaction.intPkTransactionHeadID}/${transaction.isRefunded}`
              );
            }}
            src={ViewSoaIcon}
          />
          {transaction.intPkTransactionReceiptID && (
            <img
              onClick={(e) => {
                e.preventDefault();
                navigate(
                  `/receipts/receipt-details/${transaction.intPkTransactionReceiptID}`
                );
              }}
              className="cursor-pointer"
              src={ViewReceiptIconBlue}
            />
          )}
          {staffHeadId === transaction.intPkHeadID && (
            <Dropdown
              menu={{
                items: items,
                onClick: handleMenuClick,
              }}
              placement="bottomRight"
            >
              <img
                onClick={(e) => {}}
                className="cursor-pointer"
                src={ThreeDotsVertical}
              />
            </Dropdown>
          )}
        </Col>
      </Row>
    </>
  );
};
export default SingleTransactionCard;
