import React, { useState } from "react";
import { Col, Dropdown, Row, Table, Typography } from "antd";
import type { MenuProps, TableProps } from "antd";
import { formatDate } from "../../../helpers/date";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import { getUserType } from "../../../helpers/userDetails";
import UpdateDealerModal from "../../Modals/UpdateDealerModal";

const CompanyDealersTable: React.FC<CompanyDealersTableProp> = ({
  dealers,
  reloadApi,
}) => {
  const navigate = useNavigate();
  const [activeRow, setActiveRow] = useState<CompanyDealer | null>(null);
  const [show, setShow] = useState<boolean>(false);
  const dealerOptions: MenuProps["items"] = [
    {
      key: "VIEW_PROFILE",
      label: (
        <Typography className="profile-drop-down-item">View Profile</Typography>
      ),
    },
  ];
  if (getUserType() === "CEO") {
    dealerOptions.push({
      key: "UPDATE_PROFILE",
      label: (
        <Typography className="profile-drop-down-item">
          Update Profile
        </Typography>
      ),
    });
  }
  const handleMenuClick = (e: any) => {
    if (activeRow === null) {
      return;
    }
    switch (e.key) {
      case "VIEW_PROFILE":
        navigate(`/dealer-profile/${activeRow.intPkHeadID}`);
        break;
      case "UPDATE_PROFILE":
        setShow(true);
        break;
    }
  };
  const columns: TableProps<CompanyDealer>["columns"] = [
    {
      title: "fullName",
      render: (row) => <span>{`${row.firstName} ${row.lastName}`}</span>,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Created",
      dataIndex: "dateCreated",
      key: "dateCreated",
      render: (text) => <span>{formatDate(new Date(text))}</span>,
    },
    {
      title: "Action",
      render: (row) => (
        <Dropdown
          menu={{
            items: dealerOptions,

            onClick: handleMenuClick,
          }}
          placement="bottomRight"
        >
          <MenuOutlined
            onMouseEnter={(e) => {
              setActiveRow(row);
            }}
            className="cursor-pointer"
          />
        </Dropdown>
      ),
    },
  ];
  const handleClose = (isReload: boolean) => {
    setShow(false);
    if (isReload) {
      //let's reload
      reloadApi();
    }
  };

  return (
    <>
      {show === true && activeRow && (
        <UpdateDealerModal
          show={show}
          handleClose={handleClose}
          dealer={activeRow}
        />
      )}
      <div className="netwala-users-table-container">
        <Row>
          <Col span={24}>
            <Typography className="netwala-user-tables-heading">
              Company Dealers
            </Typography>
            <Table
              className="netwala-users-table striped-table"
              columns={columns}
              dataSource={dealers}
              pagination={false}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
export default CompanyDealersTable;
