import React, { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import SuperAdminLoginComponent from "../components/auth/SuperAdminLogin";
const SuperAdminLoginPage = () => {
  const { setTitle } = useContext(TitleContext);
  useEffect(() => {
    setTitle("Super Admin Login");
  }, [setTitle]);
  return (
    <>
      <SuperAdminLoginComponent />
    </>
  );
};
export default SuperAdminLoginPage;
