import { useEffect, useState } from "react";
import { Col, Row, Typography } from "antd";
import type { MenuProps } from "antd";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import PlusIcon from "./../../assets/svgs/IconsWhite/PlusIcon.svg";
import { useNavigate } from "react-router-dom";
import useAxiosPost from "../../hooks/useAxiosPost";
import "./index.css";
import { getUserType } from "../../helpers/userDetails";
const ViewISPComponent = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/create-isp");
  };
  const [isps, setIsps] = useState<ISP[]>([]);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("isps/getISPsByCompanyWithPublicIsps");
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setIsps(data || []);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({});
  }, []);
  return (
    <>
      {getUserType() === "CEO" && (
        <Row gutter={16}>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "20px",
            }}
            span={24}
          >
            <NetwalaButton
              backgroundColor={ButtonProps.background.STANDARD}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.STANDARD}
              textColor={ButtonProps.color.STANDARD}
              onClick={() => handleClick()}
              text="Create ISP"
              icon={<img src={PlusIcon} />}
            />
          </Col>
        </Row>
      )}
      <Row gutter={[16, 16]}>
        {isps.map((item) => {
          return (
            <Col key={Math.random()} span={6}>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/view-isp/${item.intPkIspID}?activeTab=1`);
                }}
                className="view-isp-card"
              >
                <img src={item.url} />
                <Typography className="isp-name-label">
                  {item.ISPName}
                </Typography>
              </div>
            </Col>
          );
        })}
      </Row>
    </>
  );
};
export default ViewISPComponent;
