import React from "react";
import NetwalaModal from "../Items/Modal/Index";
import { Col, Row, Typography } from "antd";
import "./styles/confirmInternetPackage.css";
import RechargeIcon from "./../../assets/svgs/RechargeIcon.svg";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";

const ConfirmInternetConnectionCreationModal: React.FC<
  confirmInternetConnectionModalProps
> = ({ show, handleClose, confirmationData }) => {
  return (
    <>
      <NetwalaModal
        title="Connection Confirmation"
        headerStyle={{
          backgroundColor: "#292D8E",
          color: "#FFFFFF",
          fontSize: "16px",
          fontWeight: "400",
          height: "50px",
        }}
        open={show}
        onCancel={() => {
          handleClose(false);
        }}
      >
        <Row>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              gap: "20px",
              alignItems: "center",
              paddingLeft: "20px",
              paddingBottom: "30px",
            }}
            span={24}
          >
            <Row className="confirm-connection-top-row">
              <Col offset={2} span={10}>
                <Typography className="confirmation-connection-label">
                  First Name
                </Typography>
                <Typography className="confirmation-connection-value">
                  {confirmationData.firstName}
                </Typography>
              </Col>
              <Col offset={4} span={8}>
                <Typography className="confirmation-connection-label">
                  Last Name
                </Typography>
                <Typography className="confirmation-connection-value">
                  {confirmationData.lastName}
                </Typography>
              </Col>
            </Row>
            <Row className="confirm-connection-top-row">
              <Col offset={2} span={10}>
                <Typography className="confirmation-connection-label">
                  Phone Number
                </Typography>
                <Typography className="confirmation-connection-value">
                  {confirmationData.phoneNumber}
                </Typography>
              </Col>
            </Row>
            <Row className="confirm-connection-top-row">
              <Col offset={2} span={10}>
                <Typography className="confirmation-connection-label">
                  UserName
                </Typography>
                <Typography className="confirmation-connection-value">
                  {confirmationData.username}
                </Typography>
              </Col>
              <Col offset={4} span={8}>
                <img
                  style={{ maxWidth: "50px", maxHeight: "50px" }}
                  src={confirmationData.ispUrl}
                />
              </Col>
            </Row>
            <Row className="confirmation-connection-header-blue">
              <Col offset={2} span={10}>
                <Typography className="confirmation-connection-label">
                  Area
                </Typography>
              </Col>
              <Col offset={4} span={8}>
                <Typography className="confirmation-connection-label">
                  Sub Area
                </Typography>
              </Col>
            </Row>
            <Row className="confirmation-connection-header-values">
              <Col offset={2} span={10}>
                <Typography className="confirmation-connection-value">
                  {confirmationData.area}
                </Typography>
              </Col>
              <Col offset={4} span={8}>
                <Typography className="confirmation-connection-value">
                  {confirmationData.subArea}
                </Typography>
              </Col>
            </Row>

            <Row className="confirmation-connection-header-blue">
              <Col offset={2} span={10}>
                <Typography className="confirmation-connection-label">
                  Address
                </Typography>
              </Col>
              <Col offset={4} span={8}>
                <Typography className="confirmation-connection-label">
                  Password
                </Typography>
              </Col>
            </Row>
            <Row className="confirmation-connection-header-values">
              <Col offset={2} span={10}>
                <Typography className="confirmation-connection-value">
                  {confirmationData.address}
                </Typography>
              </Col>
              <Col offset={4} span={8}>
                <Typography className="confirmation-connection-value">
                  {confirmationData.password}
                </Typography>
              </Col>
            </Row>

            <Row className="confirmation-connection-header-blue">
              <Col offset={2} span={10}>
                <Typography className="confirmation-connection-label">
                  Package
                </Typography>
              </Col>
              <Col offset={4} span={8}>
                <Typography className="confirmation-connection-label">
                  VLAN ID
                </Typography>
              </Col>
            </Row>
            <Row className="confirmation-connection-header-values">
              <Col offset={2} span={10}>
                <Typography className="confirmation-connection-value">
                  {confirmationData.packageName}
                </Typography>
              </Col>
              <Col offset={4} span={8}>
                <Typography className="confirmation-connection-value">
                  {confirmationData.vlan}
                </Typography>
              </Col>
            </Row>

            <Row className="confirmation-connection-header-blue">
              <Col offset={2} span={10}>
                <Typography className="confirmation-connection-label">
                  Payment Detail
                </Typography>
              </Col>
            </Row>
            <Row className="confirmation-connection-header-values">
              <Col offset={2} span={10}>
                <Typography className="confirmation-connection-value">
                  Package Price{" "}
                </Typography>
              </Col>
              <Col offset={4} span={8}>
                <Typography className="confirmation-connection-value">
                  {confirmationData.packagePrice}
                </Typography>
              </Col>
            </Row>
            <div className="total-payment-details-confirmation-connection-container">
              <Typography className="total-amount-receipt-label">
                Total Amount
              </Typography>{" "}
              <Typography className="total-amount-receipt-value">
                {confirmationData.packagePrice}
              </Typography>
            </div>

            <div className="confirmation-action-queue-container">
              <NetwalaButton
                backgroundColor={ButtonProps.background.DANGER}
                height={ButtonProps.height.STANDARD}
                width={ButtonProps.width.ONE24}
                textColor={ButtonProps.color.STANDARD}
                onClick={() => handleClose(true)}
                text="Confirm"
                borderColor="#DF3131"
              />
              <NetwalaButton
                backgroundColor={ButtonProps.background.SECONDARY}
                height={ButtonProps.height.STANDARD}
                width={ButtonProps.width.ONE24}
                textColor={ButtonProps.color.BLACK}
                onClick={() => handleClose(false)}
                text="Cancel"
                //   icon={<img src={PlusIcon} />}
                borderColor="#D7D7D7"
              />
            </div>
          </Col>
        </Row>
      </NetwalaModal>
    </>
  );
};

export default ConfirmInternetConnectionCreationModal;
