import React from "react";
import "./styles.css";

const LogoMakerPrivacyPolicyPage = () => {
  return (
    <div className="policy-page">
      <header className="privacy-policy-main-heading">
        <h1>Privacy Policy for Logo Maker App</h1>
      </header>
      <section className="policy-section">
        <h2>Introduction</h2>
        <p>
          This Privacy Policy describes how we collects, uses, and discloses
          your information in connection with your use of our logo maker
          application.
        </p>
      </section>
      <section className="policy-section">
        <h2>Information We Collect</h2>
        <p>We collect two main types of information through the App:</p>
        <p>
          Non-Personal Information: This includes information that cannot be
          used to identify you directly, such as:
        </p>
        <p>
          Device Information: Your device model, operating system type and
          version, unique identifiers, and language.
        </p>
        <p>
          Usage Data: How you interact with the App, features used, and design
          elements chosen.
        </p>
        <p>
          Logo Design Data: Colors, fonts, and shapes used in your logo
          creations (but not the actual logo content itself).
        </p>
      </section>
      <section className="policy-section">
        <h2>How We Use Your Information</h2>
        <p>We use the information we collect for the following purposes:</p>
        <p>
          To provide and improve the App: We use device information and usage
          data to ensure the App functions properly, personalize your
          experience, and improve overall functionality.
        </p>
        <p>
          To provide customer support: We may use your information to respond to
          your inquiries and provide customer support.
        </p>
        <p>
          For analytics and improvement: We use anonymized data on usage and
          logo design elements to analyze trends and improve the App's features
          and design tools.
        </p>
      </section>
      <section className="policy-section">
        <h2>Logo Design Data</h2>
        <p>
          We do not store the actual content of your logo designs. We only store
          references to the design elements (colors, fonts, shapes) used in your
          creations.
        </p>
        <p>You can choose to export your logo designs for your own use.</p>
      </section>

      <section className="policy-section">
        <h2>Changes to This Policy</h2>
        <p>
          Our App is not directed to children under the age of 13. We do not
          knowingly collect personal information from children under 13. If you
          are a parent or guardian and you believe your child has provided us
          with personal information, please contact us.
        </p>
      </section>
      <section className="policy-section">
        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at fastestsecureapps@gmail.com.
        </p>
      </section>
      <footer className="privacy-policy-footer">
        <p>Last Updated: March 1, 2024</p>
      </footer>
    </div>
  );
};

export default LogoMakerPrivacyPolicyPage;
