import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import UserReceiptsComponent from "../components/SingleUser/components/UserReceipts";

const UserTransactionReceiptsPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Transaction Receipts");
  }, [setTitle]);
  return (
    <>
      <UserReceiptsComponent />
    </>
  );
};
export default UserTransactionReceiptsPage;
