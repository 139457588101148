import { useEffect, useState } from "react";
import { Col, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import RouterIcon from "./../../assets/svgs/inventoryItemsIcon/Routers.svg";
import SwitchIcon from "./../../assets/svgs/inventoryItemsIcon/Switches.svg";
import CableIcon from "./../../assets/svgs/inventoryItemsIcon/Cables.svg";
import "./index.css";
const ViewInventoryItems = () => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [items, setItems] = useState<InventoryItem[]>([]);
  const navigate = useNavigate();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("inventory/getInventoryItems");

  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setItems(data || []);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({});
  }, []);
  const getSvg = (itemType: string) => {
    switch (itemType) {
      case "ROUTER":
        return <img className="inventory-item-icon" src={RouterIcon} />;
      case "SWITCH":
        return <img src={SwitchIcon} />;
      case "BOX":
        return <img src={SwitchIcon} />;
      default:
        return <img src={CableIcon} />;
    }
  };
  return (
    <>
      {contextHolder}

      <Row gutter={[16, 16]}>
        {items.map((item) => {
          return (
            <Col span={6}>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  navigate(
                    `/inventory-item-details/${item.intPkInventoryItemID}`
                  );
                }}
                className="inventory-single-item-container"
              >
                {getSvg(item.inventoryItemType)}
                <Typography className="inventory-item-name">
                  {item.inventoryItemType}
                </Typography>
              </div>
            </Col>
          );
        })}
      </Row>
    </>
  );
};
export default ViewInventoryItems;
