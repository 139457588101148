import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import SingleReceiptCard from "../components/ReceiptDetails/SingleReceiptCard";

const SingleReceiptDetailsPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("View Receipt");
  }, [setTitle]);
  return (
    <>
      <SingleReceiptCard />
    </>
  );
};
export default SingleReceiptDetailsPage;
