import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Progress, Switch } from "antd";

import "./index.css";
import SingleDetailsRowWithLabelAndValue from "../../connectionDetails/cards/SingleDetailsRowWithLabelAndValue";
import UserBlue from "./../../../assets/svgs/UserProfileIcons/UserProfileBlue.svg";
import CNICBlue from "./../../../assets/svgs/UserProfileIcons/CNICBlue.svg";
import DesignationBlue from "./../../../assets/svgs/UserProfileIcons/DesignationBlue.svg";
import PasswordBlue from "./../../../assets/svgs/UserProfileIcons/PasswordBlue.svg";
import ShutDownBlue from "./../../../assets/svgs/UserProfileIcons/ShutDownBlue.svg";
import WalletBlue from "./../../../assets/svgs/UserProfileIcons/WalletBlue.svg";
import useAxiosPost from "../../../hooks/useAxiosPost";
import useMultiNotification from "../../../hooks/useNotification";
import UpdateStaffStatusConfirmationModal from "../../Modals/UpdateStaffStatusModal";
import UpdateStaffPasswordModal from "../../Modals/UpdateStaffPasswordModal";
const StaffProfileTabCard: React.FC<StaffListMiniCardProp> = ({
  staff,
  staffApiReload,
  isStaff,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();

  const [selectedOption, setSelectedOption] = useState<boolean>(
    Boolean(staff.enable)
  );
  const [
    postUpdateProfileApi,
    updateProfileApiResponse,
    updateProfileApiError,
    updateProfileApiLoading,
    updateProfileApiMessage,
    updateProfileApiErrorCount,
  ] = useAxiosPost<UpdateStaffStatusApiProp>("auth/updateMyStaffStatus", true);
  const [show, setShow] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const onChange = (checked: boolean) => {
    setSelectedOption(checked);
    setShow(true);
  };
  const handleClick = () => {
    setShowPassword(true);
  };
  const handleUpdateStatus = (isChange: boolean) => {
    if (isChange === true) {
      //lets call the api
      postUpdateProfileApi({
        enable: Number(selectedOption),
        intPkHeadID: staff.intPkHeadID,
      });
    }
    setShow(false);
  };
  useEffect(() => {
    if (updateProfileApiResponse !== null) {
      const { message } = updateProfileApiResponse;
      if (message) {
        staffApiReload(true);
        openNotification(`success`, "Success", message);
      }
    }
  }, [updateProfileApiResponse]);
  useEffect(() => {
    if (updateProfileApiError !== null && updateProfileApiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", updateProfileApiError);
    }
  }, [updateProfileApiError, updateProfileApiErrorCount]);
  const handleClose = (isReload: boolean) => {
    if (isReload) {
      staffApiReload(true);
    }
    setShowPassword(false);
  };
  return (
    <>
      {show && (
        <UpdateStaffStatusConfirmationModal
          show={show}
          handleClose={handleUpdateStatus}
        />
      )}
      {showPassword && (
        <UpdateStaffPasswordModal
          show={showPassword}
          handleClose={handleClose}
          intPkHeadID={staff.intPkHeadID}
          apiUrl="auth/updateMyStaffPassword"
        />
      )}

      {contextHolder}
      <Row className="staff-profile-tab-container">
        <SingleDetailsRowWithLabelAndValue
          value={`${staff.phoneNumber}`}
          label="User Phone"
          icon={UserBlue}
        />
        <SingleDetailsRowWithLabelAndValue
          value={`${staff.staffUserName}`}
          label="Username"
          icon={DesignationBlue}
        />
        {/* <SingleDetailsRowWithLabelAndValue
          value={`${staff.nicNumber ? staff.nicNumber : "None"}`}
          label="CNIC Number"
          icon={CNICBlue}
        /> */}
        <SingleDetailsRowWithLabelAndValue
          value={`${staff.headType}`}
          label="Designation"
          icon={DesignationBlue}
          isBorderBottom={isStaff}
        />

        {isStaff === true && (
          <>
            {["RMAN", "CEO", "CASHIER"].includes(staff.headType) && (
              <>
                <SingleDetailsRowWithLabelAndValue
                  value={`${staff.walletLimit} Rs`}
                  label="Wallet Limit"
                  icon={WalletBlue}
                />
                <SingleDetailsRowWithLabelAndValue
                  value={`${staff.walletDays} Days`}
                  label="Wallet Days"
                  icon={WalletBlue}
                />
              </>
            )}
            <SingleDetailsRowWithLabelAndValue
              value={`Change Password`}
              label="Password"
              icon={PasswordBlue}
              isUnderline
              onClick={handleClick}
            />
            <SingleDetailsRowWithLabelAndValue
              value={
                <span>
                  {" "}
                  <Switch
                    checked={Boolean(staff.enable)}
                    onChange={onChange}
                  />{" "}
                  Active
                </span>
              }
              label="User Status"
              icon={ShutDownBlue}
              isBorderBottom={false}
              isReactNode={true}
            />
          </>
        )}
      </Row>
    </>
  );
};
export default StaffProfileTabCard;
