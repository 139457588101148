import React, { useState } from "react";
import type { PaginationProps } from "antd";
import { Pagination } from "antd";

const NetwalaPagination: React.FC<NetwalaPagination> = ({
  pagination,
  handlePageSwitch,
}) => {
  const onChange: PaginationProps["onChange"] = (page) => {
    handlePageSwitch(page);
  };

  return (
    <Pagination
      current={pagination.currentPage}
      onChange={onChange}
      total={pagination.totalCount}
      showSizeChanger={false}
      pageSize={pagination.pageSize}
    />
  );
};

export default NetwalaPagination;
