import { useEffect, useState } from "react";
import { Row, Col, Typography, Dropdown } from "antd";
import type { MenuProps } from "antd";

import NetwalaTabs from "../Items/Tab/Index";
import UserProfile from "./components/UserProfile";
import UserConnections from "./components/UserConnections";
import UserTransactions from "./components/UserTransactions";
import UserTicketsList from "./components/UserTicketsList";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import OptionsIcon from "./../../assets/svgs/HamburgerBlue.svg";
import CATVBlue from "./../../assets/svgs/CATVBlue.svg";
import WifiIcon from "./../../assets/svgs/INTERNETIcon.svg";
const SingleUserDetailsComponent = () => {
  const [searchParams] = useSearchParams();
  const _activeTab = searchParams.get("activeTab");
  const fullName = searchParams.get("fullName");

  const { intPkHeadID } = useParams();
  const navigate = useNavigate();
  const [tabs, setTabs] = useState<NetwalaTabItemsProp[]>([
    {
      text: "Profile",
      isActive: true,
      id: 1,
    },
    {
      text: "Connections",
      isActive: false,
      id: 2,
    },

    {
      text: "Transactions",
      isActive: false,
      id: 3,
    },
    // {
    //   text: "Complaints",
    //   isActive: false,
    //   id: 4,
    // },
  ]);
  const [activeTab, setActiveTab] = useState<number>(Number(_activeTab));
  const handleTabSwitch = (tabId: number) => {
    navigate(
      `/user-details/${intPkHeadID}?activeTab=${tabId}&fullName=${fullName}`
    );
    // const updatedItems = tabs.map((item) => ({
    //   ...item,
    //   isActive: item.id === tabId,
    // }));
    // setTabs(updatedItems);
    // setActiveTab(tabId);
  };
  useEffect(() => {
    setActiveTab(Number(_activeTab));
    const updatedItems = tabs.map((item) => ({
      ...item,
      isActive: item.id === Number(_activeTab),
    }));
    setTabs(updatedItems);
  }, [activeTab, _activeTab]);
  const showActiveComponent = (activeTab: number) => {
    switch (activeTab) {
      case 1:
        return <UserProfile />;
      case 2:
        return <UserConnections />;
      case 3:
        return <UserTransactions />;
      case 4:
        return <UserTicketsList />;
    }
    return <></>;
  };
  const connectionOptionItems: MenuProps["items"] = [
    {
      key: "CATV",
      label: (
        <Typography className="profile-drop-down-item">
          <img src={CATVBlue} />
          Create CATV Connection
        </Typography>
      ),
    },
    {
      key: "INTERNET",
      label: (
        <Typography className="profile-drop-down-item">
          <img src={WifiIcon} />
          Create Internet Connection
        </Typography>
      ),
    },
  ];
  const handleMenuClick = (e: any) => {
    if (e.key === "INTERNET") {
      //intPkHeadID
      navigate(`/connections/create-internet/${intPkHeadID}`);
    } else if (e.key === "CATV") {
      navigate(`/connections/create-catv/${intPkHeadID}`);
    }
  };
  return (
    <>
      <Row gutter={[16, 16]}>
        {activeTab === 2 ? (
          <Col
            span={24}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "50px",
              marginBottom: "20px",
            }}
          >
            <Dropdown
              menu={{
                items: connectionOptionItems,

                onClick: handleMenuClick,
              }}
              placement="bottomLeft"
            >
              <img className="cursor-pointer" src={OptionsIcon} />
            </Dropdown>
          </Col>
        ) : null}
      </Row>
      <Row gutter={16}>
        <NetwalaTabs
          colSpan={8}
          tabs={tabs}
          handleTabSwitch={handleTabSwitch}
        />
      </Row>
      <Row gutter={[16, 16]}>{showActiveComponent(activeTab)}</Row>
    </>
  );
};
export default SingleUserDetailsComponent;
