import { useEffect, useState } from "react";
import { Row, Col, Typography, Form, Input, Card, Select, Button } from "antd";
import ConnectionProfileDetailsLeftCard from "./cards/ConnectionProfileDetailsLeftCard";
import useAxiosPost from "../../hooks/useAxiosPost";
import { useParams } from "react-router-dom";
import ConnectionProfileDetailsRightCard from "./cards/ConnectionProfileDetailsRightCard";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import NetwalaPagination from "../Items/Pagination/NetwalaPagination";
import SingleConnectionInvoiceCard from "./cards/SingleInvoiceCard";
const ConnectionInvoicesComponent = () => {
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ConnectionUserDetailsProp>(
    "invoices/getInvoicesByConnection"
  );
  const [invoices, setInvoices] = useState<ConnectionInvoice[]>([]);
  const [pagination, setPagination] = useState<PaginationProp>({
    totalPages: 0,
    currentPage: 1,
    previousPage: null,
    nextPage: null,
    totalCount: 0,
    pageSize: 50,
  });

  const { intPkConnectionID } = useParams();
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      const {
        currentPage,
        nextPage,
        pageSize,
        previousPage,
        totalCount,
        totalPages,
        invoices,
      } = data;
      setPagination({
        currentPage,
        totalPages,
        nextPage,
        previousPage,
        totalCount,
        pageSize,
      });
      setInvoices(invoices || []);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({ intPkConnectionID: Number(intPkConnectionID) });
  }, []);
  const handleReload = () => {
    postApiData({ intPkConnectionID: Number(intPkConnectionID) });
  };
  const handlePageSwitch = (pageNumber: number) => {
    postApiData({
      intPkConnectionID: Number(intPkConnectionID),
      page: pageNumber,
    });
  };
  return (
    <>
      {apiLoading === true ? (
        <TailSpinLoader />
      ) : (
        <>
          <Row gutter={[16, 16]} className="end-user-profile-container">
            {invoices.map((item) => {
              return (
                <Col key={Math.random()} span={24}>
                  <SingleConnectionInvoiceCard
                    key={Math.random()}
                    invoice={item}
                    reloadApi={handleReload}
                  />
                </Col>
              );
            })}
          </Row>
          <Row style={{ width: "100%" }}>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
                marginBottom: "20px",
              }}
              span={24}
            >
              <NetwalaPagination
                pagination={pagination}
                handlePageSwitch={handlePageSwitch}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
export default ConnectionInvoicesComponent;
