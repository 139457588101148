import { useContext, useEffect } from "react";

import CreateInternetForm from "../components/connections/CreateInternet";
import TitleContext from "../context/TitleContext";

const CreateInternetPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Create Internet Connection");
  }, [setTitle]);
  return (
    <>
      <CreateInternetForm />
    </>
  );
};
export default CreateInternetPage;
