import React, { useState, useEffect, useRef } from "react";

import { Col, Input, Row, Table, Tag, Typography } from "antd";
import type { TableProps } from "antd";
import type { SearchProps } from "antd/es/input/Search";

const { Search } = Input;
import "./index.css";
import IPWhiteListIcon from "./../../../assets/svgs/IPWhiteList.svg";
import IPTVIcon from "./../../../assets/svgs/IPTVIcon.svg";
import CATVIcon from "./../../../assets/svgs/CATVIcon.svg";
import INTERNETIcon from "./../../../assets/svgs/INTERNETIcon.svg";
import PromisedTransactionIcon from "./../../../assets/svgs/PromisedTransactionIcon.svg";
import { useNavigate } from "react-router-dom";
import NetwalaPagination from "../Pagination/NetwalaPagination";
import TailSpinLoader from "../Loaders/TailSpinLoader";
import { formatDateForJustMonthDayAndYear } from "../../../helpers/date";

const NetwalaUsersTable: React.FC<UsersTableProp> = ({
  users,
  pagination,
  handlePageSwitch,
  isLoading,
  handleSearch,
}) => {
  const debouncedSearch = useRef<((query: string) => void) | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const navigate = useNavigate();
  const columns: TableProps<CompanyConnection>["columns"] = [
    {
      title: "Full Name",
      // dataIndex: "intPkHeadID",
      // key: "intPkHeadID",
      render: (row) => (
        <span style={{ display: "flex" }}>
          {row.totalOpenPromises > 0 ? (
            <img
              src={PromisedTransactionIcon}
              style={{ maxWidth: "20px", marginLeft: "-10px" }}
              className="cursor-pointer"
            />
          ) : null}{" "}
          <span>{row.fullName}</span>
        </span>
      ),
    },
    {
      title: "UserName",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (text) => <Tag style={{ fontWeight: "bold" }}>{text} </Tag>,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "expiryDate",
      dataIndex: "expiryDate",
      render: (text) => (
        <span>{formatDateForJustMonthDayAndYear(new Date(text))}</span>
      ),
    },
    {
      title: "Type",
      dataIndex: "",
      render: (row) => {
        return (
          <div className="users-table-action-container">
            {/* <img src={TicketsIcon} className="cursor-pointer" /> */}
            {row.connectionType === "CATV" ? (
              <img src={CATVIcon} className="cursor-pointer" />
            ) : null}
            {row.connectionType === "INTERNET" ? (
              <img src={INTERNETIcon} className="cursor-pointer" />
            ) : null}
            {row.totalIPTVServices > 0 ? (
              <img src={IPTVIcon} className="cursor-pointer" />
            ) : null}
            {row.totalStaticIpServices > 0 ? (
              <img src={IPWhiteListIcon} className="cursor-pointer" />
            ) : null}
          </div>
        );
      },
    },
  ];
  const onSearch: SearchProps["onSearch"] = (value, _e, info) => {
    console.log(info?.source, value);
    if (value.length < 3) {
      return;
    }
    handleSearch(value, 1);
  };

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      handleSearch(searchTerm, 1);
    }, 500);

    return () => clearTimeout(delaySearch); // Cleanup the timeout on component unmount
  }, [searchTerm]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="netwala-users-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <div className="users-search-container">
              <Typography className="netwala-user-tables-heading">
                All Connections
              </Typography>
              <Search
                placeholder="Name / Username / phone Number"
                onSearch={onSearch}
                value={searchTerm}
                allowClear
                onChange={handleChange}
                style={{ width: 300 }}
              />
            </div>
            <Table
              className="netwala-users-table striped-table"
              columns={columns}
              dataSource={users}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    navigate(
                      `/user-connection-details/${record.intPkHeadID}/${record.intPkConnectionID}?activeTab=1&fullName=${record.fullName}`
                    );
                  }, // click row
                };
              }}
              // pagination={{
              //   pageSize: 10,
              //   showQuickJumper: true,
              //   showSizeChanger: true,
              //   pageSizeOptions: ["10", "20", "30", "40"],
              // }}
              pagination={false}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
              marginBottom: "20px",
            }}
            span={24}
          >
            <NetwalaPagination
              pagination={pagination}
              handlePageSwitch={handlePageSwitch}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
export default NetwalaUsersTable;
