import React from "react";
import NetwalaModal from "../Items/Modal/Index";
import { Row, Typography } from "antd";
import "./styles/RequestIPTVModal.css";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
const RefundConfirmationModal: React.FC<GeneralModalProps> = ({
  show,
  handleClose,
}) => {
  return (
    <>
      <NetwalaModal
        title="Refund Transaction"
        headerStyle={{
          backgroundColor: "#292D8E",
          color: "#FFFFFF",
          fontSize: "16px",
          fontWeight: "400",
          height: "50px",
        }}
        open={show}
        onCancel={() => {
          handleClose(false);
        }}
      >
        <Row>
          <div className="queue-confirmation-message-container">
            <Typography className="queue-confirmation-message">
              Would You Like To Refund This Transaction?
            </Typography>
          </div>
          <div className="confirmation-action-queue-container">
            <NetwalaButton
              backgroundColor={ButtonProps.background.DANGER}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.ONE24}
              textColor={ButtonProps.color.STANDARD}
              onClick={() => handleClose(true)}
              text="Yes"
              borderColor="#DF3131"
            />
            <NetwalaButton
              backgroundColor={ButtonProps.background.SECONDARY}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.ONE24}
              textColor={ButtonProps.color.BLACK}
              onClick={() => handleClose(false)}
              text="No"
              //   icon={<img src={PlusIcon} />}
              borderColor="#D7D7D7"
            />
          </div>
        </Row>
      </NetwalaModal>
    </>
  );
};

export default RefundConfirmationModal;
