import React, { useContext, useEffect } from "react";
import TitleContext from "../../context/TitleContext";
import CreateMikroNocISPForm from "../../components/superAdmin/CreateMikroNocIspForCompany";
const CreateMikroNocISPPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Create MikroNoc ISP");
  }, [setTitle]);

  return (
    <>
      <CreateMikroNocISPForm />
    </>
  );
};
export default CreateMikroNocISPPage;
