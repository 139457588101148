import React, { useEffect, useState } from "react";
import NetwalaModal from "../Items/Modal/Index";
import { Col, Row } from "antd";
import "./styles/RequestIPTVModal.css";
// import NetwalaButton from "../Items/Button/Index";
import useAxiosPost from "../../hooks/useAxiosPost";
// import { ButtonProps } from "../../helpers/constants";
const ChoosePackageColorModal: React.FC<ChoosePackageColorModalProps> = ({
  show,
  handleClose,
  handleSelectedColor,
  packageName,
}) => {
  const [
    getPackageIconAssetsApi,
    PackageIconAssetsApiResponse,
    PackageIconAssetsApiError,
    PackageIconAssetsApiLoading,
    PackageIconAssetsApiMessage,
    PackageIconAssetsApiErrorCount,
  ] = useAxiosPost<ResponseProp>("packages/getPackageColors");
  useEffect(() => {
    getPackageIconAssetsApi({});
  }, []);
  const [colors, setColors] = useState<any[]>([]);
  useEffect(() => {
    if (PackageIconAssetsApiResponse !== null) {
      const data: any = PackageIconAssetsApiResponse.responseData;
      setColors(data);
    }
  }, [PackageIconAssetsApiResponse]);

  return (
    <>
      <NetwalaModal
        title="Choose Package Color"
        headerStyle={{
          backgroundColor: "#292D8E",
          color: "#FFFFFF",
          fontSize: "16px",
          fontWeight: "400",
          height: "50px",
        }}
        open={show}
        onCancel={() => handleClose(false)}
      >
        <Col span={16} offset={4}>
          <Row style={{ padding: "20px", width: "100%" }} gutter={[16, 16]}>
            {colors.map((item: any) => {
              return (
                <Col span={12} key={Math.random()}>
                  <div
                    onClick={(e) => {
                      handleSelectedColor(item);
                      handleClose(true);
                    }}
                    className="package-color-container"
                    style={{
                      background: item.bgColor,
                      border: "1px solid",
                      borderColor: item.borderColor,
                      color: item.color,
                    }}
                  >
                    {" "}
                    {packageName}
                  </div>
                </Col>
              );
            })}
          </Row>
        </Col>
      </NetwalaModal>
    </>
  );
};

export default ChoosePackageColorModal;
