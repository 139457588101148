import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Dropdown, Checkbox } from "antd";
import "./transactions.css";
import type { CheckboxProps } from "antd";
import { formatDate } from "../../../../helpers/date";
// import ThreeDotsVertical from "./../../../../assets/svgs/ThreeDotsVertical.svg";
// import PromiseIcon from "./../../../../assets/svgs/PromiseIcon.svg";
import { useNavigate } from "react-router-dom";
// import type { MenuProps } from "antd";
// import UpdateOrCreatePromiseModal from "../../../Modals/UpdateOrCreatePromiseModal";
import MarkTransactionAsRechargedConfirmationModal from "../../../Modals/MarkTransactionAsRechargedConfirmationModal";
import useAxiosPost from "../../../../hooks/useAxiosPost";
import useMultiNotification from "../../../../hooks/useNotification";

interface TransactionRowItemProp {
  label: string;
  value: string;
  valueClass: string;
}
const DetailsItem: React.FC<TransactionRowItemProp> = ({
  label,
  value,
  valueClass,
}) => {
  return (
    <div className="transaction-single-item-col">
      <Typography className="item-label">{label}</Typography>
      <Typography className={`${valueClass}`}>{value}</Typography>
    </div>
  );
};
const DealerSingleTransactionCard: React.FC<
  DealerTransactionSingleCardProp
> = ({ transaction, staffHeadId, reloadApi }) => {
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<MarkDealerTransactionAsRechargedApiProp>(
    "invoices/markDealerTransactionAsRecharged"
  );
  const { openNotification, contextHolder } = useMultiNotification();

  console.log({ staffHeadId, transaction });
  const [show, setShow] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(
    Boolean(transaction.isDealerTransactionRecharged)
  );
  const [showRechargeConfirmation, setShowRechargeConfirmation] =
    useState<boolean>(false);
  const navigate = useNavigate();

  const handleClose = (isReload: boolean) => {
    setShow(false);
    if (isReload === true) {
      //lets reload the main api
      reloadApi();
    }
  };
  const onChange: CheckboxProps["onChange"] = (e) => {
    setIsChecked(e.target.checked);
    if (e.target.checked === true) {
      console.log(`checked = ${e.target.checked}`);
      setShowRechargeConfirmation(true);
    }
  };
  const handleConfirmationRechargeTransaction = (confirmation: boolean) => {
    setShowRechargeConfirmation(false);
    setIsChecked(confirmation);
    if (confirmation === true) {
      postApiData({
        intPkTransactionID: transaction.intPkTransactionID,
      });
    }
  };
  useEffect(() => {
    if (apiResponse !== null) {
      const { message } = apiResponse;
      if (message) {
        openNotification(`success`, "Success", message);
        reloadApi();
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", apiError);
    }
  }, [apiError, apiErrorCount]);

  return (
    <>
      {contextHolder}
      {showRechargeConfirmation === true && (
        <MarkTransactionAsRechargedConfirmationModal
          show={showRechargeConfirmation}
          handleClose={handleConfirmationRechargeTransaction}
        />
      )}

      <Row style={{ height: "100%" }} className="transaction-container">
        <Col span={6}>
          <DetailsItem
            label="Transaction type"
            value={transaction.row_enum}
            valueClass="payment-purpose"
          />
        </Col>

        <Col span={4}>
          <DetailsItem
            label="Amount"
            value={`+${transaction.amount}`}
            valueClass="in-amount-label"
          />
        </Col>
        <Col span={6}>
          <DetailsItem
            label="Transaction Date"
            value={`${formatDate(new Date(transaction.dateCreated))}`}
            valueClass="recharge-date-value"
          />
        </Col>

        <Col span={4}>
          <DetailsItem
            label="Staff"
            value={`${transaction.firstName} ${transaction.lastName} (${transaction.headType})`}
            valueClass="user-name-label"
          />
        </Col>

        <Col className="view-icons-container" span={4}>
          {staffHeadId === transaction.staffReceived && (
            <>
              <Checkbox
                disabled={
                  transaction.isDealerTransactionRecharged === 0 ? false : true
                }
                onChange={onChange}
                checked={isChecked}
              >
                Mark Recharged
              </Checkbox>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};
export default DealerSingleTransactionCard;
