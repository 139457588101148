import { useEffect, useState } from "react";
import { Col, Row, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosPost from "../../../hooks/useAxiosPost";
import StaffIcon from "./../../../assets/pngs/StaffProfile.png";
import "./../cards/SingleStaffMiniCard.css";
import DummyFront from "./../../../assets/pngs/idCards/DummyFront.png";
import DummyBack from "./../../../assets/pngs/idCards/DummyBack.png";
import NetwalaTabs from "../../Items/Tab/Index";
import StaffProfileTabCard from "./StaffProfileTabCard";
import StaffTransactionsCard from "./StaffTransactionsCard";
import NetwalaButton from "../../Items/Button/Index";
import { ButtonProps } from "../../../helpers/constants";
import TailSpinLoader from "../../Items/Loaders/TailSpinLoader";
import StaffTransactionsMiniCard from "./StaffTransactionsMiniCard";
import PanelManagerActionQueueHistory from "./PanelManagerActionQueueHistory";
import CollectStaffPaymentModal from "../../Modals/CollectStaffPaymentModal";

const ViewSingleStaffComponent = () => {
  const [showCollectionModal, setShowCollectionModal] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const { intPkHeadID } = useParams();
  const [tabs, setTabs] = useState<NetwalaTabItemsProp[]>([
    {
      text: "Profile",
      isActive: true,
      id: 1,
    },

    {
      text: "Transactions",
      isActive: false,
      id: 2,
    },
  ]);
  const [activeTab, setActiveTab] = useState(1);
  const [staff, setStaffProfile] = useState<StaffListType | null>(null);
  const [staffTransactions, setStaffTransactions] = useState<
    StaffTransaction[]
  >([]);
  const [queues, setQueues] = useState<OperatorActionQueue[]>([]);
  const [pagination, setPagination] = useState<PaginationProp>({
    totalPages: 0,
    currentPage: 1,
    previousPage: null,
    nextPage: null,
    totalCount: 0,
    pageSize: 50,
  });
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<GetStaffPropApiProp>("auth/getStaffProfile");
  const [
    getStaffTransactionsApi,
    transactionsApiResponse,
    transactionApiError,
    transactionsApiLoading,
  ] = useAxiosPost<GetStaffPropApiProp>("invoices/getMyStaffTransactions");

  const [
    getPanelHistoryApi,
    getPanelHistoryApiResponse,
    getPanelHistoryApiError,
    getPanelHistoryApiLoading,
  ] = useAxiosPost<GetStaffPropApiProp>("actionQueue/getPanelManagerHistory");
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setStaffProfile(data);
    }
  }, [apiResponse]);

  useEffect(() => {
    if (getPanelHistoryApiResponse !== null) {
      const data: any = getPanelHistoryApiResponse.responseData;
      setQueues(data.queue || []);
    }
  }, [getPanelHistoryApiResponse]);
  useEffect(() => {
    if (transactionsApiResponse !== null) {
      const data: any = transactionsApiResponse.responseData;
      const {
        currentPage,
        nextPage,
        pageSize,
        previousPage,
        totalCount,
        totalPages,
        transactions,
      } = data;
      setPagination({
        currentPage,
        totalPages,
        nextPage,
        previousPage,
        totalCount,
        pageSize,
      });

      setStaffTransactions(transactions || []);
    }
  }, [transactionsApiResponse]);
  useEffect(() => {
    postApiData({ intPkHeadID: Number(intPkHeadID) });
    getStaffTransactionsApi({ intPkHeadID: Number(intPkHeadID) });
    getPanelHistoryApi({ intPkHeadID: Number(intPkHeadID) });
  }, []);
  const handleTabSwitch = (tabId: number) => {
    setActiveTab(tabId);
    const updatedItems = tabs.map((item) => ({
      ...item,
      isActive: item.id === Number(tabId),
    }));
    setTabs(updatedItems);
  };
  const showActiveComponent = (activeTab: number, headType: string) => {
    if (!staff) {
      return <></>;
    }
    switch (activeTab) {
      case 1:
        return (
          <StaffProfileTabCard
            isStaff={true}
            staffApiReload={apiReload}
            staff={staff}
          />
        );
      case 2:
        if (transactionsApiLoading === true) {
          return <TailSpinLoader />;
        }
        if (headType === "PANEL_MANAGER") {
          return <PanelManagerActionQueueHistory queues={queues} />;
        }
        return (
          <StaffTransactionsMiniCard
            transactions={staffTransactions}
            pagination={pagination}
            handlePageSwitch={handlePageSwitch}
            intPkHeadID={Number(intPkHeadID)}
            viewType="staff"
          />
        );
    }
    return <></>;
  };
  const apiReload = (isReload: boolean) => {
    setShowCollectionModal(false);
    if (isReload === true) {
      postApiData({ intPkHeadID: Number(intPkHeadID) });
    }
  };
  const handlePageSwitch = (pageNumber: number) => {
    getStaffTransactionsApi({
      intPkHeadID: Number(intPkHeadID),
      page: pageNumber,
    });
  };

  return (
    <>
      {showCollectionModal === true && staff && (
        <CollectStaffPaymentModal
          show={showCollectionModal}
          handleClose={apiReload}
          intPkHeadID={staff.intPkHeadID}
          cashAvailable={staff.userBalance.cashAvailable}
        />
      )}
      {apiLoading === true ? (
        <TailSpinLoader />
      ) : (
        <>
          <Row gutter={[20, 20]} style={{ marginTop: "40px" }}>
            {staff && (
              <>
                {["RMAN"].includes(staff.headType) && (
                  <Col span={6} offset={18}>
                    <NetwalaButton
                      backgroundColor={ButtonProps.background.STANDARD}
                      height={ButtonProps.height.STANDARD}
                      width={ButtonProps.width.STANDARD}
                      textColor={ButtonProps.color.STANDARD}
                      onClick={() => {
                        if (staff.userBalance.cashAvailable > 0) {
                          setShowCollectionModal(true);
                        }
                      }}
                      text="Collect Payment"
                    />
                  </Col>
                )}
                <Col span={12}>
                  <div
                    className="staff-mini-card-container"
                    style={{ background: "inherit", border: "none" }}
                  >
                    <img
                      src={
                        staff.url && staff.isEncrypted === 0
                          ? staff.url
                          : StaffIcon
                      }
                      className="staff-profile-image"
                    />
                    <Typography className="staff-full-name">
                      {staff.firstName} {staff.lastName}
                    </Typography>
                    <Typography className="staff-phone-number">
                      {staff.phoneNumber}
                    </Typography>
                    <Typography className="staff-user-role">
                      {staff.headType}
                    </Typography>
                    <Typography className="">
                      <span className="staff-cash-label">Cash:</span>{" "}
                      <span className="staff-cash-value">
                        {staff.userBalance.cashAvailable}
                      </span>
                    </Typography>
                    {/* <div className="id-card-view-container">
                  <img src={DummyFront} />
                  <img src={DummyBack} />
                </div> */}
                  </div>
                  <Row gutter={[16, 16]}>
                    <Col
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                      span={24}
                    >
                      <NetwalaButton
                        backgroundColor={ButtonProps.background.STANDARD}
                        height={ButtonProps.height.STANDARD}
                        width={ButtonProps.width.STANDARD}
                        textColor={ButtonProps.color.STANDARD}
                        onClick={() => {
                          navigate(
                            `/staff-profile/update/${staff.intPkHeadID}`
                          );
                        }}
                        text="Update Staff"
                      />
                    </Col>
                  </Row>
                </Col>

                <Col span={12} style={{ marginTop: "30px" }}>
                  <Row style={{ marginBottom: "20px" }}>
                    <NetwalaTabs
                      colSpan={12}
                      tabs={tabs}
                      handleTabSwitch={handleTabSwitch}
                    />
                  </Row>

                  <Row gutter={[16, 16]}>
                    {showActiveComponent(activeTab, staff.headType)}
                  </Row>
                </Col>
              </>
            )}
          </Row>
        </>
      )}
    </>
  );
};
export default ViewSingleStaffComponent;
