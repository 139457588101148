import React, { useEffect, useState } from "react";
import { Row, Col, Typography } from "antd";

import "./index.css";
import TicketMiniCard from "./cards/TicketMiniCard";
import ComingSoonComponent from "../../coming-soon/ComingSoon";
const UserTicketsListComponent = () => {
  return (
    <>
      <ComingSoonComponent />
      {/* <Row gutter={[16, 16]} className="tickets-container">
        <Col span={12}>
          <TicketMiniCard />
        </Col>
        <Col span={12}>
          <TicketMiniCard />
        </Col>
        <Col span={12}>
          <TicketMiniCard />
        </Col>
        <Col span={12}>
          <TicketMiniCard />
        </Col>
        <Col span={12}>
          <TicketMiniCard />
        </Col>
        <Col span={12}>
          <TicketMiniCard />
        </Col>
      </Row> */}
    </>
  );
};
export default React.memo(UserTicketsListComponent);
