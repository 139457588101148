import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewSinglePackageComponent from "../components/isps/SingleIspProfile/components/packages/ViewSinglePackage";

const SinglePackagePage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Package Details");
  }, [setTitle]);
  return (
    <>
      <ViewSinglePackageComponent />
    </>
  );
};
export default SinglePackagePage;
