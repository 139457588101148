import { useContext, useEffect } from "react";
import CreateCATVForm from "../components/connections/CreateCATV";
import TitleContext from "../context/TitleContext";

const CreateCATVPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Create CATV Connection");
  }, [setTitle]);

  return (
    <>
      <CreateCATVForm />
    </>
  );
};
export default CreateCATVPage;
