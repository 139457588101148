import React, { useState, useEffect, useRef } from "react";

import { Col, Dropdown, Input, Row, Table, Tag, Typography } from "antd";
import type { TableProps } from "antd";
import type { MenuProps } from "antd";

import type { SearchProps } from "antd/es/input/Search";
import { MenuOutlined } from "@ant-design/icons";

const { Search } = Input;
import "./index.css";
import { useNavigate } from "react-router-dom";
import TailSpinLoader from "../Loaders/TailSpinLoader";
import { formatDate } from "../../../helpers/date";
import UpdateStaffPasswordModal from "../../Modals/UpdateStaffPasswordModal";

const CompanyStaffTable: React.FC<CompanyStaffTableProp> = ({
  staff,
  isLoading,
  intPkCompanyID,
}) => {
  const [activeRow, setActiveRow] = useState<CompanyStaffProp | null>(null);
  const [showPasswordUpdate, setShowPasswordUpdate] = useState<boolean>(false);
  const companyItems: MenuProps["items"] = [
    {
      key: "DISABLE_STAFF",
      label: (
        <Typography className="profile-drop-down-item">
          Disable Staff
        </Typography>
      ),
    },
    {
      key: "UPDATE_PASSWORD",
      label: (
        <Typography className="profile-drop-down-item">
          Update Staff Password
        </Typography>
      ),
    },
  ];
  // const debouncedSearch = useRef<((query: string) => void) | null>(null);
  // const [searchTerm, setSearchTerm] = useState<string>("");
  const navigate = useNavigate();
  const handleMenuClick = (e: any) => {
    if (activeRow === null) {
      return;
    }
    switch (e.key) {
      case "VIEW_STAFF":
        navigate(`/super-admin/company/view-staff/${activeRow.intPkCompanyID}`);
        break;
      case "UPDATE_PASSWORD":
        setShowPasswordUpdate(true);
        break;
    }
  };
  const columns: TableProps<CompanyStaffProp>["columns"] = [
    {
      title: "Staff Name",
      render: (row) => <span>{`${row.firstName} ${row.lastName}`}</span>,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "UserName",
      dataIndex: "staffUserName",
      key: "staffUserName",
    },
    {
      title: "Staff Role",
      dataIndex: "headType",
      key: "headType",
    },
    {
      title: "Creation Date",
      dataIndex: "dateCreated",
      key: "dateCreated",
      render: (text) => <span>{formatDate(new Date(text))}</span>,
    },
    {
      title: "Action",
      render: (row) => (
        <Dropdown
          menu={{
            items: companyItems,

            onClick: handleMenuClick,
          }}
          placement="bottomRight"
        >
          <MenuOutlined
            onMouseEnter={(e) => {
              setActiveRow(row);
            }}
            className="cursor-pointer"
          />
        </Dropdown>
      ),
    },
  ];
  // const onSearch: SearchProps["onSearch"] = (value, _e, info) => {
  //   console.log(info?.source, value);
  //   if (value.length < 3) {
  //     return;
  //   }
  //   handleSearch(value, 1);
  // };

  // useEffect(() => {
  //   const delaySearch = setTimeout(() => {
  //     handleSearch(searchTerm, 1);
  //   }, 500);

  //   return () => clearTimeout(delaySearch); // Cleanup the timeout on component unmount
  // }, [searchTerm]);
  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setSearchTerm(e.target.value);
  // };
  const handleClose = (isReload: boolean) => {
    setShowPasswordUpdate(false);
  };

  return (
    <>
      {showPasswordUpdate === true && activeRow !== null && (
        <UpdateStaffPasswordModal
          show={showPasswordUpdate}
          handleClose={handleClose}
          intPkHeadID={activeRow.intPkHeadID}
          apiUrl="superAdmin/updateStaffPassword"
        />
      )}
      <div className="netwala-users-table-container">
        {isLoading === true ? (
          <TailSpinLoader />
        ) : (
          <Row>
            <Col span={24}>
              <div className="users-search-container">
                <Typography className="netwala-user-tables-heading">
                  Company Staff ({intPkCompanyID})
                </Typography>
                {/* <Search
                placeholder="Name / Username / phone Number"
                onSearch={onSearch}
                value={searchTerm}
                allowClear
                onChange={handleChange}
                style={{ width: 300 }}
              /> */}
              </div>
              <Table
                className="netwala-users-table striped-table"
                columns={columns}
                dataSource={staff}
                // pagination={{
                //   pageSize: 10,
                //   showQuickJumper: true,
                //   showSizeChanger: true,
                //   pageSizeOptions: ["10", "20", "30", "40"],
                // }}
                pagination={false}
              />
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};
export default CompanyStaffTable;
