import React, { useContext, useEffect } from "react";
import TitleContext from "../../context/TitleContext";
import ViewInventoryItemsComponent from "../../components/superAdmin/inventory/ViewInventoryItems";
const ViewInventoryItemsPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Inventory Items");
  }, [setTitle]);

  return (
    <>
      <ViewInventoryItemsComponent />
    </>
  );
};
export default ViewInventoryItemsPage;
