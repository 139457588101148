import { Col, Form, Input, Row } from "antd";

import NetwalaSelect from "../Items/Select/Index";
import { useEffect, useState } from "react";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import PlusIcon from "./../../assets/svgs/IconsWhite/PlusIcon.svg";

import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";

const UpdateBankAccountForm = () => {
  const navigate = useNavigate();
  const { openNotification, contextHolder } = useMultiNotification();
  const { intPkHeadID } = useParams();
  const [form] = Form.useForm();
  const [rulesRequired, setRulesRequired] = useState<boolean>(true);
  const [bankProfile, setBankProfile] = useState<BankAccountList | null>(null);

  const { intPkBankAccountID } = useParams();
  const [
    postBankAccountDetailsApi,
    bankAccountDetailsApiResponse,
    bankAccountDetailsApiError,
    bankAccountDetailsApiLoading,
    bankAccountDetailsApiMessage,
    bankAccountDetailsApiErrorCount,
  ] = useAxiosPost<ResponseProp>("banks/getBankAccountDetails");

  const [
    getAreasApi,
    areasApiResponse,
    areasApiError,
    areasApiLoading,
    areasApiMessage,
    areasApiErrorCount,
  ] = useAxiosPost<ResponseProp>("banks/getAllBanks");

  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<UpdateBankAccountApiProp>(
    "banks/updateBankAccountDetails",
    true
  );

  const onFinish = (values: any) => {
    const dataToSend: UpdateBankAccountApiProp = {
      intPkBankAccountID: Number(intPkBankAccountID),
      address: rulesRequired === true ? values.address : null,
      accountTitle: values.accountTitle,
      accountNumber: values.accountNumber,
      branchCode: rulesRequired === true ? values.branchCode : null,
    };
    console.log({ dataToSend });
    postApiData(dataToSend);
  };
  const [banks, setBanks] = useState<NetwalaSelectOptions[]>([]);
  const [allBanks, setAllBanks] = useState<any[]>([]);

  useEffect(() => {
    getAreasApi({});
    postBankAccountDetailsApi({
      intPkBankAccountID: Number(intPkBankAccountID),
    });
  }, []);
  useEffect(() => {
    if (areasApiResponse !== null) {
      const data: any = areasApiResponse.responseData;
      const _banks = data.map((item: any): NetwalaSelectOptions => {
        return {
          value: item.intPkBankID,
          label: item.bankName,
        };
      });

      setBanks(_banks);
      setAllBanks(data || []);
    }
  }, [areasApiResponse]);

  const onFinishFailed = (errorInfo: any) => {};

  useEffect(() => {
    if (apiResponse !== null) {
      const { message } = apiResponse;
      if (message) {
        openNotification(`success`, "Success", message);
        setTimeout(() => {
          navigate(-1);
        }, 2500);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", apiError);
    }
  }, [apiError, apiErrorCount]);

  useEffect(() => {
    if (bankAccountDetailsApiResponse !== null) {
      const data: any = bankAccountDetailsApiResponse.responseData;
      //   setStockProfile(data);
      setBankProfile(data);
      const _isRule = data.bankType === "STANDARD" ? true : false;
      setRulesRequired(_isRule);
    }
  }, [bankAccountDetailsApiResponse]);

  if (
    apiLoading === true ||
    bankAccountDetailsApiLoading === true ||
    areasApiLoading === true
  ) {
    return (
      <Row style={{ width: "100%" }}>
        {contextHolder}

        <Col span={24}>
          <TailSpinLoader />
        </Col>
      </Row>
    );
  }
  return (
    <>
      {contextHolder}

      {bankProfile && (
        <Row style={{ width: "100%" }}>
          <Form
            name="updateBankAccountForm"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            requiredMark={false}
            style={{ width: "100%" }}
            form={form}
            initialValues={{
              intPkBankID: bankProfile.intPkBankID,
              accountTitle: bankProfile.accountTitle,
              accountNumber: bankProfile.accountNumber,
              branchCode: bankProfile.branchCode,
              address: bankProfile.address,
            }}
          >
            {/* First */}

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={<span className="create-user-form-label">Bank</span>}
                  name="intPkBankID"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Bank",
                    },
                  ]}
                >
                  <NetwalaSelect
                    value={bankProfile.intPkBankID}
                    size="large"
                    height="45px"
                    options={banks}
                    disabled={true}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <span className="create-user-form-label">
                      Account Title
                    </span>
                  }
                  name="accountTitle"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Account Title",
                    },
                  ]}
                >
                  <Input
                    className="user-input-general-height"
                    placeholder="John"
                  />
                </Form.Item>
              </Col>
            </Row>
            {/* Second */}
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={
                    <span className="create-user-form-label">
                      Account Number
                    </span>
                  }
                  name="accountNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Account Number",
                    },
                  ]}
                >
                  <Input
                    className="user-input-general-height"
                    placeholder="154215421545754"
                  />
                </Form.Item>
              </Col>
              {bankProfile.bankType === "STANDARD" ? (
                <>
                  <Col span={12}>
                    <Form.Item
                      label={
                        <span className="create-user-form-label">
                          Branch Code
                        </span>
                      }
                      name="branchCode"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Branch Code",
                        },
                      ]}
                    >
                      <Input
                        className="user-input-general-height"
                        placeholder="154215421545754"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={
                        <span className="create-user-form-label">Address</span>
                      }
                      name="address"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Address",
                        },
                      ]}
                    >
                      <Input
                        className="user-input-general-height"
                        placeholder="Thokar Niaz Baig"
                      />
                    </Form.Item>
                  </Col>
                </>
              ) : null}
            </Row>
            {/* Third */}

            <Row>
              <Col
                style={{ display: "flex", justifyContent: "center" }}
                span={24}
              >
                <Form.Item>
                  <NetwalaButton
                    htmlType="submit"
                    backgroundColor={ButtonProps.background.STANDARD}
                    height={ButtonProps.height.STANDARD}
                    width={ButtonProps.width.STANDARD}
                    textColor={ButtonProps.color.STANDARD}
                    onClick={() => {
                      console.log("clicked");
                    }}
                    text="Update Bank Account"
                    icon={<img src={PlusIcon} />}
                    disabled={apiLoading}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Row>
      )}
    </>
  );
};
export default UpdateBankAccountForm;
