import { Col, Form, Input, Row, Upload, Button, message } from "antd";
import { useEffect, useState } from "react";
import { CameraOutlined } from "@ant-design/icons";

import { useNavigate, useParams } from "react-router-dom";

import PlusIcon from "./../../assets/svgs/IconsWhite/PlusIcon.svg";
import useMultiNotification from "../../hooks/useNotification";
import useAxiosPost from "../../hooks/useAxiosPost";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import NetwalaModal from "../Items/Modal/Index";

const CreatePackageOnTheGoModalComponent: React.FC<
  CreatePackageOnTheGoModalProps
> = ({ show, handleClose, intPkIspID }) => {
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<CreatePackageByIspApiProp>(
    "packages/createNewPackage",
    true
  );
  const { openNotification, contextHolder } = useMultiNotification();

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const onFinish = (values: any) => {
    const dataToSend: CreatePackageByIspApiProp = {
      intPkIspID: Number(intPkIspID),
      intPkResourceFileID: null,
      packageName: values.packageName,
      billingMethod: "MONTHLY",
      costPrice: 0,
      salePrice: Number(values.salePrice),
      durationDays: 30,
      intPkPackageColorID: null,
    };
    postApiData(dataToSend);
  };

  const onFinishFailed = (errorInfo: any) => {};

  useEffect(() => {
    if (apiResponse !== null) {
      const { message } = apiResponse;
      if (message) {
        openNotification(`success`, "Success", message);
        setTimeout(() => {
          handleClose(true);
        }, 3000);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", apiError);
    }
  }, [apiError, apiErrorCount]);

  return (
    <NetwalaModal
      title="Create Package"
      headerStyle={{
        backgroundColor: "#292D8E",
        color: "#FFFFFF",
        fontSize: "16px",
        fontWeight: "400",
        height: "50px",
      }}
      open={show}
      onCancel={() => handleClose(false)}
    >
      {contextHolder}

      <Row style={{ width: "100%" }}>
        <Col span={16} offset={4}>
          <Form
            name="createPackageOnTheGoForm"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            requiredMark={false}
            style={{ width: "100%" }}
            form={form}
          >
            {/* First */}
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label={
                    <span className="create-user-form-label">Package Name</span>
                  }
                  name="packageName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Package Name",
                    },
                  ]}
                >
                  <Input
                    className="user-input-general-height"
                    placeholder="DT-1MB"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={
                    <span className="create-user-form-label">Sale Price</span>
                  }
                  name="salePrice"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Sale Price",
                    },
                    {
                      validator: (_, value) =>
                        value >= 0
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error("Cost Price Must be greater than 0")
                            ),
                    },
                  ]}
                >
                  <Input
                    type="number"
                    defaultValue={0}
                    className="user-input-general-height"
                    placeholder="100"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col
                style={{ display: "flex", justifyContent: "center" }}
                span={24}
              >
                <Form.Item>
                  <NetwalaButton
                    htmlType="submit"
                    backgroundColor={ButtonProps.background.STANDARD}
                    height={ButtonProps.height.STANDARD}
                    width={ButtonProps.width.STANDARD}
                    textColor={ButtonProps.color.STANDARD}
                    onClick={() => {
                      console.log("clicked");
                    }}
                    text="Create Package"
                    icon={<img src={PlusIcon} />}
                    disabled={apiLoading}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </NetwalaModal>
  );
};
export default CreatePackageOnTheGoModalComponent;
