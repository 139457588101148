import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewPendingPromisesComponent from "../components/Promises/ViewPendingPromises";

const PendingPromisesPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Pending Promises");
  }, [setTitle]);
  return (
    <>
      <ViewPendingPromisesComponent />
    </>
  );
};
export default PendingPromisesPage;
