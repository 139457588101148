import { useEffect, useState } from "react";
import { DatePicker, Col, Row } from "antd";
import dayjs from "dayjs";

import { useNavigate } from "react-router-dom";
import useAxiosPost from "../../hooks/useAxiosPost";
import PendingPromiseCard from "./PendingPromiseCard";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import "./index.css";
import NetwalaSelect from "../Items/Select/Index";
const { RangePicker } = DatePicker;
const ViewPendingPromisesComponent = () => {
  const navigate = useNavigate();
  const [promises, setPromises] = useState<PendingPromise[]>([]);
  const [actualPromises, setActualPromises] = useState<PendingPromise[]>([]);
  const [customers, setCustomers] = useState<NetwalaSelectOptions[]>([]);

  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("transactions/getPendingStaffPromises");
  const [filters, setFilters] = useState<PendingPromiseFilters>({
    startDate: "",
    endDate: "",
    intPkHeadID: "",
  });

  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      const { promises, customers } = data;
      const _customers = [
        {
          label: "Choose Customer",
          value: "",
        },
      ];
      customers.map((item: any) => {
        _customers.push({
          value: item.intPkHeadID,
          label: `${item.firstName} ${item.lastName}`,
        });
      });
      setCustomers(_customers);
      setPromises(promises || []);
      setActualPromises(promises || []);
    }
  }, [apiResponse]);
  const reloadApi = (reload: boolean) => {
    postApiData({});
  };
  useEffect(() => {
    handleSearch(filters);
  }, [filters]);
  const handleSearch = (filters: PendingPromiseFilters) => {
    let _promises = actualPromises;
    const { startDate, endDate, intPkHeadID } = filters;
    if (intPkHeadID) {
      _promises = _promises.filter(
        (item) => Number(item.customerHeadID) === Number(intPkHeadID)
      );
    }
    if (startDate && endDate) {
      _promises = _promises.filter((item) => {
        const promiseDueDate = new Date(item.promiseDueDate);
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);
        return promiseDueDate >= startDateObj && promiseDueDate <= endDateObj;
      });
    }
    setPromises(_promises);
  };
  return (
    <>
      {apiLoading === true ? (
        <TailSpinLoader />
      ) : (
        <>
          <Row style={{ marginBottom: "20px" }} gutter={[16, 16]}>
            <Col span={12} offset={12}>
              <Row style={{ width: "100%" }} gutter={[16, 16]}>
                <Col span={12}>
                  <NetwalaSelect
                    value={filters.intPkHeadID}
                    onChange={(e) => {
                      console.log({ e });
                      setFilters({
                        ...filters,
                        intPkHeadID: e,
                      });
                    }}
                    size="small"
                    height="45px"
                    options={customers}
                    showSearch
                  />
                </Col>
                <Col span={12}>
                  <RangePicker
                    style={{ height: "45px" }}
                    onChange={(e: any) => {
                      console.log({ e });
                      if (e === null) {
                        setFilters({
                          ...filters,
                          startDate: "",
                          endDate: "",
                        });
                        return;
                      }
                      const [startDate, endDate] = e;

                      setFilters({
                        ...filters,
                        startDate: dayjs(startDate).format("YYYY-MM-DD"),
                        endDate: dayjs(endDate).format("YYYY-MM-DD"),
                      });
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            {promises.map((item) => {
              return (
                <Col span={24}>
                  <PendingPromiseCard
                    reloadApi={reloadApi}
                    key={Math.random()}
                    promise={item}
                  />
                </Col>
              );
            })}
          </Row>
        </>
      )}
    </>
  );
};
export default ViewPendingPromisesComponent;
