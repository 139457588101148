import { useEffect, useState } from "react";
import { Col, Row, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosPost from "../../../hooks/useAxiosPost";
import StaffIcon from "./../../../assets/pngs/StaffProfile.png";
import "./../cards/SingleStaffMiniCard.css";
import DummyFront from "./../../../assets/pngs/idCards/DummyFront.png";
import DummyBack from "./../../../assets/pngs/idCards/DummyBack.png";
import NetwalaTabs from "../../Items/Tab/Index";
import StaffProfileTabCard from "./StaffProfileTabCard";
import StaffTransactionsCard from "./StaffTransactionsCard";
import NetwalaButton from "../../Items/Button/Index";
import { ButtonProps } from "../../../helpers/constants";
import CollectStaffPaymentModal from "../../Modals/CollectStaffPaymentModal";
import CashInHandContainer from "../../Items/CashAvaialble/Index";
import TailSpinLoader from "../../Items/Loaders/TailSpinLoader";

const ViewSingleStaffBasic = () => {
  const navigate = useNavigate();
  const { intPkHeadID } = useParams();
  const [showCollectionModal, setShowCollectionModal] =
    useState<boolean>(false);

  const [staff, setStaffProfile] = useState<StaffListType | null>(null);
  const [staffTransactions, setStaffTransactions] = useState<
    StaffTransaction[]
  >([]);
  const [pagination, setPagination] = useState<PaginationProp>({
    totalPages: 0,
    currentPage: 1,
    previousPage: null,
    nextPage: null,
    totalCount: 0,
    pageSize: 50,
  });
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<GetStaffPropApiProp>("auth/getStaffProfile");
  const [
    getStaffTransactionsApi,
    transactionsApiResponse,
    transactionApiError,
    transactionsApiLoading,
  ] = useAxiosPost<GetStaffPropApiProp>("invoices/getMyStaffTransactions");
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setStaffProfile(data);
    }
  }, [apiResponse]);
  useEffect(() => {
    if (transactionsApiResponse !== null) {
      const data: any = transactionsApiResponse.responseData;
      const {
        currentPage,
        nextPage,
        pageSize,
        previousPage,
        totalCount,
        totalPages,
        transactions,
      } = data;
      setPagination({
        currentPage,
        totalPages,
        nextPage,
        previousPage,
        totalCount,
        pageSize,
      });

      setStaffTransactions(transactions || []);
    }
  }, [transactionsApiResponse]);
  useEffect(() => {
    postApiData({ intPkHeadID: Number(intPkHeadID) });
    getStaffTransactionsApi({ intPkHeadID: Number(intPkHeadID) });
  }, []);

  const apiReload = (isReload: boolean) => {
    if (isReload === true) {
      postApiData({ intPkHeadID: Number(intPkHeadID) });
      getStaffTransactionsApi({ intPkHeadID: Number(intPkHeadID) });
    }
    setShowCollectionModal(false);
  };
  const handlePageSwitch = (pageNumber: number) => {
    getStaffTransactionsApi({
      intPkHeadID: Number(intPkHeadID),
      page: pageNumber,
    });
  };
  return (
    <>
      {showCollectionModal === true && staff && (
        <CollectStaffPaymentModal
          show={showCollectionModal}
          handleClose={apiReload}
          intPkHeadID={staff.intPkHeadID}
          cashAvailable={staff.userBalance.cashAvailable}
        />
      )}
      {staff && (
        <>
          <Row style={{ display: "flex", alignItems: "center" }} gutter={16}>
            <Col span={6}>
              <div className="basic-staff-header-profile-container">
                <img
                  src={
                    staff.url && staff.isEncrypted === 0 ? staff.url : StaffIcon
                  }
                  style={{ maxWidth: "100px", maxHeight: "100px" }}
                  className="staff-profile-image"
                />
                <div className="">
                  <Typography className="staff-full-name">
                    {staff.firstName} {staff.lastName}
                  </Typography>
                  <Typography className="staff-phone-number">
                    {staff.phoneNumber}
                  </Typography>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <CashInHandContainer
                label="Cash in Hand:"
                value={`${staff.userBalance.cashAvailable || 0}`}
              />
            </Col>
            <Col span={6}>
              <NetwalaButton
                backgroundColor={ButtonProps.background.STANDARD}
                height={ButtonProps.height.STANDARD}
                width={ButtonProps.width.STANDARD}
                textColor={ButtonProps.color.STANDARD}
                onClick={() => {
                  if (staff.userBalance.cashAvailable > 0) {
                    setShowCollectionModal(true);
                  }
                }}
                text="Collect Payment"
              />
            </Col>
          </Row>
          {transactionsApiLoading === true ? (
            <TailSpinLoader />
          ) : (
            <Row gutter={[20, 20]} style={{ marginTop: "40px" }}>
              <Col span={24} style={{ marginTop: "30px" }}>
                <StaffTransactionsCard
                  transactions={staffTransactions}
                  pagination={pagination}
                  handlePageSwitch={handlePageSwitch}
                  intPkHeadID={Number(intPkHeadID)}
                  viewType="staff"
                />
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};
export default ViewSingleStaffBasic;
