import React, { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewMiniExpensesByCEOComponent from "../components/expenses/ViewMiniExpensesByCEO";
const ViewMiniExpensesByPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Expenses");
  }, [setTitle]);

  return (
    <>
      <ViewMiniExpensesByCEOComponent />
    </>
  );
};
export default ViewMiniExpensesByPage;
