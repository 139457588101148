import { Col, Form, Input, Row, Upload, Button, message } from "antd";
import NetwalaSelect from "../Items/Select/Index";
import { useEffect, useState } from "react";
import { CameraOutlined, UploadOutlined } from "@ant-design/icons";
const { TextArea } = Input;
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import PlusIcon from "./../../assets/svgs/IconsWhite/PlusIcon.svg";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
} from "antd/lib/upload/interface";
import useAxiosPost, {
  authTokenString,
  baseURL,
} from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import { useNavigate, useParams } from "react-router-dom";

const CreateMikroNocISPForm = () => {
  const { intPkCompanyID } = useParams();
  const [
    getVlansApi,
    vlansApiResponse,
    vlansApiError,
    vlansApiLoading,
    vlansApiMessage,
    vlansApiErrorCount,
  ] = useAxiosPost<ResponseProp>("superAdmin/getMikroNocBotProbes");
  const { openNotification, contextHolder } = useMultiNotification();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [bots, setBots] = useState<NetwalaSelectOptions[]>([]);
  const onFinish = (values: any) => {
    handleCreateISP(values);
  };
  const [profilePictureList, setProfilePictureList] = useState<UploadFile[]>(
    []
  );
  useEffect(() => {
    getVlansApi({});
  }, []);
  useEffect(() => {
    if (vlansApiResponse !== null) {
      const data: any = vlansApiResponse.responseData;
      const bots = data.map((item: any): NetwalaSelectOptions => {
        return {
          value: item.intPkMikroNOCProbeID,
          label: item.mikroNocProbeIP,
        };
      });

      setBots(bots);
    }
  }, [vlansApiResponse]);
  const handleRemoveProfile = (file: UploadFile) => {
    setProfilePictureList((prevList) =>
      prevList.filter((item) => item.uid !== file.uid)
    );
  };

  const handleBeforeUploadProfile = (file: RcFile, fileList: RcFile[]) => {
    setProfilePictureList(
      fileList.map((file) => ({
        uid: file.uid,
        name: file.name,
        status: "done",
      }))
    );
    // You can add your upload logic here if needed
    return false; // Return false here to avoid auto uploading
  };

  const handleChangeProfile = (info: UploadChangeParam) => {
    setProfilePictureList(info.fileList);
  };

  const onFinishFailed = (errorInfo: any) => {};

  const handleCreateISP = async (values: any) => {
    setIsLoading(true);
    const companyID = "" + intPkCompanyID;
    const formData = new FormData();
    formData.append("authTokenString", authTokenString.toString());
    formData.append("ISPName", values.ISPName);
    formData.append("ISPSalesEmail", values.ISPSalesEmail);
    formData.append("ISPSupportEmail", values.ISPSupportEmail);
    formData.append("ISPSupportNumber", values.ISPSupportNumber);
    formData.append("IspSalesNumber", values.IspSalesNumber);
    // Here we have nas
    formData.append("nasIpAddress", values.nasIpAddress);
    formData.append("nasUsername", values.nasUsername);
    formData.append("nasPassword", values.nasPassword);
    formData.append("nasPort", values.nasPort);
    formData.append("intPkMikroNOCProbeID", values.intPkMikroNOCProbeID);
    formData.append("intPkCompanyID", companyID);

    if (profilePictureList.length > 0) {
      formData.append("ispLogo", profilePictureList[0].originFileObj as Blob);
    }
    try {
      const response = await fetch(
        `${baseURL}/superAdmin/createNewMikroNocISPWithNas`,
        {
          method: "POST",
          body: formData,
          // Note: When using FormData, do NOT set the Content-Type header
          // The browser will set it automatically, including the boundary parameter
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        openNotification(
          "success",
          "Success",
          responseData?.message || "ISP Created Successfully"
        );
        setTimeout(() => {
          navigate(`/super-admin/company/view-mikronoc-isps/${intPkCompanyID}`);
        }, 3000);
      } else {
        const responseData = await response.json();
        setIsLoading(false);
        openNotification(
          "error",
          "Operation Failed",
          responseData?.message || "Server Error"
        );
        console.error("Upload failed:", responseData);
      }
    } catch (error) {
      setIsLoading(false);

      console.error("Error during fetch:", error);
    }
  };
  //   ISPName, ISPSupportNumber, ISPSupportEmail, ISPSalesEmail,
  return (
    <>
      {contextHolder}

      <Row style={{ width: "100%" }}>
        <Form
          name="createIspForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          requiredMark={false}
          style={{ width: "100%" }}
          form={form}
        >
          {/* First */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">ISP Name</span>}
                name="ISPName"
                rules={[
                  {
                    required: true,
                    message: "Please enter ISP Name",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="Transworld"
                />
              </Form.Item>
            </Col>
          </Row>
          {/* Second */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Support Email</span>
                }
                name="ISPSupportEmail"
                rules={[
                  {
                    required: true,
                    message: "Please enter Support Email",
                  },
                  {
                    type: "email",
                    message: "Please enter a valid Support email address",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="support@xyz.com"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Sales Email</span>
                }
                name="ISPSalesEmail"
                rules={[
                  {
                    required: true,
                    message: "Please enter Sales Email",
                  },
                  {
                    type: "email",
                    message: "Please enter a valid Sale email address",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="sales@xyz.com"
                />
              </Form.Item>
            </Col>
          </Row>
          {/* Third */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Support Number</span>
                }
                name="ISPSupportNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter ISP Support Number",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="03451234567"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Sales Number</span>
                }
                name="IspSalesNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter ISP Sales Number",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="03451234567"
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Fifth */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">ISP Logo</span>}
                name="userProfile"
                rules={[
                  {
                    required: true,
                    message: "Please upload an ISP logo!",
                    validator: (_, value) =>
                      profilePictureList.length > 0
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("Please upload an ISP logo!")
                          ),
                  },
                ]}
                valuePropName="fileList"
                getValueFromEvent={(e) => {
                  if (Array.isArray(e)) {
                    return e;
                  }
                  return e && e.fileList;
                }}
              >
                <Upload
                  onRemove={handleRemoveProfile}
                  beforeUpload={handleBeforeUploadProfile}
                  onChange={handleChangeProfile}
                  fileList={profilePictureList}
                >
                  <Button icon={<CameraOutlined />}>Upload Logo</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            {/* <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">NAS Name</span>}
                name="mikrotikName"
                rules={[
                  {
                    required: true,
                    message: "Please Enter NAS Name",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="Test NAS"
                />
              </Form.Item>
            </Col> */}
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">NAS IP</span>}
                name="nasIpAddress"
                rules={[
                  {
                    required: true,
                    message: "Please Enter NAS IP",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="123.123.123.123"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">UserName</span>}
                name="nasUsername"
                rules={[
                  {
                    required: true,
                    message: "Please Enter username",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="someTestUserName"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">Password</span>}
                name="nasPassword"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Password",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="someTestPassword"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">NAS Port</span>}
                name="nasPort"
                rules={[
                  {
                    required: true,
                    message: "Please Enter NAS PORT",
                  },
                  {
                    validator: (_, value) =>
                      value > 1
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("NAS PORT Must be greater than 1")
                          ),
                  },
                  {
                    validator: (_, value) =>
                      value > 65535
                        ? Promise.reject(
                            new Error("NAS PORT Must be less than 65535")
                          )
                        : Promise.resolve(),
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="10"
                  type="number"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">MikroNoc Probe</span>
                }
                name="intPkMikroNOCProbeID"
                rules={[
                  {
                    required: true,
                    message: "Please Select MikroNoc Probe",
                  },
                ]}
              >
                <NetwalaSelect size="large" height="45px" options={bots} />
              </Form.Item>
            </Col>
          </Row>

          {/* Create Button Row */}
          <Row>
            <Col
              style={{ display: "flex", justifyContent: "center" }}
              span={24}
            >
              <Form.Item>
                <NetwalaButton
                  htmlType="submit"
                  backgroundColor={ButtonProps.background.STANDARD}
                  height={ButtonProps.height.STANDARD}
                  width={ButtonProps.width.STANDARD}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {
                    console.log("clicked");
                  }}
                  text="Create ISP"
                  icon={<img src={PlusIcon} />}
                  disabled={isLoading}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Row>
    </>
  );
};
export default CreateMikroNocISPForm;
