import { Col, Form, Input, Row, Upload, Button, message } from "antd";
import { useEffect, useState } from "react";
import { CameraOutlined } from "@ant-design/icons";

import { useNavigate, useParams } from "react-router-dom";
import useMultiNotification from "../../../../../hooks/useNotification";
import NetwalaButton from "../../../../Items/Button/Index";
import { ButtonProps } from "../../../../../helpers/constants";
import PlusIcon from "./../../../../../assets/svgs/IconsWhite/PlusIcon.svg";
import NetwalaSelect from "../../../../Items/Select/Index";
// import ChoosePackageIconModal from "../../../../Modals/ChoosePackageIconModal";
import useAxiosPost from "../../../../../hooks/useAxiosPost";
import ChoosePackageColorModal from "../../../../Modals/ChoosePackageColorModal";
const CreatePackageForISPComponent = () => {
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<CreatePackageByIspApiProp>(
    "packages/createNewPackage",
    true
  );
  const [packageName, setPackageName] = useState<string>("");
  const { openNotification, contextHolder } = useMultiNotification();
  const { intPkIspID } = useParams();
  const [show, setShow] = useState<boolean>(false);
  const [iconId, setIconId] = useState<number | null>(null);
  const [selectedColor, setSelectedColor] = useState<any>(null);

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const onFinish = (values: any) => {
    if (selectedColor === null) {
      openNotification("error", "Error", `No Color Selected For Package`);
      return;
    }
    // let { costPrice } = values;
    // if (!costPrice) {
    //   costPrice = 0;
    // } else if (costPrice < 0) {
    //   openNotification("error", "Error", `Cost Price Must be 0 or more`);
    //   return;
    // }
    const dataToSend: CreatePackageByIspApiProp = {
      intPkIspID: Number(intPkIspID),
      // intPkResourceFileID: iconId,
      packageName: values.packageName,
      billingMethod: "MONTHLY",

      costPrice: 0,
      salePrice: Number(values.salePrice),
      intPkPackageColorID: selectedColor.intPkPackageColorID,
      durationDays: 30,
    };
    postApiData(dataToSend);
  };
  const [selectedBillingMethod, setSelectedBillingMethod] =
    useState<string>("");
  const onFinishFailed = (errorInfo: any) => {};
  const handleClose = (isReload: boolean) => {
    setShow(false);
    if (isReload === false) {
      setIconId(null);
    }
  };
  const handleSelectIconId = (id: number) => {
    setIconId(id);
  };
  useEffect(() => {
    if (apiResponse !== null) {
      const { message } = apiResponse;
      if (message) {
        openNotification(`success`, "Success", message);
        setTimeout(() => {
          navigate(-1);
        }, 3000);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", apiError);
    }
  }, [apiError, apiErrorCount]);
  const handleSelectedColor = (selectedColor: any) => {
    setSelectedColor(selectedColor);
  };
  return (
    <>
      {contextHolder}
      {/* {show && (
        <ChoosePackageIconModal
          selectIconId={handleSelectIconId}
          show={show}
          handleClose={handleClose}
        />
      )} */}
      {show && (
        <ChoosePackageColorModal
          handleSelectedColor={handleSelectedColor}
          show={show}
          handleClose={handleClose}
          packageName={form.getFieldValue("packageName")}
        />
      )}
      <Row style={{ width: "100%" }}>
        <Form
          name="createPackageForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          requiredMark={false}
          style={{ width: "100%" }}
          form={form}
        >
          {/* First */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Package Name</span>
                }
                name="packageName"
                rules={[
                  {
                    required: true,
                    message: "Please enter Package Name",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="DT-1MB"
                  value={packageName}
                  onChange={(e) => setPackageName(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col
              style={{ display: "flex", gap: "10px", alignItems: "center" }}
              span={12}
            >
              <Button
                style={{ marginTop: "20px", height: "45px" }}
                onClick={(e) => {
                  if (!form.getFieldValue("packageName")) {
                    return;
                  }
                  e.preventDefault();
                  setShow(true);
                }}
                // onClick={showModal}
                icon={<CameraOutlined />}
              >
                Choose Package Color
              </Button>
              {packageName && selectedColor !== null && (
                <div
                  className="package-color-container"
                  style={{
                    background: selectedColor.bgColor,
                    border: "1px solid",
                    borderColor: selectedColor.borderColor,
                    color: selectedColor.color,
                    maxHeight: "20px",
                  }}
                >
                  {packageName}
                </div>
              )}
            </Col>
          </Row>
          {/* Second */}
          <Row gutter={16}>
            {/* <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Cost Price</span>
                }
                name="costPrice"
              >
                <Input
                  type="number"
                  className="user-input-general-height"
                  placeholder="50"
                />
              </Form.Item>
            </Col> */}
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Sale Price</span>
                }
                name="salePrice"
                rules={[
                  {
                    required: true,
                    message: "Please enter Sale Price",
                  },
                  {
                    validator: (_, value) =>
                      value >= 0
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("Cost Price Must be greater than 0")
                          ),
                  },
                ]}
              >
                <Input
                  type="number"
                  defaultValue={0}
                  className="user-input-general-height"
                  placeholder="100"
                />
              </Form.Item>
            </Col>
          </Row>
          {/* Third */}
          {/* <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Billing Method</span>
                }
                name="billingMethod"
                rules={[
                  {
                    required: true,
                    message: "Please Select Billing Method",
                  },
                ]}
              >
                <NetwalaSelect
                  value={selectedBillingMethod}
                  size="large"
                  height="45px"
                  onChange={(e) => setSelectedBillingMethod(e)}
                  options={[
                    { value: "DAYS", label: "DAYS" },
                    { value: "MONTHLY", label: "MONTHLY" },
                  ]}
                />
              </Form.Item>
            </Col>
            {selectedBillingMethod === "DAYS" && (
              <Col span={12}>
                <Form.Item
                  label={
                    <span className="create-user-form-label">
                      Duration Days
                    </span>
                  }
                  name="durationDays"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Duration Days",
                    },
                    {
                      validator: (_, value) =>
                        value >= 0
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error("Duration Days Must be more than 0")
                            ),
                    },
                  ]}
                >
                  <Input
                    type="number"
                    className="user-input-general-height"
                    placeholder="30"
                  />
                </Form.Item>
              </Col>
            )}
          </Row> */}
          {/* Final */}
          <Row>
            <Col
              style={{ display: "flex", justifyContent: "center" }}
              span={24}
            >
              <Form.Item>
                <NetwalaButton
                  htmlType="submit"
                  backgroundColor={ButtonProps.background.STANDARD}
                  height={ButtonProps.height.STANDARD}
                  width={ButtonProps.width.STANDARD}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {
                    console.log("clicked");
                  }}
                  text="Create Package"
                  icon={<img src={PlusIcon} />}
                  disabled={apiLoading}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Row>
    </>
  );
};
export default CreatePackageForISPComponent;
