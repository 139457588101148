import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Progress } from "antd";
import "./SingleStaffMiniCard.css";
import StaffProfile from "./../../../assets/pngs/StaffProfile.png";

const SingleStaffMiniCard: React.FC<StaffListMiniCardProp> = ({ staff }) => {
  return (
    <>
      <div className="staff-mini-card-container">
        <img
          src={staff.url && staff.isEncrypted === 0 ? staff.url : StaffProfile}
          className="staff-profile-image"
        />
        <Typography className="staff-full-name">
          {staff.firstName} {staff.lastName}
        </Typography>
        <Typography className="staff-phone-number">
          {staff.phoneNumber}
        </Typography>
        <Typography className="staff-user-role">{staff.headType}</Typography>
        <Typography className="">
          <span className="staff-cash-label">Cash:</span>{" "}
          <span className="staff-cash-value">
            {staff.userBalance.cashAvailable}
          </span>
        </Typography>
      </div>
    </>
  );
};
export default SingleStaffMiniCard;
