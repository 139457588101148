import React from "react";
import { Row, Col, Typography } from "antd";

import "./index.css";
import "./../../SingleUser/components/cards/transactions.css";
import { formatDate } from "../../../helpers/date";
import { useNavigate } from "react-router-dom";
import NetwalaPagination from "../../Items/Pagination/NetwalaPagination";
import ViewReceiptIconBlue from "./../../../assets/svgs/ViewReceiptIconBlue.svg";
import ViewSoaIcon from "./../../../assets/svgs/ViewSoaIcon.svg";
interface TransactionRowItemProp {
  label: string;
  value: string;
  valueClass: string;
}
const DetailsItem: React.FC<TransactionRowItemProp> = ({
  label,
  value,
  valueClass,
}) => {
  return (
    <div className="transaction-single-item-col">
      <Typography className="item-label">{label}</Typography>
      <Typography className={`${valueClass}`}>{value}</Typography>
    </div>
  );
};
const StaffTransactionsMiniCard: React.FC<StaffTransactionsMiniCardProp> = ({
  transactions,
  pagination,
  handlePageSwitch,
  intPkHeadID,
}) => {
  const navigate = useNavigate();
  const getAmountWithSign = (transaction: StaffTransaction) => {
    if (transaction.staffReceived === intPkHeadID) {
      return `+${transaction.amount}`;
    }
    if (transaction.staffGiven === intPkHeadID) {
      return `-${transaction.amount}`;
    }
    return `+${transaction.amount}`;
  };
  const getLabelClassForAmount = (transaction: StaffTransaction) => {
    if (transaction.staffReceived === intPkHeadID) {
      return `in-amount-label`;
    }
    if (transaction.staffGiven === intPkHeadID) {
      return `out-amount-label`;
    }
    return `in-amount-label`;
  };
  const getNameToDisplay = (transaction: StaffTransaction) => {
    if (transaction.staffGiven === intPkHeadID) {
      return transaction.receivedStaffFullName || "";
    }
    return transaction.customerFullName || "";
  };
  const getNameLabelToDisplay = (transaction: StaffTransaction) => {
    if (transaction.staffGiven === intPkHeadID) {
      return `Staff`;
    }
    return `Customer`;
  };
  return (
    <>
      {transactions.map((transaction) => {
        return (
          <Row
            key={Math.random()}
            style={{
              width: "100%",
              marginBottom: "10px",
            }}
            className="transaction-mini-container"
          >
            <Row style={{ width: "100%", padding: "20px" }}>
              <Col span={8}>
                <DetailsItem
                  label="Transaction type"
                  value={transaction.row_enum}
                  valueClass="payment-purpose"
                />
              </Col>
              <Col span={6}>
                <DetailsItem
                  label="Amount"
                  value={getAmountWithSign(transaction)}
                  valueClass={getLabelClassForAmount(transaction)}
                />
              </Col>
              <Col span={10}>
                <DetailsItem
                  label="Transaction Date"
                  value={`${formatDate(new Date(transaction.dateCreated))}`}
                  valueClass="recharge-date-value"
                />
              </Col>
            </Row>
            <Row
              style={{ width: "100%", background: "#F3F6FF", padding: "20px" }}
            >
              <Col span={8}>
                <DetailsItem
                  label="User Name"
                  value={transaction.username ? transaction.username : "-"}
                  valueClass="user-name-label"
                />
              </Col>
              <Col span={8}>
                <DetailsItem
                  label={getNameLabelToDisplay(transaction)}
                  value={getNameToDisplay(transaction)}
                  valueClass="user-name-label "
                />
              </Col>

              <Col className="view-icons-container" span={8}>
                <img
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    // navigate(
                    //   `/transactions/transaction-details/${transaction.intPkTransactionHeadID}/${transaction.isRefunded}`
                    // );
                  }}
                  src={ViewSoaIcon}
                />
                {transaction.intPkConnectionInvoiceID && (
                  <img
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(
                        `/receipts/receipt-details/${transaction.intPkConnectionInvoiceID}`
                      );
                    }}
                    className="cursor-pointer"
                    src={ViewReceiptIconBlue}
                  />
                )}
              </Col>
            </Row>
          </Row>
        );
      })}
      <Row>
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
            marginBottom: "20px",
          }}
          span={24}
        >
          <NetwalaPagination
            pagination={pagination}
            handlePageSwitch={handlePageSwitch}
          />
        </Col>
      </Row>
    </>
  );
};
export default StaffTransactionsMiniCard;
