import { Col, Typography } from "antd";

interface ConnectionProfileDetailsSingleRow {
  label?: string;
  value?: string | React.ReactNode;
  icon?: any;
  isGray?: boolean;
  isBorderBottom?: boolean;
  isUnderline?: boolean;
  isReactNode?: boolean;
  onClick?: () => void;
}
const SingleDetailsRowWithLabelAndValue: React.FC<
  ConnectionProfileDetailsSingleRow
> = ({
  isGray = false,
  value,
  label,
  icon,
  isBorderBottom = true,
  isUnderline,
  isReactNode = false,
  onClick,
}) => {
  return (
    <Col
      span={24}
      onClick={onClick}
      className={`profile-card-single-row ${
        isGray ? "profile-card-single-row-gray" : ""
      } ${isBorderBottom === true ? "border-bottom-1px" : ""} ${
        isUnderline === true ? "cursor-pointer" : ""
      } `}
    >
      <div className="icon-container">
        <img src={icon} />
      </div>
      <div className="values-container">
        <Typography className={`${isGray ? "user-label-gray" : "user-label"}`}>
          {label}
        </Typography>
        <Typography
          className={`${isGray ? "user-value-gray" : "user-value"} ${
            isUnderline ? "text-under-line" : ""
          }`}
        >
          {isReactNode === true ? (
            <>{value}</>
          ) : (
            <label htmlFor="">{value}</label>
          )}
        </Typography>
      </div>
    </Col>
  );
};
export default SingleDetailsRowWithLabelAndValue;
