import { useEffect, useState } from "react";
import { Row, Col, Typography, Form, Input, Card, Select, Button } from "antd";
import ConnectionProfileDetailsLeftCard from "./cards/ConnectionProfileDetailsLeftCard";
import useAxiosPost from "../../hooks/useAxiosPost";
import { useParams } from "react-router-dom";
import ConnectionProfileDetailsRightCard from "./cards/ConnectionProfileDetailsRightCard";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
const ConnectionDetailsComponent = () => {
  const [singleConnection, setSingleConnection] =
    useState<SingleConnectionApiResponse | null>(null);
  const [companySettings, SetCompanySettings] =
    useState<CompanySettingsApiProp | null>(null);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ConnectionUserDetailsProp>(
    "connections/getConnectionsByConnectionID"
  );
  const { intPkConnectionID } = useParams();
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      const { connectionDetails, companySettings } = data;
      setSingleConnection(connectionDetails);
      SetCompanySettings(companySettings);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({ intPkConnectionID: Number(intPkConnectionID) });
  }, []);
  const handleReload = (reload: boolean) => {
    postApiData({ intPkConnectionID: Number(intPkConnectionID) });
  };
  return (
    <>
      {apiLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row gutter={30}>
          <Col span={12}>
            {singleConnection && companySettings && (
              <ConnectionProfileDetailsLeftCard
                singleConnection={singleConnection}
                isReload={handleReload}
                settings={companySettings}
              />
            )}
          </Col>
          <Col span={12}>
            {singleConnection && companySettings && (
              <ConnectionProfileDetailsRightCard
                singleConnection={singleConnection}
                isReload={handleReload}
                settings={companySettings}
              />
            )}
          </Col>
        </Row>
      )}
    </>
  );
};
export default ConnectionDetailsComponent;
