import React, { useState } from "react";
import { Row, Col, Typography } from "antd";
import "./profile.css";
import ProfileIcon from "./../../../../assets/svgs/ProfileIcons/Profile.svg";
import LocationIcon from "./../../../../assets/svgs/ProfileIcons/Location.svg";
import PhoneIcon from "./../../../../assets/svgs/ProfileIcons/Phone.svg";
import EditIconBlue from "./../../../../assets/svgs/EditIconBlue.svg";

import SingleDetailsRowWithLabelAndValue from "../../../connectionDetails/cards/SingleDetailsRowWithLabelAndValue";
import UpdateSingleUserProfileModal from "../../../Modals/UpdateSingleUserProfileModal";

const UserProfileDetailsCard: React.FC<UserProfileDetailsCardProp> = ({
  profile,
  reloadApi,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const handleClose = (isReload: boolean) => {
    if (isReload === true) {
      reloadApi();
    }
    setShow(false);
  };
  return (
    <>
      {show === true && (
        <UpdateSingleUserProfileModal
          show={show}
          handleClose={handleClose}
          profile={profile}
        />
      )}
      <Row className="user-profile-details-card-container" gutter={16}>
        <Col span={24} className="user-details-title-label-container">
          <Typography className="user-details-title-label">
            User Details
          </Typography>
          <img
            src={EditIconBlue}
            onClick={(e) => setShow(true)}
            className="cursor-pointer"
          />
          {/* <Dropdown
            menu={{ items, onClick: handleMenuClick }}
            placement="bottomRight"
          >
            <MenuOutlined className="cursor-pointer" />
          </Dropdown> */}
        </Col>
        <SingleDetailsRowWithLabelAndValue
          isGray
          value={`${profile.phoneNumber}`}
          label={`${profile.firstName} ${profile.lastName}`}
          icon={ProfileIcon}
        />
        <SingleDetailsRowWithLabelAndValue
          value={`${profile.address}`}
          label="Address"
          icon={LocationIcon}
        />
        <SingleDetailsRowWithLabelAndValue
          value={`${profile.phoneNumber}`}
          label="Phone"
          icon={PhoneIcon}
          isBorderBottom={false}
        />
        {/* <SingleDetailsRowWithLabelAndValue
          value={`DT-10Mb`}
          label="Package"
          icon={PackageIcon}
        />
        <SingleDetailsRowWithLabelAndValue
          value={`DT-10Mb`}
          label="ID Card"
          icon={PackageIcon}
          isBorderBottom={false}
        /> */}
      </Row>
    </>
  );
};
export default UserProfileDetailsCard;
