import React from "react";
import NetwalaModal from "../Items/Modal/Index";
import { Row, Typography } from "antd";
import "./styles/RequestIPTVModal.css";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
const ImportUserConfirmationModal: React.FC<GeneralModalProps> = ({
  show,
  handleClose,
}) => {
  return (
    <>
      <NetwalaModal
        title="Import Confirmation"
        headerStyle={{
          backgroundColor: "#292D8E",
          color: "#FFFFFF",
          fontSize: "16px",
          fontWeight: "400",
          height: "50px",
        }}
        open={show}
        onCancel={() => {
          handleClose(false);
        }}
      >
        <Row className="service-remove-confirmation-container">
          <Typography className="remove-message">
            Are you Sure You want to import users?
          </Typography>
          <div className="remove-buttons-container">
            <NetwalaButton
              backgroundColor={ButtonProps.background.STANDARD}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.ONE24}
              textColor={ButtonProps.color.STANDARD}
              onClick={() => handleClose(true)}
              text="Confirm"
            />
            <NetwalaButton
              backgroundColor={ButtonProps.background.SECONDARY}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.ONE24}
              textColor={ButtonProps.color.BLACK}
              onClick={() => handleClose(false)}
              text="Cancel"
              //   icon={<img src={PlusIcon} />}
              borderColor="#D7D7D7"
            />
          </div>
        </Row>
      </NetwalaModal>
    </>
  );
};

export default ImportUserConfirmationModal;
