import React, { useEffect, useState } from "react";
import { Col, Row, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import RouterIcon from "./../../assets/svgs/inventoryItemsIcon/Routers.svg";
import SwitchIcon from "./../../assets/svgs/inventoryItemsIcon/Switches.svg";
import CableIcon from "./../../assets/svgs/inventoryItemsIcon/Cables.svg";
import "./index.css";
import { getUserType } from "../../helpers/userDetails";
interface InventoryItemSingleRowMiniContainer {
  value: string;
  label: string;
}
const InventoryStockItemRow: React.FC<InventoryItemSingleRowMiniContainer> = ({
  label,
  value,
}) => {
  return (
    <div className="inventory-items-mini-container-row">
      <Typography className="inventory-item-label">{label}</Typography>
      <Typography className="inventory-item-value">{value}</Typography>
    </div>
  );
};
const ViewInventoryItemDetailsComponent = () => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [items, setItems] = useState<InventorySocketItem[]>([]);
  const navigate = useNavigate();
  const { intPkInventoryItemID } = useParams();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<GetStockByInventoryItemApiProp>(
    "inventory/getStockByInventoryItem"
  );

  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setItems(data || []);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({ intPkInventoryItemID: Number(intPkInventoryItemID) });
  }, []);
  const getSvg = (itemType: string) => {
    switch (itemType) {
      case "ROUTER":
        return <img className="inventory-item-icon" src={RouterIcon} />;
      case "SWITCH":
        return <img src={SwitchIcon} />;
      case "BOX":
        return <img src={SwitchIcon} />;
      default:
        return <img src={CableIcon} />;
    }
  };
  return (
    <>
      {contextHolder}

      <Row gutter={[16, 16]}>
        {items.map((item) => {
          return (
            <Col span={24}>
              <Row
                onClick={(e) => {
                  e.preventDefault();
                  navigate(
                    `/inventory-stock-details/${item.intPkInventoryModelID}?activeTab=1`
                  );
                }}
                className="inventory-stock-item-single-container"
              >
                <Col span={4}>
                  <img style={{ maxHeight: "56px" }} src={item.brandImageUrl} />
                </Col>
                <Col span={8}>
                  <InventoryStockItemRow
                    label="Model"
                    value={`${item.inventoryModelName}`}
                  />
                </Col>
                <Col span={8}>
                  <InventoryStockItemRow
                    label="Category"
                    value={`${item.inventoryItemVariant}`}
                  />
                </Col>
                <Col span={4}>
                  <InventoryStockItemRow
                    label="Quantity"
                    value={
                      getUserType() !== "RMAN"
                        ? `${item.quantity} ${item.inventoryMeasurementUnit}S`
                        : "---"
                    }
                  />
                </Col>
              </Row>
            </Col>
          );
        })}
      </Row>
    </>
  );
};
export default ViewInventoryItemDetailsComponent;
