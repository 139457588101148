import React, { useEffect, useState } from "react";
import { Col, Row, Typography } from "antd";
import "./index.css";
import BankIcon from "./../../assets/svgs/BankIcon.svg";
import useAxiosPost from "../../hooks/useAxiosPost";
import SingleDetailsRowWithLabelAndValue from "../connectionDetails/cards/SingleDetailsRowWithLabelAndValue";
import { useNavigate, useParams } from "react-router-dom";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import { getUserType } from "../../helpers/userDetails";
const BankAccountProfile = ({}) => {
  const [bankProfile, setBankProfile] = useState<BankAccountList | null>(null);
  const { intPkBankAccountID } = useParams();
  const navigate = useNavigate();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("banks/getBankAccountDetails");

  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      //   setStockProfile(data);
      setBankProfile(data);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({ intPkBankAccountID: Number(intPkBankAccountID) });
  }, []);

  return (
    <>
      <Row className="stock-item-profile-items-container" gutter={[16, 16]}>
        {bankProfile && (
          <>
            <Col className="stock-item-brand-image-container" span={12}>
              <img src={bankProfile.url} />
            </Col>
            <Col span={12}>
              <div className="stock-item-profile-details-card-container">
                <SingleDetailsRowWithLabelAndValue
                  value={`${bankProfile.accountTitle}`}
                  label={`Account Title`}
                  icon={BankIcon}
                />

                {bankProfile.address && (
                  <SingleDetailsRowWithLabelAndValue
                    value={`${bankProfile.address}`}
                    label={`Branch Name`}
                    icon={BankIcon}
                  />
                )}
                {bankProfile.branchCode && (
                  <SingleDetailsRowWithLabelAndValue
                    value={`${bankProfile.branchCode}`}
                    label={`Branch Code`}
                    icon={BankIcon}
                  />
                )}

                <SingleDetailsRowWithLabelAndValue
                  value={`${bankProfile.accountNumber}`}
                  label={`Account Number`}
                  icon={BankIcon}
                  isBorderBottom={false}
                />
              </div>

              {getUserType() === "CEO" && (
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <NetwalaButton
                    htmlType="submit"
                    backgroundColor={ButtonProps.background.STANDARD}
                    height={ButtonProps.height.STANDARD}
                    width={ButtonProps.width.STANDARD}
                    textColor={ButtonProps.color.STANDARD}
                    onClick={() => {
                      console.log("clicked");
                      navigate(`/update-bank-account/${intPkBankAccountID}`);
                    }}
                    text="Update Details"
                    disabled={apiLoading}
                  />
                </div>
              )}
            </Col>
          </>
        )}
      </Row>
    </>
  );
};
export default BankAccountProfile;
