import React, { useEffect, useContext, useState } from "react";
import NetwalaModal from "../Items/Modal/Index";
import RechargeIcon from "./../../assets/svgs/RechargeIcon.svg";
import { Col, Row, Typography, DatePicker, Form, Input } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import "./styles/RequestIPTVModal.css";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import BalanceContext from "../../context/BalanceContext";
import EditIconYellow from "./../../assets/svgs/EditIconYellow.svg";
import {
  getDifferenceOfDaysBetweenCurrentTimeAndInputDate,
  getDifferenceOfDaysBetweenInputDateAndCurrentTime,
} from "../../helpers/date";
import WarningIcon from "./../../assets/svgs/Warning.svg";

const RechargeConnectionModal: React.FC<RechargeModalProps> = ({
  show,
  handleClose,
  connection,
  priceAfterDiscount,
  discount,
  lastExpiryDate,
  settings,
}) => {
  const { value, setValue } = useContext(BalanceContext);

  const { openNotification, contextHolder } = useMultiNotification();
  const [confirmRecharge, setConfirmRecharge] = useState<boolean>(false);
  const [rechargeConfirmationMessages, setRechargeConfirmationMessages] =
    useState<string[]>([]);
  const [dataToSend, setDataToSend] =
    useState<RechargeConnectionApiProp | null>(null);
  const [
    postConnectionRechargeApi,
    rechargeApiResponse,
    rechargeApiError,
    rechargeApiLoading,
    rechargeApiMessage,
    rechargeApiErrorCount,
  ] = useAxiosPost<RechargeConnectionApiProp>(
    "invoices/RechargeConnectionWithInvoice",
    true
  );
  const [isAmountEditEnabled, setIsAmountEditEnabled] =
    useState<boolean>(false);
  const [invoiceAmount, setInvoiceAmount] =
    useState<number>(priceAfterDiscount);
  useEffect(() => {
    if (rechargeApiError !== null && rechargeApiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", rechargeApiError);
    }
  }, [rechargeApiError, rechargeApiErrorCount]);
  useEffect(() => {
    if (rechargeApiResponse !== null) {
      const { message } = rechargeApiResponse;
      if (message) {
        setValue(value + 1);
        openNotification(`success`, "Success", message);

        setTimeout(() => {
          handleClose(true);
        }, 2500);
      }
    }
  }, [rechargeApiResponse]);
  const dateFormat = "YYYY-MM-DD";
  const currentDate = lastExpiryDate; // Get the current date
  currentDate.setMonth(currentDate.getMonth() + 1); // Add one month
  const defaultDate = moment(new Date(currentDate))
    .format(dateFormat)
    .toString();
  const onFinish = (values: any) => {
    // Handle form submission, values.email will contain the entered email address
    const { expiryDate, amount } = values;
    const currentDate = new Date();
    const differenceInDays = getDifferenceOfDaysBetweenCurrentTimeAndInputDate(
      connection.rechargeDetails?.dateCreated || currentDate.toString()
    );
    let _amount = isAmountEditEnabled === true ? amount : priceAfterDiscount;
    const differenceInExpiryDays =
      getDifferenceOfDaysBetweenInputDateAndCurrentTime(connection.expiryDate);

    const expiryDateFormatted = expiryDate.format("YYYY-MM-DD");
    const dataToSend: RechargeConnectionApiProp = {
      intPkConnectionID: connection.intPkConnectionID,
      expiryDate: expiryDateFormatted,
      amount: _amount,
    };
    console.log({ differenceInDays });
    if (differenceInDays < 1 && new Date(connection.expiryDate) > new Date()) {
      setRechargeConfirmationMessages([
        ` You have recharged ${
          connection.firstName + " " + connection.lastName
        } in Previous 24 hours`,
        `Do you want to recharge again?`,
      ]);
      setDataToSend(dataToSend);
      setConfirmRecharge(true);
    } else if (differenceInExpiryDays > 5) {
      setDataToSend(dataToSend);
      setRechargeConfirmationMessages([
        ` More than 5 days remaining of account expiring.`,
        `Do you want to recharge before time?`,
      ]);
      setConfirmRecharge(true);
    } else {
      postConnectionRechargeApi(dataToSend);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const disabledDate = (currentDate: any) => {
    const _defaultDate = dayjs(defaultDate, "YYYY-MM-DD");
    // Disable dates before 3 days from the default date and after 3 days from the default date
    return (
      currentDate.isBefore(_defaultDate.subtract(3, "days"), "day") ||
      currentDate.isAfter(_defaultDate.add(3, "days"), "day")
    );
  };

  return (
    <>
      <NetwalaModal
        title="Recharge Connection"
        titleImage={RechargeIcon}
        headerStyle={{
          backgroundColor: "#292D8E",
          color: "#FFFFFF",
          fontSize: "16px",
          fontWeight: "400",
          height: "50px",
        }}
        open={show}
        onCancel={() => handleClose(false)}
      >
        {contextHolder}

        {confirmRecharge === true ? (
          <>
            <Row>
              <Col className="recharge-confirmation-modal" span={18} offset={3}>
                <img src={WarningIcon} />
                {rechargeConfirmationMessages.map((item) => {
                  return (
                    <Typography
                      key={Math.random()}
                      className="recharge-confirmation-message"
                    >
                      {item}
                    </Typography>
                  );
                })}

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <NetwalaButton
                    backgroundColor={ButtonProps.background.DANGER}
                    height={ButtonProps.height.STANDARD}
                    width={ButtonProps.width.ONE24}
                    textColor={ButtonProps.color.STANDARD}
                    onClick={() => {
                      if (dataToSend) {
                        postConnectionRechargeApi(dataToSend);
                      }
                    }}
                    // disabled={isLoading}
                    text="Recharge"
                    //   icon={<img src={PlusIcon} />}
                    borderColor="#DF3131"
                    disabled={rechargeApiLoading}
                  />
                  <NetwalaButton
                    backgroundColor={ButtonProps.background.SECONDARY}
                    height={ButtonProps.height.STANDARD}
                    width={ButtonProps.width.ONE24}
                    textColor={ButtonProps.color.BLACK}
                    onClick={() => {
                      handleClose(false);
                    }}
                    // disabled={isLoading}
                    text="Cancel"
                    //   icon={<img src={PlusIcon} />}
                    borderColor="#D7D7D7"
                    disabled={rechargeApiLoading}
                  />
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <Row>
            <Col span={24} className="iptv-modal-connection-details-container">
              <Typography className="user-full-name">
                {connection.firstName + " " + connection.lastName}
              </Typography>
              <Typography className="user-phone">
                {connection.phoneNumber}
              </Typography>
              <Typography className="user-address">
                {`${connection.address},${connection.areaName}`}
              </Typography>
            </Col>
            {connection.connectionType === "INTERNET" ? (
              <Col span={24} className="iptv-modal-package-details">
                <div className="iptv-modal-isp-details-container">
                  <img style={{ maxHeight: "50px" }} src={connection.url} />
                  <Typography className="isp-name">
                    {connection.ISPName}
                  </Typography>
                </div>
                <Typography className="package-name">
                  {connection.packageName}
                </Typography>
              </Col>
            ) : null}

            <Col span={24} className="iptv-modal-package-details">
              <Form
                requiredMark={false}
                style={{ width: "100%" }}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                name="RechargeForm"
                initialValues={{
                  expiryDate: dayjs(defaultDate, "YYYY-MM-DD"),
                  amount: priceAfterDiscount,
                }}
              >
                <Form.Item
                  name="expiryDate"
                  rules={[
                    { required: true, message: "Please enter Expiry Date" },
                  ]}
                  label={
                    <span
                      style={{
                        color: "#DE1818",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Next Expiry Date
                    </span>
                  }
                >
                  <DatePicker
                    style={{ width: "100%", height: "48px" }}
                    format={dateFormat}
                    defaultValue={dayjs(defaultDate, "YYYY-MM-DD")}
                    disabledDate={disabledDate}
                  />
                </Form.Item>
                {isAmountEditEnabled === true && (
                  <Form.Item
                    name="amount"
                    rules={[
                      { required: true, message: "Please Enter Amount" },
                      {
                        validator: (_, value) =>
                          value > -1
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error("Amount must be greater than 0")
                              ),
                      },
                      {
                        validator: (_, value) =>
                          value > priceAfterDiscount + discount
                            ? Promise.reject(
                                new Error(
                                  "Amount must not be greater than total Amount"
                                )
                              )
                            : Promise.resolve(),
                      },
                    ]}
                    label={
                      <span
                        style={{
                          color: "#DE1818",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Invoice Amount
                      </span>
                    }
                  >
                    <Input
                      value={invoiceAmount}
                      onChange={(e) => {
                        setInvoiceAmount(Number(e.target.value));
                      }}
                      type="number"
                      className="user-input-general-height"
                      placeholder="10"
                    />
                  </Form.Item>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                    marginBottom: "10px",
                    gap: "15px",
                  }}
                >
                  <Typography
                    style={{
                      color: "#359528",
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                  >
                    {invoiceAmount}/-
                  </Typography>
                  {settings.isInvoiceAmountEditable === 1 && (
                    <img
                      className="cursor-pointer"
                      src={EditIconYellow}
                      onClick={(e) => {
                        setIsAmountEditEnabled(true);
                      }}
                    />
                  )}
                </div>
                <Form.Item>
                  <NetwalaButton
                    backgroundColor={ButtonProps.background.YELLOW}
                    height={ButtonProps.height.STANDARD}
                    width={`100%`}
                    textColor={ButtonProps.color.STANDARD}
                    onClick={() => {}}
                    htmlType="submit"
                    text="Recharge Now"
                    borderColor="#FFA726"
                    disabled={rechargeApiLoading}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        )}
      </NetwalaModal>
    </>
  );
};

export default RechargeConnectionModal;
