import React from "react";
import { Row, Col, Typography, Progress, Tag } from "antd";
import CopyToClipboard from "react-copy-to-clipboard";

import LocationBlackIcon from "./../../../../assets/svgs/BlackIcons/LocationBlack.svg";
import PromisedTransactionIcon from "./../../../../assets/svgs/PromisedTransactionIcon.svg";
import IPTVIcon from "./../../../../assets/svgs/IPTVIcon.svg";
import StaticIPIcon from "./../../../../assets/svgs/IPWhiteList.svg";
import CATVBlueBG from "./../../../../assets/svgs/CATVBlueBG.svg";
import InternetBlueBG from "./../../../../assets/svgs/InternetBlueBG.svg";
import CopyIcon from "./../../../../assets/svgs/CopyIcon.svg";
import "./connections.css";

import {
  formatDate,
  formatDateForJustMonthDayAndYear,
} from "../../../../helpers/date";
import { calculatePercent, daysRemaining } from "../../../../helpers/progress";
interface ConnectionSubscriptionCard {
  name: string;
  price: string | number;
  icon: any;
}
const ConnectionSubscription: React.FC<ConnectionSubscriptionCard> = ({
  name,
  price,
  icon,
}) => {
  return (
    <div className="single-subscription-card">
      <div className="subscription-icon-container">
        <img src={icon} />
      </div>
      <div className="subscription-details-container">
        <Typography className="subscription-label">{name}</Typography>
        <Typography className="subscription-value">{price}</Typography>
      </div>
    </div>
  );
};
const SingleConnectionCard: React.FC<SingleConnectionCardProp> = ({
  connectionDetails,
}) => {
  const gradientFill = {
    from: "#67318C",
    to: "#1C1997",
    "0%": "#67318C", // Start color
    "100%": "#1C1997", // End color
  };
  const calculateDiscount = () => {
    const { fixedDiscountAmount, onceDiscountAmount, onceDiscountExpiry } =
      connectionDetails;
    let discount = 0;
    if (fixedDiscountAmount) {
      discount = fixedDiscountAmount;
    }
    if (
      onceDiscountAmount &&
      onceDiscountExpiry &&
      new Date(onceDiscountExpiry) > new Date()
    ) {
      discount = onceDiscountAmount;
    }
    return discount;
  };

  return (
    <>
      <Row className="connection-container">
        {connectionDetails.totalOpenPromises > 0 && (
          <img
            src={PromisedTransactionIcon}
            style={{
              maxWidth: "20px",
              marginLeft: "-19px",
              marginTop: "-20px",
            }}
          />
        )}
        {/* Header */}
        <Col span={24} className="connection-details-header">
          <div style={{ display: "flex", gap: "5px" }}>
            <img
              src={
                connectionDetails.connectionType === "INTERNET"
                  ? InternetBlueBG
                  : CATVBlueBG
              }
            />
            <div className="user-name-container">
              <Typography className="user-name-label">User Name</Typography>
              <Typography className="user-name-value">
                {connectionDetails.connectionType === "INTERNET" ? (
                  <div>
                    {connectionDetails.username}{" "}
                    <CopyToClipboard
                      text={connectionDetails.username || ""}
                      onCopy={() => {}}
                    >
                      <img
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        src={CopyIcon}
                        className="cursor-pointer "
                      />
                    </CopyToClipboard>
                  </div>
                ) : (
                  `${connectionDetails.firstName} ${connectionDetails.lastName}`
                )}
              </Typography>
            </div>
          </div>
          {connectionDetails.connectionType === "INTERNET" && (
            <div>
              <img src={connectionDetails.url} style={{ maxWidth: "50px" }} />
            </div>
          )}
          <div className="recharge-date-container">
            <Typography className="recharge-label">Recharge Date</Typography>
            <Typography className="recharge-date-value">
              {connectionDetails.isNewConnection !== true ? (
                <>
                  {connectionDetails.rechargeDetails
                    ? formatDate(
                        new Date(connectionDetails.rechargeDetails.dateCreated)
                      )
                    : "------------"}
                </>
              ) : (
                "------------"
              )}
            </Typography>
          </div>
        </Col>
        <Col style={{ display: "flex", justifyContent: "flex-end" }} span={24}>
          {connectionDetails.isNewConnection !== true && (
            <div className="recharge-date-container">
              <Typography className="recharge-label">Expiry Date</Typography>
              <Tag color="red">
                {connectionDetails.expiryDate
                  ? formatDateForJustMonthDayAndYear(
                      new Date(connectionDetails.expiryDate)
                    )
                  : "------------"}
              </Tag>
            </div>
          )}
        </Col>
        {/* Header End */}
        {/* Middle */}
        <Col span={24} className="connection-middle-container">
          <div className="connection-address-container">
            <div className="address-icon">
              <img src={LocationBlackIcon} />
            </div>
            <div className="address-values">
              {" "}
              <Typography className="address-label">Address</Typography>
              <Typography className="address-value">
                {`${connectionDetails.address} , ${connectionDetails.areaName}`}
              </Typography>
            </div>
          </div>
          <div className="package-details">
            <Typography className="package-label">Package</Typography>
            {connectionDetails.connectionType === "INTERNET" ? (
              <div
                className="package-color-container"
                style={{
                  color: connectionDetails.color,
                  background: connectionDetails.bgColor,
                  border: "1px solid",
                  borderColor: connectionDetails.borderColor,
                }}
              >
                {connectionDetails.packageName}
              </div>
            ) : (
              <Typography className="package-value">CATV</Typography>
            )}
            {/* <Typography className="package-value">
              {connectionDetails.connectionType === "INTERNET"
                ? connectionDetails.packageName
                : "CATV"}
            </Typography> */}
            <Typography className="package-price">
              {connectionDetails.rechargePrice}/-
            </Typography>
            {connectionDetails.finalDiscount > 0 ? (
              <Typography className="discount-price">
                -{connectionDetails.finalDiscount}/-
              </Typography>
            ) : null}
          </div>
        </Col>
        {/* Middle End */}
        {/* Progress */}
        {connectionDetails.isNewConnection !== true && (
          <Col span={24} className="progress-bar-container">
            <Typography className="progress-bar-label">
              {daysRemaining(connectionDetails.expiryDate)}
            </Typography>
            <Progress
              percent={calculatePercent(connectionDetails.expiryDate)}
              showInfo={false}
              strokeWidth={20}
              strokeColor={gradientFill}
              trailColor="#EBEBEB"
            />
          </Col>
        )}

        {/* Progress End */}
        {/* Subscriptions */}
        <Col span={24} className="connection-subscriptions-container">
          {connectionDetails.iptvDetails && (
            <ConnectionSubscription
              name="IPTV"
              price={`${connectionDetails.iptvDetails.iptvSalePrice}/-`}
              icon={IPTVIcon}
            />
          )}
          {connectionDetails.staticIpDetails && (
            <ConnectionSubscription
              name="Static IP"
              price={`${connectionDetails.staticIpDetails.staticIpSalePrice}/-`}
              icon={StaticIPIcon}
            />
          )}

          {/* <ConnectionSubscription name="Tickets" price={2} icon={TicketsIcon} /> */}
        </Col>
        {/* Subscriptions End */}
      </Row>
    </>
  );
};
export default SingleConnectionCard;
