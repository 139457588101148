import React, { useContext, useEffect, useState } from "react";
import NetwalaModal from "../Items/Modal/Index";
import { Col, Row, Form, Input } from "antd";

import "./styles/RequestIPTVModal.css";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import type { RadioChangeEvent } from "antd";
import { Radio } from "antd";
import NetwalaSelect from "../Items/Select/Index";
import ConfirmInventorySaleModal from "./ConfirmInventorySaleModal";
import BalanceContext from "../../context/BalanceContext";
const SaleInventoryModal: React.FC<SaleInventoryModalProps> = ({
  show,
  handleClose,
  intPkInventoryStockID,
  quantity,
  inventoryProfile,
}) => {
  const { value, setValue } = useContext(BalanceContext);

  const [form] = Form.useForm();
  const [quantitySelected, setQuantitySelected] = useState<number>(0);
  const [receivingAmount, setReceivingAmount] = useState<number>(0);
  const { openNotification, contextHolder } = useMultiNotification();
  const [isSaleClicked, setIsSaleClicked] = useState<boolean>(false);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<SaleInventoryApiProp>("invoices/saleInventoryInvoice", true);
  const [dataToSend, setDataToSend] = useState<SaleInventoryApiProp | null>(
    null
  );
  const [user, setUser] = useState<string>("");
  const [getConnectionsApi, connectionsApiResponse] =
    useAxiosPost<ResponseProp>(
      "connections/getConnectionsByCompanyMiniDetails"
    );

  const onFinish = (values: any) => {
    const { enteredQuantity, connection, amount } = values;
    let username;
    const connectionObj = connectionOptions.find(
      (item) => Number(item.value) === Number(connection)
    );
    if (connectionObj) {
      username = connectionObj.label;
    }

    const _saleConfirmation: InventorySaleConfirmationDataProp = {
      itemName: inventoryProfile.inventoryModelName,
      itemType: inventoryProfile.inventoryItemVariant,
      quantity: Number(enteredQuantity),
      unitPrice: inventoryProfile.unitSalePrice,
      totalPrice: Number(enteredQuantity) * inventoryProfile.unitSalePrice,
      username: username || "",
      enteredAmount: amount,
    };
    setIsSaleClicked(true);
    setSaleConfirmation(_saleConfirmation);
    const dataToSend: SaleInventoryApiProp = {
      quantity: Number(enteredQuantity),
      intPkInventoryStockID,
      intPkConnectionID: Number(connection),
      comments: values.comments,
      invoicePaidAmount: Number(amount),
    };
    setDataToSend(dataToSend);
  };
  const [connectionOptions, setConnectionsOptions] = useState<
    NetwalaSelectOptions[]
  >([]);

  const [saleConfirmation, setSaleConfirmation] =
    useState<InventorySaleConfirmationDataProp | null>(null);
  useEffect(() => {
    if (connectionsApiResponse !== null) {
      const data: any = connectionsApiResponse.responseData;
      const connectionOptions: NetwalaSelectOptions[] = [];
      data.map((item: any) => {
        if (item.connectionType === "INTERNET") {
          connectionOptions.push({
            label: item.username,
            value: item.intPkConnectionID,
          });
        } else if (item.connectionType === "CATV") {
          connectionOptions.push({
            label: `${item.firstName} ${item.lastName} - ${item.address}`,
            value: item.intPkConnectionID,
          });
        }
      });
      setConnectionsOptions(connectionOptions);
    }
  }, [connectionsApiResponse]);

  useEffect(() => {
    if (apiResponse !== null) {
      const { message } = apiResponse;
      if (message) {
        setValue(value + 1);

        openNotification("success", "Success", message);
        setTimeout(() => {
          handleClose(true);
        }, 2500);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification("error", "Error", apiError);
    }
  }, [apiError, apiErrorCount]);
  const onFinishFailed = (errorInfo: any) => {};
  useEffect(() => {
    // getConnectionsApi({});
    getConnectionsApi({});
  }, []);

  const handleConfirmation = (isConfirmed: boolean) => {
    if (isConfirmed === false) {
      handleClose(false);
      return;
    }
    if (isConfirmed === true && dataToSend !== null) {
      postApiData(dataToSend);
    }
  };
  useEffect(() => {
    console.log({ quantitySelected });
    form.setFieldsValue({
      amount: quantitySelected * inventoryProfile.unitSalePrice,
    });
  }, [quantitySelected]);
  return (
    <>
      <NetwalaModal
        title={
          isSaleClicked === true ? "Inventory Confirmation" : "Sale Inventory"
        }
        headerStyle={{
          backgroundColor: "#292D8E",
          color: "#FFFFFF",
          fontSize: "16px",
          fontWeight: "400",
          height: "50px",
        }}
        open={show}
        onCancel={() => {
          if (apiLoading) {
            return;
          }
          handleClose(false);
        }}
      >
        {contextHolder}
        {isSaleClicked === true && saleConfirmation !== null ? (
          <ConfirmInventorySaleModal
            handleConfirmation={handleConfirmation}
            confirmationData={saleConfirmation}
            apiLoading={apiLoading}
          />
        ) : (
          <Row>
            <Col span={24} className="iptv-modal-package-details">
              <Form
                requiredMark={false}
                style={{ width: "100%", marginTop: "50px" }}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                name="saleInventoryForm"
                form={form}
              >
                <Form.Item
                  label={
                    <span className="create-user-form-label">
                      Choose Connection
                    </span>
                  }
                  name="connection"
                  rules={[
                    {
                      required: true,
                      message: "Please Choose Connection",
                    },
                  ]}
                >
                  <NetwalaSelect
                    size="large"
                    height="45px"
                    options={connectionOptions}
                    showSearch={true}
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <span className="create-user-form-label">Quantity</span>
                  }
                  name="enteredQuantity"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Quantity",
                    },
                    {
                      validator: (_, value) =>
                        value > 0
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error("Quantity must be greater than 0")
                            ),
                    },
                  ]}
                >
                  <Input
                    value={quantitySelected}
                    onChange={(e) =>
                      setQuantitySelected(Number(e.target.value))
                    }
                    type="number"
                    className="user-input-general-height"
                    placeholder="10"
                  />
                </Form.Item>
                <div>
                  <span className="create-user-form-label">
                    Total Receivable :{" "}
                    {quantitySelected * inventoryProfile.unitSalePrice}
                  </span>
                </div>

                <Form.Item
                  label={
                    <span className="create-user-form-label">
                      Amount Received
                    </span>
                  }
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Receiving Amount",
                    },
                    {
                      validator: (_, value) =>
                        value >= 0
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error(
                                "Amount must be greater than or equal 0"
                              )
                            ),
                    },
                    {
                      validator: (_, value) =>
                        value <=
                        quantitySelected * inventoryProfile.unitSalePrice
                          ? Promise.resolve()
                          : Promise.reject(new Error("Invalid Amount Entered")),
                    },
                  ]}
                >
                  <Input
                    value={receivingAmount}
                    onChange={(e) => setReceivingAmount(Number(e.target.value))}
                    type="number"
                    className="user-input-general-height"
                    placeholder="100"
                  />
                </Form.Item>

                <Form.Item
                  label={
                    <span className="create-user-form-label">Comments</span>
                  }
                  name="comments"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Comments",
                    },
                    {
                      max: 190,
                      message: "Comments are too long",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    className="user-input-general-height"
                    placeholder="Router sale to John"
                  />
                </Form.Item>

                <Form.Item>
                  <NetwalaButton
                    backgroundColor={ButtonProps.background.STANDARD}
                    height={ButtonProps.height.STANDARD}
                    width={`100%`}
                    textColor={ButtonProps.color.STANDARD}
                    onClick={() => {}}
                    htmlType="submit"
                    text="Sale"
                    disabled={apiLoading}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        )}
      </NetwalaModal>
    </>
  );
};

export default SaleInventoryModal;
