import React, { useEffect, useState } from "react";
import { Table, Input, Button, message, Row, Col } from "antd";
import { ColumnsType } from "antd/es/table";
import Typography from "antd/es/typography/Typography";
import NetwalaButton from "../../../../Items/Button/Index";
import { ButtonProps } from "../../../../../helpers/constants";
import TailSpinLoader from "../../../../Items/Loaders/TailSpinLoader";

const SyncNasProfilesComponent: React.FC<SyncNasProfilesComponentProp> = ({
  profiles,
  handleUpdateProfilesWithPrices,
  isLoading,
}) => {
  const [data, setData] = useState<MikroNocProfile[]>([]);
  useEffect(() => {
    setData(profiles);
  }, [profiles]);
  const handlePriceChange = (value: number, index: number) => {
    const newData = [...data];
    newData[index].price = value;
    setData(newData);
  };

  const handleSubmit = () => {
    const allPricesEntered = data.every(
      (item) => typeof item.price === "number" && item.price >= 1
    );
    if (allPricesEntered) {
      handleUpdateProfilesWithPrices(data);
      // Perform submit action
      //   message.success("All prices entered successfully!");
    } else {
      message.error(
        "Please enter valid prices (numbers greater than or equal to 1) before submitting."
      );
    }
  };

  const columns: ColumnsType<MikroNocProfile> = [
    {
      title: "Name",
      dataIndex: "profileName",
      key: "profileName",
    },
    {
      title: "Price",
      key: "price",
      render: (text, record, index) => (
        <Input
          value={record.price}
          onChange={(e) => handlePriceChange(Number(e.target.value), index)}
          placeholder="Enter Price"
          type="number" // Set input type to 'number'
          min={1} // Set minimum value
        />
      ),
    },
  ];

  if (isLoading) {
    return (
      <Row style={{ width: "100%" }}>
        <Col span={24}>
          <TailSpinLoader />
        </Col>
      </Row>
    );
  }
  return (
    <>
      <div className="netwala-users-table-container">
        <Row>
          <Col span={24}>
            <Typography className="netwala-user-tables-heading">
              Found Packages
            </Typography>
            <Table
              dataSource={data}
              columns={columns}
              pagination={false}
              bordered
            />
          </Col>
        </Row>
      </div>
      <Row style={{ width: "100%" }}>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "30px",
            marginBottom: "30px",
          }}
          span={24}
        >
          <NetwalaButton
            htmlType="button"
            backgroundColor={ButtonProps.background.STANDARD}
            height={ButtonProps.height.STANDARD}
            width={ButtonProps.width.STANDARD}
            textColor={ButtonProps.color.STANDARD}
            onClick={() => {
              handleSubmit();
            }}
            text="Submit"
          />
        </Col>
      </Row>
    </>
  );
};

export default SyncNasProfilesComponent;
