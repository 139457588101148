import { useEffect, useState } from "react";
import { Row, Col, Typography } from "antd";
import "./index.css";
import useAxiosPost from "../../hooks/useAxiosPost";
import { useNavigate } from "react-router-dom";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import { getUserType } from "../../helpers/userDetails";
const ViewBankAccounts = () => {
  const navigate = useNavigate();
  const [bankAccounts, setBankAccounts] = useState<BankAccountList[]>([]);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("banks/getMyBankAccounts");
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setBankAccounts(data || []);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({});
  }, []);
  return (
    <>
      {getUserType() === "CEO" && (
        <Row gutter={[32, 32]} style={{ width: "100%", marginTop: "20px" }}>
          <Col offset={18} span={6}>
            <NetwalaButton
              backgroundColor={ButtonProps.background.STANDARD}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.STANDARD}
              textColor={ButtonProps.color.STANDARD}
              onClick={() => {
                navigate("/create-bank-account");
              }}
              text="Create Bank Account"
            />
          </Col>
        </Row>
      )}
      <Row gutter={[32, 32]} style={{ width: "100%", marginTop: "20px" }}>
        {bankAccounts.map((item) => {
          return (
            <Col
              onClick={(e) => {
                e.preventDefault();
                navigate(`/bank-account-profile/${item.intPkBankAccountID}`);
              }}
              span={6}
            >
              <div className="bank-account-mini-view-container">
                <img src={item.url} style={{ maxHeight: "180px" }} />
                <Typography className="bank-account-address-value-label">
                  {item.bankType === "STANDARD"
                    ? item.address
                    : item.accountNumber}
                </Typography>
              </div>
            </Col>
          );
        })}
      </Row>
    </>
  );
};
export default ViewBankAccounts;
