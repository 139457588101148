import React, { useState, useEffect, useRef } from "react";

import { Col, Dropdown, Input, Row, Table, Tag, Typography } from "antd";
import type { TableProps } from "antd";
import type { MenuProps } from "antd";

import type { SearchProps } from "antd/es/input/Search";
import { MenuOutlined } from "@ant-design/icons";

const { Search } = Input;
import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import TailSpinLoader from "../Loaders/TailSpinLoader";
import { formatDate } from "../../../helpers/date";

const MikroNocIspsTable: React.FC<MikroNocIspsTableProp> = ({
  isps,
  isLoading,
}) => {
  const [activeRow, setActiveRow] = useState<MikroNocIsp | null>(null);

  const companyItems: MenuProps["items"] = [
    {
      key: "VIEW_STAFF",
      label: (
        <Typography className="profile-drop-down-item">View Staff</Typography>
      ),
    },
    {
      key: "VIEW_MIKRO_NOC_ISPS",
      label: (
        <Typography className="profile-drop-down-item">
          View Mikro Noc Isps
        </Typography>
      ),
    },
  ];
  // const debouncedSearch = useRef<((query: string) => void) | null>(null);
  // const [searchTerm, setSearchTerm] = useState<string>("");
  const navigate = useNavigate();
  const handleMenuClick = (e: any) => {
    if (activeRow === null) {
      return;
    }
    switch (e.key) {
      case "VIEW_STAFF":
        navigate(`/super-admin/company/view-staff/${activeRow.intPkCompanyID}`);
        break;
      case "VIEW_MIKRO_NOC_ISPS":
        navigate(
          `/super-admin/company/view-mikronoc-isps/${activeRow.intPkCompanyID}`
        );
        break;
    }
  };
  const columns: TableProps<MikroNocIsp>["columns"] = [
    {
      title: "ISP Name",
      dataIndex: "ISPName",
      key: "ISPName",
    },
    {
      title: "Logo",
      render: (row) => (
        <img src={row.url} style={{ maxWidth: "50px", maxHeight: "50px" }} />
      ),
    },
    {
      title: "ISP Support No.",
      dataIndex: "ISPSupportNumber",
      key: "ISPSupportNumber",
    },
    {
      title: "ISP Sales No.",
      dataIndex: "IspSalesNumber",
      key: "IspSalesNumber",
    },

    {
      title: "NAS IP",
      render: (row) => row?.nasDetails?.nasIpAddress,
    },
    {
      title: "NAS UserName",
      render: (row) => row?.nasDetails?.nasUsername,
    },
    {
      title: "NAS Password",
      render: (row) => row?.nasDetails?.nasPassword,
    },
    {
      title: "NAS PORT",
      render: (row) => row?.nasDetails?.nasPort,
    },
    {
      title: "MikroNoc Probe IP",
      render: (row) => row?.nasDetails?.mikroNocProbeIP,
    },

    // {
    //   title: "Action",
    //   render: (row) => (
    //     <Dropdown
    //       menu={{
    //         items: companyItems,

    //         onClick: handleMenuClick,
    //       }}
    //       placement="bottomRight"
    //     >
    //       <MenuOutlined
    //         onMouseEnter={(e) => {
    //           setActiveRow(row);
    //         }}
    //         className="cursor-pointer"
    //       />
    //     </Dropdown>
    //   ),
    // },
  ];
  // const onSearch: SearchProps["onSearch"] = (value, _e, info) => {
  //   console.log(info?.source, value);
  //   if (value.length < 3) {
  //     return;
  //   }
  //   handleSearch(value, 1);
  // };

  // useEffect(() => {
  //   const delaySearch = setTimeout(() => {
  //     handleSearch(searchTerm, 1);
  //   }, 500);

  //   return () => clearTimeout(delaySearch); // Cleanup the timeout on component unmount
  // }, [searchTerm]);
  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setSearchTerm(e.target.value);
  // };

  return (
    <div className="netwala-users-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <div className="users-search-container">
              <Typography className="netwala-user-tables-heading">
                Company MikroNoc Isps
              </Typography>
            </div>
            <Table
              className="netwala-users-table striped-table"
              columns={columns}
              dataSource={isps}
              // pagination={{
              //   pageSize: 10,
              //   showQuickJumper: true,
              //   showSizeChanger: true,
              //   pageSizeOptions: ["10", "20", "30", "40"],
              // }}
              pagination={false}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
export default MikroNocIspsTable;
