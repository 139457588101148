import React, { useContext, useEffect, useState } from "react";
import NetwalaModal from "../Items/Modal/Index";
import StaticIPWhiteIcon from "./../../assets/svgs/IconsWhite/StaticIPWhite.svg";
import RemoveIcon from "./../../assets/svgs/RemoveIcon.svg";
import PlusIcon from "./../../assets/svgs/IconsWhite/PlusIcon.svg";

import { Col, Form, Input, Row, Typography } from "antd";
import "./styles/RequestIPTVModal.css";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import RemoveConfirmationUtil from "./RemoveConfirmationUtil";
import useAxiosPost from "../../hooks/useAxiosPost";
import ThreeDotsLoader from "../Items/Loaders/Threedots";
import useMultiNotification from "../../hooks/useNotification";
import BalanceContext from "../../context/BalanceContext";
import EditIconYellow from "./../../assets/svgs/EditIconYellow.svg";

const StaticIPModal: React.FC<StaticIPModalProp> = ({
  show,
  handleClose,
  connection,
  settings,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();
  const { value, setValue } = useContext(BalanceContext);
  const [isAmountEditEnabled, setIsAmountEditEnabled] =
    useState<boolean>(false);
  const [invoiceAmount, setInvoiceAmount] = useState<number>(0);

  const [
    getStaticIPDetailsByConnection,
    subscriptionApiResponse,
    subscriptionApiError,
    subscriptionApiLoading,
    subscriptionApiMessage,
    subscriptionApiErrorCount,
  ] = useAxiosPost<IPTVSubscriptionForConnectionApiProp>(
    "connections/getStaticIPDetailsByConnection"
  );
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<IPTVByISPAndCompanyApiProp>(
    "staticIps/getStaticIPPriceByCompanyAndISP"
  );
  const [
    requestToRemoveSubscription,
    removeSubscriptionApiResponse,
    removeSubscriptionApiError,
    removeSubscriptionApiLoading,
    removeSubscriptionApiMessage,
    removeSubscriptionApiErrorCount,
  ] = useAxiosPost<IPTVSubscriptionForConnectionApiProp>(
    "connections/requestToRemoveStaticIPSubscription"
  );
  const [
    postSubscribeStaticIPApi,
    subscribeStaticIPApiResponse,
    subscribeStaticIPApiError,
    subscribeStaticIPApiLoading,
    subscribeStaticIPApiMessage,
    subscribeStaticIPApiErrorCount,
  ] = useAxiosPost<RequestIPTVSubscriptionApiProp>(
    "connections/requestStaticIPSubscription",
    true
  );
  const [isStaticIPAdded, setIsStaticIPAdded] = useState(false);
  const [isRemovedClicked, setIsRemoveClicked] = useState(false);
  const [staticIpSubscription, setStatIPSubscription] = useState<any | null>(
    null
  );
  const [staticIp, setStaticIp] = useState<Package | null>(null);

  useEffect(() => {
    getStaticIPDetailsByConnection({
      intPkConnectionID: connection.intPkConnectionID,
    });
    postApiData({ intPkIspID: Number(connection.intPkIspID) });
  }, []);
  useEffect(() => {
    if (subscriptionApiResponse !== null) {
      const data: any = subscriptionApiResponse.responseData;
      if (!data) {
        // setIptvSubscription(null);
        setStatIPSubscription(null);
        setIsStaticIPAdded(false);
      } else {
        // setIptvSubscription(data);
        setIsStaticIPAdded(true);
        setStatIPSubscription(data);
      }
    }
  }, [subscriptionApiResponse]);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      if (data) {
        setStaticIp(data);
        setInvoiceAmount(data.salePrice);
      }
      // setIptv(data);
    }
  }, [apiResponse]);

  const onFinish = (values: any) => {
    if (!staticIp) {
      openNotification(
        `error`,
        "Operation Failed",
        `Error While Subscribing , Please Try again`
      );
      return;
    }
    const { amount } = values;
    const _amount = isAmountEditEnabled === true ? amount : invoiceAmount;
    const dataToSend: RequestIPTVSubscriptionApiProp = {
      intPkConnectionID: connection.intPkConnectionID,
      intPkIspID: Number(connection.intPkIspID),
      amountReceived: _amount,
    };
    postSubscribeStaticIPApi(dataToSend);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const handleAddNewService = () => {};
  useEffect(() => {
    if (subscribeStaticIPApiResponse !== null) {
      const { message } = subscribeStaticIPApiResponse;
      if (message) {
        setValue(value + 1);
        openNotification(`success`, "Success", message);
        setTimeout(() => {
          handleClose(true);
        }, 2000);
      }
    }
  }, [subscribeStaticIPApiResponse]);
  const removeConfirmation = (confirm: boolean) => {
    if (confirm === false) {
      setIsRemoveClicked(false);
      return;
    }
    //we'll call the remove api here and get the result . once done then we'll do the handleClose
    requestToRemoveSubscription({
      intPkConnectionID: connection.intPkConnectionID,
    });
  };
  useEffect(() => {
    if (removeSubscriptionApiResponse !== null) {
      const { message } = removeSubscriptionApiResponse;
      if (message) {
        openNotification(`success`, "Success", message);
        setTimeout(() => {
          handleClose(true);
        }, 2000);
      }
    }
  }, [removeSubscriptionApiResponse]);
  useEffect(() => {
    if (
      subscribeStaticIPApiError !== null &&
      subscribeStaticIPApiErrorCount !== 0
    ) {
      openNotification(`error`, "Operation Failed", subscribeStaticIPApiError);
    }
  }, [subscribeStaticIPApiError, subscribeStaticIPApiErrorCount]);
  return (
    <>
      <NetwalaModal
        title="Static IP"
        titleImage={StaticIPWhiteIcon}
        headerStyle={{
          backgroundColor: "#292D8E",
          color: "#FFFFFF",
          fontSize: "16px",
          fontWeight: "400",
          height: "50px",
        }}
        open={show}
        onCancel={() => handleClose(false)}
      >
        {contextHolder}

        {isRemovedClicked === true ? (
          <RemoveConfirmationUtil
            confirmationButton={removeConfirmation}
            message={` Are you sure you want to remove Static IP Service?`}
            isLoading={removeSubscriptionApiLoading}
          />
        ) : (
          <Row>
            <Col span={24} className="iptv-modal-connection-details-container">
              <Typography className="user-full-name">
                {connection.firstName + " " + connection.lastName}
              </Typography>
              <Typography className="user-phone">
                {connection.username}
              </Typography>
              <Typography className="user-address">
                {connection.address},{connection.areaName}
              </Typography>
            </Col>
            <Col span={24} className="iptv-modal-package-details">
              <div className="iptv-modal-isp-details-container">
                <img style={{ maxHeight: "50px" }} src={connection.url} />
                <Typography className="isp-name">
                  {" "}
                  {connection.ISPName}
                </Typography>
              </div>
              <Typography className="package-name">
                {" "}
                {connection.packageName}
              </Typography>
            </Col>
            {subscriptionApiLoading === true || apiLoading === true ? (
              <ThreeDotsLoader />
            ) : (
              <>
                {isStaticIPAdded === true ? (
                  <>
                    <Col span={24}>
                      <div className="static-ip-subscribed-container">
                        <div className="static-ip-subscription-details">
                          <Typography className="iptv-subscription-label">
                            Static IP Added
                          </Typography>
                          <Typography className="static-ip-value">
                            {Number(staticIpSubscription.static_ip) !== -1
                              ? staticIpSubscription.static_ip
                              : "NO IP"}
                          </Typography>
                        </div>
                        <img
                          onClick={(e) => {
                            setIsRemoveClicked(true);
                          }}
                          src={RemoveIcon}
                          className="cursor-pointer"
                        />
                      </div>
                    </Col>
                  </>
                ) : (
                  <>
                    {connection.isStaticIPRequested ? (
                      <>
                        <Col
                          span={24}
                          className="iptv-service-details-container"
                        >
                          <Typography className="details-message">
                            A subscription request for static IP has already
                            been made.
                          </Typography>
                        </Col>
                      </>
                    ) : (
                      <>
                        {" "}
                        <Col
                          span={24}
                          className="iptv-service-details-container"
                        >
                          <Typography className="details-message">
                            No Static IP Services on your connection
                          </Typography>
                          <div className="price-container">
                            <Typography className="price-label">
                              Additional Price{" "}
                            </Typography>
                            {/* <Typography className="price-value">
                              {" "}
                              {staticIp && staticIp.salePrice}
                              /-
                            </Typography> */}
                          </div>
                        </Col>
                        <Col
                          span={24}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: "40px",
                          }}
                          className="iptv-service-details-container"
                        >
                          {staticIp && (
                            <Form
                              requiredMark={false}
                              style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                              layout="vertical"
                              onFinish={onFinish}
                              onFinishFailed={onFinishFailed}
                              name="requestIpTVModalForm"
                              initialValues={{
                                amount: invoiceAmount,
                              }}
                            >
                              {isAmountEditEnabled === true && (
                                <Form.Item
                                  name="amount"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Enter Amount",
                                    },
                                    {
                                      validator: (_, value) =>
                                        value > -1
                                          ? Promise.resolve()
                                          : Promise.reject(
                                              new Error(
                                                "Amount must be greater than 0"
                                              )
                                            ),
                                    },
                                    {
                                      validator: (_, value) =>
                                        value > staticIp?.salePrice
                                          ? Promise.reject(
                                              new Error(
                                                "Amount must not be greater than total Amount"
                                              )
                                            )
                                          : Promise.resolve(),
                                    },
                                  ]}
                                  label={
                                    <span
                                      style={{
                                        color: "#DE1818",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      Invoice Amount
                                    </span>
                                  }
                                >
                                  <Input
                                    value={invoiceAmount}
                                    onChange={(e) => {
                                      setInvoiceAmount(Number(e.target.value));
                                    }}
                                    type="number"
                                    className="user-input-general-height"
                                    placeholder="10"
                                  />
                                </Form.Item>
                              )}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                  gap: "15px",
                                }}
                              >
                                <Typography
                                  style={{
                                    color: "#359528",
                                    fontSize: "18px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {invoiceAmount}/-
                                </Typography>
                                {settings.isInvoiceAmountEditable === 1 && (
                                  <img
                                    className="cursor-pointer"
                                    src={EditIconYellow}
                                    onClick={(e) => {
                                      setIsAmountEditEnabled(true);
                                    }}
                                  />
                                )}
                              </div>
                              <Form.Item>
                                <NetwalaButton
                                  backgroundColor={
                                    ButtonProps.background.YELLOW
                                  }
                                  height={ButtonProps.height.STANDARD}
                                  width={ButtonProps.width.STANDARD}
                                  textColor={ButtonProps.color.STANDARD}
                                  onClick={() => {}}
                                  htmlType="submit"
                                  text="Request Static IP"
                                  icon={<img src={PlusIcon} />}
                                  borderColor="#FFA726"
                                  disabled={subscribeStaticIPApiLoading}
                                />
                              </Form.Item>
                            </Form>
                          )}
                        </Col>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Row>
        )}
      </NetwalaModal>
    </>
  );
};

export default StaticIPModal;
