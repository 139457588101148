import React, { useState, ReactNode } from "react";
import TitleContext from "./TitleContext";

interface TitleProviderProps {
  children: ReactNode;
}

const TitleProvider: React.FC<TitleProviderProps> = ({ children }) => {
  const [title, setTitle] = useState<string>("Home");

  return (
    <TitleContext.Provider value={{ title, setTitle }}>
      {children}
    </TitleContext.Provider>
  );
};

export default TitleProvider;
