import React, { useEffect } from "react";
import NetwalaModal from "../Items/Modal/Index";
import RechargeIcon from "./../../assets/svgs/RechargeIcon.svg";
import { Col, Row, Form, Input } from "antd";
import "./styles/RequestIPTVModal.css";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
const UpdateConnectionStaticIPModal: React.FC<
  UpdateStaticIPModalForConnectionModalProp
> = ({ show, handleClose, staticIP, intPkConnectionID }) => {
  const { openNotification, contextHolder } = useMultiNotification();

  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<UpdateStaticIPOfConnectionApiProp>(
    "connections/updateStaticIPInConnection",
    true
  );

  const onFinish = (values: any) => {
    const { staticIP } = values;
    postApiData({ intPkConnectionID, staticIP: staticIP });
  };
  useEffect(() => {
    if (apiResponse !== null) {
      const { message } = apiResponse;
      if (message) {
        openNotification("success", "Success", message);
        setTimeout(() => {
          handleClose(true);
        }, 2500);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification("error", "Error", apiError);
    }
  }, [apiError, apiErrorCount]);
  const onFinishFailed = (errorInfo: any) => {};
  return (
    <>
      <NetwalaModal
        title="Update Static IP"
        headerStyle={{
          backgroundColor: "#292D8E",
          color: "#FFFFFF",
          fontSize: "16px",
          fontWeight: "400",
          height: "50px",
        }}
        open={show}
        onCancel={() => {
          handleClose(false);
        }}
      >
        {contextHolder}
        <Row>
          <Col span={24} className="iptv-modal-package-details">
            <Form
              requiredMark={false}
              style={{ width: "100%", marginTop: "50px" }}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              name="updateStaticIpOfConnectionForm"
              initialValues={{
                staticIP: Number(staticIP) !== -1 ? staticIP : "",
              }}
            >
              <Form.Item
                label={
                  <span className="create-user-form-label">Static IP</span>
                }
                name="staticIP"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Static IP",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="192.168.1.1"
                />
              </Form.Item>

              <Form.Item>
                <NetwalaButton
                  backgroundColor={ButtonProps.background.YELLOW}
                  height={ButtonProps.height.STANDARD}
                  width={`100%`}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {}}
                  htmlType="submit"
                  text="Submit"
                  borderColor="#FFA726"
                  disabled={apiLoading}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </NetwalaModal>
    </>
  );
};

export default UpdateConnectionStaticIPModal;
