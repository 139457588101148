import React from "react";
import { Col, Row, Table, Tag, Typography } from "antd";
import type { TableProps } from "antd";
import "./index.css";
import { useNavigate } from "react-router-dom";
import NetwalaButton from "../Button/Index";
import { ButtonProps } from "../../../helpers/constants";

const BulkUsersImportTable: React.FC<BulkUsersDataTable> = ({
  users,
  handleUserSkip,
}) => {
  const navigate = useNavigate();
  const columns: TableProps<BulkUserImportData>["columns"] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (text) => <Tag style={{ fontWeight: "bold" }}>{text} </Tag>,
      sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
    },
    {
      title: "username",
      dataIndex: "username",
      key: "username",
      sorter: (a, b) => a.username.localeCompare(b.username),
    },

    {
      title: "balance",
      dataIndex: "balance",
      key: "balance",
      sorter: (a, b) => a.balance - b.balance,
    },
    {
      title: "Package",
      dataIndex: "packageName",
      key: "packageName",
      sorter: (a, b) => a.packageName.localeCompare(b.packageName),
    },
    {
      title: "Mapped PackageName",
      dataIndex: "mappedPackageName",
      key: "mappedPackageName",
      sorter: (a, b) => a.mappedPackageName.localeCompare(b.mappedPackageName),
    },
    {
      title: "expiry",
      dataIndex: "expiryDate",
      key: "expiryDate",
      sorter: (a, b) => {
        const dateA = new Date(a?.expiryDate || new Date());
        const dateB = new Date(b.expiryDate || new Date());
        if (dateA < dateB) {
          return -1;
        }
        if (dateA > dateB) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      sorter: (a, b) => a.address.localeCompare(b.address),
    },
    {
      title: "Skip",
      render: (row) => (
        <>
          <NetwalaButton
            backgroundColor={ButtonProps.background.DANGER}
            height={"39px"}
            width={"60px"}
            textColor={ButtonProps.color.STANDARD}
            onClick={() => {
              handleUserSkip(row.id);
            }}
            text="Skip"
            borderColor="#DF3131"
          />
        </>
      ),
    },
  ];

  return (
    <div className="netwala-users-table-container">
      <Row>
        <Col span={24}>
          <Typography className="netwala-user-tables-heading">
            Total Found Users ({users.length})
          </Typography>
          <Table
            className="netwala-users-table striped-table"
            columns={columns}
            dataSource={users}
            // pagination={false}
          />
        </Col>
      </Row>
    </div>
  );
};
export default BulkUsersImportTable;
