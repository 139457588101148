import React, { useEffect, useContext, useState } from "react";
import NetwalaModal from "../Items/Modal/Index";
import { Col, Row, Typography, DatePicker, Form, Input } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import "./styles/RequestIPTVModal.css";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import BalanceContext from "../../context/BalanceContext";

const UpdateExpiryDateModal: React.FC<UpdateConnectionExpiryModalProps> = ({
  show,
  handleClose,
  connection,
  lastExpiryDate,
}) => {
  const { value, setValue } = useContext(BalanceContext);

  const { openNotification, contextHolder } = useMultiNotification();

  const [
    postConnectionRechargeApi,
    rechargeApiResponse,
    rechargeApiError,
    rechargeApiLoading,
    rechargeApiMessage,
    rechargeApiErrorCount,
  ] = useAxiosPost<UpdateConnectionExpiryDateApiProp>(
    "connections/updateConnectionExpiryDate",
    true
  );

  useEffect(() => {
    if (rechargeApiError !== null && rechargeApiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", rechargeApiError);
    }
  }, [rechargeApiError, rechargeApiErrorCount]);
  useEffect(() => {
    if (rechargeApiResponse !== null) {
      const { message } = rechargeApiResponse;
      if (message) {
        setValue(value + 1);
        openNotification(`success`, "Success", message);

        setTimeout(() => {
          handleClose(true);
        }, 2500);
      }
    }
  }, [rechargeApiResponse]);
  const dateFormat = "YYYY-MM-DD";
  const currentDate = lastExpiryDate; // Get the current date
  const defaultDate = moment(new Date(currentDate))
    .format(dateFormat)
    .toString();
  const onFinish = (values: any) => {
    // Handle form submission, values.email will contain the entered email address
    const { expiryDate } = values;

    const expiryDateFormatted = expiryDate.format("YYYY-MM-DD");
    const dataToSend: UpdateConnectionExpiryDateApiProp = {
      intPkConnectionID: connection.intPkConnectionID,
      expiryDate: expiryDateFormatted,
    };
    postConnectionRechargeApi(dataToSend);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const disabledDate = (currentDate: any) => {
    return currentDate && currentDate < moment().startOf("day");
  };

  return (
    <>
      <NetwalaModal
        title="Update Connection Expiry"
        // titleImage={RechargeIcon}
        headerStyle={{
          backgroundColor: "#292D8E",
          color: "#FFFFFF",
          fontSize: "16px",
          fontWeight: "400",
          height: "50px",
        }}
        open={show}
        onCancel={() => handleClose(false)}
      >
        {contextHolder}

        <Row>
          <Col span={24} className="iptv-modal-package-details">
            <Form
              requiredMark={false}
              style={{ width: "100%" }}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              name="updateConnectionExpiryForm"
              initialValues={{
                expiryDate: dayjs(defaultDate, "YYYY-MM-DD"),
              }}
            >
              <Form.Item
                name="expiryDate"
                rules={[
                  { required: true, message: "Please enter Expiry Date" },
                ]}
                label={
                  <span
                    style={{
                      color: "#DE1818",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Next Expiry Date
                  </span>
                }
              >
                <DatePicker
                  style={{ width: "100%", height: "48px" }}
                  format={dateFormat}
                  defaultValue={dayjs(defaultDate, "YYYY-MM-DD")}
                  disabledDate={disabledDate}
                />
              </Form.Item>

              <Form.Item>
                <NetwalaButton
                  backgroundColor={ButtonProps.background.YELLOW}
                  height={ButtonProps.height.STANDARD}
                  width={`100%`}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {}}
                  htmlType="submit"
                  text="Update"
                  borderColor="#FFA726"
                  disabled={rechargeApiLoading}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </NetwalaModal>
    </>
  );
};

export default UpdateExpiryDateModal;
//deleteUserTransaction
