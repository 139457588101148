import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import type { MenuProps } from "antd";
import SingleStaffMiniCard from "./cards/SingleStaffMiniCard";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import PlusIcon from "./../../assets/svgs/IconsWhite/PlusIcon.svg";
import { useNavigate } from "react-router-dom";
import useAxiosPost from "../../hooks/useAxiosPost";
import { getUserType } from "../../helpers/userDetails";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";

const ViewStaffComponent = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/create-staff");
  };
  const [staffs, setStaffs] = useState<StaffListType[]>([]);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("auth/getMyStaff");
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setStaffs(data || []);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({});
  }, []);
  const apiReload = (isReload: boolean) => {};

  return (
    <>
      {getUserType() === "CEO" && (
        <Row gutter={16}>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "20px",
            }}
            span={24}
          >
            <NetwalaButton
              backgroundColor={ButtonProps.background.STANDARD}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.STANDARD}
              textColor={ButtonProps.color.STANDARD}
              onClick={() => handleClick()}
              text="Create Staff"
              icon={<img src={PlusIcon} />}
            />
          </Col>
        </Row>
      )}
      <Row gutter={[16, 16]}>
        {apiLoading === true ? (
          <TailSpinLoader />
        ) : (
          <>
            {staffs.map((item) => {
              return (
                <Col
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/staff-profile/${item.intPkHeadID}`);
                  }}
                  key={Math.random()}
                  span={8}
                >
                  <SingleStaffMiniCard
                    isStaff={true}
                    staffApiReload={apiReload}
                    staff={item}
                  />
                </Col>
              );
            })}
          </>
        )}
      </Row>
    </>
  );
};
export default ViewStaffComponent;
