import "./threeDots.css";
const ThreeDotsLoader = () => {
  return (
    <div className="loading-dots">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
export default ThreeDotsLoader;
