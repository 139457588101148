import React, { useState } from "react";

import { Col, Dropdown, Row, Table, Typography } from "antd";
import type { TableProps } from "antd";
import type { MenuProps } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import TailSpinLoader from "../Loaders/TailSpinLoader";
import "./index.css";
import AddOrUpdateInventoryItemTypesModal from "../../Modals/AddOrUpdateInventoryItemTypesModal";

const InventoryItemTypesTable: React.FC<InventoryItemTypesTableProp> = ({
  itemTypes,
  isLoading,
  reloadApi,
}) => {
  const [show, setShow] = useState<boolean>(false);

  const [activeRow, setActiveRow] = useState<InventoryItemTypeProp | null>(
    null
  );

  const companyItems: MenuProps["items"] = [
    {
      key: "EDIT",
      label: (
        <Typography className="profile-drop-down-item">Edit Item</Typography>
      ),
    },
    {
      key: "VIEW",
      label: (
        <Typography className="profile-drop-down-item">
          View Inventory Models
        </Typography>
      ),
    },
  ];

  const navigate = useNavigate();
  const handleMenuClick = (e: any) => {
    if (activeRow === null) {
      return;
    }
    switch (e.key) {
      case "EDIT":
        setShow(true);
        break;
    }
  };
  const columns: TableProps<InventoryItemTypeProp>["columns"] = [
    {
      title: "Item Variant",
      dataIndex: "inventoryItemVariant",
      key: "inventoryItemVariant",
    },

    {
      title: "Action",
      render: (row) => (
        <Dropdown
          menu={{
            items: companyItems,

            onClick: handleMenuClick,
          }}
          placement="bottomRight"
        >
          <MenuOutlined
            onMouseEnter={(e) => {
              setActiveRow(row);
            }}
            className="cursor-pointer"
          />
        </Dropdown>
      ),
    },
  ];
  const handleClose = (isReload: boolean) => {
    setShow(false);
    if (isReload === true) {
      //lets send a signal back
      reloadApi();
    }
  };
  return (
    <>
      {show === true && activeRow !== null && (
        <AddOrUpdateInventoryItemTypesModal
          itemType={activeRow}
          show={show}
          handleClose={handleClose}
          url={`updateInventoryItemType`}
          intPkInventoryItemID={activeRow.intPkInventoryItemID}
        />
      )}

      <div className="netwala-users-table-container">
        {isLoading === true ? (
          <TailSpinLoader />
        ) : (
          <Row>
            <Col span={24}>
              <div className="users-search-container">
                <Typography className="netwala-user-tables-heading">
                  Inventory Item Types
                </Typography>
              </div>
              <Table
                className="netwala-users-table striped-table"
                columns={columns}
                dataSource={itemTypes}
                pagination={false}
              />
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};
export default InventoryItemTypesTable;
