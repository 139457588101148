import { useEffect, useState } from "react";
import { Col, Dropdown, Row, Typography } from "antd";
import "./index.css";
import type { MenuProps } from "antd";

import { useNavigate } from "react-router-dom";
import CopyIcon from "./../../assets/svgs/CopyIcon.svg";
import ViewReceiptIconBlue from "./../../assets/svgs/ViewReceiptIconBlue.svg";
import ThreeDotsVertical from "./../../assets/svgs/ThreeDotsVertical.svg";
import PromisedTransactionIcon from "./../../assets/svgs/PromisedTransactionIcon.svg";

import PromiseIcon from "./../../assets/svgs/PromiseIcon.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { formatDateForJustMonthDayAndYear } from "../../helpers/date";
import UpdateOrCreatePromiseModal from "../Modals/UpdateOrCreatePromiseModal";
const PendingPromiseCard: React.FC<PendingPromiseCardProp> = ({
  promise,
  reloadApi,
}) => {
  const [show, setShow] = useState<boolean>(false);
  const navigate = useNavigate();
  const items: MenuProps["items"] = [
    {
      key: "PROMISE_ON_TRANSACTION",
      label: (
        <Typography className="profile-drop-down-item">
          <img src={PromiseIcon} />
          {promise.promiseCreationDate !== null
            ? "Update Promise"
            : "Create Promise"}
        </Typography>
      ),
    },
  ];
  const handleMenuClick = (e: any) => {
    switch (e.key) {
      case "PROMISE_ON_TRANSACTION":
        setShow(true);
        break;
    }
  };
  const handleClose = (isReload: boolean) => {
    setShow(false);
    if (isReload === true) {
      //lets reload the main api
      reloadApi(true);
    }
  };
  return (
    <>
      {show === true && (
        <UpdateOrCreatePromiseModal
          handleClose={handleClose}
          show={show}
          transaction={promise as TransactionHeadDetails}
        />
      )}
      <Row className="pending-promise-card">
        <Col className="promise-created-image-container-transaction" span={5}>
          <img
            src={PromisedTransactionIcon}
            className="promise-created-icon-in-pending-promises"
          />
          <div
            style={{ marginLeft: "20px" }}
            className="pending-promise-card-single-col"
          >
            <Typography className="pending-promise-label">
              Customer Name
            </Typography>
            <Typography className="pending-promise-value font-size-18p">
              {promise.cFirstName + " " + promise.cLastName}
            </Typography>
          </div>
        </Col>
        <Col span={3}>
          <div className="pending-promise-card-single-col">
            <Typography className="pending-promise-label">Username</Typography>
            <Typography className="pending-promise-value ">
              {promise.username}
            </Typography>
          </div>
        </Col>
        <Col span={1}>
          <CopyToClipboard text={promise.username || ""} onCopy={() => {}}>
            <img
              onClick={(e) => {
                e.preventDefault();
              }}
              src={CopyIcon}
              className="cursor-pointer mt-20"
            />
          </CopyToClipboard>
        </Col>
        <Col span={2}>
          <div className="pending-promise-card-single-col">
            <Typography className="pending-promise-label">
              Date Created
            </Typography>
            <Typography className="promise-created-date">
              {formatDateForJustMonthDayAndYear(
                new Date(promise.promiseCreationDate)
              )}
            </Typography>
          </div>
        </Col>
        <Col span={2}>
          <div className="pending-promise-card-single-col">
            <Typography className="pending-promise-label">Due Date</Typography>
            <Typography className="promise-due-date">
              {formatDateForJustMonthDayAndYear(
                new Date(promise.promiseDueDate)
              )}
            </Typography>
          </div>
        </Col>
        <Col span={9}>
          <div className="pending-promise-card-single-col">
            <Typography className="pending-promise-label">Comments</Typography>
            <Typography className="pending-promise-value ">
              {promise.promiseWords}
            </Typography>
          </div>
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            alignItems: "center",
          }}
          span={2}
        >
          {promise.intPkTransactionReceiptID !== null && (
            <img
              onClick={(e) => {
                e.preventDefault();
                navigate(
                  `/receipts/receipt-details/${promise.intPkTransactionReceiptID}`
                );
              }}
              style={{ height: "20px" }}
              className="cursor-pointer"
              src={ViewReceiptIconBlue}
            />
          )}
          <Dropdown
            menu={{
              items: items,
              onClick: handleMenuClick,
            }}
            placement="bottomRight"
          >
            <img style={{ height: "20px" }} src={ThreeDotsVertical} />
          </Dropdown>
        </Col>
      </Row>
    </>
  );
};
export default PendingPromiseCard;
