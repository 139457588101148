import { useContext, useEffect } from "react";
import DashboardComponent from "../components/dashboard/Index";
import TitleContext from "../context/TitleContext";

const DashboardPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Home");
  }, [setTitle]);

  return (
    <>
      <DashboardComponent />
    </>
  );
};
export default DashboardPage;
