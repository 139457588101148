import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import "./index.css";
import SingleConnectionCard from "./cards/ConnectionCard";
import useAxiosPost from "../../../hooks/useAxiosPost";
import { Link, useNavigate, useParams } from "react-router-dom";
import TailSpinLoader from "../../Items/Loaders/TailSpinLoader";
const UserConnectionsComponent = () => {
  const navigate = useNavigate();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<GetUserProfileApiProp>("connections/getConnectionsByUser");
  const [connections, setConnections] = useState<UserConnectionApiProp[]>([]);
  const [companySettings, SetCompanySettings] =
    useState<CompanySettingsApiProp | null>(null);
  const { intPkHeadID } = useParams();
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      const { connections, companySettings } = data;
      setConnections(connections);
      SetCompanySettings(companySettings);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({ userId: Number(intPkHeadID) });
  }, []);
  return (
    <>
      {apiLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row gutter={[16, 16]} className="connections-container">
          {connections.map((item) => {
            return (
              <Col
                className="cursor-pointer"
                // onClick={(e) => {
                //   e.preventDefault();
                //   navigate(
                //
                //   );
                // }}
                span={12}
                key={Math.random()}
              >
                <Link
                  to={`/connections/connection-details/${item.intPkConnectionID}`}
                >
                  <SingleConnectionCard connectionDetails={item} />
                </Link>
              </Col>
            );
          })}
        </Row>
      )}
    </>
  );
};
export default React.memo(UserConnectionsComponent);
