import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewDealerProfile from "../components/dealers/ViewDealerProfile";

const ViewDealerProfilePage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Dealer Profile");
  }, [setTitle]);
  return (
    <>
      <ViewDealerProfile />
    </>
  );
};
export default ViewDealerProfilePage;
