import { useEffect, useState } from "react";
import { Row, Col, Typography, Card } from "antd";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../data";
const DashboardComponent = () => {
  const navigate = useNavigate();
  const [routes, setRoutes] = useState<any>([]);
  useEffect(() => {
    const routes = AppRoutes();
    const _items = routes.filter(
      (item: any) => item.show === true && item.path !== "/"
    );
    setRoutes(_items);
  }, []);
  return (
    <>
      <Row gutter={[16, 16]}>
        {routes.map((item: any) => {
          return (
            <Col
              onClick={(e) => {
                e.preventDefault();
                navigate(item.path);
              }}
              span={6}
              key={Math.random()}
            >
              <Card className="dashboard-item-card">
                <img src={item.activeIcon} />
                <Typography className="dashboard-item-text">
                  {item.text}
                </Typography>
              </Card>
            </Col>
          );
        })}
      </Row>
    </>
  );
};
export default DashboardComponent;
