import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Typography,
  Form,
  Input,
  Card,
  Select,
  Button,
  Dropdown,
} from "antd";
import type { MenuProps, SelectProps } from "antd";
import "./index.css";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import PlusIcon from "./../../assets/svgs/IconsWhite/PlusIcon.svg";

import CATVBlue from "./../../assets/svgs/CATVBlue.svg";
import WifiIcon from "./../../assets/svgs/WifiIcon.svg";
import OptionsIcon from "./../../assets/svgs/HamburgerBlue.svg";

import NetwalaSelect from "../Items/Select/Index";
import NetwalaUsersTable from "../Items/Table/UsersTable";
import useAxiosPost from "../../hooks/useAxiosPost";
import { useNavigate } from "react-router-dom";
import ExpiredUsersTable from "../Items/Table/ExpiredUsersTable";
import ExpiringUsersTable from "../Items/Table/ExpiringUsersTable";
const CustomersComponent = () => {
  const navigate = useNavigate();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ApiWithPagination>("connections/getConnectionsByOwner");

  const [
    postSearchAPi,
    searchApiResponse,
    searchApiError,
    searchApiLoading,
    searchApiMessage,
    searchApiErrorCount,
  ] = useAxiosPost<UserSearchApiProp>("connections/searchConnections");

  const [
    getExpiredConnectionsApi,
    expiredConnectionsApiResponse,
    expiredApiError,
    expiredApiLoading,
  ] = useAxiosPost<ApiWithPagination>("connections/getExpiredConnections");

  const [
    getExpiringConnectionsApi,
    expiringConnectionsApiResponse,
    expiringApiError,
    expiringApiLoading,
  ] = useAxiosPost<ApiWithPagination>("connections/getExpiringConnections");
  const [users, setUsers] = useState<CompanyConnection[]>([]);
  const [searchedUsers, setSearchedUsers] = useState<CompanyConnection[]>([]);
  const [expiredUsers, setExpiredUsers] = useState<ExpiredUsersTableProp[]>([]);
  const [expiringUsers, setExpiringUsers] = useState<ExpiredUsersTableProp[]>(
    []
  );
  const [isSearch, setIsSearch] = useState<boolean>(false);

  const [actualExpiringUsers, setActualExpiringUsers] = useState<
    ExpiredUsersTableProp[]
  >([]);
  const [usersFilter, setUsersFilter] = useState<string>("1");
  const [expiringFilter, setExpiringFilter] = useState<string>("1");
  const [pagination, setPagination] = useState<PaginationProp>({
    totalPages: 0,
    currentPage: 1,
    previousPage: null,
    nextPage: null,
    totalCount: 0,
    pageSize: 50,
  });
  const [searchPagination, setSearchPagination] = useState<PaginationProp>({
    totalPages: 0,
    currentPage: 1,
    previousPage: null,
    nextPage: null,
    totalCount: 0,
    pageSize: 100,
  });

  const [expiringPagination, setExpiringPagination] = useState<PaginationProp>({
    totalPages: 0,
    currentPage: 1,
    previousPage: null,
    nextPage: null,
    totalCount: 0,
    pageSize: 20,
  });
  const [expiredPagination, setExpiredPagination] = useState<PaginationProp>({
    totalPages: 0,
    currentPage: 1,
    previousPage: null,
    nextPage: null,
    totalCount: 0,
    pageSize: 20,
  });
  const options = [
    {
      label: "All Connections",
      value: "1",
    },
    {
      label: "Expiring Connections",
      value: "2",
    },
    {
      label: "Expired Connections",
      value: "3",
    },
  ];
  const secondOptions = [
    {
      label: "Today",
      value: "1",
    },
    {
      label: "Tomorrow",
      value: "2",
    },
    {
      label: "This Week",
      value: "3",
    },
  ];
  const handleClick = () => {
    navigate(`/create-user`);
  };
  const handleChange = (value: string) => {
    setUsersFilter(value);
  };
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      const {
        currentPage,
        totalPages,
        nextPage,
        previousPage,
        totalCount,
        connections,
        pageSize,
      } = data;
      setPagination({
        currentPage,
        totalPages,
        nextPage,
        previousPage,
        totalCount,
        pageSize,
      });
      setUsers(
        connections.map((item: any): CompanyConnection => {
          return {
            ...item,
            fullName: `${item.firstName} ${item.lastName}`,
          };
        }) || []
      );
    }
  }, [apiResponse]);

  useEffect(() => {
    if (expiredConnectionsApiResponse !== null) {
      const data: any = expiredConnectionsApiResponse.responseData;
      const {
        currentPage,
        totalPages,
        nextPage,
        previousPage,
        totalCount,
        connections,
        pageSize,
      } = data;
      setExpiredPagination({
        currentPage,
        totalPages,
        nextPage,
        previousPage,
        totalCount,
        pageSize,
      });
      setExpiredUsers(
        connections.map((item: any): ExpiredUsersTableProp => {
          return {
            ...item,
            fullName: `${item.firstName} ${item.lastName}`,
          };
        }) || []
      );
    }
  }, [expiredConnectionsApiResponse]);

  useEffect(() => {
    if (expiringConnectionsApiResponse !== null) {
      const data: any = expiringConnectionsApiResponse.responseData;
      const {
        currentPage,
        totalPages,
        nextPage,
        previousPage,
        totalCount,
        connections,
        pageSize,
      } = data;
      setExpiringPagination({
        currentPage,
        totalPages,
        nextPage,
        previousPage,
        totalCount,
        pageSize,
      });
      const _data =
        connections.map((item: any): ExpiredUsersTableProp => {
          return {
            ...item,
            fullName: `${item.firstName} ${item.lastName}`,
          };
        }) || [];
      // setExpiringUsers(_data);
      setActualExpiringUsers(_data);
      handleFilterExpiringUsers(Number(expiringFilter), _data);
    }
  }, [expiringConnectionsApiResponse]);
  useEffect(() => {
    postApiData({ page: 1 });
    getExpiredConnectionsApi({ page: 1 });
    getExpiringConnectionsApi({ page: 1 });
  }, []);
  const getActiveUsersTable = (activeOption: number) => {
    switch (activeOption) {
      case 1:
        return (
          <NetwalaUsersTable
            isLoading={apiLoading || searchApiLoading}
            users={isSearch === true ? searchedUsers : users}
            pagination={isSearch === true ? searchPagination : pagination}
            handlePageSwitch={handleAllUsersPageSwitch}
            handleSearch={handleSearch}
          />
        );
      case 2:
        return (
          <ExpiringUsersTable
            isLoading={expiringApiLoading}
            users={expiringUsers}
            pagination={expiringPagination}
            handlePageSwitch={handleExpiringUsersPageSwitch}
            handleSearch={handleSearch}
          />
        );
      case 3:
        return (
          <ExpiredUsersTable
            isLoading={expiredApiLoading}
            users={expiredUsers}
            pagination={expiredPagination}
            handlePageSwitch={handleExpiredUsersPageSwitch}
            handleSearch={handleSearch}
          />
        );
    }
  };
  const handleFilterExpiringUsers = (
    filter: number,
    users: ExpiredUsersTableProp[]
  ) => {
    if (filter === 1) {
      const today = new Date();
      const expiringToday = users.filter((user) => {
        const expiryDate = new Date(user.expiryDate);
        return (
          expiryDate.getDate() === today.getDate() &&
          expiryDate.getMonth() === today.getMonth() &&
          expiryDate.getFullYear() === today.getFullYear()
        );
      });
      setExpiringUsers(expiringToday);
    } else if (filter === 2) {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1); // Increment by 1 day to get tomorrow's date
      const expiringTomorrow = users.filter((user) => {
        const expiryDate = new Date(user.expiryDate);
        return (
          expiryDate.getDate() === tomorrow.getDate() &&
          expiryDate.getMonth() === tomorrow.getMonth() &&
          expiryDate.getFullYear() === tomorrow.getFullYear()
        );
      });
      setExpiringUsers(expiringTomorrow);
    } else if (filter === 3) {
      setExpiringUsers(users);
    }
  };
  const handleExpiringFilterChange = (value: any) => {
    setExpiringFilter(value);
    handleFilterExpiringUsers(Number(value), actualExpiringUsers);
  };
  const handleAllUsersPageSwitch = (pageNumber: number) => {
    postApiData({ page: pageNumber });
  };
  const handleExpiringUsersPageSwitch = (pageNumber: number) => {
    getExpiringConnectionsApi({ page: pageNumber });
  };
  const handleExpiredUsersPageSwitch = (pageNumber: number) => {
    getExpiredConnectionsApi({ page: pageNumber });
  };
  const handleSearch = (search: string, type: number) => {
    if (search.trim() === "") {
      setIsSearch(false);
      return;
    }
    if (search.length < 3) {
      return;
    }
    setIsSearch(true);
    postSearchAPi({ search });
  };
  useEffect(() => {
    if (searchApiResponse !== null) {
      const data: any = searchApiResponse.responseData;

      setSearchedUsers(
        data.map((item: any): CompanyConnection => {
          return {
            ...item,
            fullName: `${item.firstName} ${item.lastName}`,
          };
        }) || []
      );
    }
  }, [searchApiResponse]);
  const connectionOptionItems: MenuProps["items"] = [
    {
      key: "CATV",
      label: (
        <Typography className="profile-drop-down-item">
          <img src={CATVBlue} />
          Create CATV Connection
        </Typography>
      ),
    },
    {
      key: "INTERNET",
      label: (
        <Typography className="profile-drop-down-item">
          <img src={WifiIcon} />
          Create Internet Connection
        </Typography>
      ),
    },
  ];
  const handleMenuClick = (e: any) => {
    if (e.key === "INTERNET") {
      //intPkHeadID
      navigate(`/connections/create-internet`);
    } else if (e.key === "CATV") {
      navigate(`/connections/create-catv`);
    }
  };
  return (
    <>
      <Row gutter={16}>
        <Col span={12} className="dashboard-select-options-container">
          <NetwalaSelect
            size="large"
            height="40px"
            onChange={handleChange}
            options={options}
            value={usersFilter}
            isStandardSelectedColor
          />
          {Number(usersFilter) === 2 ? (
            <NetwalaSelect
              size="large"
              height="40px"
              onChange={handleExpiringFilterChange}
              options={secondOptions}
              value={expiringFilter}
              isStandardSelectedColor
            />
          ) : null}
        </Col>
        <Col className="create-user-dashboard-button-container" span={12}>
          {/* <NetwalaButton
            backgroundColor={ButtonProps.background.STANDARD}
            height={ButtonProps.height.STANDARD}
            width={ButtonProps.width.STANDARD}
            textColor={ButtonProps.color.STANDARD}
            onClick={() => handleClick()}
            text="Create New Customer"
            icon={<img src={PlusIcon} />}
          /> */}

          <Dropdown
            menu={{
              items: connectionOptionItems,

              onClick: handleMenuClick,
            }}
            placement="bottomLeft"
          >
            <img className="cursor-pointer" src={OptionsIcon} />
          </Dropdown>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="mt-20">
        <Col span={24}>
          {getActiveUsersTable(Number(usersFilter))}
          {/* <NetwalaUsersTable users={users} /> */}
        </Col>
      </Row>
    </>
  );
};
export default CustomersComponent;
