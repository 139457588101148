import { Col, Form, Input, Row } from "antd";
import { useEffect, useState } from "react";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";

import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import { useNavigate, useParams } from "react-router-dom";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";

const UpdateIspForm = () => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [isp, setIsp] = useState<ISP | null>(null);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { intPkIspID } = useParams();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<GetIspDetailsByIdApiProp>("isps/getISPByID");

  const [
    postIspUpdate,
    ispUpdateResponse,
    ispUpdateError,
    ispUpdateLoading,
    ispUpdateMessage,
    ispUpdateErrorCount,
  ] = useAxiosPost<UpdateIspApiProp>("isps/updateISPDetails");

  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setIsp(data);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({ intPkIspID: Number(intPkIspID) });
  }, []);

  const onFinish = (values: any) => {
    const {
      ISPName,
      IspSalesNumber,
      ISPSupportNumber,
      ISPSupportEmail,
      ISPSalesEmail,
    } = values;
    const dataToSend: UpdateIspApiProp = {
      ISPName,
      IspSalesNumber,
      ISPSupportNumber,
      ISPSupportEmail,
      ISPSalesEmail,
      intPkIspID: Number(intPkIspID),
    };
    postIspUpdate(dataToSend);
  };

  const onFinishFailed = (errorInfo: any) => {};

  useEffect(() => {
    if (ispUpdateResponse !== null) {
      const { message } = ispUpdateResponse;
      if (message) {
        openNotification(`success`, "Success", message);
        setTimeout(() => {
          navigate(-1);
        }, 3000);
      }
    }
  }, [ispUpdateResponse]);
  useEffect(() => {
    if (ispUpdateError !== null && ispUpdateErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", ispUpdateError);
    }
  }, [ispUpdateError, ispUpdateErrorCount]);

  if (apiLoading) {
    return (
      <Row style={{ width: "100%" }}>
        <Col span={24}>
          <TailSpinLoader />
        </Col>
      </Row>
    );
  }

  return (
    <>
      {contextHolder}

      {isp !== null && (
        <Row style={{ width: "100%" }}>
          <Form
            name="updateIspForm"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            requiredMark={false}
            style={{ width: "100%" }}
            form={form}
            initialValues={{
              ISPName: isp.ISPName,
              ISPSupportEmail: isp.ISPSupportEmail,
              ISPSalesEmail: isp.ISPSalesEmail,
              ISPSupportNumber: isp.ISPSupportNumber,
              IspSalesNumber: isp.IspSalesNumber,
            }}
          >
            {/* First */}
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={
                    <span className="create-user-form-label">ISP Name</span>
                  }
                  name="ISPName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter ISP Name",
                    },
                  ]}
                >
                  <Input
                    className="user-input-general-height"
                    placeholder="Transworld"
                  />
                </Form.Item>
              </Col>
            </Row>
            {/* Second */}
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={
                    <span className="create-user-form-label">
                      Support Email
                    </span>
                  }
                  name="ISPSupportEmail"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Support Email",
                    },
                    {
                      type: "email",
                      message: "Please enter a valid Support email address",
                    },
                  ]}
                >
                  <Input
                    className="user-input-general-height"
                    placeholder="support@xyz.com"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <span className="create-user-form-label">Sales Email</span>
                  }
                  name="ISPSalesEmail"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Sales Email",
                    },
                    {
                      type: "email",
                      message: "Please enter a valid Sale email address",
                    },
                  ]}
                >
                  <Input
                    className="user-input-general-height"
                    placeholder="sales@xyz.com"
                  />
                </Form.Item>
              </Col>
            </Row>
            {/* Third */}
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={
                    <span className="create-user-form-label">
                      Support Number
                    </span>
                  }
                  name="ISPSupportNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please enter ISP Support Number",
                    },
                  ]}
                >
                  <Input
                    className="user-input-general-height"
                    placeholder="03451234567"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <span className="create-user-form-label">Sales Number</span>
                  }
                  name="IspSalesNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please enter ISP Sales Number",
                    },
                  ]}
                >
                  <Input
                    className="user-input-general-height"
                    placeholder="03451234567"
                  />
                </Form.Item>
              </Col>
            </Row>

            {/* Create Button Row */}
            <Row>
              <Col
                style={{ display: "flex", justifyContent: "center" }}
                span={24}
              >
                <Form.Item>
                  <NetwalaButton
                    htmlType="submit"
                    backgroundColor={ButtonProps.background.STANDARD}
                    height={ButtonProps.height.STANDARD}
                    width={ButtonProps.width.STANDARD}
                    textColor={ButtonProps.color.STANDARD}
                    onClick={() => {
                      console.log("clicked");
                    }}
                    text="Update ISP"
                    disabled={ispUpdateLoading}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Row>
      )}
    </>
  );
};
export default UpdateIspForm;
