import React, { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import CreateBankAccountForm from "../components/banks/CreateBankAccount";
const CreateBankAccountPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Create Account");
  }, [setTitle]);

  return (
    <>
      <CreateBankAccountForm />
    </>
  );
};
export default CreateBankAccountPage;
