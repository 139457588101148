import React, { useContext, useEffect, useState } from "react";
import NetwalaModal from "../Items/Modal/Index";
import MultiColorChangePackageIcon from "./../../assets/pngs/MultiColorChangePackageIcon.png";
import ISPLogo from "./../../assets/pngs/ISPLogo.png";
import { Col, Row, Typography, DatePicker, Form, Input } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import "./styles/RequestIPTVModal.css";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import NetwalaSelect from "../Items/Select/Index";
import useMultiNotification from "../../hooks/useNotification";
import useAxiosPost from "../../hooks/useAxiosPost";
import EditIconYellow from "./../../assets/svgs/EditIconYellow.svg";
import BalanceContext from "../../context/BalanceContext";

const ChangeConnectionPackageModal: React.FC<RechargeModalProps> = ({
  show,
  handleClose,
  connection,
  totalIPTVAndStaticIPPrice,
  settings,
  discount,
}) => {
  const [form] = Form.useForm();
  const { value, setValue } = useContext(BalanceContext);

  const dateFormat = "YYYY-MM-DD";
  const currentDate = new Date(); // Get the current date
  currentDate.setMonth(currentDate.getMonth() + 1); // Add one month
  const defaultDate = moment(new Date(currentDate))
    .format(dateFormat)
    .toString();
  const [expiry, setExpiry] = useState<any>(defaultDate);
  const [selectedPackage, setSelectedPackage] = useState<any>(null);
  const [isAmountEditEnabled, setIsAmountEditEnabled] =
    useState<boolean>(false);
  const { openNotification, contextHolder } = useMultiNotification();
  const [
    getPackagesApi,
    packagesApiResponse,
    packagesApiError,
    packagesApiLoading,
    packagesApiMessage,
    packagesApiErrorCount,
  ] = useAxiosPost<PackagesAndVlansByISPApiProp>("packages/getPackagesByISP");
  const [packagesOptions, setPackageOptions] = useState<NetwalaSelectOptions[]>(
    []
  );
  const [packages, setPackages] = useState<PackageWithAsset[]>([]);
  const [invoiceAmount, setInvoiceAmount] = useState<number>(
    totalIPTVAndStaticIPPrice
  );
  const [actualInvoiceAmount, setActualInvoiceAmount] = useState<number>(
    totalIPTVAndStaticIPPrice
  );

  useEffect(() => {
    getPackagesApi({ ispId: Number(connection.intPkIspID) });
  }, []);
  useEffect(() => {
    if (packagesApiResponse !== null) {
      const data: any = packagesApiResponse.responseData;
      setPackages(data);
      const selectedPackage = data.find(
        (item: any) =>
          Number(item.intPkPackageID) === Number(connection.intPkPackageID)
      );
      if (selectedPackage) {
        setSelectedPackage(selectedPackage);
        setInvoiceAmount(totalIPTVAndStaticIPPrice + selectedPackage.salePrice);
        setActualInvoiceAmount(
          totalIPTVAndStaticIPPrice + selectedPackage.salePrice
        );
      }
      const packages = data.map((item: any): NetwalaSelectOptions => {
        return {
          value: item.intPkPackageID,
          label: item.packageName,
        };
      });
      setPackages(data || []);
      setPackageOptions(packages);
    }
  }, [packagesApiResponse]);

  const [
    postConnectionRechargeApi,
    rechargeApiResponse,
    rechargeApiError,
    rechargeApiLoading,
    rechargeApiMessage,
    rechargeApiErrorCount,
  ] = useAxiosPost<UpdateConnectionPackageApiProp>(
    "invoices/updateConnectionPackage",
    true
  );

  useEffect(() => {
    if (rechargeApiError !== null && rechargeApiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", rechargeApiError);
    }
  }, [rechargeApiError, rechargeApiErrorCount]);
  useEffect(() => {
    if (rechargeApiResponse !== null) {
      const { message } = rechargeApiResponse;
      if (message) {
        setValue(value + 1);
        openNotification(`success`, "Success", message);
        setTimeout(() => {
          handleClose(true);
        }, 2500);
      }
    }
  }, [rechargeApiResponse]);

  const onFinish = (values: any) => {
    // Handle form submission, values.email will contain the entered email address
    const { expiryDate, intPkPackageID, amount } = values;
    const _amount = amount ? amount : actualInvoiceAmount;
    const dataToSend: UpdateConnectionPackageApiProp = {
      expiryDate,
      intPkPackageID,
      intPkConnectionID: connection.intPkConnectionID,
      amount: Number(_amount),
    };
    postConnectionRechargeApi(dataToSend);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const disabledDate = (currentDate: any) => {
    const _defaultDate = dayjs(defaultDate, "YYYY-MM-DD");
    // Disable dates before 3 days from the default date and after 3 days from the default date
    return (
      currentDate.isBefore(_defaultDate.subtract(3, "days"), "day") ||
      currentDate.isAfter(_defaultDate.add(3, "days"), "day")
    );
  };
  useEffect(() => {
    form.setFieldsValue({ amount: actualInvoiceAmount });
  }, [actualInvoiceAmount]);
  return (
    <>
      <NetwalaModal
        title="Change Package"
        titleImage={MultiColorChangePackageIcon}
        headerStyle={{
          backgroundColor: "#359528",
          color: "#FFFFFF",
          fontSize: "16px",
          fontWeight: "400",
          height: "50px",
        }}
        open={show}
        onCancel={() => handleClose(false)}
      >
        {contextHolder}
        <Row>
          <Col span={24} className="iptv-modal-connection-details-container">
            <Typography className="user-full-name">
              {" "}
              {connection.firstName + " " + connection.lastName}
            </Typography>
            <Typography className="user-phone">
              {" "}
              {connection.phoneNumber}
            </Typography>
            <Typography className="user-address">
              {`${connection.address},${connection.areaName}`}
            </Typography>
          </Col>
          <Col span={24} className="iptv-modal-package-details">
            <div className="iptv-modal-isp-details-container">
              <img src={ISPLogo} />
              <Typography className="isp-name">
                {" "}
                {connection.ISPName}
              </Typography>
            </div>
            <Typography className="package-name">
              {" "}
              {connection.packageName}
            </Typography>
          </Col>
          <Col span={24} className="iptv-modal-package-details">
            <Form
              requiredMark={false}
              style={{ width: "100%" }}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              name="changePackageForm"
              initialValues={{
                expiryDate: dayjs(defaultDate, "YYYY-MM-DD"),
                intPkPackageID: connection.intPkPackageID,
                amount: actualInvoiceAmount,
              }}
              form={form}
            >
              <Form.Item
                name="intPkPackageID"
                rules={[
                  { required: true, message: "Please Choose New Package" },
                ]}
                label={
                  <span
                    style={{
                      color: "#359528",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Change Package To
                  </span>
                }
              >
                <NetwalaSelect
                  size="large"
                  onChange={(value) => {
                    const selectedPackage = packages.find(
                      (item) => Number(item.intPkPackageID) === Number(value)
                    );
                    if (selectedPackage) {
                      setInvoiceAmount(
                        totalIPTVAndStaticIPPrice + selectedPackage.salePrice
                      );
                      setActualInvoiceAmount(
                        totalIPTVAndStaticIPPrice + selectedPackage.salePrice
                      );
                    }
                  }}
                  options={packagesOptions}
                />
              </Form.Item>
              <Form.Item
                name="expiryDate"
                rules={[
                  { required: true, message: "Please enter Expiry Date" },
                ]}
                label={
                  <span
                    style={{
                      color: "#359528",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Next Expiry Date
                  </span>
                }
              >
                <DatePicker
                  style={{ width: "100%", height: "48px" }}
                  format={dateFormat}
                  defaultValue={dayjs(defaultDate, "YYYY-MM-DD")}
                  disabledDate={disabledDate}
                />
              </Form.Item>
              {isAmountEditEnabled === true && (
                <Form.Item
                  name="amount"
                  rules={[
                    { required: true, message: "Please Enter Amount" },
                    {
                      validator: (_, value) =>
                        value > -1
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error("Amount must be greater than 0")
                            ),
                    },
                    {
                      validator: (_, value) =>
                        value > actualInvoiceAmount
                          ? Promise.reject(
                              new Error(
                                "Amount must not be greater than total Amount"
                              )
                            )
                          : Promise.resolve(),
                    },
                  ]}
                  label={
                    <span
                      style={{
                        color: "#359528",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Invoice Amount
                    </span>
                  }
                >
                  <Input
                    value={invoiceAmount}
                    onChange={(e) => {
                      setInvoiceAmount(Number(e.target.value));
                    }}
                    type="number"
                    className="user-input-general-height"
                    placeholder="10"
                  />
                </Form.Item>
              )}
              {discount > 0 && (
                <Typography
                  style={{
                    color: "#C30404",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Note: Previous discount of {discount} Rs will be removed
                </Typography>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                  marginBottom: "10px",
                  gap: "15px",
                }}
              >
                <Typography
                  style={{
                    color: "#359528",
                    fontSize: "18px",
                    fontWeight: "500",
                  }}
                >
                  {invoiceAmount}/-
                </Typography>

                {settings.isInvoiceAmountEditable === 1 && (
                  <img
                    className="cursor-pointer"
                    src={EditIconYellow}
                    onClick={(e) => {
                      setIsAmountEditEnabled(true);
                    }}
                  />
                )}
              </div>
              <Form.Item>
                <NetwalaButton
                  backgroundColor={ButtonProps.background.YELLOW}
                  height={ButtonProps.height.STANDARD}
                  width={`100%`}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {}}
                  htmlType="submit"
                  text="Request Upgrade"
                  borderColor="#FFA726"
                  disabled={rechargeApiLoading}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </NetwalaModal>
    </>
  );
};

export default ChangeConnectionPackageModal;
