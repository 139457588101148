import React, { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewActionQueue from "../components/actionQueue/ViewActionQueue";
const ActionQueuePage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Action Queue");
  }, [setTitle]);

  return (
    <>
      <ViewActionQueue />
    </>
  );
};
export default ActionQueuePage;
