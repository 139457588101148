import { useEffect, useState } from "react";
import { Row, Col } from "antd";
import useAxiosPost from "../../hooks/useAxiosPost";
import { useNavigate, useParams } from "react-router-dom";
import useMultiNotification from "../../hooks/useNotification";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import PlusIcon from "./../../assets/svgs/IconsWhite/PlusIcon.svg";
import MikroNocIspsTable from "../Items/Table/MikroNocIspsTable";

const ViewCompanyMikroNocIspsComponent = () => {
  const navigate = useNavigate();
  const { intPkCompanyID } = useParams();
  const { openNotification, contextHolder } = useMultiNotification();
  const [isps, setIsps] = useState<MikroNocIsp[]>([]);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<GetCompanyStaffApiProp>(
    "superAdmin/getMikroNocIspsByCompanyID"
  );

  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setIsps(data || []);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({ intPkCompanyID: Number(intPkCompanyID) });
  }, []);

  return (
    <>
      {contextHolder}
      <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
        <Col span={6} offset={18}>
          <NetwalaButton
            backgroundColor={ButtonProps.background.STANDARD}
            height={ButtonProps.height.STANDARD}
            width={ButtonProps.width.STANDARD}
            textColor={ButtonProps.color.STANDARD}
            onClick={() => {
              navigate(
                `/super-admin/company/create-mikronoc-isp/${intPkCompanyID}`
              );
            }}
            text="Create New ISP"
            icon={<img src={PlusIcon} />}
          />
        </Col>
      </Row>
      <Row style={{ width: "100%" }} gutter={30}>
        <Col span={24}>
          <MikroNocIspsTable isps={isps} isLoading={apiLoading} />
        </Col>
      </Row>
    </>
  );
};
export default ViewCompanyMikroNocIspsComponent;
