import { useContext, useEffect } from "react";
import TransactionDetailsComponent from "../components/TransactionDetails/Index";
import TitleContext from "../context/TitleContext";

const TransactionDetailsPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Transaction Details");
  }, [setTitle]);
  return (
    <>
      <TransactionDetailsComponent />
    </>
  );
};
export default TransactionDetailsPage;
