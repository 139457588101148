//;
import { useEffect, useState } from "react";
import { Row, Col, Typography, Dropdown } from "antd";
import type { MenuProps } from "antd";

import "./../index.css";
import useAxiosPost from "../../../../../hooks/useAxiosPost";
import ThreeDotsVertical from "./../../../../../assets/svgs/ThreeDotsVertical.svg";
import UpdateVlanModal from "../../../../Modals/UpdateVlanModal";
const VlansByIspComponent: React.FC<SingleISPProfileTabProp> = ({
  intPkIspID,
}) => {
  const [vlans, setVlans] = useState<Vlan[]>([]);
  const [activeVlan, setActiveVlan] = useState<Vlan | null>(null);
  const [show, setShow] = useState<boolean>(false);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<PackagesAndVlansByISPApiProp>("isps/vlans/getVLANsByISP");
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setVlans(data);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({ ispId: intPkIspID });
  }, []);
  const vlanOptions: MenuProps["items"] = [
    {
      key: "UPDATE",
      label: (
        <Typography className="profile-drop-down-item">Update Vlan</Typography>
      ),
    },
  ];
  const handleMenuClick = (e: any) => {
    switch (e.key) {
      case "UPDATE":
        setShow(true);
        break;
    }
  };
  const handleClose = (isReload: boolean) => {
    setShow(false);
    if (isReload === true) {
      postApiData({ ispId: intPkIspID });
    }
  };
  return (
    <>
      {show === true && activeVlan && (
        <UpdateVlanModal
          show={show}
          handleClose={handleClose}
          vlanDetails={activeVlan}
        />
      )}
      <Row gutter={[32, 32]} style={{ width: "100%", marginTop: "20px" }}>
        {vlans.map((item) => {
          return (
            <Col span={6}>
              <div className="single-vlan-card">
                <Typography className="vlan-value">{item.vlanValue}</Typography>
                <Dropdown
                  menu={{
                    items: vlanOptions,

                    onClick: handleMenuClick,
                  }}
                  placement="bottomRight"
                >
                  <img
                    onMouseEnter={(e) => setActiveVlan(item)}
                    className="cursor-pointer"
                    src={ThreeDotsVertical}
                  />
                </Dropdown>
              </div>
            </Col>
          );
        })}
      </Row>
    </>
  );
};
export default VlansByIspComponent;
