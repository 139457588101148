import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import useAxiosPost from "../../hooks/useAxiosPost";

import useMultiNotification from "../../hooks/useNotification";
import RecentExpensesTable from "../Items/Table/RecentExpensesTable";
import AddExpenseForm from "./addExpenseForm";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import { useNavigate } from "react-router-dom";

const ViewMiniExpensesByCEOComponent = () => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [expenses, setExpenses] = useState<RecentExpensesProp[]>([]);
  const navigate = useNavigate();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("invoices/getMyStaffExpensesDetails");
  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setExpenses(data || []);
    }
  }, [apiResponse]);

  return (
    <>
      <Row style={{ width: "100%", marginBottom: "20px" }}>
        <Col span={6} offset={18}>
          <NetwalaButton
            backgroundColor={ButtonProps.background.STANDARD}
            height={ButtonProps.height.STANDARD}
            width={ButtonProps.width.STANDARD}
            textColor={ButtonProps.color.STANDARD}
            onClick={() => {
              navigate(`/new-expenses`);
            }}
            text="New Expense"
          />
        </Col>
      </Row>
      <Row style={{ width: "100%" }} gutter={[16, 16]}>
        <RecentExpensesTable
          expenses={expenses}
          isLoading={apiLoading}
          title="Staff Expenses"
          isCEOView={true}
        />
      </Row>
    </>
  );
};
export default ViewMiniExpensesByCEOComponent;
