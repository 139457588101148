export const getCodeByUserName = (username: string): string => {
  const codes = localStorage.getItem("companyCodes");
  if (!codes) {
    return "";
  }
  const parsedCodes = JSON.parse(codes);
  const found = parsedCodes?.find((item: any) => item.username == username);
  if (found) {
    return found.code;
  }
  return "";
};

export const pushNewCodeWithUserName = (username: string, code: string) => {
  const codes = localStorage.getItem("companyCodes");
  const data = { username, code };
  if (!codes) {
    localStorage.setItem("companyCodes", JSON.stringify([data]));
    return;
  }
  const parsedCodes = JSON.parse(codes);
  //   parsedCodes.push(data);
  const updatedCodes = parsedCodes.filter(
    (item: any) => item.username !== username
  );
  updatedCodes.push(data);
  localStorage.setItem("companyCodes", JSON.stringify(updatedCodes));
};
