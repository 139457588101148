import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./index.css";
import PendingVouchersComponent from "./components/PendingVouchers";
import NetwalaTabs from "../Items/Tab/Index";
import RedeemedVouchersComponent from "./components/RedeemedVouchers";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import CreateNewVoucherModal from "../Modals/CreateNewVoucherModal";
const ViewDiscountVouchersComponent = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const _activeTab = searchParams.get("activeTab") || 1;
  const [activeTab, setActiveTab] = useState<number>(Number(_activeTab));
  const [show, setShow] = useState<boolean>(false);
  const [tabs, setTabs] = useState<NetwalaTabItemsProp[]>([
    {
      text: "Unused Vouchers",
      isActive: true,
      id: 1,
    },
    {
      text: "Used Vouchers",
      isActive: false,
      id: 2,
    },
  ]);

  const showActiveComponent = (activeTab: number) => {
    switch (activeTab) {
      case 1:
        return <PendingVouchersComponent />;
      case 2:
        return <RedeemedVouchersComponent />;
    }
    return <></>;
  };
  const handleTabSwitch = (tabId: number) => {
    navigate(`/discount-vouchers?activeTab=${tabId}`);
  };
  useEffect(() => {
    setActiveTab(Number(_activeTab));
    const updatedItems = tabs.map((item) => ({
      ...item,
      isActive: item.id === Number(_activeTab),
    }));
    setTabs(updatedItems);
  }, [activeTab, _activeTab]);
  const handleClose = (isReload: boolean) => {
    setShow(false);
    if (isReload === true) {
      window.location.href = "/discount-vouchers?activeTab=1";
    }
  };
  return (
    <>
      {show === true && (
        <CreateNewVoucherModal show={show} handleClose={handleClose} />
      )}
      <Row gutter={16}>
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: "50px",
            marginBottom: "20px",
          }}
        >
          <NetwalaButton
            backgroundColor={ButtonProps.background.STANDARD}
            height={ButtonProps.height.STANDARD}
            width={ButtonProps.width.STANDARD}
            textColor={ButtonProps.color.STANDARD}
            onClick={() => {
              setShow(true);
            }}
            text="Generate Voucher"
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <NetwalaTabs
          colSpan={12}
          tabs={tabs}
          handleTabSwitch={handleTabSwitch}
        />
      </Row>
      <Row style={{ marginTop: "20px" }}>{showActiveComponent(activeTab)}</Row>
    </>
  );
};
export default ViewDiscountVouchersComponent;
