import { useContext, useEffect } from "react";
import SingleUserDetailsComponent from "../components/SingleUser/SingleUserDetails";
import TitleContext from "../context/TitleContext";
import { useSearchParams } from "react-router-dom";

const UserDetailsPage = () => {
  const { setTitle } = useContext(TitleContext);
  const [searchParams] = useSearchParams();
  const fullName = searchParams.get("fullName");
  console.log({ fullName });
  useEffect(() => {
    setTitle(`Customer Profile (${fullName})`);
  }, [setTitle]);
  return (
    <>
      <SingleUserDetailsComponent />
    </>
  );
};
export default UserDetailsPage;
