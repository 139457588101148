import { Row, Typography } from "antd";
import "./styles/removeConfirmationUtil.css";
import PromiseFulfilIcon from "./../../assets/svgs/PromiseFulfilIcon.svg";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import React from "react";
const FulfilledPromiseConfirmationUtil: React.FC<RemoveServiceUtilProps> = ({
  confirmationButton,
  message,
  isLoading,
}) => {
  return (
    <Row className="service-remove-confirmation-container">
      <img src={PromiseFulfilIcon} />
      <Typography className="remove-message">{message}</Typography>
      <div className="remove-buttons-container">
        <NetwalaButton
          backgroundColor={ButtonProps.background.DANGER}
          height={ButtonProps.height.STANDARD}
          width={ButtonProps.width.ONE24}
          textColor={ButtonProps.color.STANDARD}
          onClick={() => confirmationButton(true)}
          disabled={isLoading}
          text="Yes"
          borderColor="#DF3131"
        />
        <NetwalaButton
          backgroundColor={ButtonProps.background.SECONDARY}
          height={ButtonProps.height.STANDARD}
          width={ButtonProps.width.ONE24}
          textColor={ButtonProps.color.BLACK}
          onClick={() => confirmationButton(false)}
          disabled={isLoading}
          text="Cancel"
          //   icon={<img src={PlusIcon} />}
          borderColor="#D7D7D7"
        />
      </div>
    </Row>
  );
};
export default FulfilledPromiseConfirmationUtil;
