export const ButtonProps = {
  background: {
    STANDARD: "linear-gradient(276.9deg, #67318C 5.85%, #1C1997 93.97%)",
    YELLOW: "linear-gradient(97.05deg, #DB7C38 1.74%, #FFA726 97.54%)",
    DANGER: "linear-gradient(96.95deg, #AB1B1A 1.36%, #DF3131 96.17%)",
    SECONDARY: "#D7D7D7",
  },
  width: {
    STANDARD: "262px",
    ONE24: "124px",
  },
  height: {
    STANDARD: "40px",
  },
  color: {
    STANDARD: "#FFFFFF",
    BLACK: "#545454",
  },
};
