import { notification } from "antd";

type NotificationType = "success" | "info" | "warning" | "error";

const useMultiNotification = () => {
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (
    type: NotificationType,
    title: string,
    description: string
  ) => {
    api[type]({
      message: title,
      description,
    });
  };

  return { openNotification, contextHolder };
};

export default useMultiNotification;
