import React from "react";
import { Modal, ModalProps } from "antd";
import { ReactNode } from "react";
import { ReactComponent as DefaultModalCloseIcon } from "./../../../assets/svgs/DefaultModalCloseIcon.svg";
interface NetwalaModalProps extends ModalProps {
  headerStyle?: React.CSSProperties;
  closeIcon?: ReactNode;
  children?: ReactNode;
  title: string;
  titleImage?: string;
}
const NetwalaModal: React.FC<NetwalaModalProps> = ({
  headerStyle,
  closeIcon,
  children,
  title,
  titleImage,
  ...rest
}) => {
  const titleContent = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...headerStyle,
      }}
    >
      {titleImage && (
        <img src={titleImage} alt="Title" style={{ marginRight: "10px" }} />
      )}
      {title}
    </div>
  );
  return (
    <Modal
      {...rest}
      wrapClassName="netwala-modal"
      closeIcon={closeIcon || <DefaultModalCloseIcon />}
      title={titleContent}
      footer={null}
      keyboard={false}
      maskClosable={false}
    >
      {children}
    </Modal>
  );
};
export default NetwalaModal;
