import React, { useState, useEffect, useRef } from "react";

import { Col, Dropdown, Input, Row, Table, Tag, Typography } from "antd";
import type { TableProps } from "antd";
import type { MenuProps } from "antd";

import type { SearchProps } from "antd/es/input/Search";
import { MenuOutlined } from "@ant-design/icons";

const { Search } = Input;
import "./index.css";
import { useNavigate } from "react-router-dom";
import TailSpinLoader from "../Loaders/TailSpinLoader";
import { formatDate } from "../../../helpers/date";
import AddOrUpdateInventoryItemsModal from "../../Modals/AddOrUpdateInventoryItemsModal";

const InventoryItemsTable: React.FC<InventoryItemsTableProp> = ({
  items,
  isLoading,
  reloadApi,
}) => {
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(false);

  const [activeRow, setActiveRow] = useState<InventoryItemProp | null>(null);

  const companyItems: MenuProps["items"] = [
    {
      key: "EDIT",
      label: (
        <Typography className="profile-drop-down-item">Edit Item</Typography>
      ),
    },
    {
      key: "VIEW",
      label: (
        <Typography className="profile-drop-down-item">
          View Item Types
        </Typography>
      ),
    },
  ];

  const handleMenuClick = (e: any) => {
    if (activeRow === null) {
      return;
    }
    switch (e.key) {
      case "EDIT":
        setShow(true);
        break;

      case "VIEW":
        navigate(
          `/super-admin/inventory/view-inventory-item-types/${activeRow.intPkInventoryItemID}`
        );
        break;
    }
  };
  const columns: TableProps<InventoryItemProp>["columns"] = [
    {
      title: "Item Type",
      dataIndex: "inventoryItemType",
      key: "inventoryItemType",
    },
    {
      title: "Measurement Unit",
      dataIndex: "inventoryMeasurementUnit",
      key: "inventoryMeasurementUnit",
    },
    {
      title: "Action",
      render: (row) => (
        <Dropdown
          menu={{
            items: companyItems,

            onClick: handleMenuClick,
          }}
          placement="bottomRight"
        >
          <MenuOutlined
            onMouseEnter={(e) => {
              setActiveRow(row);
            }}
            className="cursor-pointer"
          />
        </Dropdown>
      ),
    },
  ];
  const handleClose = (isReload: boolean) => {
    setShow(false);
    if (isReload === true) {
      //lets send a signal back
      reloadApi();
    }
  };
  return (
    <>
      {show === true && activeRow !== null && (
        <AddOrUpdateInventoryItemsModal
          item={activeRow}
          show={show}
          handleClose={handleClose}
          url={`updateInventoryItem`}
        />
      )}

      <div className="netwala-users-table-container">
        {isLoading === true ? (
          <TailSpinLoader />
        ) : (
          <Row>
            <Col span={24}>
              <div className="users-search-container">
                <Typography className="netwala-user-tables-heading">
                  Inventory Items
                </Typography>
              </div>
              <Table
                className="netwala-users-table striped-table"
                columns={columns}
                dataSource={items}
                pagination={false}
              />
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};
export default InventoryItemsTable;
