import React from "react";
import { Col, Row, Table, Tag, Typography } from "antd";
import type { TableProps } from "antd";
import "./index.css";
import IPWhiteListIcon from "./../../../assets/svgs/IPWhiteList.svg";
import IPTVIcon from "./../../../assets/svgs/IPTVIcon.svg";
import CATVIcon from "./../../../assets/svgs/CATVIcon.svg";
import INTERNETIcon from "./../../../assets/svgs/INTERNETIcon.svg";
import { useNavigate } from "react-router-dom";
import NetwalaPagination from "../Pagination/NetwalaPagination";
import TailSpinLoader from "../Loaders/TailSpinLoader";
import { formatDateForJustMonthDayAndYear } from "../../../helpers/date";

const ExpiredUsersTable: React.FC<ExpiredUsersTable> = ({
  users,
  pagination,
  handlePageSwitch,
  isLoading,
}) => {
  const navigate = useNavigate();
  const columns: TableProps<ExpiredUsersTableProp>["columns"] = [
    {
      title: "S.No",
      dataIndex: "intPkConnectionID",
      key: "intPkConnectionID",
      render: (text) => <span>{text < 10 ? `0${text}` : text}</span>,
    },
    {
      title: "ISP",
      render: (row) =>
        row.ispLogoUrl && (
          <img style={{ maxHeight: "40px" }} src={row.ispLogoUrl} />
        ),
    },
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
      render: (text) => (
        <Tag style={{ fontWeight: "bold" }}>{text ? text : "-"} </Tag>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "expiryDate",
      dataIndex: "expiryDate",
      render: (text) => (
        <span>{formatDateForJustMonthDayAndYear(new Date(text))}</span>
      ),
    },
    {
      title: "Expiring",
      dataIndex: "",
      render: (row) => {
        return (
          <div className="users-table-action-container">
            {row.connectionType === "CATV" ? (
              <img src={CATVIcon} className="cursor-pointer" />
            ) : null}
            {row.connectionType === "INTERNET" ? (
              <img src={INTERNETIcon} className="cursor-pointer" />
            ) : null}
            {row.staticIpDetails !== null ? (
              <img src={IPWhiteListIcon} className="cursor-pointer" />
            ) : null}
            {row.iptvDetails !== null ? (
              <img src={IPTVIcon} className="cursor-pointer" />
            ) : null}
          </div>
        );
      },
    },
  ];

  return (
    <div className="netwala-users-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <Typography className="netwala-user-tables-heading">
              Expired Connections
            </Typography>
            <Table
              className="netwala-users-table striped-table"
              columns={columns}
              dataSource={users}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    navigate(
                      `/user-connection-details/${record.intPkHeadID}/${record.intPkConnectionID}?activeTab=1&fullName=${record.fullName}`
                    );
                  }, // click row
                };
              }}
              pagination={false}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
              marginBottom: "20px",
            }}
            span={24}
          >
            <NetwalaPagination
              pagination={pagination}
              handlePageSwitch={handlePageSwitch}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
export default ExpiredUsersTable;
