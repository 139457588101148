import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewInventoryItems from "../components/inventory/ViewItems";

const InventoryItemsPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Inventory");
  }, [setTitle]);
  return (
    <>
      <ViewInventoryItems />
    </>
  );
};
export default InventoryItemsPage;
