import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewISPComponent from "../components/isps/ViewIsps";
// import ViewISPComponent from "../components/isps/ViewIsps";

const ViewISPsPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("ISPs");
  }, [setTitle]);
  return (
    <>
      <ViewISPComponent />
    </>
  );
};
export default ViewISPsPage;
