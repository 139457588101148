import { Col, Form, Input, Row, Upload, Button, message } from "antd";

import NetwalaSelect from "../Items/Select/Index";
import { useContext, useEffect, useState } from "react";
import { CameraOutlined, UploadOutlined } from "@ant-design/icons";
const { TextArea } = Input;
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import PlusIcon from "./../../assets/svgs/IconsWhite/PlusIcon.svg";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
} from "antd/lib/upload/interface";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import ConfirmInternetConnectionCreationModal from "../Modals/ConfirmInternetConnectionCreationModal";
import BalanceContext from "../../context/BalanceContext";
import { removeSpacesFromString } from "../../helpers/strings";

const CreateInternetForm = () => {
  const { value, setValue } = useContext(BalanceContext);

  const navigate = useNavigate();

  const { openNotification, contextHolder } = useMultiNotification();
  const { intPkHeadID } = useParams();
  const [form] = Form.useForm();
  const [
    getAreasApi,
    areasApiResponse,
    areasApiError,
    areasApiLoading,
    areasApiMessage,
    areasApiErrorCount,
  ] = useAxiosPost<ResponseProp>("areas/getAreasByOwner");
  const [
    getSubAreasApi,
    subAreasApiResponse,
    subAreasApiError,
    subAreasApiLoading,
    subAreasApiMessage,
    subAreasApiErrorCount,
  ] = useAxiosPost<SubAreasByAreaIDApiProp>("areas/getSubAreasByAreaID");

  const [
    getIspsApi,
    ispsApiResponse,
    ispsApiError,
    ispsApiLoading,
    ispsApiMessage,
    ispsApiErrorCount,
  ] = useAxiosPost<ResponseProp>("isps/getISPsByCompanyWithPublicIsps");
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<InternetConnectionCreation>(
    "connections/createInternetConnection",
    true
  );
  const [
    getPackagesApi,
    packagesApiResponse,
    packagesApiError,
    packagesApiLoading,
    packagesApiMessage,
    packagesApiErrorCount,
  ] = useAxiosPost<PackagesAndVlansByISPApiProp>("packages/getPackagesByISP");
  const [
    getVlansApi,
    vlansApiResponse,
    vlansApiError,
    vlansApiLoading,
    vlansApiMessage,
    vlansApiErrorCount,
  ] = useAxiosPost<PackagesAndVlansByISPApiProp>("isps/vlans/getVLANsByISP");
  const [show, setShow] = useState<boolean>(false);
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedISP, setSelectedISP] = useState("");
  const [selectedSubArea, setSelectedSubArea] = useState<null | string>(null);
  const [allIsps, setAllIsps] = useState<any[]>([]);
  const [allPackages, setAllPackages] = useState<any[]>([]);
  const [areas, setAreas] = useState<NetwalaSelectOptions[]>([]);
  const [subAreas, setSubAreas] = useState<NetwalaSelectOptions[]>([]);
  const [isps, setIsps] = useState<NetwalaSelectOptions[]>([]);
  const [packages, setPackages] = useState<NetwalaSelectOptions[]>([]);
  const [vlans, setVlans] = useState<NetwalaSelectOptions[]>([]);
  const [confirmationData, setConfirmationData] =
    useState<ConfirmInternetConnection | null>(null);
  const [dataToSend, setDataToSend] =
    useState<InternetConnectionCreation | null>(null);

  useEffect(() => {
    getAreasApi({});
    getIspsApi({});
  }, []);
  useEffect(() => {
    if (areasApiResponse !== null) {
      const data: any = areasApiResponse.responseData;
      const areas = data.map((item: any): NetwalaSelectOptions => {
        return {
          value: item.intPkAreaID,
          label: item.areaName,
        };
      });

      setAreas(areas);
    }
  }, [areasApiResponse]);
  useEffect(() => {
    if (ispsApiResponse !== null) {
      const data: any = ispsApiResponse.responseData;
      const isps = data.map((item: any): NetwalaSelectOptions => {
        return {
          value: item.intPkIspID,
          label: item.ISPName,
        };
      });
      setAllIsps(data || []);
      setIsps(isps);
    }
  }, [ispsApiResponse]);
  useEffect(() => {
    if (subAreasApiResponse !== null) {
      const data: any = subAreasApiResponse.responseData;
      const subAreas = data.map((item: any): NetwalaSelectOptions => {
        return {
          value: item.intPkAreaID,
          label: item.areaName,
        };
      });

      setSubAreas(subAreas);
    }
  }, [subAreasApiResponse]);

  useEffect(() => {
    if (packagesApiResponse !== null) {
      const data: any = packagesApiResponse.responseData;
      const packages = data.map((item: any): NetwalaSelectOptions => {
        return {
          value: item.intPkPackageID,
          label: item.packageName,
        };
      });
      setAllPackages(data || []);
      setPackages(packages);
    }
  }, [packagesApiResponse]);

  useEffect(() => {
    if (vlansApiResponse !== null) {
      const data: any = vlansApiResponse.responseData;
      const vlans = data.map((item: any): NetwalaSelectOptions => {
        return {
          value: item.intPkVlanID,
          label: item.vlanValue,
        };
      });

      setVlans(vlans);
    }
  }, [vlansApiResponse]);

  useEffect(() => {
    if (apiResponse !== null) {
      const { message } = apiResponse;
      if (message) {
        setValue(value + 1);

        openNotification(`success`, "Success", message);
        setTimeout(() => {
          navigate(-1);
        }, 2500);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", apiError);
    }
  }, [apiError, apiErrorCount]);
  const onFinishFailed = (errorInfo: any) => {};
  const handleAreaChange = (value: string) => {
    setSelectedSubArea(null);
    form.setFieldsValue({ subArea: undefined });

    getSubAreasApi({ intPkAreaID: Number(value) });
  };
  const handleChangeISP = (value: string) => {
    form.setFieldsValue({ package: undefined });
    form.setFieldsValue({ vlan: undefined });
    getPackagesApi({ ispId: Number(value) });
    getVlansApi({ ispId: Number(value) });
  };
  const onFinish = (values: any) => {
    const dateFormat = "YYYY-MM-DD";
    const currentDate = new Date(); // Get the current date
    currentDate.setMonth(currentDate.getMonth() + 1); // Add one month
    const defaultDate = moment(new Date(currentDate))
      .format(dateFormat)
      .toString();
    const area = areas.find(
      (item) => Number(item.value) === Number(values.area)
    );
    const subArea = subAreas.find(
      (item) => Number(item.value) === Number(values.subArea)
    );
    const vlan = values.vlan;
    const packageObj = allPackages.find(
      (item: any) => Number(item.intPkPackageID) === Number(values.package)
    );
    const vlanObj = vlans.find((item) => Number(item.value) === Number(vlan));
    const ispObj = allIsps.find(
      (item: any) => Number(item.intPkIspID) === Number(values.isp)
    );
    if (!ispObj || !packageObj || !area) {
      openNotification(
        "error",
        "Validation Error",
        "Some Error Occurs Please try again"
      );
      return;
    }
    const _confirmationData: ConfirmInternetConnection = {
      ispUrl: ispObj.url,
      packageName: packageObj.packageName,
      packagePrice: packageObj.salePrice,
      area: area.label,
      subArea: subArea?.label || "",
      username: removeSpacesFromString(values.username),
      password: values.password,
      vlan: vlanObj?.label || "",
      address: values.address,
      voucherNumber: values.voucherNumber,
      ontID: values.ontID ? values.ontID : null,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
    };
    setConfirmationData(_confirmationData);
    setShow(true);

    const dataToSend: InternetConnectionCreation = {
      areaId: values.subArea ? values.subArea : values.area,
      address: values.address,
      // userId: Number(intPkHeadID),
      expiryDate: defaultDate,
      intPkVlanID: values.vlan ? Number(values.vlan) : null,
      intPkPackageID: Number(values.package),
      intPkIspID: Number(values.isp),
      password: values.password,
      username: removeSpacesFromString(values.username),
      voucherNumber: values.voucherNumber,
      ontID: values.ontID ? values.ontID : null,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      amountPaid: values.amountPaid,
    };
    setDataToSend(dataToSend);
  };
  const validateConfirmPassword = (_: any, value: string) => {
    const { password } = form.getFieldsValue();
    console.log({ password });
    if (value && value !== password) {
      return Promise.reject(
        new Error("The two passwords that you entered do not match!")
      );
    }
    return Promise.resolve();
  };
  const handleClose = (isConfirm: boolean) => {
    setShow(false);
    if (isConfirm === true && dataToSend !== null) {
      postApiData(dataToSend);
    }
  };

  return (
    <>
      {show === true && confirmationData !== null && (
        <ConfirmInternetConnectionCreationModal
          show={show}
          handleClose={handleClose}
          confirmationData={confirmationData}
        />
      )}
      {contextHolder}

      <Row style={{ width: "100%" }}>
        <Form
          name="createInternetConnectionForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          requiredMark={false}
          style={{ width: "100%" }}
          form={form}
        >
          {/* First */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">First Name</span>
                }
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please enter First Name",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="John"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Last Name</span>
                }
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please enter Last Name",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="Doe"
                />
              </Form.Item>
            </Col>
          </Row>
          {/* Second */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Phone Number</span>
                }
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter Phone number",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="03001235467"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">Address</span>}
                name="address"
                rules={[
                  {
                    required: true,
                    message: "Please enter Address",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="House No."
                />
              </Form.Item>
            </Col>
          </Row>
          {/* Third */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">Area</span>}
                name="area"
                rules={[
                  {
                    required: true,
                    message: "Please Select Area",
                  },
                ]}
              >
                <NetwalaSelect
                  value={selectedArea}
                  onChange={handleAreaChange}
                  size="large"
                  height="45px"
                  options={areas}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">Sub Area</span>}
                name="subArea"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please enter Sub Area",
                //   },
                // ]}
              >
                <NetwalaSelect
                  value={selectedSubArea}
                  size="large"
                  height="45px"
                  options={subAreas}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Fourth */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">ISP</span>}
                name="isp"
                rules={[
                  {
                    required: true,
                    message: "Please Select ISP",
                  },
                ]}
              >
                <NetwalaSelect
                  value={selectedISP}
                  onChange={handleChangeISP}
                  size="large"
                  height="45px"
                  options={isps}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">Package</span>}
                name="package"
                rules={[
                  {
                    required: true,
                    message: "Please Select Package",
                  },
                ]}
              >
                <NetwalaSelect
                  onChange={(e) => {
                    console.log({ e });
                    const found = allPackages.find(
                      (item: any) => Number(item.intPkPackageID) === Number(e)
                    );
                    if (found) {
                      form.setFieldsValue({ amountPaid: found.salePrice || 0 });
                    }
                  }}
                  size="large"
                  height="45px"
                  options={packages}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Amount Paid</span>
                }
                name="amountPaid"
                rules={[
                  {
                    required: true,
                    message: "Please enter Amount Paid",
                  },
                  {
                    validator: (_, value) =>
                      value >= 0
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(
                              "Amount Paid must be greater than or equal to zero"
                            )
                          ),
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="100"
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Fifth */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">Vlan</span>}
                name="vlan"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Select Vlan",
                //   },
                // ]}
              >
                <NetwalaSelect size="large" height="45px" options={vlans} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">UserName</span>}
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please enter UserName",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="johnDeo121"
                />
              </Form.Item>
            </Col>
          </Row>
          {/* Sixth */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">Password</span>}
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter Password",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="Password"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">
                    Confirm Password
                  </span>
                }
                dependencies={["password"]}
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: "Please enter Password",
                  },
                  {
                    validator: validateConfirmPassword,
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="Password"
                />
              </Form.Item>
            </Col>
          </Row>
          {/* Seventh */}

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">Ont ID</span>}
                name="ontID"
              >
                <Input
                  className="user-input-general-height"
                  placeholder="Ont ID"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Voucher Number</span>
                }
                name="voucherNumber"
              >
                <Input
                  className="user-input-general-height"
                  placeholder="ABCDEFG21"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              style={{ display: "flex", justifyContent: "center" }}
              span={24}
            >
              <Form.Item>
                <NetwalaButton
                  htmlType="submit"
                  backgroundColor={ButtonProps.background.STANDARD}
                  height={"50px"}
                  width={"372px"}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {
                    console.log("clicked");
                  }}
                  text="Create Internet Connection"
                  icon={<img src={PlusIcon} />}
                  disabled={apiLoading}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Row>
    </>
  );
};
export default CreateInternetForm;
