import { useEffect, useState } from "react";
import { Row, Col, Typography, Form, Input, Card, Select, Button } from "antd";
import useAxiosPost from "../../hooks/useAxiosPost";
import { useNavigate, useParams } from "react-router-dom";
import useMultiNotification from "../../hooks/useNotification";
import AllCompaniesTable from "../Items/Table/AllCompaniesTable";
import CompanyStaffTable from "../Items/Table/CompanyStaffTable";
const ViewCompanyStaffComponent = () => {
  const navigate = useNavigate();
  const { intPkCompanyID } = useParams();
  const { openNotification, contextHolder } = useMultiNotification();
  const [staff, setStaff] = useState<CompanyStaffProp[]>([]);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<GetCompanyStaffApiProp>("superAdmin/getCompanyStaff");

  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setStaff(data || []);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({ intPkCompanyID: Number(intPkCompanyID) });
  }, []);

  return (
    <>
      {contextHolder}
      <Row style={{ width: "100%" }} gutter={30}>
        <Col span={24}>
          <CompanyStaffTable
            intPkCompanyID={Number(intPkCompanyID)}
            staff={staff}
            isLoading={apiLoading}
          />
        </Col>
      </Row>
    </>
  );
};
export default ViewCompanyStaffComponent;
