import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Progress, Switch, Tag } from "antd";

import "./index.css";
import "./../../SingleUser/components/cards/transactions.css";
import { formatDate } from "../../../helpers/date";
import { useNavigate } from "react-router-dom";
import useAxiosPost from "../../../hooks/useAxiosPost";
interface TransactionRowItemProp {
  label: string;
  value: string;
  valueClass: string;
}
const DetailsItem: React.FC<TransactionRowItemProp> = ({
  label,
  value,
  valueClass,
}) => {
  return (
    <div className="transaction-single-item-col">
      <Typography className="item-label">{label}</Typography>
      <Typography className={`${valueClass}`}>{value}</Typography>
    </div>
  );
};
const PanelManagerActionQueueHistory: React.FC<PanelManagerHistoryCard> = ({
  queues,
}) => {
  return (
    <>
      {queues.map((queue) => {
        return (
          <Row
            key={Math.random()}
            style={{
              width: "100%",
              marginBottom: "10px",
              paddingBottom: "20px",
            }}
            gutter={[16, 16]}
            className="cursor-pointer transaction-container"
          >
            <Col span={6}>
              <DetailsItem
                label="Activity type"
                value={queue.actionQueueType}
                valueClass="payment-purpose"
              />
            </Col>
            <Col span={6}>
              <DetailsItem
                label="Phone"
                value={queue.phoneNumber}
                valueClass="user-name-label"
              />
            </Col>
            <Col span={6}>
              <DetailsItem
                label="Username"
                value={queue.username}
                valueClass="user-name-label"
              />
            </Col>
            <Col span={6}>
              <DetailsItem
                label="Request Date"
                value={`${formatDate(new Date(queue.dateCreated))}`}
                valueClass="recharge-date-value"
              />
            </Col>

            <Col span={4}>
              <Tag
                style={{ fontWeight: "bold", marginTop: "20px" }}
                color={
                  queue.actionQueueStatus === "PROCESSED" ? "green" : "red"
                }
              >
                {queue.actionQueueStatus}{" "}
              </Tag>
            </Col>
            <Col span={2}>
              <DetailsItem
                label="Vlan"
                value={`${queue.vlanValue}`}
                valueClass="user-name-label"
              />
            </Col>
            <Col span={6}>
              <DetailsItem
                label="ISP"
                value={`${queue.ISPName}`}
                valueClass="user-name-label"
              />
            </Col>
            <Col span={6}>
              <DetailsItem
                label="Package"
                value={`${queue.packageName}`}
                valueClass="user-name-label"
              />
            </Col>

            <Col span={6}>
              <DetailsItem
                label="Process Date"
                value={`${formatDate(new Date(queue.updatedAt || new Date()))}`}
                valueClass="recharge-date-value"
              />
            </Col>
          </Row>
        );
      })}
    </>
  );
};
export default PanelManagerActionQueueHistory;
