import { Col, Form, Input, Row, Upload, Button } from "antd";
import "./index.css";
import NetwalaSelect from "../Items/Select/Index";
import { useContext, useEffect, useState } from "react";
import { CameraOutlined } from "@ant-design/icons";

import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import PlusIcon from "./../../assets/svgs/IconsWhite/PlusIcon.svg";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
} from "antd/lib/upload/interface";
import useAxiosPost, {
  authTokenString,
  baseURL,
} from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import RecentExpensesTable from "../Items/Table/RecentExpensesTable";
import BalanceContext from "../../context/BalanceContext";

const AddExpenseForm = () => {
  const { value, setValue } = useContext(BalanceContext);

  const { openNotification, contextHolder } = useMultiNotification();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [expenses, setExpenses] = useState<RecentExpensesProp[]>([]);

  const [form] = Form.useForm();
  const [
    getAreasApi,
    areasApiResponse,
    areasApiError,
    areasApiLoading,
    areasApiMessage,
    areasApiErrorCount,
  ] = useAxiosPost<ResponseProp>("transactions/getExpenseTags");

  const [
    getRecentExpensesApi,
    recentExpenseApiResponse,
    recentExpenseApiError,
    recentExpenseApiLoading,
    recentExpenseApiMessage,
    recentExpenseApiErrorCount,
  ] = useAxiosPost<ResponseProp>("invoices/getCashierRecentExpenses");
  useEffect(() => {
    getRecentExpensesApi({});
  }, []);

  const onFinish = (values: any) => {
    if (fileList.length !== 1) {
      openNotification("error", "Error", "Invalid Receipt Uploaded");
      return;
    }
    // const dataToSend: CreateUserApiProp = {
    //   phoneNumber: values.phoneNumber,
    //   firstName: values.firstName,
    //   lastName: values.lastName,
    //   areaId: Number(values.subArea),
    //   notes: notes,
    //   address: values.address,
    // };
    // postApiData(dataToSend);
    handleCreateUser(values);
  };
  const [tags, setTags] = useState<NetwalaSelectOptions[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleRemove = (file: UploadFile) => {
    setFileList((prevList) => prevList.filter((item) => item.uid !== file.uid));
  };

  const handleBeforeUpload = (file: RcFile, fileList: RcFile[]) => {
    setFileList(
      fileList.map((file) => ({
        uid: file.uid,
        name: file.name,
        status: "done",
      }))
    );
    // You can add your upload logic here if needed
    return false; // Return false here to avoid auto uploading
  };

  const handleChange = (info: UploadChangeParam) => {
    setFileList(info.fileList);
  };

  useEffect(() => {
    getAreasApi({});
  }, []);
  useEffect(() => {
    if (areasApiResponse !== null) {
      const data: any = areasApiResponse.responseData;
      const _tags = data.map((item: any): NetwalaSelectOptions => {
        return {
          value: item.intPkExpenseTagID,
          label: item.tagName,
        };
      });

      setTags(_tags);
    }
  }, [areasApiResponse]);

  const onFinishFailed = (errorInfo: any) => {};

  useEffect(() => {
    if (recentExpenseApiResponse !== null) {
      const data: any = recentExpenseApiResponse.responseData;
      // console.log({ data });
      setExpenses(data || []);
    }
  }, [recentExpenseApiResponse]);

  const handleCreateUser = async (values: any) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("authTokenString", authTokenString.toString());
    formData.append("amount", values.amount);
    formData.append("comments", values.comments);
    formData.append("intPkExpenseTagID", values.intPkExpenseTagID);

    if (fileList.length > 0) {
      formData.append("expenseSlip", fileList[0].originFileObj as Blob);
    }

    try {
      const response = await fetch(`${baseURL}/invoices/expenseByStaff`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.json();
        openNotification(
          "success",
          "Success",
          responseData?.message || "Expense done Successfully"
        );

        setTimeout(() => {
          // navigate(`/`);
          window.location.reload();
        }, 3000);
      } else {
        const responseData = await response.json();
        setIsLoading(false);

        openNotification(
          "error",
          "Operation Failed",
          responseData?.message || "Server Error"
        );
        console.error("Upload failed:", responseData);
      }
    } catch (error) {
      setIsLoading(false);

      console.error("Error during fetch:", error);
    }
  };

  return (
    <>
      {contextHolder}

      <Row style={{ width: "100%" }}>
        <Form
          name="addExpenseForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          requiredMark={false}
          style={{ width: "100%" }}
          form={form}
        >
          {/* First */}
          <Row gutter={16}>
            <Col span={12} offset={6}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Expense Tag
                      </span>
                    }
                    name="intPkExpenseTagID"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Tag",
                      },
                    ]}
                  >
                    <NetwalaSelect size="large" height="45px" options={tags} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">Amount</span>
                    }
                    name="amount"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Amount",
                      },
                      {
                        validator: (_, value) =>
                          value >= 0
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error("Amount Must be greater than 0")
                              ),
                      },
                    ]}
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="100"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">Comments</span>
                    }
                    name="comments"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Comments",
                      },
                      {
                        max: 190,
                        message: "Comments are too long.",
                      },
                    ]}
                  >
                    <Input
                      className="user-input-general-height"
                      placeholder="Food etc."
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Upload Receipt
                      </span>
                    }
                    name="idCardFront"
                  >
                    <Upload
                      onRemove={handleRemove}
                      beforeUpload={handleBeforeUpload}
                      onChange={handleChange}
                      fileList={fileList}
                    >
                      <Button icon={<CameraOutlined />}>
                        Choose Expense Slip Receipt
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
                <Col
                  span={24}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Form.Item>
                    <NetwalaButton
                      htmlType="submit"
                      backgroundColor={ButtonProps.background.STANDARD}
                      height={ButtonProps.height.STANDARD}
                      width={ButtonProps.width.STANDARD}
                      textColor={ButtonProps.color.STANDARD}
                      onClick={() => {
                        console.log("clicked");
                      }}
                      text="Add Expense"
                      icon={<img src={PlusIcon} />}
                      disabled={isLoading}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Row>
      <Row style={{ width: "100%", marginTop: "40px" }}>
        <RecentExpensesTable
          expenses={expenses}
          isLoading={recentExpenseApiLoading}
          title="Recent Expenses"
          isCEOView={false}
        />
      </Row>
    </>
  );
};
export default AddExpenseForm;
