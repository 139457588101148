import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import UpdateProfileComponent from "../components/auth/profile/UpdateProfileComponent";

const UpdateProfilePage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Update Profile");
  }, [setTitle]);
  return (
    <>
      <UpdateProfileComponent />
    </>
  );
};
export default UpdateProfilePage;
