import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import UpdateIspForm from "../components/isps/UpdateIspForm";

const UpdateISPPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Update ISP");
  }, [setTitle]);
  return (
    <>
      <UpdateIspForm />
    </>
  );
};
export default UpdateISPPage;
