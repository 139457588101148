import React, { useState, useEffect, useRef } from "react";

import { Col, Dropdown, Input, Row, Table, Tag, Typography } from "antd";
import type { TableProps } from "antd";
import type { MenuProps } from "antd";

import type { SearchProps } from "antd/es/input/Search";
import { MenuOutlined } from "@ant-design/icons";

const { Search } = Input;
import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import TailSpinLoader from "../Loaders/TailSpinLoader";
import { formatDate } from "../../../helpers/date";

const AllCompaniesTable: React.FC<CompaniesTableProp> = ({
  companies,
  isLoading,
}) => {
  const [activeRow, setActiveRow] = useState<CompanyProp | null>(null);

  const companyItems: MenuProps["items"] = [
    {
      key: "VIEW_STAFF",
      label: (
        <Typography className="profile-drop-down-item">View Staff</Typography>
      ),
    },
    {
      key: "VIEW_MIKRO_NOC_ISPS",
      label: (
        <Typography className="profile-drop-down-item">
          View Mikro Noc Isps
        </Typography>
      ),
    },
  ];
  // const debouncedSearch = useRef<((query: string) => void) | null>(null);
  // const [searchTerm, setSearchTerm] = useState<string>("");
  const navigate = useNavigate();
  const handleMenuClick = (e: any) => {
    if (activeRow === null) {
      return;
    }
    switch (e.key) {
      case "VIEW_STAFF":
        navigate(`/super-admin/company/view-staff/${activeRow.intPkCompanyID}`);
        break;
      case "VIEW_MIKRO_NOC_ISPS":
        navigate(
          `/super-admin/company/view-mikronoc-isps/${activeRow.intPkCompanyID}`
        );
        break;
    }
  };
  const columns: TableProps<CompanyProp>["columns"] = [
    {
      title: "Id",
      dataIndex: "intPkCompanyID",
      key: "intPkCompanyID",
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Creation Date",
      dataIndex: "dateCreated",
      key: "dateCreated",
      render: (text) => <span>{formatDate(new Date(text))}</span>,
    },
    {
      title: "Action",
      render: (row) => (
        <Dropdown
          menu={{
            items: companyItems,

            onClick: handleMenuClick,
          }}
          placement="bottomRight"
        >
          <MenuOutlined
            onMouseEnter={(e) => {
              setActiveRow(row);
            }}
            className="cursor-pointer"
          />
        </Dropdown>
      ),
    },
    {
      title: "Login",
      render: (row) => (
        <Link target="_blank" to={`/company-login/${row.intPkCompanyID}`}>
          Login
        </Link>
      ),
    },
  ];
  // const onSearch: SearchProps["onSearch"] = (value, _e, info) => {
  //   console.log(info?.source, value);
  //   if (value.length < 3) {
  //     return;
  //   }
  //   handleSearch(value, 1);
  // };

  // useEffect(() => {
  //   const delaySearch = setTimeout(() => {
  //     handleSearch(searchTerm, 1);
  //   }, 500);

  //   return () => clearTimeout(delaySearch); // Cleanup the timeout on component unmount
  // }, [searchTerm]);
  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setSearchTerm(e.target.value);
  // };

  return (
    <div className="netwala-users-table-container">
      {isLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row>
          <Col span={24}>
            <div className="users-search-container">
              <Typography className="netwala-user-tables-heading">
                All Companies
              </Typography>
              {/* <Search
                placeholder="Name / Username / phone Number"
                onSearch={onSearch}
                value={searchTerm}
                allowClear
                onChange={handleChange}
                style={{ width: 300 }}
              /> */}
            </div>
            <Table
              className="netwala-users-table striped-table"
              columns={columns}
              dataSource={companies}
              // pagination={{
              //   pageSize: 10,
              //   showQuickJumper: true,
              //   showSizeChanger: true,
              //   pageSizeOptions: ["10", "20", "30", "40"],
              // }}
              pagination={false}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
export default AllCompaniesTable;
