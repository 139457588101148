import React from "react";
import "./styles.css";

const PhotoCollageMakerPrivacyPolicyPage = () => {
  return (
    <div className="policy-page">
      <header className="privacy-policy-main-heading">
        <h1>Privacy Policy for Photo Collage Maker</h1>
      </header>
      <section className="policy-section">
        <h2>Introduction</h2>
        <p>
          This Privacy Policy describes how we collects, uses, and discloses
          your information in connection with your use of our photo collage
          maker application.
        </p>
      </section>
      <section className="policy-section">
        <h2>Information We Collect</h2>
        <p>We collect two main types of information through the App:</p>
        <p>
          Device Information: This includes basic information about your device,
          such as its model, operating system type and version, unique
          identifiers, and language. This information helps us understand how
          you interact with the App and improve its performance.
        </p>
        <p>
          Photo Access: The App requires access to your device's photo library
          to allow you to select photos for your collages. We do not store or
          transmit the actual photos themselves.
        </p>
      </section>

      <section className="policy-section">
        <h2>How We Use Your Information</h2>
        <p>We use the information we collect for the following purposes:</p>
        <p>
          To provide and improve the App: We use device information to ensure
          the App functions properly on your device and to diagnose any
          technical issues.
        </p>
        <p>
          Analytics and Improvement: We may use anonymized data to analyze how
          users interact with the App and improve its functionality and design.
        </p>
      </section>

      <section className="policy-section">
        <h2>Security</h2>
        <p>
          We take reasonable steps to protect the information we collect from
          loss, misuse, and unauthorized access, disclosure, alteration, and
          destruction.
        </p>
        <p>
          We do not store or transmit your actual photos. Only references to
          their location on your device are used for collage creation.
        </p>
      </section>

      <section className="policy-section">
        <h2>Children's Privacy</h2>
        <p>
          Our App is not directed to children under the age of 13. We do not
          knowingly collect personal information from children under 13. If you
          are a parent or guardian and you believe your child has provided us
          with personal information, please contact us.
        </p>
      </section>

      <section className="policy-section">
        <h2>Changes to This Policy</h2>
        <p>
          We may update this Policy from time to time. We will notify you of any
          changes by posting the new Policy on the App. You are advised to
          review this Policy periodically for any changes.
        </p>
      </section>
      <section className="policy-section">
        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Policy, please contact us at
          info@photoeditorcollagemaker.com
        </p>
      </section>
      <footer className="privacy-policy-footer">
        <p>Last Updated: March 1, 2024</p>
      </footer>
    </div>
  );
};

export default PhotoCollageMakerPrivacyPolicyPage;
