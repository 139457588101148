import React, { useContext, useEffect } from "react";
import TitleContext from "../../context/TitleContext";
import ViewCompanyMikroNocIspsComponent from "../../components/superAdmin/ViewCompanyMikroNocIsps";
const ViewCompanyMikroNOCIspsPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("MikroNoc Isps");
  }, [setTitle]);

  return (
    <>
      <ViewCompanyMikroNocIspsComponent />
    </>
  );
};
export default ViewCompanyMikroNOCIspsPage;
