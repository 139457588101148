import { useEffect, useState } from "react";
import { Col, Dropdown, Row, Typography } from "antd";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// import "./../cards/SingleStaffMiniCard.css";
import useAxiosPost from "../../hooks/useAxiosPost";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import { formatDate } from "../../helpers/date";
import { getUserType } from "../../helpers/userDetails";
import VerticalThreeDots from "./../../assets/svgs/ThreeDotsVertical.svg";
import ViewReceiptIcon from "./../../assets/svgs/ViewReceiptIcon.svg";
import { MenuOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import BankDepositRequestConfirmationModal from "../Modals/BankDepositRequestConfirmationModal";
import useMultiNotification from "../../hooks/useNotification";
import ViewReceiptModal from "../Modals/ViewReceiptModal";
import NetwalaTabs from "../Items/Tab/Index";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";

const ViewBankDepositRequests = () => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [searchParams] = useSearchParams();
  const _activeTab = searchParams.get("activeTab") || 1;
  const [tabs, setTabs] = useState<NetwalaTabItemsProp[]>([
    {
      text: "Pending",
      isActive: true,
      id: 1,
    },

    {
      text: "Processed",
      isActive: false,
      id: 2,
    },
  ]);
  const [activeTab, setActiveTab] = useState<number>(Number(_activeTab));

  const [showApprovalModal, setShowApprovalModal] = useState<boolean>(false);
  const [showSlipModal, setShowSlipModal] = useState<boolean>(false);
  const [intPkResourceFileID, setIntPkResourceFileID] = useState<number | null>(
    null
  );
  const [activeRow, setActiveRow] = useState<BankDepositRequest | null>(null);
  const navigate = useNavigate();
  const [requests, setRequests] = useState<BankDepositRequest[]>([]);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<BankDepositRequestsApiProp>(
    "transactions/getBankDepositsRequests"
  );

  const [
    postConfirmationApproval,
    approvalApiResponse,
    approvalApiError,
    approvalApiLoading,
    approvalApiMessage,
    approvalApiErrorCount,
  ] = useAxiosPost<ApproveBankDepositRequestApiProp>(
    "invoices/approveBankDepositRequest"
  );
  const handleClose = (isConfirm: boolean) => {
    if (isConfirm === true && activeRow !== null) {
      postConfirmationApproval({
        intPkBankDepositRequestID: activeRow.intPkBankDepositRequestID,
      });
    }
    setActiveRow(null);
    setShowApprovalModal(false);
  };
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setRequests(data || []);
    }
  }, [apiResponse]);

  useEffect(() => {
    if (approvalApiResponse !== null) {
      if (Number(_activeTab) === 1) {
        postApiData({ status: "PENDING" });
      } else {
        postApiData({ status: "PROCESSED" });
      }

      const { message } = approvalApiResponse;
      if (message) {
        openNotification("success", "Success", message);
      }
    }
  }, [approvalApiResponse]);
  useEffect(() => {
    if (approvalApiError !== null && approvalApiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", approvalApiError);
    }
  }, [approvalApiError, approvalApiErrorCount]);
  const handleMenuClick = (e: any) => {
    switch (e.key) {
      case "PROCESSED":
        setShowApprovalModal(true);
        break;
    }
  };
  const items: MenuProps["items"] = [
    {
      key: "PROCESSED",
      label: (
        <Typography className="profile-drop-down-item">
          Confirm Payment
        </Typography>
      ),
    },
  ];
  const handleCloseSliPModal = (isReload: boolean) => {
    setIntPkResourceFileID(null);
    setShowSlipModal(true);
  };
  const handleTabSwitch = (tabId: number) => {
    navigate(`/bank-deposit-requests?activeTab=${tabId}`);
  };
  useEffect(() => {
    setActiveTab(Number(_activeTab));

    const updatedItems = tabs.map((item) => ({
      ...item,
      isActive: item.id === Number(_activeTab),
    }));
    setTabs(updatedItems);
  }, [activeTab, _activeTab]);
  useEffect(() => {
    if (Number(_activeTab) === 1) {
      postApiData({ status: "PENDING" });
    } else {
      postApiData({ status: "PROCESSED" });
    }
  }, [_activeTab]);
  return (
    <>
      {apiLoading === true || approvalApiLoading === true ? (
        <TailSpinLoader />
      ) : (
        <>
          {showSlipModal === true && intPkResourceFileID !== null && (
            <ViewReceiptModal
              show={showSlipModal}
              handleClose={handleCloseSliPModal}
              intPkResourceFileID={intPkResourceFileID}
            />
          )}
          {/* <ViewReceiptModal */}
          {showApprovalModal === true && activeRow !== null && (
            <BankDepositRequestConfirmationModal
              show={showApprovalModal}
              handleClose={handleClose}
              intPkBankDepositRequestID={activeRow.intPkBankDepositRequestID}
              request={activeRow}
            />
          )}
          {getUserType() === "CASHIER" && (
            <Row gutter={16}>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "20px",
                }}
                span={24}
              >
                <NetwalaButton
                  backgroundColor={ButtonProps.background.STANDARD}
                  height={ButtonProps.height.STANDARD}
                  width={ButtonProps.width.STANDARD}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {
                    navigate(`/create-bank-deposit-request`);
                  }}
                  text="New Deposit Request"
                />
              </Col>
            </Row>
          )}
          <Row style={{ marginBottom: "20px" }} gutter={[16, 16]}>
            <NetwalaTabs
              colSpan={12}
              tabs={tabs}
              handleTabSwitch={handleTabSwitch}
            />
          </Row>
          {requests.map((item) => {
            return (
              <Row
                key={Math.random()}
                gutter={[20, 20]}
                className="bank-deposit-request-container"
              >
                <Col className="" span={3}>
                  <img src={item.url} />
                </Col>
                <Col className="bank-deposit-request-item" span={3}>
                  <Typography className="bank-deposit-request-label">
                    Bank Account No.
                  </Typography>
                  <Typography className="bank-deposit-request-branch-address">
                    {item.accountNumber}
                  </Typography>
                </Col>
                <Col className="bank-deposit-request-item" span={4}>
                  <Typography className="bank-deposit-request-label">
                    Branch
                  </Typography>
                  <Typography className="bank-deposit-request-branch-address">
                    {item.address}
                  </Typography>
                </Col>
                <Col className="bank-deposit-request-item" span={3}>
                  <Typography className="bank-deposit-request-label">
                    Submitted By
                  </Typography>
                  <Typography className="bank-deposit-request-branch-address">
                    {item.firstName + " " + item.lastName}
                  </Typography>
                </Col>
                <Col className="bank-deposit-request-item" span={2}>
                  <Typography className="bank-deposit-request-label">
                    Amount
                  </Typography>
                  <Typography className="bank-deposit-request-amount">
                    {item.amount}/-
                  </Typography>
                </Col>
                <Col className="bank-deposit-request-item" span={4}>
                  <Typography className="bank-deposit-request-label">
                    Transaction Date
                  </Typography>
                  <Typography className="bank-deposit-request-date-label">
                    {formatDate(new Date(item.dateCreated))}
                  </Typography>
                </Col>
                {getUserType() === "CEO" ? (
                  <>
                    <Col className="bank-deposit-request-item" span={3}>
                      <Typography className="bank-deposit-request-label">
                        Status
                      </Typography>
                      <Typography
                        className={`bank-deposit-request-status-${item.status}`}
                      >
                        {item.status}
                      </Typography>
                    </Col>
                    <Col
                      style={{ display: "flex", alignItems: "center" }}
                      span={1}
                    >
                      <img
                        onClick={(e) => {
                          e.preventDefault();
                          setShowSlipModal(true);
                          setIntPkResourceFileID(item.bankDepositSlipID);
                        }}
                        src={ViewReceiptIcon}
                        className="cursor-pointer"
                      />
                    </Col>
                    <Col
                      style={{ display: "flex", alignItems: "center" }}
                      span={1}
                    >
                      {item.status === "PENDING" && (
                        <Dropdown
                          menu={{
                            items: items,
                            onClick: handleMenuClick,
                          }}
                          placement="bottomRight"
                        >
                          <img
                            onMouseEnter={(e) => {
                              e.preventDefault();
                              setActiveRow(item);
                            }}
                            src={VerticalThreeDots}
                            className="cursor-pointer"
                          />
                        </Dropdown>
                      )}
                    </Col>
                  </>
                ) : (
                  <>
                    <Col className="bank-deposit-request-item" span={3}>
                      <Typography className="bank-deposit-request-label">
                        Status
                      </Typography>
                      <Typography
                        className={`bank-deposit-request-status-${item.status}`}
                      >
                        {item.status}
                      </Typography>
                    </Col>
                    <Col
                      style={{ display: "flex", alignItems: "center" }}
                      span={1}
                    >
                      <img
                        onClick={(e) => {
                          e.preventDefault();
                          setShowSlipModal(true);
                          setIntPkResourceFileID(item.bankDepositSlipID);
                        }}
                        src={ViewReceiptIcon}
                        className="cursor-pointer"
                      />
                    </Col>
                  </>
                )}
              </Row>
            );
          })}
        </>
      )}
    </>
  );
};
export default ViewBankDepositRequests;
