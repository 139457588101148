import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import "./index.css";
import UserProfileDetailsCard from "./cards/UserProfileDetailsCard";
import UserRecentActivitiesCard from "./cards/UserRecentActivitiesCard";
import useAxiosPost from "../../../hooks/useAxiosPost";
import { useParams } from "react-router-dom";
const UserProfileComponent = () => {
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<GetUserProfileApiProp>("users/getUserById");
  const [profileData, setProfileData] = useState<SingleUserProfileProp | null>(
    null
  );
  const { intPkHeadID } = useParams();
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setProfileData(data);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({ userId: Number(intPkHeadID) });
  }, []);
  const reloadApi = () => {
    postApiData({ userId: Number(intPkHeadID) });
  };
  return (
    <>
      <Row gutter={[32, 32]} className="end-user-profile-container">
        <Col span={12}>
          {profileData !== null ? (
            <UserProfileDetailsCard
              reloadApi={reloadApi}
              profile={profileData}
            />
          ) : null}
        </Col>
        <Col span={12}>
          <UserRecentActivitiesCard />
        </Col>
      </Row>
    </>
  );
};
export default React.memo(UserProfileComponent);
