import { useEffect, useState } from "react";
import { Row, Col, Typography, Form, Input, Card, Select, Button } from "antd";
import useAxiosPost from "../../hooks/useAxiosPost";
import { useNavigate, useParams } from "react-router-dom";
import useMultiNotification from "../../hooks/useNotification";
import AllCompaniesTable from "../Items/Table/AllCompaniesTable";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
const AllCompaniesComponent = () => {
  const navigate = useNavigate();

  const { openNotification, contextHolder } = useMultiNotification();
  const [companies, setCompanies] = useState<CompanyProp[]>([]);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("superAdmin/getAllCompanies");

  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setCompanies(data || []);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({});
  }, []);

  return (
    <>
      {contextHolder}
      <Row style={{ marginBottom: "20px" }} gutter={[16, 16]}>
        <Col offset={18} span={6}>
          <NetwalaButton
            backgroundColor={ButtonProps.background.STANDARD}
            height={ButtonProps.height.STANDARD}
            width={ButtonProps.width.STANDARD}
            textColor={ButtonProps.color.STANDARD}
            onClick={() => {
              navigate(`/super-admin/create-company`);
            }}
            text="Create Company"
          />
        </Col>
      </Row>
      <Row style={{ width: "100%" }} gutter={30}>
        <Col span={24}>
          <AllCompaniesTable companies={companies} isLoading={apiLoading} />
        </Col>
      </Row>
    </>
  );
};
export default AllCompaniesComponent;
