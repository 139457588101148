import React from "react";

interface BalanceContextType {
  value: number;
  setValue: (value: number) => void;
}

const BalanceContext = React.createContext<BalanceContextType>({
  value: 0,
  setValue: () => {},
});

export default BalanceContext;
