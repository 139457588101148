import React, { useEffect } from "react";
import NetwalaModal from "../Items/Modal/Index";
import RechargeIcon from "./../../assets/svgs/RechargeIcon.svg";
import { Col, Row, Form, Input } from "antd";
import "./styles/RequestIPTVModal.css";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
const CollectStaffPaymentModal: React.FC<CollectPaymentFromStaffModalProps> = ({
  show,
  handleClose,
  intPkHeadID,
  cashAvailable,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();

  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<DepositStaffApiProp>("invoices/depositStaff", true);

  const onFinish = (values: any) => {
    const { amount } = values;
    postApiData({ intPkHeadID, amount: Number(amount) });
  };
  useEffect(() => {
    if (apiResponse !== null) {
      const { message } = apiResponse;
      if (message) {
        openNotification("success", "Success", message);
        setTimeout(() => {
          handleClose(true);
        }, 2500);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification("error", "Error", apiError);
    }
  }, [apiError, apiErrorCount]);
  const onFinishFailed = (errorInfo: any) => {};
  return (
    <>
      <NetwalaModal
        title="Collect Payment"
        headerStyle={{
          backgroundColor: "#292D8E",
          color: "#FFFFFF",
          fontSize: "16px",
          fontWeight: "400",
          height: "50px",
        }}
        open={show}
        onCancel={() => {
          handleClose(false);
        }}
      >
        {contextHolder}
        <Row>
          <Col span={24} className="iptv-modal-package-details">
            <Form
              requiredMark={false}
              style={{ width: "100%", marginTop: "50px" }}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              name="createAreaForm"
            >
              <Form.Item
                label={<span className="create-user-form-label">Collect</span>}
                name="amount"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Amount",
                  },
                  {
                    validator: (_, value) =>
                      value > 0
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("Amount must be greater than 0")
                          ),
                  },
                  {
                    validator: (_, value) =>
                      value > cashAvailable
                        ? Promise.reject(
                            new Error(
                              `Amount must be less than or equal to :${cashAvailable}`
                            )
                          )
                        : Promise.resolve(),
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="100"
                />
              </Form.Item>

              <Form.Item>
                <NetwalaButton
                  backgroundColor={ButtonProps.background.YELLOW}
                  height={ButtonProps.height.STANDARD}
                  width={`100%`}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {}}
                  htmlType="submit"
                  text="Submit"
                  borderColor="#FFA726"
                  disabled={apiLoading}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </NetwalaModal>
    </>
  );
};

export default CollectStaffPaymentModal;
