import React, { useEffect } from "react";
import NetwalaModal from "../Items/Modal/Index";
import { Col, Row, Form, Input } from "antd";
import "./styles/RequestIPTVModal.css";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
const UpdateSingleUserProfileModal: React.FC<
  UpdateSingleUserProfileModalProps
> = ({ show, handleClose, profile }) => {
  const { openNotification, contextHolder } = useMultiNotification();

  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<UpdateSingleUserProfileApiProps>(
    "users/updateUserProfile",
    true
  );

  const onFinish = (values: any) => {
    const { firstName, lastName, address, phoneNumber } = values;
    const dataToSend: UpdateSingleUserProfileApiProps = {
      firstName,
      lastName,
      userId: profile.intPkHeadID,
      address,
      phoneNumber,
    };
    postApiData(dataToSend);
  };
  useEffect(() => {
    if (apiResponse !== null) {
      const { message } = apiResponse;
      if (message) {
        openNotification("success", "Success", message);
        setTimeout(() => {
          handleClose(true);
        }, 2500);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification("error", "Error", apiError);
    }
  }, [apiError, apiErrorCount]);
  const onFinishFailed = (errorInfo: any) => {};

  return (
    <>
      <NetwalaModal
        title="Update Profile"
        headerStyle={{
          backgroundColor: "#292D8E",
          color: "#FFFFFF",
          fontSize: "16px",
          fontWeight: "400",
          height: "50px",
        }}
        open={show}
        onCancel={() => handleClose(false)}
      >
        {contextHolder}

        <Row>
          <Col span={24} className="iptv-modal-package-details">
            <Form
              requiredMark={false}
              style={{ width: "100%", marginTop: "50px" }}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              name="updateUserProfileForm"
              initialValues={{
                firstName: profile.firstName,
                lastName: profile.lastName,
                address: profile.address,
                phoneNumber: profile.phoneNumber,
              }}
            >
              <Form.Item
                label={
                  <span className="create-user-form-label">First Name</span>
                }
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please Enter First Name",
                  },
                  {
                    min: 3,
                    message: "First Name Should be more than 2 characters",
                  },
                ]}
              >
                <Input className="user-input-general-height" />
              </Form.Item>
              <Form.Item
                label={
                  <span className="create-user-form-label">Last Name</span>
                }
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Last Name",
                  },
                  {
                    min: 2,
                    message: "Last Name Should be at least 2 characters",
                  },
                ]}
              >
                <Input className="user-input-general-height" />
              </Form.Item>

              <Form.Item
                label={<span className="create-user-form-label">Address</span>}
                name="address"
                rules={[
                  {
                    required: true,
                    message: "Please enter Address",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="House No."
                />
              </Form.Item>

              <Form.Item
                label={
                  <span className="create-user-form-label">Phone Number</span>
                }
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter Phone Number",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="03001234567"
                />
              </Form.Item>

              <Form.Item>
                <NetwalaButton
                  backgroundColor={ButtonProps.background.YELLOW}
                  height={ButtonProps.height.STANDARD}
                  width={`100%`}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {}}
                  htmlType="submit"
                  text="Update Profile"
                  borderColor="#FFA726"
                  disabled={apiLoading}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </NetwalaModal>
    </>
  );
};

export default UpdateSingleUserProfileModal;
