import React from "react";
import "./styles.css";

const CamScannerPdfConverterPrivacyPolicyPage = () => {
  return (
    <div className="policy-page">
      <header className="privacy-policy-main-heading">
        <h1>Privacy Policy for CamScanner - PDF Converter App</h1>
      </header>
      <section className="policy-section">
        <h2>Introduction</h2>
        <p>
          This Privacy Policy describes how we collects, uses, and discloses
          your information in connection with your use of our mobile
          application, CamScanner - PDF Converter App.
        </p>
      </section>
      <section className="policy-section">
        <h2>Information We Collect</h2>
        <p>We collect two main types of information through the App:</p>
        <p>
          Device Information: This includes basic information about your device,
          such as its model, operating system type and version, unique
          identifiers, and language. This information helps us understand how
          you interact with the App and improve its performance.
        </p>
        <p>
          Image and Document Data: The App allows you to scan physical documents
          and images using your device's camera. We do not store the actual
          content of the scanned documents or images on our servers. The
          processed versions (converted to PDF or other formats) may be
          temporarily stored on our servers for processing purposes only. This
          temporary storage is encrypted and automatically deleted after a short
          period.
        </p>
      </section>
      <section className="policy-section">
        <h2>How We Use Your Information</h2>
        <p>We use the information we collect for the following purposes:</p>
        <p>
          To provide the App functionality: Device information is used to ensure
          the App functions properly on your device and optimize the scanning
          and conversion process.
        </p>
        <p>
          Temporary Processing: Processed versions of scanned documents/images
          may be temporarily stored for conversion and optimization purposes.
          This data is encrypted and automatically deleted.
        </p>
        <p>
          To provide customer support: We may use your information to respond to
          your inquiries and provide customer support.
        </p>
        <p>
          App Analytics (Anonymized): We may use anonymized data on App usage,
          such as features used and document types scanned, to analyze trends
          and improve the App's features and functionality.
        </p>
      </section>

      <section className="policy-section">
        <h2>Security</h2>
        <p>
          We take reasonable steps to protect the information we collect from
          loss, misuse, and unauthorized access, disclosure, alteration, and
          destruction.
        </p>
        <p>
          We do not store the actual content of the documents or images you scan
          on our servers.
        </p>
        <p>
          Temporary processing storage is encrypted and automatically deleted.
        </p>
      </section>

      <section className="policy-section">
        <h2>Children's Privacy</h2>
        <p>
          Our App is not directed to children under the age of 13. We do not
          knowingly collect personal information from children under 13. If you
          are a parent or guardian and you believe your child has provided us
          with personal information, please contact us.
        </p>
      </section>

      <section className="policy-section">
        <h2>Changes to This Policy</h2>
        <p>
          We may update this Policy from time to time. We will notify you of any
          changes by posting the new Policy on the App. You are advised to
          review this Policy periodically for any changes.
        </p>
      </section>
      <section className="policy-section">
        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at bestcamscanner@gmail.com
        </p>
      </section>
      <footer className="privacy-policy-footer">
        <p>Last Updated: March 1, 2024</p>
      </footer>
    </div>
  );
};

export default CamScannerPdfConverterPrivacyPolicyPage;
