import React, { useContext, useEffect } from "react";
import TitleContext from "../../context/TitleContext";
import AllCompaniesComponent from "../../components/superAdmin/AllCompanies";
import LoginAsCompanyCEO from "../../components/superAdmin/LoginCompany";
const CompanyLoginAsCEOPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("All Companies");
  }, [setTitle]);

  return (
    <>
      <LoginAsCompanyCEO />
    </>
  );
};
export default CompanyLoginAsCEOPage;
