import React, { useEffect } from "react";
import NetwalaModal from "../Items/Modal/Index";
import { Col, Row, Form, Input } from "antd";

import "./styles/RequestIPTVModal.css";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
const AddOrUpdateInventoryItemTypesModal: React.FC<
  AddOrUpdateInventoryItemTypeModalProps
> = ({ show, handleClose, itemType, url, intPkInventoryItemID }) => {
  const { openNotification, contextHolder } = useMultiNotification();

  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<AddOrUpdateInventoryItemTypeApiProp>(
    `superAdmin/${url}`,
    true
  );

  const onFinish = (values: any) => {
    const { inventoryItemVariant } = values;
    const dataToSend: AddOrUpdateInventoryItemTypeApiProp = {
      intPkInventoryItemTypeID:
        itemType === null ? null : itemType.intPkInventoryItemTypeID,
      intPkInventoryItemID,
      inventoryItemVariant,
    };
    postApiData(dataToSend);
  };
  useEffect(() => {
    if (apiResponse !== null) {
      const { message } = apiResponse;
      if (message) {
        openNotification("success", "Success", message);
        setTimeout(() => {
          handleClose(true);
        }, 2500);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification("error", "Error", apiError);
    }
  }, [apiError, apiErrorCount]);
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <NetwalaModal
        title={itemType !== null ? "Update Item Type" : "Add Item Type"}
        headerStyle={{
          backgroundColor: "#292D8E",
          color: "#FFFFFF",
          fontSize: "16px",
          fontWeight: "400",
          height: "50px",
        }}
        open={show}
        onCancel={() => {
          if (apiLoading) {
            return;
          }
          handleClose(false);
        }}
      >
        {contextHolder}

        <Row>
          <Col span={24} className="iptv-modal-package-details">
            <Form
              requiredMark={false}
              style={{ width: "100%", marginTop: "50px" }}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              name="addOrUpdateInventoryItemTypeForm"
              initialValues={
                itemType !== null
                  ? {
                      inventoryItemVariant: itemType.inventoryItemVariant,
                    }
                  : {}
              }
            >
              <Form.Item
                label={
                  <span className="create-user-form-label">Enter Variant</span>
                }
                name="inventoryItemVariant"
                rules={[
                  {
                    required: true,
                    message: "Please Choose Item",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="Variant Name"
                />
              </Form.Item>

              <Form.Item>
                <NetwalaButton
                  backgroundColor={ButtonProps.background.STANDARD}
                  height={ButtonProps.height.STANDARD}
                  width={`100%`}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {}}
                  htmlType="submit"
                  text={itemType !== null ? "Update Type" : "Add Type"}
                  disabled={apiLoading}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </NetwalaModal>
    </>
  );
};

export default AddOrUpdateInventoryItemTypesModal;
