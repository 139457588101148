import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import CreateVlanComponent from "../components/isps/SingleIspProfile/components/vlans/CreateVlan";
// import CreateVlanComponent from "../components/isps/SingleIspProfile/components/vlans/CreateVlan";

const CreateVlanPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Create Vlan");
  }, [setTitle]);
  return (
    <>
      <CreateVlanComponent />
    </>
  );
};
export default CreateVlanPage;
