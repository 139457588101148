import React, { useEffect, useState } from "react";
import NetwalaModal from "../Items/Modal/Index";
import { Col, Row } from "antd";
import "./styles/RequestIPTVModal.css";

import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
const ViewReceiptModal: React.FC<ViewEncryptedImagesModal> = ({
  show,
  handleClose,
  intPkResourceFileID,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ViewEncryptedImageApiProp>(
    "cloudStorage/decryptionServiceApi"
  );
  useEffect(() => {
    postApiData({ intPkResourceFileID });
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      console.log(data);
      setImageUrl(data);
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", apiError);
    }
  }, [apiError, apiErrorCount]);

  return (
    <>
      <NetwalaModal
        title="View Slip"
        headerStyle={{
          backgroundColor: "#292D8E",
          color: "#FFFFFF",
          fontSize: "16px",
          fontWeight: "400",
          height: "50px",
        }}
        open={show}
        onCancel={() => handleClose(false)}
      >
        <Row>
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "400px",
            }}
            span={24}
          >
            {apiLoading === true ? (
              <TailSpinLoader />
            ) : (
              imageUrl !== null && (
                <img
                  style={{
                    maxWidth: "80%",
                    margin: "20px",
                    border: "1px solid #E2DFFF",
                  }}
                  src={`data:image/jpeg;base64,${imageUrl}`}
                />
              )
            )}
          </Col>
        </Row>
      </NetwalaModal>
    </>
  );
};

export default ViewReceiptModal;
