import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import useAxiosPost from "../../hooks/useAxiosPost";
import ActionQueueTable from "../Items/Table/ActionQueueTable";
import useMultiNotification from "../../hooks/useNotification";
import NetwalaTabs from "../Items/Tab/Index";
// import "./index.css";
const ViewActionQueue = () => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [searchParams] = useSearchParams();
  const _activeTab = searchParams.get("activeTab") || 1;
  const [tabs, setTabs] = useState<NetwalaTabItemsProp[]>([
    {
      text: "Pending",
      isActive: true,
      id: 1,
    },

    {
      text: "Approved",
      isActive: false,
      id: 2,
    },
    {
      text: "Rejected",
      isActive: false,
      id: 3,
    },
  ]);
  const navigate = useNavigate();
  const [queue, setQueue] = useState<OperatorActionQueue[]>([]);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("actionQueue/getPendingActionQueue");
  const [
    postAcceptedApiData,
    AcceptedApiResponse,
    AcceptedApiError,
    AcceptedApiLoading,
    AcceptedApiMessage,
    AcceptedApiErrorCount,
  ] = useAxiosPost<ResponseProp>("actionQueue/getAcceptedActionQueue");
  const [
    postRejectedApiData,
    RejectedApiResponse,
    RejectedApiError,
    RejectedApiLoading,
    RejectedApiMessage,
    RejectedApiErrorCount,
  ] = useAxiosPost<ResponseProp>("actionQueue/getRejectedActionQueue");

  const [
    postAcceptActionQueue,
    acceptActionQueueResponse,
    acceptActionQueueError,
    acceptActionQueueLoading,
    acceptActionQueueMessage,
    acceptActionQueueErrorCount,
  ] = useAxiosPost<AcceptActionQueueApiProp>("actionQueue/acceptActionQueue");

  const [
    postReOpenRejectedItem,
    reOpenRejectedItemApiResponse,
    reOpenRejectedItemApiError,
    reOpenRejectedItemApiLoading,
    reOpenRejectedItemApiMessage,
    reOpenRejectedItemApiErrorCount,
  ] = useAxiosPost<AcceptActionQueueApiProp>(
    "actionQueue/reOpenRejectedQueueItem"
  );

  const [
    postRejectActionQueue,
    rejectActionQueueResponse,
    rejectActionQueueError,
    rejectActionQueueLoading,
    rejectActionQueueMessage,
    rejectActionQueueErrorCount,
  ] = useAxiosPost<RejectActionQueueApiProp>("actionQueue/rejectActionQueue");

  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setQueue(data?.queue || []);
    }
  }, [apiResponse]);

  useEffect(() => {
    if (AcceptedApiResponse !== null) {
      const data: any = AcceptedApiResponse.responseData;
      setQueue(data?.queue || []);
    }
  }, [AcceptedApiResponse]);
  useEffect(() => {
    if (RejectedApiResponse !== null) {
      const data: any = RejectedApiResponse.responseData;
      setQueue(data?.queue || []);
    }
  }, [RejectedApiResponse]);
  // useEffect(() => {
  //   postApiData({});
  // }, []);
  const handleAcceptAction = (
    intPkOperatorActionQueueID: number,
    staticIP?: string
  ) => {
    postAcceptActionQueue({ intPkOperatorActionQueueID, staticIP });
  };
  const handleRejectionAction = (
    intPkOperatorActionQueueID: number,
    rejectionReason: string
  ) => {
    postRejectActionQueue({ intPkOperatorActionQueueID, rejectionReason });
  };
  useEffect(() => {
    if (acceptActionQueueResponse !== null) {
      const { message } = acceptActionQueueResponse;
      if (message) {
        openNotification(`success`, "Success", message);
        postApiData({});
      }
    }
  }, [acceptActionQueueResponse]);
  useEffect(() => {
    if (acceptActionQueueError !== null && acceptActionQueueErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", acceptActionQueueError);
    }
  }, [acceptActionQueueError, acceptActionQueueErrorCount]);

  useEffect(() => {
    if (rejectActionQueueResponse !== null) {
      const { message } = rejectActionQueueResponse;
      if (message) {
        openNotification(`success`, "Success", message);
        postApiData({});
      }
    }
  }, [rejectActionQueueResponse]);
  useEffect(() => {
    if (rejectActionQueueError !== null && rejectActionQueueErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", rejectActionQueueError);
    }
  }, [rejectActionQueueError, rejectActionQueueErrorCount]);
  const [activeTab, setActiveTab] = useState<number>(Number(_activeTab));
  const handleTabSwitch = (tabId: number) => {
    navigate(`/action-queue?activeTab=${tabId}`);
  };
  useEffect(() => {
    setActiveTab(Number(_activeTab));

    const updatedItems = tabs.map((item) => ({
      ...item,
      isActive: item.id === Number(_activeTab),
    }));
    setTabs(updatedItems);
  }, [activeTab, _activeTab]);
  useEffect(() => {
    callApiAccordingToTab(Number(_activeTab));
  }, [activeTab]);
  const callApiAccordingToTab = (activeTab: number) => {
    if (activeTab === 1) {
      postApiData({});
    } else if (activeTab === 2) {
      postAcceptedApiData({});
    } else if (activeTab === 3) {
      postRejectedApiData({});
    }

    console.log({ activeTab });
  };
  const handleReOpenItem = (intPkOperatorActionQueueID: number) => {
    postReOpenRejectedItem({ intPkOperatorActionQueueID });
  };
  useEffect(() => {
    if (reOpenRejectedItemApiResponse !== null) {
      const { message } = reOpenRejectedItemApiResponse;
      if (message) {
        openNotification(`success`, "Success", message);
        postRejectedApiData({});
      }
    }
  }, [reOpenRejectedItemApiResponse]);
  useEffect(() => {
    if (
      reOpenRejectedItemApiError !== null &&
      reOpenRejectedItemApiErrorCount !== 0
    ) {
      openNotification(`error`, "Operation Failed", reOpenRejectedItemApiError);
    }
  }, [reOpenRejectedItemApiError, reOpenRejectedItemApiErrorCount]);
  return (
    <>
      {contextHolder}
      <Row style={{ marginBottom: "20px" }} gutter={[16, 16]}>
        <NetwalaTabs
          colSpan={8}
          tabs={tabs}
          handleTabSwitch={handleTabSwitch}
        />
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <ActionQueueTable
            queues={queue}
            handleAcceptAction={handleAcceptAction}
            handleRejectionAction={handleRejectionAction}
            activeTab={activeTab}
            handleReOpenItem={handleReOpenItem}
          />
        </Col>
      </Row>
    </>
  );
};
export default ViewActionQueue;
