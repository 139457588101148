import React, { useContext, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import { Menu, Layout, Breadcrumb } from "antd";
import type { MenuProps, MenuTheme } from "antd/es/menu";
// import Logo from "./../../assets/pngs/logo/Logo.png";
import Logo from "./../../assets/svgs/Logo/Netwala-Logo.svg";
import "./layout.css";
import { AppRoutes } from "../../data";
import { useLocation, useNavigate } from "react-router-dom";
import TopNavBar from "./TopNav";
import PageTitle from "./CurrentPageTitle";
import TitleContext from "../../context/TitleContext";
import DashboardPage from "../../pages/DashboardPage";
const { Sider, Header, Content } = Layout;
type MenuItem = Required<MenuProps>["items"][number];

const AppLayout = () => {
  const { title } = useContext(TitleContext);
  const isLoggedIn = localStorage.getItem("auth_token") || false;
  const [items, setItems] = useState<MenuItem[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const routes = AppRoutes();
    const _items = routes
      .filter((item: any) => item.show === true)
      .map((item: any) => {
        return {
          key: item.path,
          icon: (
            <img
              src={
                location.pathname === item.path ? item.activeIcon : item.icon
              }
            />
          ),
          label: item.text,
        };
      });
    setItems(_items);
  }, [location]);

  const [routes, setRoutes] = useState<any>([]);

  useEffect(() => {
    setRoutes(AppRoutes());
  }, []);

  return (
    <>
      <Layout>
        <Header
          style={{
            display: "flex",
            alignItems: "center",
            padding: "0px",
            height: "120px",
            background: "white",
          }}
        >
          <div className="top-nav-bar-custom">
            <div className="logo ">
              <img src={Logo} alt="Logo" className="cursor-pointer" />
              {/* {activePageTitle !== null ? (
                <PageTitle title={activePageTitle} />
              ) : null} */}
            </div>
            <div className="top-nav-sub-item">
              <PageTitle title={title} />
              <TopNavBar />
            </div>
          </div>
        </Header>
        <Layout>
          <Sider
            className="side-bar-main"
            style={{ minHeight: "100vh" }}
            width={200}
          >
            <Menu
              mode="inline"
              selectedKeys={[location.pathname]}
              onClick={(e) => {
                navigate(e.key);
              }}
              defaultOpenKeys={["sub1"]}
              style={{ height: "100%", borderRight: 0, width: "inherit" }}
              defaultSelectedKeys={["1"]}
              items={items}
              className="side-bar-menu-items-container"
              // theme={theme}
            />
          </Sider>
          <Layout style={{ padding: "0 24px 24px", background: "#F3F6FF" }}>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            >
              <Routes>
                <Route path="/" element={<DashboardPage />} />
                {isLoggedIn &&
                  routes.map((route: any) => {
                    return (
                      <Route
                        path={route.path}
                        key={Math.random()}
                        element={<route.Component />}
                      />
                    );
                  })}
              </Routes>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
};

export default AppLayout;
