import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewInventoryItemDetailsComponent from "../components/inventory/ViewInventoryItemModels";

const InventoryItemDetailsPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Inventory Item Details");
  }, [setTitle]);
  return (
    <>
      <ViewInventoryItemDetailsComponent />
    </>
  );
};
export default InventoryItemDetailsPage;
