import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import CreateISPForm from "../components/isps/CreateIspForm";
// import CreateISPForm from "../components/isps/CreateIspForm";

const CreateISPPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Create ISP");
  }, [setTitle]);
  return (
    <>
      <CreateISPForm />
    </>
  );
};
export default CreateISPPage;
