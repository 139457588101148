import { useEffect, useState } from "react";
import { Row, Col, Typography, Form, Input, Card, Select, Button } from "antd";
import { useNavigate } from "react-router-dom";
import useMultiNotification from "../../../hooks/useNotification";
import useAxiosPost from "../../../hooks/useAxiosPost";
import NetwalaButton from "../../Items/Button/Index";
import { ButtonProps } from "../../../helpers/constants";
import InventoryItemsTable from "../../Items/Table/InventoryItemsTable";
import AddOrUpdateInventoryItemsModal from "../../Modals/AddOrUpdateInventoryItemsModal";

const ViewInventoryItemsComponent = () => {
  const navigate = useNavigate();

  const { openNotification, contextHolder } = useMultiNotification();
  const [show, setShow] = useState<boolean>(false);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("superAdmin/getInventoryItems");
  const [items, setItems] = useState<InventoryItemProp[]>([]);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setItems(data || []);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({});
  }, []);
  const handleClose = (isReload: boolean) => {
    setShow(false);
    if (isReload === true) {
      postApiData({});
    }
  };
  const reloadApi = () => {
    postApiData({});
  };
  return (
    <>
      {contextHolder}
      {show === true && (
        <AddOrUpdateInventoryItemsModal
          item={null}
          show={show}
          handleClose={handleClose}
          url={`addNewInventoryItem`}
        />
      )}
      <Row style={{ marginBottom: "20px" }} gutter={[16, 16]}>
        <Col offset={18} span={6}>
          <NetwalaButton
            backgroundColor={ButtonProps.background.STANDARD}
            height={ButtonProps.height.STANDARD}
            width={ButtonProps.width.STANDARD}
            textColor={ButtonProps.color.STANDARD}
            onClick={() => {
              setShow(true);
            }}
            text="Create New Item"
          />
        </Col>
      </Row>
      <Row style={{ width: "100%" }} gutter={30}>
        <Col span={24}>
          <InventoryItemsTable
            reloadApi={reloadApi}
            items={items}
            isLoading={apiLoading}
          />
        </Col>
      </Row>
    </>
  );
};
export default ViewInventoryItemsComponent;
