import { useEffect, useState } from "react";
import { Col, Row, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import useAxiosPost from "../../hooks/useAxiosPost";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import CollectDealerPaymentModal from "../Modals/CollectDealerPaymentModal";
import DealerSingleTransactionCard from "../SingleUser/components/cards/DealerTransactionCard";

const ViewDealerProfile = () => {
  const [getStaffProfile, staffProfileApiResponse] =
    useAxiosPost<ResponseProp>("auth/getMyProfile");
  const navigate = useNavigate();
  const { intPkHeadID } = useParams();
  const [showCollectionModal, setShowCollectionModal] =
    useState<boolean>(false);
  const [staff, setStaffProfile] = useState<StaffListType | null>(null);

  const [dealer, setDealerProfile] = useState<CompanyDealer | null>(null);
  const [transactions, setTransactions] = useState<DealerTransaction[]>([]);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<GetStaffPropApiProp>("users/getCompanyDealerProfile");

  const [
    getStaffTransactionsApi,
    transactionsApiResponse,
    transactionApiError,
    transactionsApiLoading,
  ] = useAxiosPost<GetStaffPropApiProp>("invoices/getMyDealerTransactions");
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setDealerProfile(data);
    }
  }, [apiResponse]);
  useEffect(() => {
    if (transactionsApiResponse !== null) {
      const data: any = transactionsApiResponse.responseData;
      setTransactions(data || []);
    }
  }, [transactionsApiResponse]);
  useEffect(() => {
    postApiData({ intPkHeadID: Number(intPkHeadID) });
    getStaffTransactionsApi({ intPkHeadID: Number(intPkHeadID) });
    getStaffProfile({});
  }, []);
  useEffect(() => {
    if (staffProfileApiResponse !== null) {
      const data: any = staffProfileApiResponse.responseData;
      setStaffProfile(data);
    }
  }, [staffProfileApiResponse]);

  const apiReload = () => {
    postApiData({ intPkHeadID: Number(intPkHeadID) });
    getStaffTransactionsApi({ intPkHeadID: Number(intPkHeadID) });
  };

  const handleClose = (isReload: boolean) => {
    if (isReload === true) {
      postApiData({ intPkHeadID: Number(intPkHeadID) });
      getStaffTransactionsApi({ intPkHeadID: Number(intPkHeadID) });
    }
    setShowCollectionModal(false);
  };
  return (
    <>
      {dealer && (
        <>
          {showCollectionModal === true && (
            <CollectDealerPaymentModal
              intPkHeadID={dealer.intPkHeadID}
              show={showCollectionModal}
              handleClose={handleClose}
            />
          )}
          <Row style={{ display: "flex", alignItems: "center" }} gutter={16}>
            <Col span={18}>
              <div className="basic-staff-header-profile-container">
                <div className="">
                  <Typography className="staff-full-name">
                    {dealer.firstName} {dealer.lastName}
                  </Typography>
                  <Typography className="staff-phone-number">
                    {dealer.phoneNumber}
                  </Typography>
                </div>
              </div>
            </Col>

            <Col span={6}>
              <NetwalaButton
                backgroundColor={ButtonProps.background.STANDARD}
                height={ButtonProps.height.STANDARD}
                width={ButtonProps.width.STANDARD}
                textColor={ButtonProps.color.STANDARD}
                onClick={() => {
                  setShowCollectionModal(true);
                }}
                text="Recharge Dealer"
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="end-user-profile-container">
            {transactions.map((item) => {
              return (
                <Col key={Math.random()} span={24}>
                  <DealerSingleTransactionCard
                    key={Math.random()}
                    transaction={item}
                    staffHeadId={staff?.intPkHeadID || -1}
                    reloadApi={apiReload}
                  />
                </Col>
              );
            })}
          </Row>
        </>
      )}
    </>
  );
};
export default ViewDealerProfile;
