import React, { useState } from "react";
import { Row, Typography } from "antd";
import type { MenuProps } from "antd";
import "./../../SingleUser/components/cards/profile.css";
import PackageIcon from "./../../../assets/svgs/ProfileIcons/Package.svg";
import IPTVIcon from "./../../../assets/svgs/IPTVIconBlue.svg";
import WifiIcon from "./../../../assets/svgs/WifiIcon.svg";
import StaticIPIcon from "./../../../assets/svgs/StaticIPIconBlue.svg";
import RechargeIcon from "./../../../assets/svgs/RechargeIcon.svg";
import CATVIcon from "./../../../assets/svgs/CATVIcon.svg";
import DiscountIcon from "./../../../assets/svgs/DiscountIcon.svg";
import EditIconBlue from "./../../../assets/svgs/EditIconBlue.svg";

import SingleDetailsRowWithLabelAndValue from "./SingleDetailsRowWithLabelAndValue";
import UpdateConnectionStaticIPModal from "../../Modals/UpdateConnectionStaticIPModal";

const ConnectionProfileDetailsRightCard: React.FC<
  ConnectionDetailsCardProp
> = ({ singleConnection, isReload }) => {
  const [show, setShow] = useState<boolean>(false);
  const calculatePriceOfPackage = () => {
    const { sale_price, iptvDetails, staticIpDetails } = singleConnection;
    if (singleConnection.connectionType === "CATV") {
      return sale_price;
    }
    let iSalePrice = sale_price;
    if (iptvDetails) {
      iSalePrice = iSalePrice + iptvDetails.iptvSalePrice;
    }
    if (staticIpDetails) {
      iSalePrice = iSalePrice + staticIpDetails.staticIpSalePrice;
    }
    return iSalePrice;
  };
  const calculateDiscount = () => {
    const { fixedDiscountAmount, onceDiscountAmount, onceDiscountExpiry } =
      singleConnection;
    let discount = 0;
    if (fixedDiscountAmount) {
      discount = fixedDiscountAmount;
    }
    if (
      onceDiscountAmount &&
      onceDiscountExpiry &&
      new Date(onceDiscountExpiry) > new Date()
    ) {
      discount = onceDiscountAmount;
    }
    return discount;
  };
  const calculatePriceOfPackageWithDiscount = () => {
    const { sale_price, iptvDetails, staticIpDetails } = singleConnection;
    const discount = calculateDiscount();
    if (singleConnection.connectionType === "CATV") {
      return sale_price - discount;
    }
    let iSalePrice = sale_price;
    if (iptvDetails) {
      iSalePrice = iSalePrice + iptvDetails.iptvSalePrice;
    }
    if (staticIpDetails) {
      iSalePrice = iSalePrice + staticIpDetails.staticIpSalePrice;
    }
    return iSalePrice - discount;
  };
  const handleClose = (isApiReload: boolean) => {
    setShow(false);
    if (isApiReload === true) {
      isReload(true);
    }
  };
  return (
    <>
      {show === true && singleConnection.staticIpDetails && (
        <UpdateConnectionStaticIPModal
          show={show}
          handleClose={handleClose}
          intPkConnectionID={singleConnection.intPkConnectionID}
          staticIP={singleConnection.staticIpDetails.static_ip}
        />
      )}
      <Row className="user-profile-details-card-container" gutter={16}>
        {singleConnection.connectionType === "INTERNET" ? (
          <SingleDetailsRowWithLabelAndValue
            value={`${singleConnection.sale_price}/-`}
            label={
              singleConnection.connectionType === "INTERNET"
                ? "Internet Price"
                : "CATV Price"
            }
            icon={
              singleConnection.connectionType === "INTERNET"
                ? WifiIcon
                : CATVIcon
            }
          />
        ) : null}
        {singleConnection.connectionType === "INTERNET" &&
          singleConnection.iptvDetails && (
            <SingleDetailsRowWithLabelAndValue
              value={`${singleConnection.iptvDetails.iptvSalePrice}`}
              label="IPTV Price"
              icon={IPTVIcon}
            />
          )}

        {singleConnection.connectionType === "INTERNET" &&
          singleConnection.staticIpDetails && (
            <SingleDetailsRowWithLabelAndValue
              value={`${singleConnection.staticIpDetails.staticIpSalePrice}`}
              label="Static IP Price"
              icon={StaticIPIcon}
            />
          )}

        {singleConnection.connectionType === "INTERNET" &&
          singleConnection.staticIpDetails && (
            <SingleDetailsRowWithLabelAndValue
              value={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                  className={`${
                    Number(singleConnection.staticIpDetails.static_ip) === -1
                      ? "blink-label"
                      : ""
                  }`}
                >
                  {" "}
                  {Number(singleConnection.staticIpDetails.static_ip) !== -1
                    ? `${singleConnection.staticIpDetails.static_ip}`
                    : "NO IP"}
                  <img
                    onClick={(e) => {
                      setShow(true);
                    }}
                    className="cursor-pointer"
                    src={EditIconBlue}
                  />
                </div>
              }
              label="Static IP"
              icon={StaticIPIcon}
              isReactNode={true}
            />
          )}

        <SingleDetailsRowWithLabelAndValue
          value={`${singleConnection.rechargePrice}`}
          label="Total Package Price"
          icon={PackageIcon}
        />
        <SingleDetailsRowWithLabelAndValue
          label={`Abatement (Discount)`}
          value={
            singleConnection.finalDiscount > 0 ? (
              <span className="out-amount-label">
                {" "}
                -{singleConnection.finalDiscount}
              </span>
            ) : (
              "None"
            )
          }
          icon={DiscountIcon}
          isReactNode={singleConnection.finalDiscount ? true : false}
        />
        <SingleDetailsRowWithLabelAndValue
          label={`ReceiveAble Amount`}
          value={`${singleConnection.receiveAbleAmount}/-`}
          icon={PackageIcon}
          isBorderBottom={false}
        />
      </Row>
    </>
  );
};
export default ConnectionProfileDetailsRightCard;
