import React, { useEffect, useState } from "react";
import { Col, Row, Typography } from "antd";
import "./index.css";
import useAxiosPost from "../../../hooks/useAxiosPost";
import CategoryIcon from "./../../../assets/svgs/inventoryItemsIcon/Category.svg";
import BrandIcon from "./../../../assets/svgs/inventoryItemsIcon/Brand.svg";
import ModelIcon from "./../../../assets/svgs/inventoryItemsIcon/Model.svg";
import PriceIcon from "./../../../assets/svgs/inventoryItemsIcon/Price.svg";
import SingleDetailsRowWithLabelAndValue from "../../connectionDetails/cards/SingleDetailsRowWithLabelAndValue";
import NetwalaButton from "../../Items/Button/Index";
import { ButtonProps } from "../../../helpers/constants";
import UpdateInventoryPriceModal from "../../Modals/UpdateInventoryPriceModal";
import { getUserType } from "../../../helpers/userDetails";
const SingleModalStockProfile: React.FC<ModalStockItemTabComponent> = ({
  intPkInventoryModelID,
}) => {
  const [stockProfile, setStockProfile] = useState<InventorySocketItem | null>(
    null
  );
  const [show, setShow] = useState<boolean>(false);
  const handleClose = (isReload: boolean) => {
    if (isReload) {
      postApiData({ intPkInventoryModelID: intPkInventoryModelID });
    }
    setShow(false);
  };
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<InventoryModalProfileApiProp>(
    "inventory/getInventoryModelProfileByModalId"
  );

  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setStockProfile(data);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({ intPkInventoryModelID: intPkInventoryModelID });
  }, []);

  return (
    <>
      {show === true &&
        stockProfile &&
        stockProfile.intPkInventoryStockID !== -1 && (
          <UpdateInventoryPriceModal
            show={show}
            handleClose={handleClose}
            intPkInventoryStockID={stockProfile.intPkInventoryStockID}
          />
        )}
      <Row className="stock-item-profile-items-container" gutter={[16, 16]}>
        {stockProfile && (
          <>
            <Col className="stock-item-brand-image-container" span={12}>
              <img
                className="stock-item-brand-image"
                src={stockProfile.brandImageUrl}
              />
            </Col>
            <Col span={12}>
              <div className="stock-item-profile-details-card-container">
                <SingleDetailsRowWithLabelAndValue
                  value={`${stockProfile.brandName}`}
                  label={`Brand`}
                  icon={BrandIcon}
                />
                <SingleDetailsRowWithLabelAndValue
                  value={`${stockProfile.inventoryModelName}`}
                  label={`Model`}
                  icon={ModelIcon}
                />
                <SingleDetailsRowWithLabelAndValue
                  value={`${stockProfile.inventoryItemVariant}`}
                  label={`Category`}
                  icon={CategoryIcon}
                />
                <SingleDetailsRowWithLabelAndValue
                  value={`${stockProfile.unitSalePrice}`}
                  label={`Price`}
                  icon={PriceIcon}
                  isBorderBottom={false}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "20px",
                }}
              >
                {getUserType() === "CEO" && (
                  <NetwalaButton
                    backgroundColor={ButtonProps.background.STANDARD}
                    height={ButtonProps.height.STANDARD}
                    width={ButtonProps.width.STANDARD}
                    textColor={ButtonProps.color.STANDARD}
                    onClick={() => {
                      if (stockProfile.intPkInventoryStockID === -1) {
                        return;
                      }
                      setShow(true);
                    }}
                    disabled={
                      stockProfile.intPkInventoryStockID === -1 ? true : false
                    }
                    text="Update Price"
                  />
                )}
              </div>
            </Col>
          </>
        )}
      </Row>
    </>
  );
};
export default SingleModalStockProfile;
