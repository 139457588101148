import React, { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ConnectionProfileComponent from "../components/connectionDetails/ConnectionProfile";
const UserConnectionPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Connection Details");
  }, [setTitle]);

  return (
    <>
      <ConnectionProfileComponent />
    </>
  );
};
export default UserConnectionPage;
