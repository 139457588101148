import { useEffect, useRef, useState } from "react";
import { Col, Row, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosPost from "../../hooks/useAxiosPost";
import "./index.css";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import { formatDate } from "../../helpers/date";
import SaveIconBlue from "./../../assets/svgs/SaveIconBlue.svg";
import html2canvas from "html2canvas";
const SingleReceiptCard = () => {
  const downloadImage = async (containerRef: any) => {
    const element = containerRef.current;
    if (!element) return;
    if (!receipt) {
      return;
    }
    const canvas = await html2canvas(element);
    const imageData = canvas.toDataURL("image/png");

    const link = document.createElement("a");
    link.href = imageData;
    link.download = `receipt-${
      receipt.receiptDetails.intPkConnectionInvoiceID
    }-${Date.now()}.png`; // Adjust filename as needed
    link.click();
  };
  const containerRef = useRef<any>(null);

  const handleDownload = () => {
    downloadImage(containerRef);
  };
  const navigate = useNavigate();
  const { intPkConnectionInvoiceID, intPkConnectionID } = useParams();
  const [receipt, setReceipt] = useState<SingleReceiptApiResponseProp | null>(
    null
  );
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<SingleReceiptApiProp>(
    "invoices/receipts/getSingleReceiptDetails"
  );
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setReceipt(data);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({
      intPkConnectionInvoiceID: Number(intPkConnectionInvoiceID),
      intPkConnectionID: Number(intPkConnectionID),
    });
  }, []);
  return (
    <>
      <Row gutter={[16, 16]}>
        {apiLoading === true ? (
          <TailSpinLoader />
        ) : (
          receipt && (
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                gap: "20px",
                alignItems: "center",
              }}
              span={24}
            >
              <div ref={containerRef} className="receipt-card-container">
                <div className="receipt-invoice-details">
                  <Typography className="receipt-title-label">
                    {receipt.inventoryStockDetails !== null
                      ? "Inventory Invoice"
                      : "Package Invoice"}
                  </Typography>
                  <Typography className="receipt-invoice-value">
                    Invoice # {receipt.receiptDetails.intPkConnectionInvoiceID}
                  </Typography>
                  <Typography className="invoice-date-value">
                    {formatDate(
                      new Date(receipt.receiptDetails.invoiceCreatedAt)
                    )}
                  </Typography>
                </div>

                <div className="receipt-user-details-container">
                  <div className="receipt-user-details-left">
                    <Typography className="user-fullname-label">
                      {`${receipt.connectionDetails.firstName} ${receipt.connectionDetails.lastName}`}
                    </Typography>
                    <Typography className="user-phone-label">
                      {receipt.connectionDetails.phoneNumber}
                    </Typography>
                    <Typography className="user-address-label">
                      {receipt.connectionDetails.address +
                        " " +
                        receipt.connectionDetails.areaName}
                    </Typography>
                  </div>
                  <div className="receipt-user-details-right">
                    <img
                      src={receipt.connectionDetails.url}
                      className="receipt-isp-image"
                    />
                    <Typography className="user-phone-label">
                      {receipt.connectionDetails.ISPName}
                    </Typography>
                  </div>
                </div>
                <div className="receipt-connection-details-container">
                  <Typography className="connection-details-label">
                    User Detail
                  </Typography>
                  <div className="connection-username-details">
                    <Typography className="connection-username-label">
                      UserName
                    </Typography>
                    <Typography className="connection-username-value">
                      {receipt.connectionDetails.username
                        ? receipt.connectionDetails.username
                        : "-"}
                    </Typography>
                  </div>
                </div>
                {receipt.inventoryStockDetails !== null ? (
                  <>
                    <div className="receipt-connection-details-container">
                      <Typography className="connection-details-label">
                        Item Details
                      </Typography>
                      <div className="connection-username-details">
                        <Typography className="connection-username-label">
                          Item Name
                        </Typography>
                        <Typography className="connection-username-value">
                          {receipt.inventoryStockDetails.inventoryItemType}
                        </Typography>
                      </div>
                      <div className="connection-username-details">
                        <Typography className="connection-username-label">
                          Quantity
                        </Typography>
                        <Typography className="connection-username-value">
                          {receipt.receiptDetails.inventoryUnitsSold}
                        </Typography>
                      </div>

                      <div className="connection-username-details">
                        <Typography className="connection-username-label">
                          Item Type
                        </Typography>
                        <Typography className="connection-username-value">
                          {receipt.inventoryStockDetails.inventoryItemVariant}
                        </Typography>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="receipt-connection-details-container">
                    <Typography className="connection-details-label">
                      Package Detail
                    </Typography>
                    <div className="connection-username-details">
                      <Typography className="connection-username-label">
                        Package
                      </Typography>
                      <Typography className="connection-username-value">
                        {receipt.connectionDetails.packageName
                          ? receipt.connectionDetails.packageName
                          : "CATV"}
                      </Typography>
                    </div>
                  </div>
                )}

                <div className="receipt-connection-details-container">
                  <Typography className="connection-details-label">
                    Payment Details
                  </Typography>

                  <div
                    key={Math.random()}
                    className="connection-username-details"
                  >
                    <Typography className="connection-username-label">
                      {receipt.inventoryStockDetails
                        ? "Inventory Price"
                        : "Package Price"}
                    </Typography>
                    <Typography className="connection-username-value">
                      {receipt.inventoryStockDetails
                        ? Number(receipt.receiptDetails.inventoryPerUnitPrice) *
                          Number(receipt.receiptDetails.inventoryUnitsSold)
                        : receipt.receiptDetails.packagePrice}
                    </Typography>
                  </div>
                  {receipt.receiptDetails.staticIPPrice && (
                    <div
                      key={Math.random()}
                      className="connection-username-details"
                    >
                      <Typography className="connection-username-label">
                        Static IP Price
                      </Typography>
                      <Typography className="connection-username-value">
                        {receipt.receiptDetails.staticIPPrice}
                      </Typography>
                    </div>
                  )}
                  {receipt.receiptDetails.IPTVPrice && (
                    <div
                      key={Math.random()}
                      className="connection-username-details"
                    >
                      <Typography className="connection-username-label">
                        IPTV Price
                      </Typography>
                      <Typography className="connection-username-value">
                        {receipt.receiptDetails.IPTVPrice}
                      </Typography>
                    </div>
                  )}
                  <div
                    key={Math.random()}
                    className="connection-username-details"
                  >
                    <Typography className="connection-username-label">
                      Discount
                    </Typography>
                    <Typography className="discount-amount-receipt">
                      -{receipt.receiptDetails.invoiceDiscountAmount}
                    </Typography>
                  </div>

                  <div
                    key={Math.random()}
                    className="connection-username-details"
                  >
                    <Typography className="connection-username-label">
                      Total Amount
                    </Typography>
                    <Typography className="connection-username-value">
                      {receipt.receiptDetails.invoiceTotalAmount}
                    </Typography>
                  </div>
                  <div className="receipt-divider"></div>
                  {receipt.receiptDetails.invoicePaidAmount && (
                    <div
                      key={Math.random()}
                      className="connection-username-details"
                    >
                      <Typography className="connection-username-label">
                        Paid Amount
                      </Typography>
                      <Typography className="connection-username-value">
                        {receipt.receiptDetails.invoicePaidAmount}
                      </Typography>
                    </div>
                  )}
                </div>
                {receipt.receiptDetails.invoiceRemainingAmount > 0 && (
                  <div
                    key={Math.random()}
                    className="connection-username-details"
                  >
                    <Typography className="connection-username-label">
                      Balance
                    </Typography>
                    <Typography className="connection-username-value">
                      {receipt.receiptDetails.invoiceRemainingAmount}
                    </Typography>
                  </div>
                )}
                <div style={{ height: "30px" }}></div>
              </div>

              <div
                onClick={handleDownload}
                className="cursor-pointer save-button-container"
              >
                <img src={SaveIconBlue} />
              </div>
            </Col>
          )
        )}
      </Row>
    </>
  );
};
export default SingleReceiptCard;
