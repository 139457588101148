import React, { useEffect, useState } from "react";
import { Row } from "antd";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useMultiNotification from "../../hooks/useNotification";
import "./index.css";
import NetwalaTabs from "../Items/Tab/Index";
import SingleModalStockProfile from "./model-stock-item/ModelProfile";
import SingleModalStockDataComponent from "./model-stock-item/ModalStock";
import ModelStockActivityLogsComponent from "./model-stock-item/ModelStockActivityLogs";

const ViewInventoryModelStockDetailsComponent = () => {
  const [searchParams] = useSearchParams();
  const _activeTab = searchParams.get("activeTab");
  const [tabs, setTabs] = useState<NetwalaTabItemsProp[]>([
    {
      text: "Profile",
      isActive: true,
      id: 1,
    },

    {
      text: "Stock",
      isActive: false,
      id: 2,
    },
    {
      text: "Activity",
      isActive: false,
      id: 3,
    },
  ]);
  const { openNotification, contextHolder } = useMultiNotification();
  const navigate = useNavigate();
  const { intPkInventoryModelID } = useParams();
  const [activeTab, setActiveTab] = useState<number>(Number(_activeTab));
  const handleTabSwitch = (tabId: number) => {
    navigate(
      `/inventory-stock-details/${intPkInventoryModelID}?activeTab=${tabId}`
    );
  };
  useEffect(() => {
    setActiveTab(Number(_activeTab));
    const updatedItems = tabs.map((item) => ({
      ...item,
      isActive: item.id === Number(_activeTab),
    }));
    setTabs(updatedItems);
  }, [activeTab, _activeTab]);

  const showActiveComponent = (activeTab: number) => {
    switch (activeTab) {
      case 1:
        return (
          <SingleModalStockProfile
            intPkInventoryModelID={Number(intPkInventoryModelID)}
          />
        );
      case 2:
        return (
          <SingleModalStockDataComponent
            intPkInventoryModelID={Number(intPkInventoryModelID)}
          />
        );
      case 3:
        return (
          <ModelStockActivityLogsComponent
            intPkInventoryModelID={Number(intPkInventoryModelID)}
          />
        );
    }
    return <></>;
  };
  return (
    <>
      {contextHolder}

      <Row gutter={[16, 16]}>
        <NetwalaTabs
          colSpan={8}
          tabs={tabs}
          handleTabSwitch={handleTabSwitch}
        />
      </Row>
      <Row style={{ marginTop: "20px" }}>{showActiveComponent(activeTab)}</Row>
    </>
  );
};
export default ViewInventoryModelStockDetailsComponent;
