import { Col, Form, Input, Row, Upload, Button, message } from "antd";
import NetwalaSelect from "../Items/Select/Index";
import { useEffect, useState } from "react";
import { CameraOutlined, UploadOutlined } from "@ant-design/icons";
const { TextArea } = Input;
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import PlusIcon from "./../../assets/svgs/IconsWhite/PlusIcon.svg";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
} from "antd/lib/upload/interface";
import useAxiosPost, {
  authTokenString,
  baseURL,
} from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import { removeSpacesFromString } from "../../helpers/strings";

const CreateStaffForm = () => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const onFinish = (values: any) => {
    handleCreateStaff(values);
  };
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileListBack, setFileListBack] = useState<UploadFile[]>([]);
  const [role, setRole] = useState("");
  const [profilePictureList, setProfilePictureList] = useState<UploadFile[]>(
    []
  );

  const handleRemoveProfile = (file: UploadFile) => {
    setProfilePictureList((prevList) =>
      prevList.filter((item) => item.uid !== file.uid)
    );
  };

  const handleBeforeUploadProfile = (file: RcFile, fileList: RcFile[]) => {
    setProfilePictureList(
      fileList.map((file) => ({
        uid: file.uid,
        name: file.name,
        status: "done",
      }))
    );
    // You can add your upload logic here if needed
    return false; // Return false here to avoid auto uploading
  };

  const handleChangeProfile = (info: UploadChangeParam) => {
    setProfilePictureList(info.fileList);
  };

  const handleRemove = (file: UploadFile) => {
    setFileList((prevList) => prevList.filter((item) => item.uid !== file.uid));
  };

  const handleBeforeUpload = (file: RcFile, fileList: RcFile[]) => {
    setFileList(
      fileList.map((file) => ({
        uid: file.uid,
        name: file.name,
        status: "done",
      }))
    );
    // You can add your upload logic here if needed
    return false; // Return false here to avoid auto uploading
  };

  const handleChange = (info: UploadChangeParam) => {
    setFileList(info.fileList);
  };
  const handleRemoveBack = (file: UploadFile) => {
    setFileListBack((prevList) =>
      prevList.filter((item) => item.uid !== file.uid)
    );
  };

  const handleBeforeUploadBack = (file: RcFile, fileList: RcFile[]) => {
    setFileListBack(
      fileList.map((file) => ({
        uid: file.uid,
        name: file.name,
        status: "done",
      }))
    );
    // You can add your upload logic here if needed
    return false; // Return false here to avoid auto uploading
  };

  const handleChangeBack = (info: UploadChangeParam) => {
    setFileListBack(info.fileList);
  };
  const onFinishFailed = (errorInfo: any) => {};

  const handleCreateStaff = async (values: any) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("authTokenString", authTokenString.toString());
    formData.append("address", values.address);
    formData.append("designation", values.designation);
    formData.append("firstName", values.firstName);
    formData.append("lastName", values.lastName);
    formData.append("nicNumber", "rightNowStatic");
    formData.append("password", values.password);
    formData.append("phoneNumber", values.phoneNumber);
    formData.append("walletDays", values.walletDays || 1);
    formData.append("walletLimit", values.walletLimit || 1);
    formData.append(
      "staffUserName",
      removeSpacesFromString(values.staffUserName)
    );

    if (fileList.length > 0) {
      formData.append("idCardFront", fileList[0].originFileObj as Blob);
    }
    if (fileListBack.length > 0) {
      formData.append("idCardBack", fileListBack[0].originFileObj as Blob);
    }
    if (profilePictureList.length > 0) {
      formData.append(
        "profilePicture",
        profilePictureList[0].originFileObj as Blob
      );
    }
    try {
      const response = await fetch(`${baseURL}/auth/SignUpStaff`, {
        method: "POST",
        body: formData,
        // Note: When using FormData, do NOT set the Content-Type header
        // The browser will set it automatically, including the boundary parameter
      });

      if (response.ok) {
        const responseData = await response.json();
        openNotification(
          "success",
          "Success",
          responseData?.message || "Staff Created Successfully"
        );
        setTimeout(() => {
          navigate(`/staff`);
        }, 3000);
      } else {
        const responseData = await response.json();
        setIsLoading(false);

        openNotification(
          "error",
          "Operation Failed",
          responseData?.message || "Server Error"
        );
        console.error("Upload failed:", responseData);
      }
    } catch (error) {
      setIsLoading(false);

      console.error("Error during fetch:", error);
    }
  };
  return (
    <>
      {contextHolder}

      <Row style={{ width: "100%" }}>
        <Form
          name="createStaffForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          requiredMark={false}
          style={{ width: "100%" }}
          form={form}
        >
          {/* First */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">First Name</span>
                }
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please enter First Name",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="John"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Last Name</span>
                }
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please enter Last Name",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="Deo"
                />
              </Form.Item>
            </Col>
          </Row>
          {/* Second */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Phone Number</span>
                }
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter Phone Number",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="03001234567"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">UserName</span>}
                name="staffUserName"
                rules={[
                  {
                    required: true,
                    message: "Please enter Staff UserName",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="johndoe1"
                />
              </Form.Item>
            </Col>
          </Row>
          {/* Third */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">Password</span>}
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter Staff Password",
                  },
                  {
                    min: 6,
                    message: "Minimum 6 Characters Are required for password",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="password"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">Address</span>}
                name="address"
                rules={[
                  {
                    required: true,
                    message: "Please enter Address",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="House No."
                />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">CNIC Number</span>
                }
                name="nicNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter CNIC Number",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="03001234567"
                />
              </Form.Item>
            </Col> */}
          </Row>
          {/* Fourth */}

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Designation</span>
                }
                name="designation"
                rules={[
                  {
                    required: true,
                    message: "Please enter First Name",
                  },
                ]}
              >
                <NetwalaSelect
                  size="large"
                  height="45px"
                  onChange={(e) => {
                    setRole(e);
                  }}
                  options={[
                    // { value: "LINEMAN", label: "LINEMAN" },
                    { value: "CASHIER", label: "CASHIER" },
                    { value: "RMAN", label: "RMAN" },
                    // { value: "SALESMAN", label: "SALESMAN" },
                    { value: "PANEL_MANAGER", label: "PANEL_MANAGER" },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">User Profile</span>
                }
                name="userProfile"
              >
                <Upload
                  onRemove={handleRemoveProfile}
                  beforeUpload={handleBeforeUploadProfile}
                  onChange={handleChangeProfile}
                  fileList={profilePictureList}
                >
                  <Button icon={<CameraOutlined />}>Upload Pic</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          {/* Fifth */}
          {(role === "RMAN" || role === "CASHIER") && (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={
                    <span className="create-user-form-label">Wallet Limit</span>
                  }
                  name="walletLimit"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Wallet Limit",
                    },
                    {
                      validator: (_, value) =>
                        value > 0
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error("Wallet Limit Must be greater than 0")
                            ),
                    },
                  ]}
                >
                  <Input
                    type="number"
                    className="user-input-general-height"
                    placeholder="50000"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <span className="create-user-form-label">Wallet Days</span>
                  }
                  name="walletDays"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Wallet Days",
                    },
                    {
                      validator: (_, value) =>
                        value > 0
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error("Wallet Days Must be greater than 0")
                            ),
                    },
                  ]}
                >
                  <Input
                    type="number"
                    className="user-input-general-height"
                    placeholder="7"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {/* Final */}
          {/* <Row>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">
                    ID Card (Front)
                  </span>
                }
                name="idCardFront"
              >
                <Upload
                  onRemove={handleRemove}
                  beforeUpload={handleBeforeUpload}
                  onChange={handleChange}
                  fileList={fileList}
                >
                  <Button icon={<CameraOutlined />}>Upload Pic</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">ID Card (Back)</span>
                }
                name="idBack"
              >
                <Upload
                  onRemove={handleRemoveBack}
                  beforeUpload={handleBeforeUploadBack}
                  onChange={handleChangeBack}
                  fileList={fileListBack}
                >
                  <Button icon={<CameraOutlined />}>Upload Pic</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row> */}
          {/* Create Button Row */}
          <Row>
            <Col
              style={{ display: "flex", justifyContent: "center" }}
              span={24}
            >
              <Form.Item>
                <NetwalaButton
                  htmlType="submit"
                  backgroundColor={ButtonProps.background.STANDARD}
                  height={ButtonProps.height.STANDARD}
                  width={ButtonProps.width.STANDARD}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {
                    console.log("clicked");
                  }}
                  text="Create Staff"
                  icon={<img src={PlusIcon} />}
                  disabled={isLoading}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Row>
    </>
  );
};
export default CreateStaffForm;
