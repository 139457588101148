import React from "react";
import ReactApexChart from "react-apexcharts";
interface RadialBarChartProps {
  labels: string[];
  series: any[];
  colors: string[];
  // totalUsers: number;
  // activeUsers: number;
  // expiredUsers: number;
}

const RadialBarChart: React.FC<RadialBarChartProps> = ({
  colors,
  labels,
  series,
}) => {
  const options = {
    chart: {
      type: "radialBar" as "radialBar",
      height: 350,
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: "30%",
          background: "transparent",
        },
        track: {
          background: "#D8D8D8", // Background color for remaining portion after total users
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: 0,
            fontSize: "22px",
            color: "#fff",
            formatter: function (val: any) {
              return val + "%";
            },
          },
          //   total: {
          //     show: true,
          //     label: "Total",
          //     formatter: function () {
          //       return totalUsers.toString(); // Convert totalUsers to string
          //     },
          //   },
        },
      },
    },
    labels: labels, //["Total Users", "Active Users", "Expired Users"],
    colors: colors, //["#6856DB", "#27D538", "#C71414"],
    legend: {
      show: true,
      floating: true,
      fontSize: "16px",
      position: "left" as "left",
      offsetX: 160,
      offsetY: 15,
      labels: {
        useSeriesColors: true,
      },
      formatter: function (seriesName: any, opts: any) {
        return seriesName;
      },
      itemMargin: {
        vertical: 3,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  // const series = [totalUsers, activeUsers, expiredUsers];

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="radialBar"
        height={350}
      />
    </div>
  );
};

export default RadialBarChart;
