import React, { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ActionQueueSingleComponent from "../components/actionQueue/ActionQueueSingleComponent";
const ActionQueueModernPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Action Queue");
  }, [setTitle]);

  return (
    <>
      <ActionQueueSingleComponent />
    </>
  );
};
export default ActionQueueModernPage;
