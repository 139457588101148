import React, { useState } from "react";
import { Row, Col, Typography, Dropdown } from "antd";
import "./css/invoiceCard.css";
import ViewSoaIcon from "./../../../assets/svgs/ViewSoaIcon.svg";
import ViewReceiptIconBlue from "./../../../assets/svgs/ViewReceiptIconBlue.svg";
import DepositRequestIconBlue from "./../../../assets/svgs/MenuIcons/DepositRequestIconBlue.svg";
import ThreeDotsVertical from "./../../../assets/svgs/ThreeDotsVertical.svg";
import { useNavigate } from "react-router-dom";
import type { MenuProps } from "antd";
import { DeleteOutlined, DollarOutlined } from "@ant-design/icons";
import { getUserType } from "../../../helpers/userDetails";
import UpdateOrCreatePromiseModal from "../../Modals/UpdateOrCreatePromiseModal";
import { formatDate } from "../../../helpers/date";
import CollectOpenInvoicePaymentModal from "../../Modals/CollectOpenInvoicePaymentModal";
import RevertInvoiceModal from "../../Modals/RevertInvoiceModal";

interface TransactionRowItemProp {
  label: string;
  value: string;
  valueClass: string;
  extraContainerClass?: string;
}
const DetailsItem: React.FC<TransactionRowItemProp> = ({
  label,
  value,
  valueClass,
  extraContainerClass = "",
}) => {
  return (
    <div className={`invoice-single-item-col ${extraContainerClass}`}>
      <Typography className="item-label">{label}</Typography>
      <Typography className={`${valueClass}`}>{value}</Typography>
    </div>
  );
};
const SingleConnectionInvoiceCard: React.FC<
  ConnectionSingleInvoiceCardProp
> = ({ invoice, reloadApi }) => {
  const [show, setShow] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const navigate = useNavigate();
  const items: MenuProps["items"] = [];
  if (invoice.invoiceStatus === "OPEN") {
    items.push({
      key: "COLLECT_PAYMENT",
      label: (
        <Typography className="profile-drop-down-item">
          <DollarOutlined /> Collect Payment
        </Typography>
      ),
    });
  }
  if (invoice.isInvoiceReverted === 0) {
    items.push({
      key: "REVERT_TRANSACTION",
      label: (
        <Typography className="profile-drop-down-item">
          <DeleteOutlined />
          Revert Transaction
        </Typography>
      ),
    });
  }

  const handleMenuClick = (e: any) => {
    switch (e.key) {
      case "COLLECT_PAYMENT":
        setShow(true);
        break;
      case "REVERT_TRANSACTION":
        setShowDelete(true);
        break;
    }
  };
  const handleClose = (isReload: boolean) => {
    setShow(false);
    setShowDelete(false);
    if (isReload === true) {
      //lets reload the main api
      reloadApi();
    }
  };

  return (
    <>
      {show === true && (
        <CollectOpenInvoicePaymentModal
          handleClose={handleClose}
          show={show}
          invoice={invoice}
        />
      )}
      {showDelete === true && (
        <RevertInvoiceModal
          handleClose={handleClose}
          show={showDelete}
          invoice={invoice}
        />
      )}
      <Row
        style={{
          maxHeight: "200px",
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
        className="invoice-card-container"
        gutter={[16, 16]}
      >
        <Col span={4}>
          <DetailsItem
            label="Invoice Type"
            value={invoice.shortTitle}
            valueClass="payment-purpose"
          />
        </Col>

        <Col span={2}>
          <DetailsItem
            label="Status"
            value={invoice.invoiceStatus}
            valueClass="payment-purpose"
          />
        </Col>
        <Col span={2}>
          <DetailsItem
            label="Total Amount"
            value={`${invoice.invoiceTotalAmount}`}
            valueClass="user-name-label"
          />
        </Col>
        <Col span={2}>
          <DetailsItem
            label="Paid"
            value={`+${invoice.invoicePaidAmount}`}
            valueClass="in-amount-label"
            extraContainerClass="align-vertical-center"
          />
        </Col>

        <Col span={2}>
          <DetailsItem
            label="Discount"
            value={`-${invoice.invoiceDiscountAmount}`}
            valueClass="out-amount-label"
            extraContainerClass="align-vertical-center"
          />
        </Col>
        <Col span={3}>
          <DetailsItem
            label="Remaining"
            value={`${invoice.invoiceRemainingAmount}`}
            valueClass="user-name-label"
          />
        </Col>
        <Col span={4}>
          <DetailsItem
            label="Invoice Date"
            value={`${formatDate(new Date(invoice.invoiceCreatedAt))}`}
            valueClass="recharge-date-value"
          />
        </Col>

        <Col span={3}>
          <DetailsItem
            label="Staff"
            value={`${invoice.firstName} ${invoice.lastName}`}
            valueClass="user-name-label"
            extraContainerClass="align-vertical-center"
          />
        </Col>

        <Col className="view-icons-container" span={2}>
          <img
            className="cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              navigate(
                `/transactions/transaction-details/${invoice.intPkConnectionInvoiceID}`
              );
            }}
            src={ViewSoaIcon}
          />
          {invoice.intPkConnectionInvoiceID && (
            <img
              onClick={(e) => {
                e.preventDefault();
                navigate(
                  `/receipts/receipt-details/${invoice.intPkConnectionInvoiceID}/${invoice.intPkConnectionID}`
                );
              }}
              className="cursor-pointer"
              src={ViewReceiptIconBlue}
            />
          )}
          {getUserType() === "CEO" && (
            <Dropdown
              menu={{
                items: items,
                onClick: handleMenuClick,
              }}
              placement="bottomRight"
            >
              <img
                onClick={(e) => {}}
                className="cursor-pointer"
                src={ThreeDotsVertical}
              />
            </Dropdown>
          )}
        </Col>
      </Row>
    </>
  );
};
export default SingleConnectionInvoiceCard;
