import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewBankAccounts from "../components/banks/ViewBankAccounts";

const ViewBankAccountsPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Bank Accounts");
  }, [setTitle]);
  return (
    <>
      <ViewBankAccounts />
    </>
  );
};
export default ViewBankAccountsPage;
