import React, { useState } from "react";
import { Col, Input, Row, Typography } from "antd";

import NetwalaButton from "../Button/Index";
import { ButtonProps } from "../../../helpers/constants";
import NetwalaModal from "../Modal/Index";
import "./index.css";
const ActionQueueProcessConfirmation: React.FC<
  ProcessActionQueueAcceptModalProps
> = ({ confirmationButton, show, handleClose, actionQueueType }) => {
  const [ip, setIp] = useState<string>("");
  return (
    <>
      <NetwalaModal
        title="Process This Action"
        headerStyle={{
          backgroundColor: "#292D8E",
          color: "#FFFFFF",
          fontSize: "16px",
          fontWeight: "400",
          height: "50px",
        }}
        open={show}
        onCancel={() => handleClose(false)}
      >
        <Row>
          <div className="queue-confirmation-message-container">
            <Typography className="queue-confirmation-message">
              Are you sure you want to Accept this action?
            </Typography>
          </div>
          {actionQueueType === "STATIC_IP" && (
            <div
              style={{
                display: "flex",
                width: "60%",
                marginLeft: "20%",
                marginRight: "20%",
                marginTop: "20px",
              }}
            >
              <Input
                style={{ width: "100%", height: "45px" }}
                value={ip}
                onChange={(e) => {
                  setIp(e.target.value);
                }}
                placeholder="Please Enter IP"
              />
            </div>
          )}
          <div className="confirmation-action-queue-container">
            <NetwalaButton
              backgroundColor={ButtonProps.background.YELLOW}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.ONE24}
              textColor={ButtonProps.color.STANDARD}
              onClick={() => confirmationButton(true, ip)}
              text="Accept"
              borderColor="#FFA726"
            />
            <NetwalaButton
              backgroundColor={ButtonProps.background.SECONDARY}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.ONE24}
              textColor={ButtonProps.color.BLACK}
              onClick={() => confirmationButton(false)}
              text="Cancel"
              //   icon={<img src={PlusIcon} />}
              borderColor="#D7D7D7"
            />
          </div>
        </Row>
      </NetwalaModal>
    </>
  );
};
export default ActionQueueProcessConfirmation;
