import { useEffect, useState } from "react";
import { Col, Form, Input, Row, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosPost from "../../hooks/useAxiosPost";

import StaffIcon from "./../../assets/pngs/StaffProfile.png";
import DummyFront from "./../../assets/pngs/idCards/DummyFront.png";
import DummyBack from "./../../assets/pngs/idCards/DummyBack.png";

import "./cards/SingleStaffMiniCard.css";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import useMultiNotification from "../../hooks/useNotification";

const UpdateStaffProfileComponent = () => {
  const [form] = Form.useForm();
  const { openNotification, contextHolder } = useMultiNotification();
  const { intPkHeadID } = useParams();

  const navigate = useNavigate();
  const [staff, setStaffProfile] = useState<StaffListType | null>(null);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<GetStaffPropApiProp>("auth/getStaffProfile");

  const [
    postUpdateProfileApi,
    updateProfileApiResponse,
    updateProfileApiError,
    updateProfileApiLoading,
    updateProfileApiMessage,
    updateProfileApiErrorCount,
  ] = useAxiosPost<UpdateStaffProfileApiProp>(
    "auth/updateMyStaffProfile",
    true
  );
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setStaffProfile(data);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({ intPkHeadID: Number(intPkHeadID) });
  }, []);
  const onFinishFailed = (errorInfo: any) => {};
  const onFinish = (values: any) => {
    const dataToSend: UpdateStaffProfileApiProp = {
      firstName: values.firstName,
      lastName: values.lastName,
      address: values.address,
      intPkHeadID: Number(intPkHeadID),
    };
    postUpdateProfileApi(dataToSend);
  };
  useEffect(() => {
    if (updateProfileApiResponse !== null) {
      const { message } = updateProfileApiResponse;
      if (message) {
        openNotification(`success`, "Success", message);
      }
    }
  }, [updateProfileApiResponse]);
  useEffect(() => {
    if (updateProfileApiError !== null && updateProfileApiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", updateProfileApiError);
    }
  }, [updateProfileApiError, updateProfileApiErrorCount]);
  return (
    <>
      {contextHolder}
      <Row gutter={[20, 20]} style={{ marginTop: "40px" }}>
        {staff && (
          <>
            <Col span={12}>
              <div
                className="staff-mini-card-container"
                style={{ background: "inherit", border: "none" }}
              >
                <img
                  src={
                    staff.url && staff.isEncrypted === 0 ? staff.url : StaffIcon
                  }
                  className="staff-profile-image"
                />
                <Typography className="staff-full-name">
                  {staff.firstName} {staff.lastName}
                </Typography>
                <Typography className="staff-phone-number">
                  {staff.phoneNumber}
                </Typography>
                <Typography className="staff-user-role">
                  {staff.headType}
                </Typography>
                <Typography className="">
                  <span className="staff-cash-label">Cash:</span>{" "}
                  <span className="staff-cash-value">001</span>
                </Typography>
                {/* <div className="id-card-view-container">
                  <img src={DummyFront} />
                  <img src={DummyBack} />
                </div> */}
              </div>
            </Col>

            <Col span={12} style={{ marginTop: "30px" }}>
              <Row style={{ width: "100%" }}>
                <Form
                  name="updateProfileForm"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  requiredMark={false}
                  style={{ width: "100%" }}
                  form={form}
                  initialValues={{
                    firstName: staff.firstName,
                    lastName: staff.lastName,
                    nicNumber: staff.nicNumber,
                    address: staff.address,
                  }}
                >
                  {/* First */}
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <span className="create-user-form-label">
                            First Name
                          </span>
                        }
                        name="firstName"
                        rules={[
                          {
                            required: true,
                            message: "Please enter First Name",
                          },
                        ]}
                      >
                        <Input
                          className="user-input-general-height"
                          placeholder="John"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <span className="create-user-form-label">
                            Last Name
                          </span>
                        }
                        name="lastName"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Last Name",
                          },
                        ]}
                      >
                        <Input
                          className="user-input-general-height"
                          placeholder="Deo"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {/* Second */}

                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <span className="create-user-form-label">
                            CNIC Number
                          </span>
                        }
                        name="nicNumber"
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please enter CNIC Number",
                        //   },
                        // ]}
                      >
                        <Input
                          disabled={true}
                          className="user-input-general-height"
                          placeholder="03001234567"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <span className="create-user-form-label">
                            Address
                          </span>
                        }
                        name="address"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Address",
                          },
                        ]}
                      >
                        <Input
                          className="user-input-general-height"
                          placeholder="House No."
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      style={{ display: "flex", justifyContent: "center" }}
                      span={24}
                    >
                      <Form.Item>
                        <NetwalaButton
                          htmlType="submit"
                          backgroundColor={ButtonProps.background.STANDARD}
                          height={ButtonProps.height.STANDARD}
                          width={ButtonProps.width.STANDARD}
                          textColor={ButtonProps.color.STANDARD}
                          onClick={() => {
                            console.log("clicked");
                          }}
                          text="Update"
                          disabled={updateProfileApiLoading}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Row>
            </Col>
          </>
        )}
      </Row>
    </>
  );
};
export default UpdateStaffProfileComponent;
