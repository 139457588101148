import React, { useContext, useEffect } from "react";
import TitleContext from "../../context/TitleContext";
import ViewCompanyStaffComponent from "../../components/superAdmin/ViewCompanyStaff";
const ViewCompanyStaffPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Company Staff");
  }, [setTitle]);

  return (
    <>
      <ViewCompanyStaffComponent />
    </>
  );
};
export default ViewCompanyStaffPage;
