import { useEffect, useState } from "react";
import { Row, Col, Typography, Form, Input, Card, Select, Button } from "antd";
import useAxiosPost from "../../hooks/useAxiosPost";
import { useNavigate, useParams } from "react-router-dom";
import useMultiNotification from "../../hooks/useNotification";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
const LoginAsCompanyCEO = () => {
  const navigate = useNavigate();

  const { openNotification, contextHolder } = useMultiNotification();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<SuperAdminCompanyLogin>("superAdmin/loginAsCEO");
  const { intPkCompanyID } = useParams();
  useEffect(() => {
    if (apiResponse !== null) {
      const responseData: any = apiResponse.responseData;
      const { token, role, isProfileCompleted } = responseData;
      console.log({ responseData });
      sessionStorage.setItem("CompanyAdminToken", token || "");
      sessionStorage.setItem("CompanyAdminType", role || "");
      sessionStorage.setItem("isProfileCompleted", isProfileCompleted);
      window.location.href = "/";
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({ intPkCompanyID: Number(intPkCompanyID) });
  }, []);

  return (
    <>
      {contextHolder}

      <Row style={{ width: "100%" }}>
        <Col span={24}>
          <TailSpinLoader />
        </Col>
      </Row>
    </>
  );
};
export default LoginAsCompanyCEO;
