import React, { useEffect, useContext, useState } from "react";
import NetwalaModal from "../Items/Modal/Index";
import { Col, Row, DatePicker, Form, Input } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import "./styles/RequestIPTVModal.css";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import FulfilledPromiseConfirmationUtil from "./FulfilledPromiseConfirmationUtil";
const { TextArea } = Input;
const UpdateOrCreatePromiseModal: React.FC<UpdateOrCreatePromiseModalProp> = ({
  show,
  handleClose,
  transaction,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();

  const [
    postConnectionRechargeApi,
    rechargeApiResponse,
    rechargeApiError,
    rechargeApiLoading,
    rechargeApiMessage,
    rechargeApiErrorCount,
  ] = useAxiosPost<CreateOrUpdatePromiseApiProp>(
    "transactions/addOrUpdatePromiseOnTransaction",
    true
  );

  const [
    postFulfilPromiseApi,
    fulfilPromiseApiResponse,
    fulfilPromiseApiError,
    fulfilPromiseApiLoading,
    fulfilPromiseApiMessage,
    fulfilPromiseApiErrorCount,
  ] = useAxiosPost<FulfilPromiseApiProp>(
    "transactions/updatePromiseToFulfil",
    true
  );
  const [isFulfilledClicked, setIsFulfilledClicked] = useState<boolean>(false);
  useEffect(() => {
    if (rechargeApiError !== null && rechargeApiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", rechargeApiError);
    }
  }, [rechargeApiError, rechargeApiErrorCount]);
  useEffect(() => {
    if (rechargeApiResponse !== null) {
      const { message } = rechargeApiResponse;
      if (message) {
        openNotification(`success`, "Success", message);

        setTimeout(() => {
          handleClose(true);
        }, 2500);
      }
    }
  }, [rechargeApiResponse]);
  const dateFormat = "YYYY-MM-DD";
  const currentDate =
    transaction.promiseDueDate !== null
      ? new Date(transaction.promiseDueDate.slice(0, 10))
      : new Date(); // Get the current date
  const defaultDate = moment(new Date(currentDate))
    .format(dateFormat)
    .toString();
  const onFinish = (values: any) => {
    // Handle form submission, values.email will contain the entered email address
    const { dueDate, promiseWords, promiseAmountCollected } = values;
    // expiryDate.format("YYYY-MM-DD").
    const promiseDateFormatted = dueDate.format("YYYY-MM-DD");
    const dataToSend: CreateOrUpdatePromiseApiProp = {
      intPkTransactionHeadID: transaction.intPkTransactionHeadID,
      promiseDate: promiseDateFormatted,
      promiseWords,
      promiseAmountCollected: Number(promiseAmountCollected),
    };
    postConnectionRechargeApi(dataToSend);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const disabledDate = (currentDate: any) => {
    // Disable dates before today
    return currentDate && currentDate < dayjs().startOf("day");
  };
  const fulfilConfirmation = (confirm: boolean) => {
    if (confirm === false) {
      setIsFulfilledClicked(false);
      return;
    }
    postFulfilPromiseApi({
      intPkTransactionHeadID: transaction.intPkTransactionHeadID,
    });
  };
  useEffect(() => {
    if (fulfilPromiseApiError !== null && fulfilPromiseApiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", fulfilPromiseApiError);
    }
  }, [fulfilPromiseApiError, fulfilPromiseApiErrorCount]);
  useEffect(() => {
    if (fulfilPromiseApiResponse !== null) {
      const { message } = fulfilPromiseApiResponse;
      if (message) {
        openNotification(`success`, "Success", message);

        setTimeout(() => {
          handleClose(true);
        }, 2500);
      }
    }
  }, [fulfilPromiseApiResponse]);
  return (
    <>
      <NetwalaModal
        title={
          transaction.promiseCreationDate !== null
            ? "Update Promise"
            : "Create Promise"
        }
        // titleImage={RechargeIcon}
        headerStyle={{
          backgroundColor: "#292D8E",
          color: "#FFFFFF",
          fontSize: "16px",
          fontWeight: "400",
          height: "50px",
        }}
        open={show}
        onCancel={() => handleClose(false)}
      >
        {contextHolder}
        {isFulfilledClicked === true ? (
          <FulfilledPromiseConfirmationUtil
            confirmationButton={fulfilConfirmation}
            message={` Did you received the final payment?`}
            isLoading={fulfilPromiseApiLoading}
          />
        ) : (
          <Row>
            <Col span={24} className="iptv-modal-package-details">
              <Form
                requiredMark={false}
                style={{ width: "100%" }}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                name="promiseForm"
                initialValues={{
                  dueDate: dayjs(defaultDate, "YYYY-MM-DD"),
                  promiseWords: transaction.promiseWords,
                  totalAmount: transaction.receivable || 0,
                  promiseAmountCollected:
                    transaction.promiseAmountCollected || 0,
                }}
              >
                <Row gutter={[16, 16]} style={{ width: "100%" }}>
                  <Col span={12}>
                    <Form.Item
                      name="totalAmount"
                      label={
                        <span className="create-user-form-label">
                          Total Amount
                        </span>
                      }
                    >
                      <Input
                        className="user-input-general-height"
                        placeholder="100"
                        type="number"
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="promiseAmountCollected"
                      rules={[
                        { required: true, message: "Please enter Amount" },
                        {
                          validator: (_, value) =>
                            value > 0
                              ? Promise.resolve()
                              : Promise.reject(new Error("Invalid Amount")),
                        },
                        {
                          validator: (_, value) =>
                            value > Number(transaction.receivable)
                              ? Promise.reject(new Error("Invalid Amount"))
                              : Promise.resolve(),
                        },
                      ]}
                      label={
                        <span className="create-user-form-label">
                          Amount Collected
                        </span>
                      }
                    >
                      <Input
                        className="user-input-general-height"
                        placeholder="100"
                        type="number"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  name="dueDate"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Promise Due Date",
                    },
                  ]}
                  label={
                    <span className="create-user-form-label">
                      Promise Due Date
                    </span>
                  }
                >
                  <DatePicker
                    style={{ width: "100%", height: "48px" }}
                    format={dateFormat}
                    defaultValue={dayjs(defaultDate, "YYYY-MM-DD")}
                    disabledDate={disabledDate}
                  />
                </Form.Item>

                <Form.Item
                  name="promiseWords"
                  rules={[
                    { required: true, message: "Please Add your comments" },
                  ]}
                  label={
                    <span className="create-user-form-label">Comments</span>
                  }
                >
                  <TextArea
                    className="user-input-general-height"
                    placeholder="user have the promise of 3 days"
                    // type="text"
                  />
                </Form.Item>
                <Form.Item>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      paddingBottom: "30px",
                      width: "100%",
                      paddingTop: "50px",
                    }}
                  >
                    <NetwalaButton
                      backgroundColor={ButtonProps.background.DANGER}
                      height={ButtonProps.height.STANDARD}
                      width={ButtonProps.width.ONE24}
                      textColor={ButtonProps.color.STANDARD}
                      onClick={() => {}}
                      htmlType="submit"
                      text={
                        transaction.promiseCreationDate !== null
                          ? "Update"
                          : "Create"
                      }
                      borderColor="#DF3131"
                      disabled={rechargeApiLoading}
                    />
                    <NetwalaButton
                      disabled={rechargeApiLoading}
                      backgroundColor={ButtonProps.background.SECONDARY}
                      height={ButtonProps.height.STANDARD}
                      width={ButtonProps.width.ONE24}
                      textColor={ButtonProps.color.BLACK}
                      onClick={() => handleClose(false)}
                      text="Cancel"
                      borderColor="#D7D7D7"
                    />
                  </div>
                </Form.Item>
                {transaction.promiseCreationDate !== null &&
                  Number(transaction.isPromiseFulfilled) !== 1 && (
                    <Form.Item>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingBottom: "30px",
                          width: "100%",
                        }}
                      >
                        <NetwalaButton
                          backgroundColor={ButtonProps.background.STANDARD}
                          height={ButtonProps.height.STANDARD}
                          width={ButtonProps.width.STANDARD}
                          textColor={ButtonProps.color.STANDARD}
                          onClick={() => {
                            setIsFulfilledClicked(true);
                          }}
                          htmlType="button"
                          text="Fullfil Promise"
                          disabled={rechargeApiLoading}
                        />
                      </div>
                    </Form.Item>
                  )}
              </Form>
            </Col>
          </Row>
        )}
      </NetwalaModal>
    </>
  );
};

export default UpdateOrCreatePromiseModal;
