import React, { useEffect, useContext, useState } from "react";
import NetwalaModal from "../Items/Modal/Index";
import RechargeIcon from "./../../assets/svgs/RechargeIcon.svg";
import { Col, Row, Typography, Form, Input } from "antd";
import "./styles/RequestIPTVModal.css";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import BalanceContext from "../../context/BalanceContext";

const CollectOpenInvoicePaymentModal: React.FC<
  CollectOpenInvoicePaymentModalProps
> = ({ show, handleClose, invoice }) => {
  const { value, setValue } = useContext(BalanceContext);
  const { openNotification, contextHolder } = useMultiNotification();
  const [
    postConnectionRechargeApi,
    rechargeApiResponse,
    rechargeApiError,
    rechargeApiLoading,
    rechargeApiMessage,
    rechargeApiErrorCount,
  ] = useAxiosPost<PayOpenInvoiceApiProp>(
    "invoices/payOpenConnectionInvoice",
    true
  );
  useEffect(() => {
    if (rechargeApiError !== null && rechargeApiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", rechargeApiError);
    }
  }, [rechargeApiError, rechargeApiErrorCount]);
  useEffect(() => {
    if (rechargeApiResponse !== null) {
      const { message } = rechargeApiResponse;
      if (message) {
        setValue(value + 1);
        openNotification(`success`, "Success", message);

        setTimeout(() => {
          handleClose(true);
        }, 2500);
      }
    }
  }, [rechargeApiResponse]);
  const dateFormat = "YYYY-MM-DD";

  const onFinish = (values: any) => {
    // Handle form submission, values.email will contain the entered email address
    const { amount } = values;
    const data: PayOpenInvoiceApiProp = {
      intPkConnectionID: invoice.intPkConnectionID,
      intPkConnectionInvoiceID: invoice.intPkConnectionInvoiceID,
      amount: Number(amount),
    };
    postConnectionRechargeApi(data);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <NetwalaModal
        title="Collect Remaining Payment Of Invoice"
        // titleImage={RechargeIcon}
        headerStyle={{
          backgroundColor: "#292D8E",
          color: "#FFFFFF",
          fontSize: "16px",
          fontWeight: "400",
          height: "50px",
        }}
        open={show}
        onCancel={() => handleClose(false)}
      >
        {contextHolder}

        <Row>
          <Col span={24} className="iptv-modal-package-details">
            <Form
              requiredMark={false}
              style={{ width: "100%" }}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              name="collectInvoicePaymentForm"
              initialValues={{
                amount: invoice.invoiceRemainingAmount,
              }}
            >
              <Form.Item
                name="amount"
                rules={[
                  { required: true, message: "Please Enter Amount" },
                  {
                    validator: (_, value) =>
                      value > -1
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("Amount must be greater than 0")
                          ),
                  },
                  {
                    validator: (_, value) =>
                      value > invoice.invoiceRemainingAmount
                        ? Promise.reject(
                            new Error(
                              "Amount must not be greater than Remaining Amount"
                            )
                          )
                        : Promise.resolve(),
                  },
                ]}
                label={
                  <span
                    style={{
                      color: "#DE1818",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Remaining Amount
                  </span>
                }
              >
                <Input
                  type="number"
                  className="user-input-general-height"
                  placeholder="10"
                />
              </Form.Item>

              <Form.Item>
                <NetwalaButton
                  backgroundColor={ButtonProps.background.YELLOW}
                  height={ButtonProps.height.STANDARD}
                  width={`100%`}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {}}
                  htmlType="submit"
                  text="Collect Now"
                  borderColor="#FFA726"
                  disabled={rechargeApiLoading}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </NetwalaModal>
    </>
  );
};

export default CollectOpenInvoicePaymentModal;
