import { useEffect, useState } from "react";
import { Row, Col, Typography, Form, Input, Card, Select, Button } from "antd";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ConnectionDetailsComponent from "./Index";
import NetwalaTabs from "../Items/Tab/Index";
import ConnectionInvoicesComponent from "./ConnectionInvoices";
const ConnectionProfileComponent = () => {
  const [searchParams] = useSearchParams();
  const _activeTab = searchParams.get("activeTab");
  const fullName = searchParams.get("fullName");

  const { intPkHeadID, intPkConnectionID } = useParams();
  const navigate = useNavigate();
  const [tabs, setTabs] = useState<NetwalaTabItemsProp[]>([
    {
      text: "Profile",
      isActive: true,
      id: 1,
    },

    {
      text: "Invoices",
      isActive: false,
      id: 2,
    },
  ]);
  const [activeTab, setActiveTab] = useState<number>(Number(_activeTab));
  const handleTabSwitch = (tabId: number) => {
    navigate(
      `/user-connection-details/${intPkHeadID}/${intPkConnectionID}?activeTab=${tabId}&fullName=${fullName}`
    );
  };
  useEffect(() => {
    setActiveTab(Number(_activeTab));
    const updatedItems = tabs.map((item) => ({
      ...item,
      isActive: item.id === Number(_activeTab),
    }));
    setTabs(updatedItems);
  }, [activeTab, _activeTab]);
  const showActiveComponent = (activeTab: number) => {
    switch (activeTab) {
      case 1:
        return <ConnectionDetailsComponent />;
      case 2:
        return <ConnectionInvoicesComponent />;
    }
    return <></>;
  };

  return (
    <>
      <Row gutter={16}>
        <NetwalaTabs
          colSpan={12}
          tabs={tabs}
          handleTabSwitch={handleTabSwitch}
        />
      </Row>
      <Row gutter={[16, 16]}>{showActiveComponent(activeTab)}</Row>
    </>
  );
};
export default ConnectionProfileComponent;
