import React, { useEffect } from "react";
import NetwalaModal from "../Items/Modal/Index";
import { Col, Row, Form, Input } from "antd";

import "./styles/RequestIPTVModal.css";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import NetwalaSelect from "../Items/Select/Index";
const AddOrUpdateInventoryItemsModal: React.FC<
  AddOrUpdateInventoryItemModalProps
> = ({ show, handleClose, item, url }) => {
  const { openNotification, contextHolder } = useMultiNotification();

  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<AddOrUpdateInventoryItemApiProp>(`superAdmin/${url}`, true);

  const onFinish = (values: any) => {
    const { inventoryItemType, inventoryMeasurementUnit } = values;
    const dataToSend: AddOrUpdateInventoryItemApiProp = {
      intPkInventoryItemID: item === null ? null : item.intPkInventoryItemID,
      inventoryItemType,
      inventoryMeasurementUnit,
    };
    postApiData(dataToSend);
  };
  useEffect(() => {
    if (apiResponse !== null) {
      const { message } = apiResponse;
      if (message) {
        openNotification("success", "Success", message);
        setTimeout(() => {
          handleClose(true);
        }, 2500);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification("error", "Error", apiError);
    }
  }, [apiError, apiErrorCount]);
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <NetwalaModal
        title={item !== null ? "Update Item" : "Add Item"}
        headerStyle={{
          backgroundColor: "#292D8E",
          color: "#FFFFFF",
          fontSize: "16px",
          fontWeight: "400",
          height: "50px",
        }}
        open={show}
        onCancel={() => {
          if (apiLoading) {
            return;
          }
          handleClose(false);
        }}
      >
        {contextHolder}

        <Row>
          <Col span={24} className="iptv-modal-package-details">
            <Form
              requiredMark={false}
              style={{ width: "100%", marginTop: "50px" }}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              name="addOrUpdateInventoryItemForm"
              initialValues={
                item !== null
                  ? {
                      inventoryItemType: item.inventoryItemType,
                      inventoryMeasurementUnit: item.inventoryMeasurementUnit,
                    }
                  : {}
              }
            >
              <Form.Item
                label={
                  <span className="create-user-form-label">Choose Item</span>
                }
                name="inventoryItemType"
                rules={[
                  {
                    required: true,
                    message: "Please Choose Item",
                  },
                ]}
              >
                <NetwalaSelect
                  size="large"
                  height="45px"
                  options={[
                    { label: "ROUTER", value: "ROUTER" },
                    { label: "BOX", value: "BOX" },
                    { label: "SWITCH", value: "SWITCH" },
                    { label: "DROP_WIRE", value: "DROP_WIRE" },
                    { label: "FIBER_OPTIC", value: "FIBER_OPTIC" },
                    { label: "CAT5", value: "CAT5" },
                    { label: "CAT6", value: "CAT6" },
                    { label: "CAT7", value: "CAT7" },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label={
                  <span className="create-user-form-label">Choose Unit</span>
                }
                name="inventoryMeasurementUnit"
                rules={[
                  {
                    required: true,
                    message: "Please Choose Unit",
                  },
                ]}
              >
                <NetwalaSelect
                  size="large"
                  height="45px"
                  options={[
                    { label: "PIECE", value: "PIECE" },
                    { label: "METER", value: "METER" },
                  ]}
                />
              </Form.Item>
              <Form.Item>
                <NetwalaButton
                  backgroundColor={ButtonProps.background.STANDARD}
                  height={ButtonProps.height.STANDARD}
                  width={`100%`}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {}}
                  htmlType="submit"
                  text={item !== null ? "Update" : "Add"}
                  disabled={apiLoading}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </NetwalaModal>
    </>
  );
};

export default AddOrUpdateInventoryItemsModal;
