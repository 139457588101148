import React from "react";
import BackButtonIcon from "./../../assets/svgs/BackButton.svg";
import { useNavigate } from "react-router-dom";
import { Typography } from "antd";
interface PageTitleProp {
  title: string;
}
const PageTitle: React.FC<PageTitleProp> = ({ title }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="active-page-header-container">
        <img
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
          style={{ marginTop: "2px", cursor: "pointer" }}
          src={BackButtonIcon}
        />
        <Typography className="current-active-page-title">{title}</Typography>
      </div>
    </>
  );
};

export default PageTitle;
