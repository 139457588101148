import React, { useEffect } from "react";
import NetwalaModal from "../Items/Modal/Index";
import { Col, Row, Form, Input } from "antd";

import "./styles/RequestIPTVModal.css";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
const AddInventoryModal: React.FC<AddInventoryModalProps> = ({
  show,
  handleClose,
  intPkInventoryModelID,
  inventoryProfile,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();

  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<AddOrCreateNewInventoryApiProp>(
    "inventory/addNewInventory",
    true
  );

  const onFinish = (values: any) => {
    const { quantity, unitSalePrice } = values;
    const dataToSend: AddOrCreateNewInventoryApiProp = {
      quantity: Number(quantity),
      unitSalePrice:
        inventoryProfile.intPkInventoryStockID === -1
          ? Number(unitSalePrice)
          : inventoryProfile.unitSalePrice,
      intPkInventoryModelID,
    };
    postApiData(dataToSend);
  };
  useEffect(() => {
    if (apiResponse !== null) {
      const { message } = apiResponse;
      if (message) {
        openNotification("success", "Success", message);
        setTimeout(() => {
          handleClose(true);
        }, 2500);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification("error", "Error", apiError);
    }
  }, [apiError, apiErrorCount]);
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <NetwalaModal
        title="Add Items"
        headerStyle={{
          backgroundColor: "#292D8E",
          color: "#FFFFFF",
          fontSize: "16px",
          fontWeight: "400",
          height: "50px",
        }}
        open={show}
        onCancel={() => {
          if (apiLoading) {
            return;
          }
          handleClose(false);
        }}
      >
        {contextHolder}

        <Row>
          <Col span={24} className="iptv-modal-package-details">
            <Form
              requiredMark={false}
              style={{ width: "100%", marginTop: "50px" }}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              name="addInventoryFrom"
            >
              <Form.Item
                label={
                  <span className="create-user-form-label">Items to Add</span>
                }
                name="quantity"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Quantity",
                  },
                  {
                    validator: (_, value) =>
                      value > 0
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("Quantity must be greater than 0")
                          ),
                  },
                ]}
              >
                <Input
                  type="number"
                  className="user-input-general-height"
                  placeholder="12"
                />
              </Form.Item>
              {inventoryProfile.intPkInventoryStockID === -1 && (
                <Form.Item
                  label={
                    <span className="create-user-form-label">Unit Cost</span>
                  }
                  name="unitSalePrice"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Unit Sale Price",
                    },
                    {
                      validator: (_, value) =>
                        value > 0
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error("Unit Cost must be greater than 0")
                            ),
                    },
                  ]}
                >
                  <Input
                    type="number"
                    className="user-input-general-height"
                    placeholder="1200"
                  />
                </Form.Item>
              )}

              <Form.Item>
                <NetwalaButton
                  backgroundColor={ButtonProps.background.STANDARD}
                  height={ButtonProps.height.STANDARD}
                  width={`100%`}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {}}
                  htmlType="submit"
                  text="Add"
                  disabled={apiLoading}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </NetwalaModal>
    </>
  );
};

export default AddInventoryModal;
