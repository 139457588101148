import React, { useEffect, useState } from "react";
import NetwalaModal from "../Items/Modal/Index";
import RechargeIcon from "./../../assets/svgs/RechargeIcon.svg";
import { Col, Row, Form, Input } from "antd";
import "./styles/RequestIPTVModal.css";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import NetwalaSelect from "../Items/Select/Index";
const UpdateCATVConnectionModal: React.FC<UpdateConnectionModalProp> = ({
  show,
  handleClose,
  connection,
}) => {
  const [form] = Form.useForm();

  const { openNotification, contextHolder } = useMultiNotification();
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedSubArea, setSelectedSubArea] = useState<null | string>(null);
  const [areas, setAreas] = useState<NetwalaSelectOptions[]>([]);
  const [subAreas, setSubAreas] = useState<NetwalaSelectOptions[]>([]);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<UpdateCATVConnectionApiProp>(
    "connections/updateCATVConnectionDetails",
    true
  );

  const [
    getAreasApi,
    areasApiResponse,
    areasApiError,
    areasApiLoading,
    areasApiMessage,
    areasApiErrorCount,
  ] = useAxiosPost<ResponseProp>("areas/getAreasByOwner");
  const [
    getSubAreasApi,
    subAreasApiResponse,
    subAreasApiError,
    subAreasApiLoading,
    subAreasApiMessage,
    subAreasApiErrorCount,
  ] = useAxiosPost<SubAreasByAreaIDApiProp>("areas/getSubAreasByAreaID");

  useEffect(() => {
    // getAreasApi({});
  }, []);

  useEffect(() => {
    if (areasApiResponse !== null) {
      const data: any = areasApiResponse.responseData;
      const areas = data.map((item: any): NetwalaSelectOptions => {
        return {
          value: item.intPkAreaID,
          label: item.areaName,
        };
      });

      setAreas(areas);
    }
  }, [areasApiResponse]);
  useEffect(() => {
    if (subAreasApiResponse !== null) {
      const data: any = subAreasApiResponse.responseData;
      const subAreas = data.map((item: any): NetwalaSelectOptions => {
        return {
          value: item.intPkAreaID,
          label: item.areaName,
        };
      });

      setSubAreas(subAreas);
    }
  }, [subAreasApiResponse]);

  const onFinish = (values: any) => {
    const { sale_price, address } = values;
    const dataToSend: UpdateCATVConnectionApiProp = {
      intPkConnectionID: connection.intPkConnectionID,
      address,
      sale_price: Number(sale_price),
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      areaId: connection.intPkAreaID,
    };
    postApiData(dataToSend);
  };
  useEffect(() => {
    if (apiResponse !== null) {
      const { message } = apiResponse;
      if (message) {
        openNotification("success", "Success", message);
        setTimeout(() => {
          handleClose(true);
        }, 2500);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification("error", "Error", apiError);
    }
  }, [apiError, apiErrorCount]);

  const onFinishFailed = (errorInfo: any) => {};

  const handleAreaChange = (value: string) => {
    setSelectedSubArea(null);
    form.setFieldsValue({ subArea: undefined });

    getSubAreasApi({ intPkAreaID: Number(value) });
  };
  return (
    <>
      <NetwalaModal
        title="Update CATV Connection"
        headerStyle={{
          backgroundColor: "#292D8E",
          color: "#FFFFFF",
          fontSize: "16px",
          fontWeight: "400",
          height: "50px",
        }}
        open={show}
        onCancel={() => {
          handleClose(false);
        }}
      >
        {contextHolder}
        <Row>
          <Col span={24} className="iptv-modal-package-details">
            <Form
              requiredMark={false}
              style={{ width: "100%", marginTop: "50px" }}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              form={form}
              name="updateCATVConnectionForm"
              initialValues={{
                sale_price: connection.sale_price,
                address: connection.address,
                firstName: connection.firstName,
                lastName: connection.lastName,
                phoneNumber: connection.phoneNumber,
              }}
            >
              <Form.Item
                label={
                  <span className="create-user-form-label">First Name</span>
                }
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please enter First Name",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="John"
                />
              </Form.Item>
              <Form.Item
                label={
                  <span className="create-user-form-label">Last Name</span>
                }
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please enter Last Name",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="Doe"
                />
              </Form.Item>
              <Form.Item
                label={
                  <span className="create-user-form-label">Phone Number</span>
                }
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter Phone number",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="03001235467"
                />
              </Form.Item>

              <Form.Item
                label={<span className="create-user-form-label">Address</span>}
                name="address"
                rules={[
                  {
                    required: true,
                    message: "Please enter Address",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="House No."
                />
              </Form.Item>
              <Form.Item
                label={
                  <span className="create-user-form-label">Sale Price</span>
                }
                name="sale_price"
                rules={[
                  {
                    required: true,
                    message: "Please enter Sale Price",
                  },
                  {
                    validator: (_, value) =>
                      value > 0
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("Sale Price must be greater than 0")
                          ),
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="300"
                  type="number"
                />
              </Form.Item>
              {/* <Form.Item
                label={<span className="create-user-form-label">Area</span>}
                name="area"
                rules={[
                  {
                    required: true,
                    message: "Please Select Area",
                  },
                ]}
              >
                <NetwalaSelect
                  value={selectedArea}
                  onChange={handleAreaChange}
                  size="large"
                  height="45px"
                  options={areas}
                />
              </Form.Item>

              <Form.Item
                label={<span className="create-user-form-label">Sub Area</span>}
                name="subArea"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please enter Sub Area",
                //   },
                // ]}
              >
                <NetwalaSelect
                  value={selectedSubArea}
                  size="large"
                  height="45px"
                  options={subAreas}
                />
              </Form.Item> */}

              <Form.Item>
                <NetwalaButton
                  backgroundColor={ButtonProps.background.YELLOW}
                  height={ButtonProps.height.STANDARD}
                  width={`100%`}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {}}
                  htmlType="submit"
                  text="Submit"
                  borderColor="#FFA726"
                  disabled={apiLoading}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </NetwalaModal>
    </>
  );
};

export default UpdateCATVConnectionModal;
