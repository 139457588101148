import { useEffect, useState } from "react";
import { Col, Row, Typography, Input, Button } from "antd";
import { useNavigate } from "react-router-dom";
import useAxiosPost, {
  authTokenString,
  baseURL,
} from "../../../hooks/useAxiosPost";
import StaffIcon from "./../../../assets/pngs/StaffProfile.png";
// import DummyFront from "./../../../assets/pngs/idCards/DummyFront.png";
// import DummyBack from "./../../../assets/pngs/idCards/DummyBack.png";
import StaffProfileTabCard from "../../staff/singleStaff/StaffProfileTabCard";
import "./../../staff/cards/SingleStaffMiniCard.css";
import NetwalaButton from "../../Items/Button/Index";
import { ButtonProps } from "../../../helpers/constants";
import StaffTransactionsCard from "../../staff/singleStaff/StaffTransactionsCard";
import TailSpinLoader from "../../Items/Loaders/TailSpinLoader";
import NetwalaSelect from "../../Items/Select/Index";
import { EditOutlined, UploadOutlined } from "@ant-design/icons";
import useMultiNotification from "../../../hooks/useNotification";

const ViewProfileComponent = () => {
  const navigate = useNavigate();
  const { openNotification, contextHolder } = useMultiNotification();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [staff, setStaffProfile] = useState<StaffListType | null>(null);
  const [staffTransactions, setStaffTransactions] = useState<
    StaffTransaction[]
  >([]);
  const [selectedConnectionType, setSelectedConnectionType] =
    useState<string>("");

  const [rechargeType, setRechargeType] = useState<string>("");
  const [username, setUserName] = useState<string>("");
  const [fullName, setFullName] = useState<string>("");
  const [pagination, setPagination] = useState<PaginationProp>({
    totalPages: 0,
    currentPage: 1,
    previousPage: null,
    nextPage: null,
    totalCount: 0,
    pageSize: 50,
  });
  const [
    getStaffTransactionsApi,
    transactionsApiResponse,
    transactionApiError,
    transactionsApiLoading,
  ] = useAxiosPost<ResponseProp>("invoices/getMyTransactions");
  useEffect(() => {
    if (transactionsApiResponse !== null) {
      const data: any = transactionsApiResponse.responseData;
      const {
        currentPage,
        nextPage,
        pageSize,
        previousPage,
        totalCount,
        totalPages,
        transactions,
      } = data;
      setPagination({
        currentPage,
        totalPages,
        nextPage,
        previousPage,
        totalCount,
        pageSize,
      });

      setStaffTransactions(transactions || []);
    }
  }, [transactionsApiResponse]);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("auth/getMyProfile");
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setStaffProfile(data);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({});
    getStaffTransactionsApi({});
  }, []);
  const apiReload = (isReload: boolean) => {};
  const handlePageSwitch = (pageNumber: number) => {
    getStaffTransactionsApi({ page: pageNumber });
  };
  const handleClickEdit = () => {
    // Simulate click on hidden file input element

    const imageInput = document.getElementById("imageInput");
    if (imageInput) {
      imageInput.click();
    }
  };
  const [imageFile, setImageFile] = useState<File | null>(null);

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setImageFile(event.target.files?.[0] ?? null);
    if (event.target.files && event.target.files.length > 0) {
      setIsLoading(true);

      const formData = new FormData();
      formData.append("authTokenString", authTokenString.toString());
      formData.append("profilePicture", event.target.files[0]);

      try {
        const response = await fetch(`${baseURL}/auth/updateMyProfilePicture`, {
          method: "POST",
          body: formData,
          // Note: When using FormData, do NOT set the Content-Type header
          // The browser will set it automatically, including the boundary parameter
        });

        if (response.ok) {
          const responseData = await response.json();
          openNotification(
            "success",
            "Success",
            responseData?.message || "Profile Updated Successfully"
          );
          postApiData({});
          setIsLoading(false);
        } else {
          const responseData = await response.json();

          openNotification(
            "error",
            "Operation Failed",
            responseData?.message || "Server Error"
          );
          console.error("Upload failed:", responseData);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error during fetch:", error);
      }
    }
  };
  if (apiLoading || isLoading) {
    return (
      <Row style={{ width: "100%" }}>
        <Col span={24}>
          <TailSpinLoader />
        </Col>
      </Row>
    );
  }
  return (
    <>
      {contextHolder}
      <Row gutter={[20, 20]} style={{ marginTop: "40px" }}>
        {staff && (
          <>
            <Col span={12}>
              <div
                className="staff-mini-card-container"
                style={{ background: "inherit", border: "none" }}
              >
                <div className="staff-image-container">
                  <img
                    src={
                      staff.url && staff.isEncrypted === 0
                        ? staff.url
                        : StaffIcon
                    }
                    className="staff-profile-image"
                  />
                  <div className="edit-icon-staff-profile">
                    <Button
                      icon={<EditOutlined />}
                      onClick={handleClickEdit}
                    ></Button>
                  </div>
                  <input
                    type="file"
                    id="imageInput"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />
                </div>

                <Typography className="staff-full-name">
                  {staff.firstName} {staff.lastName}
                </Typography>
                <Typography className="staff-phone-number">
                  {staff.phoneNumber}
                </Typography>
                <Typography className="staff-user-role">
                  {staff.headType}
                </Typography>
                {["RMAN", "CASHIER"].includes(staff.headType) && (
                  <Typography className="">
                    <span className="staff-cash-label">Cash:</span>{" "}
                    <span className="staff-cash-value">
                      {staff?.userBalance?.cashAvailable || 0}
                    </span>
                  </Typography>
                )}
                {/* <div className="id-card-view-container">
                  <img src={DummyFront} />
                  <img src={DummyBack} />
                </div> */}
              </div>
            </Col>

            <Col span={12} style={{ marginTop: "30px" }}>
              <StaffProfileTabCard
                isStaff={false}
                staff={staff}
                staffApiReload={apiReload}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <NetwalaButton
                  backgroundColor={ButtonProps.background.STANDARD}
                  height={ButtonProps.height.STANDARD}
                  width={ButtonProps.width.STANDARD}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {
                    navigate(`/auth/update-profile`);
                  }}
                  text="Update Profile"
                />
              </div>
            </Col>
          </>
        )}
      </Row>

      {transactionsApiLoading === true ? (
        <TailSpinLoader />
      ) : (
        <Row gutter={[20, 20]} style={{ marginTop: "40px" }}>
          <Col span={24} style={{ marginTop: "30px" }}>
            {/* Filters Here */}

            <Row gutter={20}>
              <Col span={6}>
                <span className="create-user-form-label">Connection Type</span>
                <NetwalaSelect
                  value={selectedConnectionType}
                  onChange={(e) => setSelectedConnectionType(e)}
                  size="large"
                  height="45px"
                  options={[
                    { label: "INTERNET", value: "INTERNET" },
                    { label: "CATV", value: "CATV" },
                  ]}
                />
              </Col>
              <Col span={6}>
                <span className="create-user-form-label">User Name</span>
                <Input
                  className="user-input-general-height"
                  placeholder="johndoe"
                  value={username}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </Col>
              <Col span={6}>
                <span className="create-user-form-label">Full Name</span>
                <Input
                  className="user-input-general-height"
                  placeholder="John Doe"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </Col>
              <Col span={6}>
                <span className="create-user-form-label">Recharge Type</span>
                <NetwalaSelect
                  value={rechargeType}
                  onChange={(e) => setRechargeType(e)}
                  size="large"
                  height="45px"
                  options={[
                    { label: "RECHARGE", value: "RECHARGE" },
                    { label: "NEW", value: "NEW" },
                  ]}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <StaffTransactionsCard
              transactions={staffTransactions}
              pagination={pagination}
              handlePageSwitch={handlePageSwitch}
              intPkHeadID={Number(staff?.intPkHeadID)}
              viewType="profile"
            />
          </Col>
        </Row>
      )}
    </>
  );
};
export default ViewProfileComponent;
