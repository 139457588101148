import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Typography,
  Form,
  Input,
  Button,
  Checkbox,
  Card,
  Switch,
} from "antd";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import "./styles/companySettings.css";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import CompanySettingsUpdateConfirmationModal from "../Modals/CompanySettingsUpdateConfirmationModal";
interface SingleSettingContainer {
  value: boolean;
  label: string;
  isBorderBottom: boolean;
  handleSettingsChange: (key: string, value: number) => void;
  settingKey: string;
}
const SingleGlobalSettingComponent: React.FC<SingleSettingContainer> = ({
  value,
  label,
  isBorderBottom,
  handleSettingsChange,
  settingKey,
}) => {
  const onChange = (checked: boolean) => {
    console.log(`switch to ${checked}`);
    handleSettingsChange(settingKey, Number(checked));
  };
  return (
    <div
      className={`single-setting-container ${
        isBorderBottom === true ? "border-bottom-settings-card" : ""
      } `}
    >
      <Typography className="company-settings-label">{label}</Typography>
      <Switch value={value} onChange={onChange} />
    </div>
  );
};
const CompanyGlobalSettingsComponent = () => {
  const navigate = useNavigate();
  const { openNotification, contextHolder } = useMultiNotification();
  const [settings, setSettings] = useState<CompanySettingsApiProp | null>(null);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("auth/getCompanyGlobalSettings");

  const [
    postSettingsUpdateApi,
    updateApiResponse,
    updateApiError,
    updateApiLoading,
    updateApiMessage,
    updateApiErrorCount,
  ] = useAxiosPost<UpdateCompanySettingsApiProp>(
    "auth/updateCompanyGlobalSettings"
  );
  const [show, setShow] = useState<boolean>(false);
  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setSettings(data);
    }
  }, [apiResponse]);
  const handleSettingsChange = (key: string, value: number) => {
    if (settings === null) {
      return;
    }
    setSettings({
      ...settings,
      [key]: value,
    });
  };
  const handleClose = (doesUpdate: boolean) => {
    setShow(false);
    if (doesUpdate === true && settings !== null) {
      postSettingsUpdateApi(settings);
    }
  };
  useEffect(() => {
    if (updateApiResponse !== null) {
      const { message } = updateApiResponse;
      if (message) {
        openNotification(`success`, "Success", message);
        postApiData({});
      }
    }
  }, [updateApiResponse]);
  useEffect(() => {
    if (updateApiError !== null && updateApiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", updateApiError);
    }
  }, [updateApiError, updateApiErrorCount]);
  return (
    <>
      {contextHolder}
      {show === true && (
        <CompanySettingsUpdateConfirmationModal
          show={show}
          handleClose={handleClose}
        />
      )}
      <div className="company-settings-container">
        {apiLoading === true || settings === null ? (
          <TailSpinLoader />
        ) : (
          <>
            <Card className="company-settings-card">
              <SingleGlobalSettingComponent
                label="Bypass Panel Manager"
                value={Boolean(settings.isPanelManagerByPass)}
                isBorderBottom
                settingKey="isPanelManagerByPass"
                handleSettingsChange={handleSettingsChange}
              />
              <SingleGlobalSettingComponent
                label="Show Connections Password"
                value={Boolean(settings.usersPasswordViewToRman)}
                isBorderBottom
                settingKey="usersPasswordViewToRman"
                handleSettingsChange={handleSettingsChange}
              />
              <SingleGlobalSettingComponent
                label="Invoice Edit"
                value={Boolean(settings.isInvoiceAmountEditable)}
                isBorderBottom={false}
                settingKey="isInvoiceAmountEditable"
                handleSettingsChange={handleSettingsChange}
              />
            </Card>
            <NetwalaButton
              backgroundColor={ButtonProps.background.STANDARD}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.STANDARD}
              textColor={ButtonProps.color.STANDARD}
              onClick={() => {
                setShow(true);
              }}
              text="Update Settings"
            />
          </>
        )}
      </div>
    </>
  );
};
export default CompanyGlobalSettingsComponent;
