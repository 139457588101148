import { useEffect, useState } from "react";
import { Row, Col, Typography, Dropdown } from "antd";
import type { MenuProps } from "antd";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import PlusIcon from "./../../../assets/svgs/IconsWhite/PlusIcon.svg";
import NetwalaTabs from "../../Items/Tab/Index";
import SingleISPProfileComponent from "./components/IspProfile";
import PackagesByIspComponent from "./components/packages/ViewPackage";
import NetwalaButton from "../../Items/Button/Index";
import { ButtonProps } from "../../../helpers/constants";
import VlansByIspComponent from "./components/vlans/ViewVlans";
import { getUserType } from "../../../helpers/userDetails";
import ISPBulkUserImport from "./components/import/BulkUserImport";
const SingleIspDetailsComponent = () => {
  const [searchParams] = useSearchParams();
  const _activeTab = searchParams.get("activeTab");
  const { intPkIspID } = useParams();
  const navigate = useNavigate();
  //getISPByID
  const [tabs, setTabs] = useState<NetwalaTabItemsProp[]>([
    {
      text: "Profile",
      isActive: true,
      id: 1,
    },

    {
      text: "Packages",
      isActive: false,
      id: 2,
    },
    {
      text: "Vlans",
      isActive: false,
      id: 3,
    },
    {
      text: "Import Users",
      isActive: false,
      id: 4,
    },
  ]);
  const [activeTab, setActiveTab] = useState<number>(Number(_activeTab));
  const handleTabSwitch = (tabId: number) => {
    navigate(`/view-isp/${intPkIspID}?activeTab=${tabId}`);
  };
  useEffect(() => {
    setActiveTab(Number(_activeTab));
    const updatedItems = tabs.map((item) => ({
      ...item,
      isActive: item.id === Number(_activeTab),
    }));
    setTabs(updatedItems);
  }, [activeTab, _activeTab]);
  const showActiveComponent = (activeTab: number) => {
    switch (activeTab) {
      case 1:
        return <SingleISPProfileComponent intPkIspID={Number(intPkIspID)} />;
      case 2:
        return <PackagesByIspComponent intPkIspID={Number(intPkIspID)} />;
      case 3:
        return <VlansByIspComponent intPkIspID={Number(intPkIspID)} />;
      case 4:
        return <ISPBulkUserImport intPkIspID={Number(intPkIspID)} />;
    }
    return <></>;
  };

  const handleButtonClick = () => {
    if (activeTab === 2) {
      //intPkHeadID
      navigate(`/isps/packages/create-package/${intPkIspID}`);
    } else if (activeTab === 3) {
      navigate(`/isps/vlans/create-vlan/${intPkIspID}`);
    }
  };
  const getButtonTitle = () => {
    if (activeTab === 2) {
      return "Create Package";
    }
    if (activeTab === 3) {
      return "Create Vlan";
    }
    return "";
  };
  return (
    <>
      <Row gutter={[16, 16]}>
        {getUserType() === "CEO" && (activeTab === 2 || activeTab === 3) ? (
          <Col
            span={24}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "50px",
              marginBottom: "20px",
            }}
          >
            <NetwalaButton
              htmlType="submit"
              backgroundColor={ButtonProps.background.STANDARD}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.STANDARD}
              textColor={ButtonProps.color.STANDARD}
              onClick={() => {
                handleButtonClick();
              }}
              text={getButtonTitle()}
              icon={<img src={PlusIcon} />}
            />
          </Col>
        ) : null}
      </Row>
      <Row gutter={16}>
        <NetwalaTabs
          colSpan={6}
          tabs={tabs}
          handleTabSwitch={handleTabSwitch}
        />
      </Row>
      <Row gutter={[16, 16]}>{showActiveComponent(activeTab)}</Row>
    </>
  );
};
export default SingleIspDetailsComponent;
