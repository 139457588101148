import React, { useEffect, useState } from "react";
import NetwalaModal from "../Items/Modal/Index";
import { Col, Row, Typography } from "antd";
import "./styles/RequestIPTVModal.css";
import "./styles/confirmBankDepositModal.css";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import { formatDate } from "../../helpers/date";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import useMultiNotification from "../../hooks/useNotification";
import useAxiosPost from "../../hooks/useAxiosPost";
const BankDepositRequestConfirmationModal: React.FC<
  BankDepositRequestConfirmationModalProp
> = ({ show, handleClose, intPkBankDepositRequestID, request }) => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ViewEncryptedImageApiProp>(
    "cloudStorage/decryptionServiceApi"
  );
  useEffect(() => {
    postApiData({ intPkResourceFileID: request.bankDepositSlipID });
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      console.log(data);
      setImageUrl(data);
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", apiError);
    }
  }, [apiError, apiErrorCount]);

  return (
    <>
      <NetwalaModal
        title="Confirm Payment"
        headerStyle={{
          backgroundColor: "#292D8E",
          color: "#FFFFFF",
          fontSize: "16px",
          fontWeight: "400",
          height: "50px",
        }}
        open={show}
        onCancel={() => {
          handleClose(false);
        }}
      >
        <Row>
          <Col
            span={24}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              gap: "20px",
              alignItems: "center",
              paddingLeft: "20px",
              paddingBottom: "30px",
            }}
          >
            <div className="description-container">
              <Typography className="description-label">Description</Typography>
              <Typography className="description-value">
                {request.comments}
              </Typography>
            </div>
            <Row className="confirmation-connection-header-blue">
              <Col offset={2} span={10}>
                <Typography className="confirmation-connection-label">
                  Bank Details
                </Typography>
              </Col>
            </Row>

            <Row className="confirmation-connection-header-values">
              <Col offset={2} span={10}>
                <Typography className="bank-deposit-request-label">
                  Bank Name
                </Typography>
              </Col>
              <Col offset={4} span={8}>
                <Typography className="bank-deposit-request-value">
                  {request.bankName}
                </Typography>
              </Col>
            </Row>
            <Row className="confirmation-connection-header-values">
              <Col offset={2} span={10}>
                <Typography className="bank-deposit-request-label">
                  Branch
                </Typography>
              </Col>
              <Col offset={4} span={8}>
                <Typography className="bank-deposit-request-value">
                  {request.address}
                </Typography>
              </Col>
            </Row>
            <Row className="confirmation-connection-header-values">
              <Col offset={2} span={10}>
                <Typography className="bank-deposit-request-label">
                  Account Number
                </Typography>
              </Col>
              <Col offset={4} span={8}>
                <Typography className="bank-deposit-request-value">
                  {request.accountNumber}
                </Typography>
              </Col>
            </Row>

            <Row className="confirmation-connection-header-blue">
              <Col offset={2} span={10}>
                <Typography className="confirmation-connection-label">
                  Payment Details
                </Typography>
              </Col>
            </Row>

            <Row className="confirmation-connection-header-values">
              <Col offset={2} span={10}>
                <Typography className="bank-deposit-request-label">
                  Amount Submitted
                </Typography>
              </Col>
              <Col offset={4} span={8}>
                <Typography className="bank-deposit-request-value">
                  {request.amount}/-
                </Typography>
              </Col>
            </Row>

            <Row className="confirmation-connection-header-values">
              <Col offset={2} span={10}>
                <Typography className="bank-deposit-request-label">
                  Amount Submitted
                </Typography>
              </Col>
              <Col offset={4} span={8}>
                <Typography className="bank-deposit-request-value">
                  {request.amount}/-
                </Typography>
              </Col>
            </Row>

            <Row className="confirmation-connection-header-values">
              <Col offset={2} span={10}>
                <Typography className="bank-deposit-request-label">
                  Entry Time
                </Typography>
              </Col>
              <Col offset={4} span={8}>
                <Typography className="transaction-date-value">
                  {formatDate(new Date(request.dateCreated))}
                </Typography>
              </Col>
            </Row>

            {/* <div className="queue-confirmation-message-container">
            <Typography className="queue-confirmation-message">
              Did you received the payment in your account?
            </Typography>
          </div> */}
            <Row>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  maxHeight: "200px",
                }}
                span={24}
              >
                {apiLoading === true ? (
                  <TailSpinLoader />
                ) : (
                  imageUrl !== null && (
                    <img
                      style={{
                        maxWidth: "80%",
                        margin: "20px",
                        border: "1px solid #E2DFFF",
                      }}
                      src={`data:image/jpeg;base64,${imageUrl}`}
                    />
                  )
                )}
              </Col>
            </Row>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                paddingBottom: "30px",
                width: "100%",
                paddingTop: "50px",
              }}
            >
              <NetwalaButton
                backgroundColor={ButtonProps.background.DANGER}
                height={ButtonProps.height.STANDARD}
                width={ButtonProps.width.ONE24}
                textColor={ButtonProps.color.STANDARD}
                onClick={() => handleClose(true)}
                text="Approve"
                borderColor="#DF3131"
              />
              <NetwalaButton
                backgroundColor={ButtonProps.background.SECONDARY}
                height={ButtonProps.height.STANDARD}
                width={ButtonProps.width.ONE24}
                textColor={ButtonProps.color.BLACK}
                onClick={() => handleClose(false)}
                text="Cancel"
                borderColor="#D7D7D7"
              />
            </div>
          </Col>
        </Row>
      </NetwalaModal>
    </>
  );
};

export default BankDepositRequestConfirmationModal;
