import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";

import "./index.css";
import SingleTransactionCard from "./cards/TransactionCard";
import useAxiosPost from "../../../hooks/useAxiosPost";
import { useParams } from "react-router-dom";
import NetwalaPagination from "../../Items/Pagination/NetwalaPagination";
import TailSpinLoader from "../../Items/Loaders/TailSpinLoader";
import NetwalaSelect from "../../Items/Select/Index";
const UserTransactionsComponent = () => {
  const [getConnectionsApi, connectionsApiResponse] =
    useAxiosPost<GetUserProfileApiProp>(
      "connections/getConnectionsByUserIdWithMiniDetails"
    );
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<GetUserTransactionsByIdApiProp>(
    "transactions/getMyUserTransactions"
  );
  const [transactions, setTransactions] = useState<TransactionHeadDetails[]>(
    []
  );
  const [selectedConnection, setSelectedConnection] = useState<string>("");
  const [connections, setConnections] = useState<NetwalaSelectOptions[]>([
    {
      label: "All",
      value: "",
    },
  ]);
  const [staff, setStaffProfile] = useState<StaffListType | null>(null);
  const [getStaffProfile, staffProfileApiResponse] =
    useAxiosPost<ResponseProp>("auth/getMyProfile");
  useEffect(() => {
    if (staffProfileApiResponse !== null) {
      const data: any = staffProfileApiResponse.responseData;
      setStaffProfile(data);
    }
  }, [staffProfileApiResponse]);

  const [pagination, setPagination] = useState<PaginationProp>({
    totalPages: 0,
    currentPage: 1,
    previousPage: null,
    nextPage: null,
    totalCount: 0,
    pageSize: 50,
  });

  const { intPkHeadID } = useParams();
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      const {
        currentPage,
        nextPage,
        pageSize,
        previousPage,
        totalCount,
        totalPages,
        transactions,
      } = data;
      setPagination({
        currentPage,
        totalPages,
        nextPage,
        previousPage,
        totalCount,
        pageSize,
      });
      setTransactions(transactions || []);
    }
  }, [apiResponse]);

  useEffect(() => {
    if (connectionsApiResponse !== null) {
      const data: any = connectionsApiResponse.responseData;
      const connections: any = [
        {
          label: "All",
          value: "",
        },
      ];
      data.map((item: any) => {
        if (item.connectionType === "INTERNET") {
          connections.push({
            label: item.username,
            value: item.intPkConnectionID,
          });
        } else if (item.connectionType === "CATV") {
          connections.push({
            label: `${item.firstName} ${item.lastName} - ${item.address}`,
            value: item.intPkConnectionID,
          });
        }
      });
      setConnections(connections);
    }
  }, [connectionsApiResponse]);
  useEffect(() => {
    postApiData({ intPkHeadID: Number(intPkHeadID) });
    getConnectionsApi({ userId: Number(intPkHeadID) });
    getStaffProfile({});
  }, []);
  const handlePageSwitch = (pageNumber: number) => {
    postApiData({
      intPkHeadID: Number(intPkHeadID),
      page: pageNumber,
      intPkConnectionID: selectedConnection ? Number(selectedConnection) : null,
    });
  };
  useEffect(() => {
    if (selectedConnection === "") {
      postApiData({
        intPkHeadID: Number(intPkHeadID),
        page: 1,
        intPkConnectionID: null,
      });
    } else {
      postApiData({
        intPkHeadID: Number(intPkHeadID),
        page: 1,
        intPkConnectionID: Number(selectedConnection),
      });
    }
  }, [selectedConnection]);
  const reloadAPi = () => {
    if (selectedConnection === "") {
      postApiData({
        intPkHeadID: Number(intPkHeadID),
        page: pagination.currentPage,
        intPkConnectionID: null,
      });
    } else {
      postApiData({
        intPkHeadID: Number(intPkHeadID),
        page: pagination.currentPage,
        intPkConnectionID: Number(selectedConnection),
      });
    }
  };
  return (
    <>
      {/* <Row
        gutter={[32, 32]}
        style={{ width: "100%", marginBottom: "20px", marginTop: "20px" }}
      >
        <Col offset={18} span={6}>
          <span className="create-user-form-label">Choose Connection</span>
          <NetwalaSelect
            size="large"
            height="45px"
            options={connections}
            showSearch={true}
            value={selectedConnection}
            placeHolder="Choose Connection"
            onChange={(e) => setSelectedConnection(e)}
          />
        </Col>
      </Row> */}
      {apiLoading === true ? (
        <TailSpinLoader />
      ) : (
        <>
          <Row gutter={[16, 16]} className="end-user-profile-container">
            {transactions.map((item) => {
              return (
                <Col key={Math.random()} span={24}>
                  <SingleTransactionCard
                    key={Math.random()}
                    transaction={item}
                    staffHeadId={staff?.intPkHeadID || -1}
                    reloadApi={reloadAPi}
                  />
                </Col>
              );
            })}
          </Row>
          <Row style={{ width: "100%" }}>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
                marginBottom: "20px",
              }}
              span={24}
            >
              <NetwalaPagination
                pagination={pagination}
                handlePageSwitch={handlePageSwitch}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
export default React.memo(UserTransactionsComponent);
