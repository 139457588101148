import { Col, Form, Input, Row, Upload, Button, message } from "antd";
import "./index.css";
import NetwalaSelect from "../Items/Select/Index";
import { useEffect, useState } from "react";
import { CameraOutlined, UploadOutlined } from "@ant-design/icons";
const { TextArea } = Input;
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import PlusIcon from "./../../assets/svgs/IconsWhite/PlusIcon.svg";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
} from "antd/lib/upload/interface";
import useAxiosPost, {
  authTokenString,
  baseURL,
} from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import { useNavigate } from "react-router-dom";

const BankDepositRequestFrom = () => {
  const navigate = useNavigate();

  const { openNotification, contextHolder } = useMultiNotification();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [form] = Form.useForm();
  const [
    getAreasApi,
    areasApiResponse,
    areasApiError,
    areasApiLoading,
    areasApiMessage,
    areasApiErrorCount,
  ] = useAxiosPost<ResponseProp>("banks/getAllBanks");
  const [
    getSubAreasApi,
    subAreasApiResponse,
    subAreasApiError,
    subAreasApiLoading,
    subAreasApiMessage,
    subAreasApiErrorCount,
  ] = useAxiosPost<BankAccountsByBankApiProps>("banks/getBankAccountByBank");

  const onFinish = (values: any) => {
    if (fileList.length !== 1) {
      openNotification("error", "Error", "Invalid Receipt Uploaded");
      return;
    }
    handleCreateBankDepositRequest(values);
  };
  const [selectedBank, setSelectedBank] = useState("");
  const [selectedAccount, setSelectedAccount] = useState<null | string>(null);
  const [banks, setBanks] = useState<NetwalaSelectOptions[]>([]);
  const [bankAccounts, setBankAccounts] = useState<NetwalaSelectOptions[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleRemove = (file: UploadFile) => {
    setFileList((prevList) => prevList.filter((item) => item.uid !== file.uid));
  };

  const handleBeforeUpload = (file: RcFile, fileList: RcFile[]) => {
    setFileList(
      fileList.map((file) => ({
        uid: file.uid,
        name: file.name,
        status: "done",
      }))
    );
    // You can add your upload logic here if needed
    return false; // Return false here to avoid auto uploading
  };

  const handleChange = (info: UploadChangeParam) => {
    setFileList(info.fileList);
  };

  useEffect(() => {
    getAreasApi({});
  }, []);
  useEffect(() => {
    if (areasApiResponse !== null) {
      const data: any = areasApiResponse.responseData;
      const _banks = data.map((item: any): NetwalaSelectOptions => {
        return {
          value: item.intPkBankID,
          label: item.bankName,
        };
      });

      setBanks(_banks);
    }
  }, [areasApiResponse]);
  useEffect(() => {
    if (subAreasApiResponse !== null) {
      const data: any = subAreasApiResponse.responseData;
      const _bankAccounts = data.map((item: any): NetwalaSelectOptions => {
        return {
          value: item.intPkBankAccountID,
          label: item.accountNumber,
        };
      });

      setBankAccounts(_bankAccounts);
    }
  }, [subAreasApiResponse]);

  const onFinishFailed = (errorInfo: any) => {};
  const handleBankChange = (value: string) => {
    setSelectedAccount(null);
    form.setFieldsValue({ intPkBankAccountID: undefined });

    getSubAreasApi({ intPkBankID: Number(value) });
  };

  const handleCreateBankDepositRequest = async (values: any) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("authTokenString", authTokenString.toString());
    formData.append("intPkBankAccountID", values.intPkBankAccountID);
    formData.append("amount", values.amount);
    formData.append("comments", values.comments);
    if (fileList.length > 0) {
      formData.append("transactionSlip", fileList[0].originFileObj as Blob);
    }
    try {
      const response = await fetch(
        `${baseURL}/transactions/bankDepositByCashier`,
        {
          method: "POST",
          body: formData,
          // Note: When using FormData, do NOT set the Content-Type header
          // The browser will set it automatically, including the boundary parameter
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        openNotification(
          "success",
          "Success",
          responseData?.message || "New Bank Deposit Request Created"
        );
        setTimeout(() => {
          navigate(`/bank-deposit-requests`);
        }, 3000);
      } else {
        const responseData = await response.json();
        setIsLoading(false);

        openNotification(
          "error",
          "Operation Failed",
          responseData?.message || "Server Error"
        );
        console.error("Upload failed:", responseData);
      }
    } catch (error) {
      setIsLoading(false);

      console.error("Error during fetch:", error);
    }
  };
  return (
    <>
      {contextHolder}

      <Row style={{ width: "100%" }}>
        <Form
          name="createDepositRequestForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          requiredMark={false}
          style={{ width: "100%" }}
          form={form}
        >
          {/* First */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">Amount</span>}
                name="amount"
                rules={[
                  {
                    required: true,
                    message: "Please enter Amount",
                  },
                  {
                    validator: (_, value) =>
                      value >= 0
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("Amount Must be greater than 0")
                          ),
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="100"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Choose Bank</span>
                }
                name="intPkBankID"
                rules={[
                  {
                    required: true,
                    message: "Please Select Bank",
                  },
                ]}
              >
                <NetwalaSelect
                  value={selectedBank}
                  onChange={handleBankChange}
                  size="large"
                  height="45px"
                  options={banks}
                />
              </Form.Item>
            </Col>
          </Row>
          {/* Second */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Bank Account</span>
                }
                name="intPkBankAccountID"
                rules={[
                  {
                    required: true,
                    message: "Please Choose Bank Account",
                  },
                ]}
              >
                <NetwalaSelect
                  value={selectedAccount}
                  size="large"
                  height="45px"
                  options={bankAccounts}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">Comments</span>}
                name="comments"
                rules={[
                  {
                    required: true,
                    message: "Please Choose Enter Comments",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="Deposited on march 20 etc."
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Third */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Upload Receipt</span>
                }
                name="idCardFront"
              >
                <Upload
                  onRemove={handleRemove}
                  beforeUpload={handleBeforeUpload}
                  onChange={handleChange}
                  fileList={fileList}
                >
                  <Button icon={<CameraOutlined />}>
                    Choose Bank Deposit Slip
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          {/* Final */}

          <Row>
            <Col
              style={{ display: "flex", justifyContent: "center" }}
              span={12}
              offset={6}
            >
              <Form.Item>
                <NetwalaButton
                  htmlType="submit"
                  backgroundColor={ButtonProps.background.STANDARD}
                  height={ButtonProps.height.STANDARD}
                  width={ButtonProps.width.STANDARD}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {
                    console.log("clicked");
                  }}
                  text="Create Deposit Request"
                  icon={<img src={PlusIcon} />}
                  disabled={isLoading}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Row>
    </>
  );
};
export default BankDepositRequestFrom;
