import React from "react";
import "./styles.css";

const SpeechToTextConverterPrivacyPolicyPage = () => {
  return (
    <div className="policy-page">
      <header className="privacy-policy-main-heading">
        <h1>Privacy Policy for Speech to text converter App</h1>
      </header>
      <section className="policy-section">
        <h2>Introduction</h2>
        <p>
          This Privacy Policy describes how we collects, uses, and discloses
          your information in connection with your use of our "Speech to text
          converter - tts" application.
        </p>
      </section>
      <section className="policy-section">
        <h2>Information We Collect</h2>
        <p>
          For a better experience, while using our Service, I may require you to
          provide us with certain personally identifiable information. The
          information that I request will be retained on your device and is not
          collected by me in any way.
        </p>
        <p>
          The app does use third-party services that may collect information
          used to identify you.
        </p>
        <p>
          Link to the privacy policy of third-party service providers used by
          the app
        </p>
        <p>Google Play Services</p>
        <p>Google Analytics for Firebase</p>
        <p>Firebase Crashlytics</p>
      </section>
      <section className="policy-section">
        <h2>How We Use Your Information</h2>
        <p>We use the information we collect for the following purposes:</p>
        <p>
          To provide and improve the App: We use your information to operate and
          maintain the App, provide customer support, and improve the overall
          user experience.
        </p>
        <p>
          To personalize your experience: We may use your non-personal
          information to personalize your experience with the App, such as by
          tailoring the content you see to your interests.
        </p>
        <p>
          To comply with legal obligations: We may use your information to
          comply with applicable laws and regulations.
        </p>
      </section>

      <section className="policy-section">
        <h2>Data Storage and Security</h2>
        <p>
          We take reasonable steps to protect the information you provide to us
          from unauthorized access, disclosure, alteration, or destruction.
          However, no security measures are perfect, and we cannot guarantee the
          absolute security of your information.
        </p>
      </section>

      <section className="policy-section">
        <h2>Children's Privacy</h2>
        <p>
          Our App is not directed to children under the age of 13. We do not
          knowingly collect personal information from children under 13. If you
          are a parent or guardian and you believe your child has provided us
          with personal information, please contact us.
        </p>
      </section>

      <section className="policy-section">
        <h2>Changes to This Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on the App.
        </p>
      </section>
      <section className="policy-section">
        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Policy, please contact us at
          info@ttvoicetranslate.com
        </p>
      </section>
      <footer className="privacy-policy-footer">
        <p>Last Updated: March 1, 2024</p>
      </footer>
    </div>
  );
};

export default SpeechToTextConverterPrivacyPolicyPage;
