import { useEffect, useRef, useState } from "react";
import { Row, Col } from "antd";

import "./index.css";
import useAxiosPost from "../../../../hooks/useAxiosPost";
import TailSpinLoader from "../../../Items/Loaders/TailSpinLoader";
import { Spin } from "antd";
import NetwalaButton from "../../../Items/Button/Index";
import { ButtonProps } from "../../../../helpers/constants";
import { useNavigate, useParams } from "react-router-dom";
import SyncNasProfilesComponent from "./syncing/SyncNasProfilesComponent";
import useMultiNotification from "../../../../hooks/useNotification";
import SyncNASSecretsComponent from "./syncing/SyncNASSecretsComponent";

const SyncMikroNocIspComponent = () => {
  const secretsResponseRef = useRef<any>(null);
  const profilesResponseRef = useRef<any>(null);
  const { openNotification, contextHolder } = useMultiNotification();

  const { intPkIspID } = useParams();
  const navigate = useNavigate();
  const [isp, setIsp] = useState<ISP | null>(null);
  const [secrets, setSecrets] = useState<MikroNocSecret[]>([]);
  const [profiles, setProfiles] = useState<MikroNocProfile[]>([]);
  const [isPackagesMapped, setIsPackagesMapped] = useState<boolean>(false);
  const [isSecretsLoaded, setIsSecretsLoaded] = useState<boolean>(false);
  const [packages, setPackages] = useState<NetwalaSelectOptions[]>([]);
  const [allPackages, setAllPackages] = useState<any[]>([]);

  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<GetIspDetailsByIdApiProp>("isps/getISPByID");
  const [
    getPackagesApi,
    packagesApiResponse,
    packagesApiError,
    packagesApiLoading,
    packagesApiMessage,
    packagesApiErrorCount,
  ] = useAxiosPost<PackagesAndVlansByISPApiProp>("packages/getPackagesByISP");
  const [
    postCreatePackageThroughSyncApi,
    createPackageThroughSyncApiResponse,
    createPackageThroughSyncApiError,
    createPackageThroughSyncApiLoading,
    createPackageThroughSyncApiMessage,
    createPackageThroughSyncApiErrorCount,
  ] = useAxiosPost<CreatePackageThroughNasSyncApiProp>(
    "packages/createPackagesFromSync"
  );

  const [
    postPackagesSyncRequest,
    syncPackagesRequestResponse,
    syncPackagesRequestError,
    syncPackagesRequestLoading,
    syncPackagesRequestMessage,
    syncPackagesRequestErrorCount,
  ] = useAxiosPost<GetIspDetailsByIdApiProp>(
    "mikroNoc/requests/fetchPackagesFromMikrotikRequest"
  );

  const [
    getPackagesSyncResponseApi,
    syncPackagesResponse,
    syncPackagesResponseApiError,
    syncPackagesResponseApiLoading,
    syncPackagesResponseApiMessage,
    syncPackagesResponseApiErrorCount,
  ] = useAxiosPost<GetIspDetailsByIdApiProp>(
    "mikroNoc/responses/fetchPackagesFromMikrotikResPonse"
  );

  const [
    postSecretsSyncRequest,
    syncSecretsRequestResponse,
    syncSecretsRequestError,
    syncSecretsRequestLoading,
    syncSecretsRequestMessage,
    syncSecretsRequestErrorCount,
  ] = useAxiosPost<GetIspDetailsByIdApiProp>(
    "mikroNoc/requests/fetchUsersFromMikrotikRequest"
  );

  // const [
  //   getSecretsSyncResponseApi,
  //   syncSecretsResponse,
  //   syncSecretsResponseApiError,
  //   syncSecretsResponseApiLoading,
  //   syncSecretsResponseApiMessage,
  //   syncSecretsResponseApiErrorCount,
  // ] = useAxiosPost<GetIspDetailsByIdApiProp>(
  //   "mikroNoc/responses/fetchUsersFromMikrotikResPonse"
  // );

  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setIsp(data);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({ intPkIspID: Number(intPkIspID) });
  }, []);
  // ! SECRETS START
  // useEffect(() => {
  //   if (syncSecretsRequestResponse !== null) {
  //
  //     const data: any = syncSecretsRequestResponse.responseData;
  //     if (data !== null) {
  //       getSecretsSyncResponseApi({ intPkIspID: Number(intPkIspID) });
  //       startSecretsInterval();
  //     }
  //   }
  // }, [syncSecretsRequestResponse]);

  // const startSecretsInterval = () => {
  //   secretsResponseRef.current = setInterval(() => {
  //     getSecretsSyncResponseApi({ intPkIspID: Number(intPkIspID) });
  //   }, 3000);
  //   setTimeout(() => {
  //     clearInterval(secretsResponseRef.current);
  //   }, 30000);
  // };

  // useEffect(() => {
  //   if (syncSecretsResponse !== null) {
  //
  //     const responseData: any = syncSecretsResponse.responseData;
  //     const { data, isDataLoaded } = responseData;
  //     if (isDataLoaded) {
  //       setIsSecretsLoaded(true);
  //       if (data.length > 0) {
  //         clearInterval(secretsResponseRef.current);
  //         console.log({ data });
  //         const _data = data.map((item: any) => {
  //           return {
  //             ...item,
  //             intPkPackageID: null,
  //             firstName: "",
  //             lastName: "",
  //             phoneNumber: "",
  //           };
  //         });
  //         setSecrets(_data || []);
  //       }
  //     }
  //   }
  // }, [syncSecretsResponse]);
  // const handleCreateNewConnectionsFromSecrets = (
  //   secrets: MikroNocSecret[]
  // ) => {};
  //  ! SECRETS END

  //** * Packages START

  useEffect(() => {
    if (syncPackagesRequestResponse !== null) {
      const data: any = syncPackagesRequestResponse.responseData;
      if (data !== null) {
        getPackagesSyncResponseApi({ intPkIspID: Number(intPkIspID) });
        startPackagesInterval();
      }
    }
  }, [syncPackagesRequestResponse]);
  const startPackagesInterval = () => {
    profilesResponseRef.current = setInterval(() => {
      getPackagesSyncResponseApi({ intPkIspID: Number(intPkIspID) });
    }, 3000);
    setTimeout(() => {
      clearInterval(profilesResponseRef.current);
    }, 30000);
  };
  useEffect(() => {
    if (syncPackagesResponse !== null) {
      const responseData: any = syncPackagesResponse.responseData;
      const { data, isDataLoaded } = responseData;
      console.log({ data, isDataLoaded });
      if (isDataLoaded) {
        clearInterval(profilesResponseRef.current);
        if (data.length > 0) {
          const _data = data.map((item: any) => {
            return {
              ...item,
              price: 0,
            };
          });
          setProfiles(_data || []);
        } else {
          setIsPackagesMapped(true);
          // getPackagesApi({ ispId: Number(intPkIspID) });
          postSecretsSyncRequest({ intPkIspID: Number(intPkIspID) });
        }
      }
    }
  }, [syncPackagesResponse]);
  const handleUpdateProfilesWithPrices = (_profiles: MikroNocProfile[]) => {
    const packages = _profiles.map((item) => {
      return {
        packageName: item.profileName,
        price: item.price,
        nasPackageID: item.id,
      };
    });
    const dataToSend = {
      packages,
      intPkIspID: Number(intPkIspID),
    };
    postCreatePackageThroughSyncApi(dataToSend);

    setProfiles(_profiles);
  };

  useEffect(() => {
    if (createPackageThroughSyncApiResponse !== null) {
      const { message } = createPackageThroughSyncApiResponse;
      if (message) {
        openNotification(`success`, "Success", message);
        //lets do the rest here
        setIsPackagesMapped(true);
        postSecretsSyncRequest({ intPkIspID: Number(intPkIspID) });

        // getPackagesApi({ ispId: Number(intPkIspID) });
      }
    }
  }, [createPackageThroughSyncApiResponse]);

  useEffect(() => {
    if (
      createPackageThroughSyncApiError !== null &&
      createPackageThroughSyncApiErrorCount !== 0
    ) {
      openNotification(
        `error`,
        "Operation Failed",
        createPackageThroughSyncApiError
      );
    }
  }, [createPackageThroughSyncApiError, createPackageThroughSyncApiErrorCount]);

  useEffect(() => {
    if (
      syncPackagesRequestError !== null &&
      syncPackagesRequestErrorCount !== 0
    ) {
      openNotification(`error`, "Operation Failed", syncPackagesRequestError);
    }
  }, [syncPackagesRequestError, syncPackagesRequestErrorCount]);

  useEffect(() => {
    if (packagesApiResponse !== null) {
      const data: any = packagesApiResponse.responseData;
      const packages = data.map((item: any): NetwalaSelectOptions => {
        return {
          value: item.intPkPackageID,
          label: item.packageName,
        };
      });
      setAllPackages(data || []);
      setPackages(packages);
    }
  }, [packagesApiResponse]);

  //** * PACKAGES END

  // loader
  if (apiLoading) {
    return (
      <Row style={{ width: "100%" }}>
        <Col span={24}>
          <TailSpinLoader />
        </Col>
      </Row>
    );
  }

  return (
    <>
      {contextHolder}
      <Row gutter={[16, 16]} style={{ width: "100%" }}>
        {isp && isp.ispTypeEnum === "MIKRO_NOC" && (
          <>
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "30px",
                marginBottom: "30px",
              }}
              span={24}
            >
              <NetwalaButton
                htmlType="button"
                backgroundColor={ButtonProps.background.STANDARD}
                height={ButtonProps.height.STANDARD}
                width={ButtonProps.width.STANDARD}
                textColor={ButtonProps.color.STANDARD}
                onClick={() => {
                  postPackagesSyncRequest({ intPkIspID: Number(intPkIspID) });
                  // postSecretsSyncRequest({
                  //   intPkIspID: Number(intPkIspID),
                  // });
                }}
                text="Sync Now"
                disabled={
                  syncSecretsRequestLoading ||
                  // syncSecretsResponseApiLoading ||
                  syncPackagesRequestLoading ||
                  syncPackagesResponseApiLoading
                }
              />
            </Col>
            {profiles.length > 0 && isPackagesMapped === false && (
              <Col span={24}>
                <SyncNasProfilesComponent
                  profiles={profiles}
                  handleUpdateProfilesWithPrices={
                    handleUpdateProfilesWithPrices
                  }
                  isLoading={createPackageThroughSyncApiLoading}
                />
              </Col>
            )}
            {/* {isPackagesMapped === true && isSecretsLoaded === true && (
              <Col span={24}>
                <SyncNASSecretsComponent
                  secrets={secrets}
                  isLoading={apiLoading}
                  handleCreateNewConnectionsFromSecrets={
                    handleCreateNewConnectionsFromSecrets
                  }
                  packages={packages}
                />
              </Col>
            )} */}
          </>
        )}
      </Row>
    </>
  );
};
export default SyncMikroNocIspComponent;
