import React from "react";
import "./styles.css";

const DocumentViewerPrivacyPolicyPage = () => {
  return (
    <div className="policy-page">
      <header className="privacy-policy-main-heading">
        <h1>Privacy Policy for Document Viewer - DOC, PDF, ZIP App</h1>
      </header>
      <section className="policy-section">
        <h2>Introduction</h2>
        <p>
          This Privacy Policy describes we collects, uses, and discloses your
          information in connection with your use of our document viewer
          application.
        </p>
      </section>
      <section className="policy-section">
        <h2>Information We Collect</h2>
        <p>We collect two main types of information through the App:</p>
        <p>
          Device Information: This includes basic information about your device,
          such as its model, operating system type and version, unique
          identifiers, and language. This information helps us understand how
          you interact with the App and improve its performance.
        </p>
        <p>
          File Information: The App requires access to your device's storage to
          allow you to open and view documents. We do not access or store the
          actual content of the documents you view.
        </p>
      </section>
      <section className="policy-section">
        <h2>How We Use Your Information</h2>
        <p>To process your requests for payment recovery.</p>
        <p>To communicate with you about your use of the App.</p>
      </section>

      <section className="policy-section">
        <h2>Security</h2>
        <p>
          We take reasonable steps to protect the information we collect from
          loss, misuse, and unauthorized access.
        </p>
        <p>
          We do not access or store the actual content of the documents you view
          through the App.
        </p>
      </section>

      <section className="policy-section">
        <h2>Children's Privacy</h2>
        <p>
          Our App is not directed to children under the age of 13. We do not
          knowingly collect personal information from children under 13. If you
          are a parent or guardian and you believe your child has provided us
          with personal information, please contact us.
        </p>
      </section>

      <section className="policy-section">
        <h2>Changes to This Policy</h2>
        <p>
          We may update this Policy from time to time. We will notify you of any
          changes by posting the new Policy on the App. You are advised to
          review this Policy periodically for any changes.
        </p>
      </section>
      <section className="policy-section">
        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Policy, please contact us at
          info@documentreaderpdfreader.com
        </p>
      </section>
      <footer className="privacy-policy-footer">
        <p>Last Updated: March 1, 2024</p>
      </footer>
    </div>
  );
};

export default DocumentViewerPrivacyPolicyPage;
