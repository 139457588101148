import HomeIcon from "./assets/svgs/MenuIcons/dashboard.svg";
import UsersIcon from "./assets/svgs/MenuIcons/UsersIconWhite.svg";
import StaffIcon from "./assets/svgs/MenuIcons/StaffIcon.svg";
import IspsIcon from "./assets/svgs/MenuIcons/ISPsIcon.svg";
import InventoryIcon from "./assets/svgs/MenuIcons/InventoryIcon.svg";
import LocationIcon from "./assets/svgs/MenuIcons/LocationIcon.svg";
import ActionQueueIcon from "./assets/svgs/MenuIcons/ActionQueue.svg";
import ExpenseIcon from "./assets/svgs/MenuIcons/ExpenseIcon.svg";
import BankIcon from "./assets/svgs/MenuIcons/BankIcon.svg";
import DepositRequestIcon from "./assets/svgs/MenuIcons/DepositRequestIcon.svg";
import PromiseMenuIconWhite from "./assets/svgs/MenuIcons/PromiseMenuIconWhite.svg";
import DiscountVouchersIconWhite from "./assets/svgs/MenuIcons/DiscountVouchersIconWhite.svg";

//Active
import HomeBlue from "./assets/svgs/MenuIcons/dashboardBlue.svg";
import UsersIconBlue from "./assets/svgs/MenuIcons/UsersIconBlue.svg";
import DiscountVouchersIconBlue from "./assets/svgs/MenuIcons/DiscountVouchersIconBlue.svg";
import StaffBlue from "./assets/svgs/MenuIcons/StaffBlue.svg";
import IspsBLue from "./assets/svgs/MenuIcons/IspsBlue.svg";
import LocationBlueIcon from "./assets/svgs/MenuIcons/LocationIconBlue.svg";
import ActionQueueIconBlue from "./assets/svgs/MenuIcons/ActionQueueBlue.svg";
import ExpenseIconBlue from "./assets/svgs/MenuIcons/ExpenseIconBlue.svg";
import InventoryIconBlue from "./assets/svgs/MenuIcons/InventoryIconBlue.svg";
import BankIconBlue from "./assets/svgs/MenuIcons/BankIconBlue.svg";
import DepositRequestIconBlue from "./assets/svgs/MenuIcons/DepositRequestIconBlue.svg";
import PromiseMenuIconBlue from "./assets/svgs/MenuIcons/PromiseMenuIconBlue.svg";

import ConnectionDetailsPage from "./pages/ConnectionDetailsPage";
import CreateCATVPage from "./pages/CreateCATVPage";
import CreateInternetPage from "./pages/CreateInternetPage";
import CreateStaffPage from "./pages/CreateStaffPage";
import CreateNewUserPage from "./pages/CreateUserPage";

import HomePage from "./pages/HomePage";
import StaffProfilePage from "./pages/StaffProfilePage";
import TransactionDetailsPage from "./pages/TransactionDetailsPage";
import UserDetailsPage from "./pages/UserDetailsPage";
import ViewStaffPage from "./pages/ViewStaffPage";
import ViewISPsPage from "./pages/ViewISPsPage";
import CreateISPPage from "./pages/CreateISPPage";
import IspProfilePage from "./pages/IspProfilePage";
import CreatePackagePage from "./pages/CreatePackagePage";
import CreateVlanPage from "./pages/CreateVlanPage";
import AreasPage from "./pages/AreasPage";
import SubAreasPage from "./pages/SubAreasPage";
import { getUserType } from "./helpers/userDetails";
import MyProfilePage from "./pages/MyProfilePage";
import UpdateProfilePage from "./pages/UpdateProfilePage";
import UpdatePasswordPage from "./pages/UpdatePasswordPage";
import ActionQueuePage from "./pages/ActionQueuePage";
import InventoryItemsPage from "./pages/InventoryItemsPage";
import ViewInventoryModelStockPage from "./pages/ViewInventoryModelStockPage";
import InventoryItemDetailsPage from "./pages/InventoryItemDetailsPage";
import SinglePackagePage from "./pages/SinglePackagePage";
import UpdatePackagePage from "./pages/UpdatePackagePage";
import UpdateStaffPage from "./pages/UpdateStaffPage";
import AddExpensePage from "./pages/AddExpensePage";
import ViewBankAccountsPage from "./pages/ViewBankAccountsPage";
import CreateBankAccountPage from "./pages/CreateBankAccountPage";
import ViewBankAccountProfilePage from "./pages/ViewBankAccountProfilePage";
import CreateBankDepositRequestPage from "./pages/CreateBankDepositRequestPage";
import ViewBankDepositRequestsPage from "./pages/ViewBankDepositRequestsPage";
import UserTransactionReceiptsPage from "./pages/UserTransactionReceiptsPage";
import SingleReceiptDetailsPage from "./pages/SingleReceiptDetailsPage";
import ViewMiniExpensesByPage from "./pages/CEOMiniExpensesPage";
import CompanySettingsPage from "./pages/CompanySettingsPage";
import PendingPromisesPage from "./pages/PendingPromisesPage";
import DashboardPage from "./pages/DashboardPage";
import DiscountVouchersPage from "./pages/DiscountVouchersPage";
import AllCompaniesPage from "./pages/superAdmin/AllCompaniesPage";
import ViewCompanyStaffPage from "./pages/superAdmin/ViewCompanyStaffPage";
import CreateCompanyPage from "./pages/superAdmin/CreateCompanyPage";
import ViewInventoryItemsPage from "./pages/superAdmin/ViewInventoryItemsPage";
import ViewInventoryItemTypesPage from "./pages/superAdmin/ViewInventoryItemTypesPage";
import ActionQueueModernPage from "./pages/ActionQueueModernPage";
import UpdateISPPage from "./pages/UpdateIspPage";
import CompanyLoginAsCEOPage from "./pages/superAdmin/CompanyLoginAsCEOPage";
import ViewCompanyMikroNOCIspsPage from "./pages/superAdmin/ViewCompanyMikroNOCIspsPage";
import CreateMikroNocISPPage from "./pages/superAdmin/CreateMikroNocIspFormCompanyPage";
import MikroNoCIspSyncPage from "./pages/MikroNoCIspSyncPage";
import SMSBotProbesPage from "./pages/SMSBotProbesPage";
import ViewDealersPage from "./pages/ViewDealersPage";
import ViewDealerProfilePage from "./pages/ViewDealerProfilePage";
import UpdateBankAccountPage from "./pages/UpdateBankAccountPage";
import UserConnectionPage from "./pages/UserConnectionPage";

const routesList: Array<Route> = [
  {
    path: "/",
    Component: DashboardPage,
    roles: ["CEO", "RMAN", "PANEL_MANAGER", "CASHIER", "SUPER_ADMIN"],
    text: "Home",
    show: true,
    icon: HomeIcon,
    activeIcon: HomeBlue,
  },

  {
    path: "/connections",
    Component: HomePage,
    roles: ["CEO", "RMAN", "PANEL_MANAGER", "CASHIER"],
    text: "Connections",
    show: true,
    icon: UsersIcon,
    activeIcon: UsersIconBlue,
  },
  {
    path: "/dealers",
    Component: ViewDealersPage,
    roles: ["CEO", "RMAN", "CASHIER"],
    text: "Dealers",
    show: true,
    icon: UsersIcon,
    activeIcon: UsersIconBlue,
  },
  {
    path: "/dealer-profile/:intPkHeadID",
    Component: ViewDealerProfilePage,
    roles: ["CEO", "RMAN", "CASHIER"],
    text: "Dealers",
    show: false,
    icon: "",
    activeIcon: "",
  },

  {
    path: "/user-details/:intPkHeadID",
    Component: UserDetailsPage,
    roles: ["CEO", "RMAN", "CASHIER"],
    text: "User Details",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/user-details/transaction-receipts/:intPkHeadID",
    Component: UserTransactionReceiptsPage,
    roles: ["CEO", "RMAN", "CASHIER"],
    text: "Transaction Receipts",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/receipts/receipt-details/:intPkConnectionInvoiceID/:intPkConnectionID",
    Component: SingleReceiptDetailsPage,
    roles: ["CEO", "RMAN", "CASHIER"],
    text: "Transaction Receipts",
    show: false,
    icon: "",
    activeIcon: "",
  },

  // {
  //   path: "/create-user",
  //   Component: CreateNewUserPage,
  //   roles: ["CEO", "RMAN", "CASHIER"],
  //   text: "Create User",
  //   show: false,
  //   icon: "",
  //   activeIcon: "",
  // },
  // {
  //   path: "/connections/connection-details/:intPkConnectionID",
  //   Component: ConnectionDetailsPage,
  //   roles: ["CEO", "RMAN", "CASHIER"],
  //   text: "Home",
  //   show: false,
  //   icon: "",
  //   activeIcon: "",
  // },
  {
    path: "/user-connection-details/:intPkHeadID/:intPkConnectionID",
    Component: UserConnectionPage,
    roles: ["CEO", "RMAN", "CASHIER"],
    text: "Home",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/transactions/transaction-details/:intPkTransactionHeadID/:isRefunded",
    Component: TransactionDetailsPage,
    roles: ["CEO", "RMAN", "CASHIER"],
    text: "Home",
    show: false,
    icon: "",
    activeIcon: "",
  },
  // {
  //   path: "/connections/create-catv/:intPkHeadID",
  //   Component: CreateCATVPage,
  //   roles: ["CEO", "RMAN", "CASHIER"],
  //   text: "Home",
  //   show: false,
  //   icon: "",
  //   activeIcon: "",
  // },
  // {
  //   path: "/connections/create-internet/:intPkHeadID",
  //   Component: CreateInternetPage,
  //   roles: ["CEO", "RMAN", "CASHIER"],
  //   text: "Home",
  //   show: false,
  //   icon: "",
  //   activeIcon: "",
  // },

  {
    path: "/connections/create-catv",
    Component: CreateCATVPage,
    roles: ["CEO", "RMAN", "CASHIER"],
    text: "Home",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/connections/create-internet",
    Component: CreateInternetPage,
    roles: ["CEO", "RMAN", "CASHIER"],
    text: "Home",
    show: false,
    icon: "",
    activeIcon: "",
  },

  {
    path: "/isps",
    Component: ViewISPsPage,
    roles: ["CEO", "CASHIER"],
    text: "ISPs",
    show: true,
    icon: IspsIcon,
    activeIcon: IspsBLue,
  },
  {
    path: "/inventory",
    Component: InventoryItemsPage,
    roles: ["CEO", "RMAN", "CASHIER"],
    text: "Inventory",
    show: true,
    icon: InventoryIcon,
    activeIcon: InventoryIconBlue,
  },
  {
    path: "/inventory-stock-details/:intPkInventoryModelID",
    Component: ViewInventoryModelStockPage,
    roles: ["CEO", "RMAN", "CASHIER"],
    text: "Inventory",
    show: false,
    icon: "",
    activeIcon: "",
  },

  {
    path: "/create-isp",
    Component: CreateISPPage,
    roles: ["CEO"],
    text: "Create ISP",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/update-isp/:intPkIspID",
    Component: UpdateISPPage,
    roles: ["CEO"],
    text: "Update ISP",
    show: false,
    icon: "",
    activeIcon: "",
  },

  {
    path: "/auth/company-settings",
    Component: CompanySettingsPage,
    roles: ["CEO"],
    text: "Company Settings",
    show: false,
    icon: "",
    activeIcon: "",
  },

  {
    path: "/isps/packages/create-package/:intPkIspID",
    Component: CreatePackagePage,
    roles: ["CEO"],
    text: "Create Package",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/isps/packages/view-package/:intPkPackageID",
    Component: SinglePackagePage,
    roles: ["CEO", "CASHIER"],
    text: "View Single Package",
    show: false,
    icon: "",
    activeIcon: "",
  },

  {
    path: "/isps/packages/update-package/:intPkPackageID",
    Component: UpdatePackagePage,
    roles: ["CEO"],
    text: "View Single Package",
    show: false,
    icon: "",
    activeIcon: "",
  },

  {
    path: "/isps/vlans/create-vlan/:intPkIspID",
    Component: CreateVlanPage,
    roles: ["CEO"],
    text: "Create Package",
    show: false,
    icon: "",
    activeIcon: "",
  },

  {
    path: "/view-isp/:intPkIspID",
    Component: IspProfilePage,
    roles: ["CEO", "CASHIER"],
    text: "View ISP",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/view-isp/sync-with-mikronoc/:intPkIspID",
    Component: MikroNoCIspSyncPage,
    roles: ["CEO", "CASHIER"],
    text: "View ISP",
    show: false,
    icon: "",
    activeIcon: "",
  },

  {
    path: "/staff",
    Component: ViewStaffPage,
    roles: ["CEO", "CASHIER"],
    text: "Staff",
    show: true,
    icon: StaffIcon,
    activeIcon: StaffBlue,
  },
  {
    path: "/create-staff",
    Component: CreateStaffPage,
    roles: ["CEO"],
    text: "Create Staff",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/staff-profile/:intPkHeadID",
    Component: StaffProfilePage,
    roles: ["CEO", "CASHIER"],
    text: "View Staff",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/staff-profile/update/:intPkHeadID",
    Component: UpdateStaffPage,
    roles: ["CEO"],
    text: "Update Staff",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/areas",
    Component: AreasPage,
    roles: ["CEO", "RMAN", "CASHIER"],
    text: "Areas",
    show: true,
    icon: LocationIcon,
    activeIcon: LocationBlueIcon,
  },
  {
    path: "/sub-areas-by-area/:intPkAreaID/:areaName",
    Component: SubAreasPage,
    roles: ["CEO", "RMAN", "CASHIER"],
    text: "Sub Areas",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/auth/profile",
    Component: MyProfilePage,
    roles: ["CEO", "RMAN", "CASHIER", "PANEL_MANAGER"],
    text: "My Profile",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/auth/update-profile",
    Component: UpdateProfilePage,
    roles: ["CEO", "RMAN", "CASHIER", "PANEL_MANAGER"],
    text: "Update Profile",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/auth/update-password",
    Component: UpdatePasswordPage,
    roles: ["CEO", "RMAN", "CASHIER", "PANEL_MANAGER"],
    text: "Update Password",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/inventory-item-details/:intPkInventoryItemID",
    Component: InventoryItemDetailsPage,
    roles: ["CEO", "RMAN", "CASHIER"],
    text: "Update Password",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/action-queue",
    Component: ActionQueuePage,
    roles: ["CEO", "PANEL_MANAGER", "RMAN", "CASHIER"],
    text: "Action Queue",
    show: true,
    icon: ActionQueueIcon,
    activeIcon: ActionQueueIconBlue,
  },
  {
    path: "/action-queue-modern",
    Component: ActionQueueModernPage,
    roles: ["CEO", "PANEL_MANAGER", "RMAN", "CASHIER"],
    text: "Action Queue",
    show: false,
    icon: ActionQueueIcon,
    activeIcon: ActionQueueIconBlue,
  },

  {
    path: "/add-expenses",
    Component: AddExpensePage,
    roles: ["CASHIER"],
    text: "Expense",
    show: true,
    icon: ExpenseIcon,
    activeIcon: ExpenseIconBlue,
  },
  {
    path: "/mini-expenses",
    Component: ViewMiniExpensesByPage,
    roles: ["CEO"],
    text: "Expenses",
    show: true,
    icon: ExpenseIcon,
    activeIcon: ExpenseIconBlue,
  },
  {
    path: "/banks-accounts",
    Component: ViewBankAccountsPage,
    roles: ["CASHIER", "CEO"],
    text: "Banks",
    show: true,
    icon: BankIcon,
    activeIcon: BankIconBlue,
  },
  {
    path: "/create-bank-account",
    Component: CreateBankAccountPage,
    roles: ["CEO"],
    text: "Banks",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/bank-account-profile/:intPkBankAccountID",
    Component: ViewBankAccountProfilePage,
    roles: ["CEO", "CASHIER"],
    text: "Banks",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/update-bank-account/:intPkBankAccountID",
    Component: UpdateBankAccountPage,
    roles: ["CEO"],
    text: "Banks",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/create-bank-deposit-request",
    Component: CreateBankDepositRequestPage,
    roles: ["CASHIER"],
    text: "Create Bank Deposit Request",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/bank-deposit-requests",
    Component: ViewBankDepositRequestsPage,
    roles: ["CASHIER", "CEO"],
    text: "Deposit Requests",
    show: true,
    icon: DepositRequestIcon,
    activeIcon: DepositRequestIconBlue,
  },
  {
    path: "/promises",
    Component: PendingPromisesPage,
    roles: ["CEO", "RMAN", "PANEL_MANAGER", "CASHIER"],
    text: "Promises",
    show: true,
    icon: PromiseMenuIconWhite,
    activeIcon: PromiseMenuIconBlue,
  },
  {
    path: "/discount-vouchers",
    Component: DiscountVouchersPage,
    roles: ["CEO"],
    text: "Vouchers",
    show: true,
    icon: DiscountVouchersIconWhite,
    activeIcon: DiscountVouchersIconBlue,
  },
  {
    path: "/sms-bots",
    Component: SMSBotProbesPage,
    roles: ["CEO"],
    text: "SMS Bots",
    show: true,
    icon: UsersIcon,
    activeIcon: UsersIconBlue,
  },
  {
    path: "/new-expenses",
    Component: AddExpensePage,
    roles: ["CEO"],
    text: "Expense",
    show: false,
    icon: "",
    activeIcon: "",
  },
  // SUPER ADMIN ROUTES
  {
    path: "/all-companies",
    Component: AllCompaniesPage,
    roles: ["SUPER_ADMIN"],
    text: "Companies",
    show: true,
    icon: StaffIcon,
    activeIcon: StaffBlue,
  },

  {
    path: "/super-admin/create-company",
    Component: CreateCompanyPage,
    roles: ["SUPER_ADMIN"],
    text: "Create Company",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/super-admin/company/view-staff/:intPkCompanyID",
    Component: ViewCompanyStaffPage,
    roles: ["SUPER_ADMIN"],
    text: "Companies",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/super-admin/company/view-mikronoc-isps/:intPkCompanyID",
    Component: ViewCompanyMikroNOCIspsPage,
    roles: ["SUPER_ADMIN"],
    text: "Companies",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/super-admin/company/create-mikronoc-isp/:intPkCompanyID",
    Component: CreateMikroNocISPPage,
    roles: ["SUPER_ADMIN"],
    text: "Companies",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/super-admin/inventory",
    Component: ViewInventoryItemsPage,
    roles: ["SUPER_ADMIN"],
    text: "Inventory",
    show: true,
    icon: InventoryIcon,
    activeIcon: InventoryIconBlue,
  },
  {
    path: "/super-admin/inventory/view-inventory-item-types/:intPkInventoryItemID",
    Component: ViewInventoryItemTypesPage,
    roles: ["SUPER_ADMIN"],
    text: "Companies",
    show: false,
    icon: "",
    activeIcon: "",
  },
  {
    path: "/company-login/:intPkCompanyID",
    Component: CompanyLoginAsCEOPage,
    roles: ["SUPER_ADMIN"],
    text: "Companies",
    show: false,
    icon: "",
    activeIcon: "",
  },
];
export const AppRoutes = () => {
  const role = getUserType();
  return routesList.filter((route: Route) => route.roles.includes(role));
};
