import React, { useContext, useEffect } from "react";
import ViewStaffComponent from "../components/staff/ViewStaff";
import TitleContext from "../context/TitleContext";
const ViewStaffPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("View Staff");
  }, [setTitle]);

  return (
    <>
      <ViewStaffComponent />
    </>
  );
};
export default ViewStaffPage;
