export const calculatePercent = (expiryDateString: string) => {
  if (!expiryDateString) {
    expiryDateString = new Date().toString();
  }
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const expiryDate = new Date(expiryDateString);
  expiryDate.setHours(23, 59, 59, 999);

  const totalDuration = 30;
  const oneDay = 24 * 60 * 60 * 1000;

  const timeDiff = expiryDate.getTime() - currentDate.getTime();

  const remainingDays = Math.max(0, Math.ceil(timeDiff / oneDay));

  const percentRemaining = (remainingDays / totalDuration) * 100;

  return Math.min(100, percentRemaining);
};
export const daysRemaining = (expiryDateString: string) => {
  if (!expiryDateString) {
    expiryDateString = new Date().toString();
  }
  const currentDate = new Date();
  const expiryDate = new Date(expiryDateString);
  const oneDay = 24 * 60 * 60 * 1000;

  const remainingTime = expiryDate.getTime() - currentDate.getTime();

  const remainingDays = Math.ceil(Math.round(remainingTime / oneDay));

  return `${remainingDays} days remaining`;
};
