import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";

import "./index.css";
import SingleTransactionCard from "./cards/TransactionCard";
import useAxiosPost from "../../../hooks/useAxiosPost";
import { useNavigate, useParams } from "react-router-dom";
import ReceiptMiniCard from "./cards/ReceiptMiniCard";
const UserReceiptsComponent = () => {
  const navigate = useNavigate();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<GetUserTransactionsByIdApiProp>(
    "transactions/receipts/getMyUserReceipts"
  );
  const [receipts, setReceipts] = useState<ReceiptMini[]>([]);

  const { intPkHeadID } = useParams();
  console.log({ intPkHeadID });
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setReceipts(data || []);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({ intPkHeadID: Number(intPkHeadID) });
  }, []);

  return (
    <>
      <Row gutter={[16, 16]} className="end-user-profile-container">
        {receipts.map((item) => {
          return (
            <Col
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                navigate(
                  `/receipts/receipt-details/${item.intPkTransactionReceiptID}`
                );
              }}
              key={Math.random()}
              span={24}
            >
              <ReceiptMiniCard key={Math.random()} receiptMini={item} />
            </Col>
          );
        })}
      </Row>
    </>
  );
};
export default UserReceiptsComponent;
