import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import SingleIspDetailsComponent from "../components/isps/SingleIspProfile/IspDetails";

const IspProfilePage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("ISP Profile");
  }, [setTitle]);
  return (
    <>
      <SingleIspDetailsComponent />
    </>
  );
};
export default IspProfilePage;
