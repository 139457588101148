import React, { useContext, useEffect } from "react";
import TitleContext from "../../context/TitleContext";
import ViewInventoryItemTypesComponent from "../../components/superAdmin/inventory/ViewInventoryItemTypes";
const ViewInventoryItemTypesPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Inventory Item Types");
  }, [setTitle]);

  return (
    <>
      <ViewInventoryItemTypesComponent />
    </>
  );
};
export default ViewInventoryItemTypesPage;
