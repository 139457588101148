import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import CreatePackageForISPComponent from "../components/isps/SingleIspProfile/components/packages/CreatePackage";
// import CreatePackageForISPComponent from "../components/isps/SingleIspProfile/components/packages/CreatePackage";

const CreatePackagePage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Create Package");
  }, [setTitle]);
  return (
    <>
      <CreatePackageForISPComponent />
    </>
  );
};
export default CreatePackagePage;
