import { useEffect, useState } from "react";
import { Row, Col, Typography } from "antd";
import "./../index.css";
import useAxiosPost from "../../../../../hooks/useAxiosPost";
import { useNavigate, useParams } from "react-router-dom";
import RadialBarChart from "../../../../Charts/RadialBarChart";
import PackageIcon from "./../../../../../assets/svgs/Package/PackageIcon.svg";
import IspIcon from "./../../../../../assets/svgs/Package/IspIcon.svg";
import CostPriceIcon from "./../../../../../assets/svgs/Package/CostPriceIcon.svg";
import SalePriceIcon from "./../../../../../assets/svgs/Package/SalePriceIcon.svg";
import SingleDetailsRowWithLabelAndValue from "../../../../connectionDetails/cards/SingleDetailsRowWithLabelAndValue";
import NetwalaButton from "../../../../Items/Button/Index";
import { ButtonProps } from "../../../../../helpers/constants";
import { getUserType } from "../../../../../helpers/userDetails";
interface PackageDetailsUserIndicator {
  customColorClass: string;
  value: string;
  label: string;
}
const UsersIndicatorContainer: React.FC<PackageDetailsUserIndicator> = ({
  customColorClass,
  value,
  label,
}) => {
  return (
    <div>
      <Typography className={`package-user-label ${customColorClass}`}>
        {label}
      </Typography>
      <div className={`package-user-value-box ${customColorClass}`}>
        {value}
      </div>
    </div>
  );
};
const ViewSinglePackageComponent = ({}) => {
  const navigate = useNavigate();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<SinglePackageDetailsApiProp>("packages/getPackageById");
  const { intPkPackageID } = useParams();
  const [packageDetails, setPackageDetails] = useState<PackageDetails | null>(
    null
  );
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setPackageDetails(data);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({ packageId: Number(intPkPackageID) });
  }, []);
  return (
    <>
      {packageDetails && (
        <Row gutter={[16, 16]} style={{ width: "100%", marginTop: "20px" }}>
          <Col span={12}>
            <div className="single-package-users-stats">
              <UsersIndicatorContainer
                customColorClass="package-color-blue"
                label="All Users"
                value={`${packageDetails.stats.totalPackageUsers}`}
              />
              <UsersIndicatorContainer
                customColorClass="package-color-green"
                label="Active"
                value={`${packageDetails.stats.totalActiveUsers}`}
              />
              <UsersIndicatorContainer
                customColorClass="package-color-red"
                label="Expired"
                value={`${packageDetails.stats.totalExpiredUsers}`}
              />
            </div>
            <RadialBarChart
              series={[
                packageDetails.stats.totalPackageUsers,
                packageDetails.stats.totalActiveUsers,
                packageDetails.stats.totalExpiredUsers,
              ]}
              labels={["Total Users", "Active Users", "Expired Users"]}
              colors={["#6856DB", "#27D538", "#C71414"]}
            />
          </Col>
          <Col span={12}>
            <div className="single-package-details-container">
              <SingleDetailsRowWithLabelAndValue
                value={`${packageDetails.packageName}`}
                label={`Package Name`}
                icon={PackageIcon}
              />
              <SingleDetailsRowWithLabelAndValue
                value={`${packageDetails.ISPName}`}
                label={`ISP`}
                icon={IspIcon}
              />
              {/* <SingleDetailsRowWithLabelAndValue
                value={`${packageDetails.costPrice}`}
                label={`Cost Price`}
                icon={CostPriceIcon}
              /> */}
              <SingleDetailsRowWithLabelAndValue
                value={`${packageDetails.salePrice}`}
                label={`Selling Price`}
                icon={SalePriceIcon}
                isBorderBottom={false}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "20px",
              }}
            >
              {getUserType() === "CEO" && (
                <NetwalaButton
                  htmlType="submit"
                  backgroundColor={ButtonProps.background.STANDARD}
                  height={ButtonProps.height.STANDARD}
                  width={ButtonProps.width.STANDARD}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {
                    navigate(
                      `/isps/packages/update-package/${packageDetails.intPkPackageID}`
                    );
                  }}
                  text="Update Package"
                  disabled={apiLoading}
                />
              )}
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};
export default ViewSinglePackageComponent;
//
