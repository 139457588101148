import React, { useEffect } from "react";
import NetwalaModal from "../Items/Modal/Index";
import { Col, Row, Form, Input } from "antd";
import "./styles/RequestIPTVModal.css";
import "./styles/changeUserStatusModal.css";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import useMultiNotification from "../../hooks/useNotification";
import useAxiosPost from "../../hooks/useAxiosPost";
const UpdateStaffPasswordModal: React.FC<UpdateStaffPasswordModalProp> = ({
  show,
  handleClose,
  intPkHeadID,
  apiUrl,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [form] = Form.useForm();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<UpdateStaffPasswordApiProp>(apiUrl, true);

  const onFinish = (values: any) => {
    const { password } = values;
    const dataToSend: UpdateStaffPasswordApiProp = {
      intPkHeadID,
      password,
    };
    postApiData(dataToSend);
  };
  useEffect(() => {
    if (apiResponse !== null) {
      const { message } = apiResponse;
      if (message) {
        openNotification("success", "Success", message);
        setTimeout(() => {
          handleClose(true);
        }, 2500);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification("error", "Error", apiError);
    }
  }, [apiError, apiErrorCount]);
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const validateConfirmPassword = (_: any, value: string) => {
    const { password } = form.getFieldsValue();
    console.log({ password });
    if (value && value !== password) {
      return Promise.reject(
        new Error("The two passwords that you entered do not match!")
      );
    }
    return Promise.resolve();
  };
  return (
    <>
      <NetwalaModal
        title="Change Password"
        headerStyle={{
          backgroundColor: "#292D8E",
          color: "#FFFFFF",
          fontSize: "16px",
          fontWeight: "400",
          height: "50px",
        }}
        open={show}
        onCancel={() => {
          if (apiLoading) {
            return;
          }
          handleClose(false);
        }}
      >
        {contextHolder}

        <Row>
          <Col span={24} className="iptv-modal-package-details">
            <Form
              requiredMark={false}
              style={{ width: "100%", marginTop: "50px" }}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              form={form}
              name="updateInventoryPriceFOrm"
            >
              <Form.Item
                label={
                  <span className="create-user-form-label">New Password</span>
                }
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please Enter New Password",
                  },
                  {
                    min: 6,
                    message: "There Should be minium of 6 characters",
                  },
                ]}
              >
                <Input.Password
                  className="user-input-general-height"
                  placeholder="Enter New Password"
                />
              </Form.Item>
              <Form.Item
                dependencies={["password"]}
                label={
                  <span className="create-user-form-label">
                    Confirm Password
                  </span>
                }
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: "Please Confirm Password",
                  },
                  // {
                  //   min: 6,
                  //   message: "There Should be minium of 6 characters",
                  // },
                  {
                    validator: validateConfirmPassword,
                  },
                ]}
              >
                <Input.Password
                  className="user-input-general-height"
                  placeholder="Enter New Password"
                />
              </Form.Item>
              <Form.Item>
                <NetwalaButton
                  backgroundColor={ButtonProps.background.STANDARD}
                  height={ButtonProps.height.STANDARD}
                  width={`100%`}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {}}
                  htmlType="submit"
                  text="Update Password"
                  disabled={apiLoading}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </NetwalaModal>
    </>
  );
};

export default UpdateStaffPasswordModal;
