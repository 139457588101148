import { useEffect, useState } from "react";
import { DatePicker, Col, Row } from "antd";
import dayjs from "dayjs";

import { useNavigate } from "react-router-dom";
import useAxiosPost from "../../hooks/useAxiosPost";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import "./index.css";
import SMSBotProbesTable from "../Items/Table/SMSBotProbesTable";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import CreateNewSMSBotProbeModal from "../Modals/CreateNewSMSBotProbeModal";
const ViewSMSBotProbesComponent = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(false);
  const [bots, setBots] = useState<SMSBotProbe[]>([]);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("bots/getSmsProbeBots");

  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setBots(data || []);
    }
  }, [apiResponse]);
  const handleClose = (reload: boolean) => {
    setShow(false);
    if (reload) {
      postApiData({});
    }
  };
  const handleChangeStatus = (status: number, intPkSMSProbeID: number) => {};
  return (
    <>
      {show === true && (
        <CreateNewSMSBotProbeModal show={show} handleClose={handleClose} />
      )}
      {apiLoading === true ? (
        <TailSpinLoader />
      ) : (
        <>
          <Row style={{ width: "100%" }}>
            <Col offset={18} span={6} style={{ marginBottom: "20px" }}>
              <NetwalaButton
                backgroundColor={ButtonProps.background.STANDARD}
                height={ButtonProps.height.STANDARD}
                width={`100%`}
                textColor={ButtonProps.color.STANDARD}
                onClick={() => {
                  setShow(true);
                }}
                htmlType="submit"
                text="Create New Bot"
                disabled={apiLoading}
              />
            </Col>
            <Col span={24}>
              <SMSBotProbesTable
                bots={bots}
                handleChangeStatus={handleChangeStatus}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
export default ViewSMSBotProbesComponent;
