import React, { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import CompanyGlobalSettingsComponent from "../components/auth/CompanyGlobalSettings";
const CompanySettingsPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Company Settings");
  }, [setTitle]);

  return (
    <>
      <CompanyGlobalSettingsComponent />
    </>
  );
};
export default CompanySettingsPage;
