import React from "react";
import "./styles.css";

const MetroVpnPolicyPage = () => {
  return (
    <div className="policy-page">
      <header className="privacy-policy-main-heading">
        <h1>Metro VPN Terms of Service and Privacy Policy</h1>
      </header>
      <section className="policy-section">
        <h2>Introduction</h2>
        <p>
          This Terms of Service document (the “Terms”) outlines the terms and
          conditions of use of the VPN services (the “Services” or “Service”)
          provided by Metro VPN International Ltd. (“MetroVPN”). These Terms
          also govern the use of and access to Metro VPN’s content (the
          “Content”), which includes the MetroVPN website (the “Site”),
          applications (the “Apps”), and any software provided by MetroVPN (the
          “Software”).
        </p>
        <p>
          By agreeing to these Terms, you are also agreeing to the Privacy
          Policy (“Privacy Policy”).
        </p>
      </section>
      <section className="policy-section">
        <h2>Acceptance</h2>

        <p>
          By accessing the Content or Services, you are agreeing on behalf of
          yourself or those you represent (“you”) to comply with and be legally
          bound by these Terms in their entirety. These Terms constitute a
          legally binding agreement (the “Agreement”) between you and MetroVPN.
          If you do not agree with any part of the Terms, you may not use our
          Services.
        </p>
      </section>
      <section className="policy-section">
        <h2>Privacy Policy</h2>

        <p>
          MetroVPN is committed to your privacy and does not collect or log
          browsing history, traffic destination, data content, or DNS queries
          from Subscribers connected to our VPN. We may collect some sensitive
          personal information, such as your email address information. We only
          collect information that are necessary for the proper delivery of the
          Site and Services.
        </p>
        <p>
          For the sake of clarity and transparency, we have placed all
          information related to data collection in a separate document known as
          the Privacy Policy, which is available on the Site. Please review the
          Privacy Policy in its entirety to get a clear understanding of how we
          handle your sensitive data.
        </p>
        <p>
          We only collect the data when user contacts via email . Email is used
          for communication with users. Also, the contact data is not shared
          with any third-party.
        </p>
      </section>

      <section className="policy-section">
        <h2>Device information</h2>
        <p>
          We collect information from and about the device you use to access our
          services, including about the browsers and Metro VPN apps you use to
          access our services. For example, we may collect device identifiers,
          browser types, device types and settings, operating system versions,
          mobile, wireless, and other network information (such as internet
          service provider name, carrier name and signal strength), and
          application version numbers.
        </p>
      </section>

      <section className="policy-section">
        <h2>Acceptable Use Policy</h2>
        <p>
          MetroVPN Services may be accessed from all around the world, so it is
          your responsibility to assess whether using the Site, Apps, Software,
          or Services is in compliance with local laws and regulations. Whenever
          you use the Site, Apps, Software, or Services, you should comply with
          these Terms and applicable laws, regulations, and policies
        </p>
        <p>
          You understand that it is your responsibility to keep your MetroVPN
          account information confidential. You are responsible for all activity
          under your account. If you ever discover or suspect that someone has
          accessed your account without your authorization, you are advised to
          inform us immediately so that we may revoke your account credentials
          and issue new ones.
        </p>
      </section>

      <section className="policy-section">
        <h2>Data security</h2>
        <p>
          We take data security very seriously and take all steps reasonably
          necessary to secure your data (whether technical, physical, or
          administrative). However, no company can guarantee the absolute
          security of internet communications. By using the Services, you
          expressly acknowledge that we cannot guarantee the security of any
          data provided to or received by us through the Services and that any
          information received from you through the website or our Services is
          provided at your own responsibility.
        </p>
      </section>

      <section className="policy-section">
        <h2>Choice of Law</h2>
        <p>
          This Agreement shall be governed by and construed in accordance with
          the laws of the British Isle of Man, excluding its rules governing
          conflicts of law.
        </p>
      </section>

      <section className="policy-section">
        <h2>Language and Translation of Content</h2>
        <p>
          All of our Content was originally written in English. Any translation
          of our Content is done on a best-effort basis. We cannot guarantee the
          accuracy of translated Content. In the event of any discrepancy
          between the translated Content and the English Content, the English
          Content shall prevail.
        </p>
      </section>

      <section className="policy-section">
        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at lightspeedvpn44@gmail.com
        </p>
      </section>
      <footer className="privacy-policy-footer">
        <p>Last Updated: November 1, 2024</p>
      </footer>
    </div>
  );
};

export default MetroVpnPolicyPage;
