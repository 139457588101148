import React, { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import UpdateStaffProfileComponent from "../components/staff/UpdateStaff";
const UpdateStaffPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Update Staff");
  }, [setTitle]);

  return (
    <>
      <UpdateStaffProfileComponent />
    </>
  );
};
export default UpdateStaffPage;
