import { useEffect, useState } from "react";
import { Row, Col, Typography, Dropdown, MenuProps } from "antd";
import "./index.css";
import useAxiosPost from "../../hooks/useAxiosPost";
import { useNavigate } from "react-router-dom";
import CreateAreaModal from "../Modals/CreateAreaModal";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import { getUserType } from "../../helpers/userDetails";
import { MenuOutlined } from "@ant-design/icons";
import UpdateAreaModal from "../Modals/UpdateAreaModal";
const ViewAreasComponent = () => {
  const navigate = useNavigate();
  const [areas, setAreas] = useState<Area[]>([]);
  const [activeRow, setActiveRow] = useState<Area | null>(null);
  const [show, setShow] = useState<boolean>(false);
  const [showUpdateArea, setShowUpdateArea] = useState<boolean>(false);
  const handleClose = (isReload: boolean) => {
    setShow(false);
    setShowUpdateArea(false);
    if (isReload === true) {
      postApiData({});
    }
  };
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("areas/getAreasByOwner");
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setAreas(data);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({});
  }, []);
  const catvItems: MenuProps["items"] = [
    {
      key: "UPDATE_AREA",
      label: (
        <Typography className="profile-drop-down-item">Update Area</Typography>
      ),
    },
  ];
  const handleMenuClick = (e: any) => {
    switch (e.key) {
      case "UPDATE_AREA":
        setShowUpdateArea(true);
        break;
    }
  };
  return (
    <>
      {show === true ? (
        <CreateAreaModal show={show} handleClose={handleClose} />
      ) : null}
      {showUpdateArea === true && activeRow !== null && (
        <UpdateAreaModal
          area={activeRow}
          show={showUpdateArea}
          handleClose={handleClose}
        />
      )}
      {getUserType() === "CEO" && (
        <Row gutter={[32, 32]} style={{ width: "100%", marginTop: "20px" }}>
          <Col offset={18} span={6}>
            <NetwalaButton
              backgroundColor={ButtonProps.background.STANDARD}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.STANDARD}
              textColor={ButtonProps.color.STANDARD}
              onClick={() => {
                setShow(true);
              }}
              text="Create Area"
            />
          </Col>
        </Row>
      )}
      <Row gutter={[32, 32]} style={{ width: "100%", marginTop: "20px" }}>
        {areas.map((item) => {
          return (
            <Col key={Math.random()} span={6}>
              <div className=" single-area-card">
                <Typography
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(
                      `/sub-areas-by-area/${item.intPkAreaID}/${item.areaName}`
                    );
                  }}
                  className="cursor-pointer area-name"
                >
                  {item.areaName}
                </Typography>
                <Dropdown
                  menu={{
                    items: catvItems,
                    onClick: handleMenuClick,
                  }}
                  placement="bottomRight"
                >
                  <MenuOutlined
                    onMouseEnter={(e) => {
                      setActiveRow(item);
                    }}
                    className="cursor-pointer"
                  />
                </Dropdown>
              </div>
            </Col>
          );
        })}
      </Row>
    </>
  );
};
export default ViewAreasComponent;
