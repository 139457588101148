import React from "react";
import { Row, Col, Typography } from "antd";
import "./recentActivities.css";
import ProfileIcon from "./../../../../assets/svgs/ProfileIcons/Profile.svg";
import LocationIcon from "./../../../../assets/svgs/ProfileIcons/Location.svg";
import PhoneIcon from "./../../../../assets/svgs/ProfileIcons/Phone.svg";
import PackageIcon from "./../../../../assets/svgs/ProfileIcons/Package.svg";
import SingleDetailsRowWithLabelAndValue from "../../../connectionDetails/cards/SingleDetailsRowWithLabelAndValue";
import ComingSoonComponent from "../../../coming-soon/ComingSoon";

const UserRecentActivitiesCard = () => {
  return (
    <>
      <Row className="user-profile-details-card-container" gutter={16}>
        <Col span={24} className="user-details-title-label-container">
          <Typography className="user-details-title-label">
            Recent Activities
          </Typography>
        </Col>
        <ComingSoonComponent />
        {/* <SingleDetailsRowWithLabelAndValue
          isGray
          value={`3006716966`}
          label={`Niyaz ahmad khan`}
          icon={ProfileIcon}
        />
        <SingleDetailsRowWithLabelAndValue
          value={`149-D, Gulbahar Colony, G.T. Road, RYK`}
          label="Address"
          icon={LocationIcon}
        />
        <SingleDetailsRowWithLabelAndValue
          value={`03001234567`}
          label="Phone"
          icon={PhoneIcon}
        />
        <SingleDetailsRowWithLabelAndValue
          value={`DT-10Mb`}
          label="Package"
          icon={PackageIcon}
        />
        <SingleDetailsRowWithLabelAndValue
          value={`DT-10Mb`}
          label="ID Card"
          icon={PackageIcon}
          isBorderBottom={false}
        /> */}
      </Row>
    </>
  );
};
export default UserRecentActivitiesCard;
