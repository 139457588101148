import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ChangePasswordComponent from "../components/auth/profile/ChangePasswordComponent";

const UpdatePasswordPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Update Password");
  }, [setTitle]);
  return (
    <>
      <ChangePasswordComponent />
    </>
  );
};
export default UpdatePasswordPage;
