import React, { useState } from "react";
import { Col, Dropdown, Row, Table, Typography } from "antd";
import type { TableProps } from "antd";
import "./index.css";
import type { MenuProps } from "antd";

import { formatDate } from "../../../helpers/date";
import ActionQueueProcessConfirmation from "../ActionQueueUtils/ActionQueueConfirmation";
import ActionQueueProcessRejection from "../ActionQueueUtils/ActionQueueRjection";
import NetwalaButton from "../Button/Index";
import { ButtonProps } from "../../../helpers/constants";
import ReOpenConfirmationModal from "../../Modals/ReOpenConfirmationModal";
import { EllipsisOutlined } from "@ant-design/icons";
import { getUserType } from "../../../helpers/userDetails";
import IPWhiteListIcon from "./../../../assets/svgs/IPWhiteList.svg";
import IPTVIcon from "./../../../assets/svgs/IPTVIcon.svg";
const ActionQueueTable: React.FC<OperatorActionQueueTable> = ({
  queues,
  handleAcceptAction,
  handleRejectionAction,
  activeTab,
  handleReOpenItem,
}) => {
  const role = getUserType();
  const [activeRow, setActiveRow] = useState<OperatorActionQueue | null>(null);
  const [showProcess, setShowProcess] = useState<boolean>(false);
  const [showRejection, setShowRejection] = useState<boolean>(false);
  const [showReOpen, setShowReOpen] = useState<boolean>(false);
  const handleClose = (isReload: boolean) => {
    setShowProcess(false);
    setShowRejection(false);
    setShowReOpen(false);
    setActiveRow(null);
  };
  const handleMenuClick = (e: any) => {
    switch (e.key) {
      case "PROCESSED":
        setShowProcess(true);
        break;
      case "REJECTED":
        setShowRejection(true);
        break;
    }
  };
  const actionQueueItems: MenuProps["items"] = [
    {
      key: "PROCESSED",
      label: (
        <Typography className="profile-drop-down-item">Process</Typography>
      ),
    },
    {
      key: "REJECTED",
      label: <Typography className="profile-drop-down-item">Reject</Typography>,
    },
  ];
  const columns: TableProps<OperatorActionQueue>["columns"] = [
    {
      title: "S.No",
      dataIndex: "intPkOperatorActionQueueID",
      key: "intPkOperatorActionQueueID",
      render: (text) => <span>{text < 10 ? `0${text}` : text}</span>,
    },
    {
      title: "Name",
      // dataIndex: "phoneNumber",
      // key: "phoneNumber",
      render: (row) => (
        <span>
          {row.firstName} {row.lastName}
        </span>
      ),
    },
    {
      title: "User Phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Connection ID",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Password",
      dataIndex: "password",
      key: "password",
    },
    {
      title: "ISP",
      dataIndex: "ISPName",
      key: "ISPName",
    },
    {
      title: "Package",
      // dataIndex: "",
      // key: "packageName",
      render: (row) => {
        return (
          <div
            className="package-color-container"
            style={{
              color: row.color,
              background: row.bgColor,
              border: "1px solid",
              borderColor: row.borderColor,
            }}
          >
            {row.packageName}
          </div>
        );
      },
    },
    {
      title: "Vlan",
      dataIndex: "vlanValue",
      key: "vlanValue",
    },
    {
      title: "Activity Type",
      dataIndex: "actionQueueType",
      key: "actionQueueType",
    },
    {
      title: "Addons",
      dataIndex: "",
      render: (row) => {
        return (
          <div className="users-table-action-container">
            {/* <img src={TicketsIcon} className="cursor-pointer" /> */}

            {row.totalIPTVServices > 0 ? (
              <img src={IPTVIcon} className="cursor-pointer" />
            ) : null}
            {row.totalStaticIpServices > 0 ? (
              <img src={IPWhiteListIcon} className="cursor-pointer" />
            ) : null}
            {/* <img src={MoneySignIcon} className="cursor-pointer" /> */}
          </div>
        );
      },
    },

    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <span>{formatDate(new Date(text))}</span>,
    },
  ];
  if (activeTab === 1) {
    if (role === "PANEL_MANAGER" || role === "CEO") {
      columns.push({
        title: "Action",
        render: (row) => (
          <>
            <Dropdown
              menu={{
                items: actionQueueItems,
                onClick: handleMenuClick,
              }}
              placement="bottomRight"
            >
              <EllipsisOutlined
                onMouseEnter={(e) => {
                  e.preventDefault();
                  setActiveRow(row);
                }}
              />

              {/* <img
              style={{ display: "block", height: "100%", width: "100%" }}
              onMouseEnter={(e) => {
                e.preventDefault();
                setActiveRow(row);
              }}
              src={ThreeDotsHorizontal}
              className="cursor-pointer"
            /> */}
            </Dropdown>
          </>
        ),
      });
    }
  } else if (activeTab === 2) {
    columns.push({
      title: "Updated",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => <span>{formatDate(new Date(text))}</span>,
    });
  } else if (activeTab === 3) {
    columns.push({
      title: "Updated",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => <span>{formatDate(new Date(text))}</span>,
    });
    columns.push({
      title: "Rejection Reason",
      dataIndex: "rejectionReason",
      key: "rejectionReason",
    });
    if (role !== "PANEL_MANAGER") {
      columns.push({
        title: "Action",
        render: (row) => (
          <>
            <NetwalaButton
              backgroundColor={ButtonProps.background.STANDARD}
              height={"39px"}
              width={"100px"}
              textColor={ButtonProps.color.STANDARD}
              onClick={() => {
                setActiveRow(row);
                setShowReOpen(true);
              }}
              text="ReOpen"
            />
          </>
        ),
      });
    }
  }

  const confirmationButton = (isProcess: boolean, ip?: string) => {
    setShowProcess(false);
    if (isProcess === true && activeRow !== null) {
      handleAcceptAction(activeRow.intPkOperatorActionQueueID, ip);
    }
  };
  const handleReject = (isReject: boolean, value: string) => {
    setShowRejection(false);

    if (isReject === true && activeRow !== null) {
      handleRejectionAction(activeRow.intPkOperatorActionQueueID, value);
    }
  };
  const handleReopen = (isOpen: boolean) => {
    if (isOpen === true && activeRow) {
      //lets re open
      handleReOpenItem(activeRow.intPkOperatorActionQueueID);
    }
    setActiveRow(null);
    setShowReOpen(false);
  };
  const rowClassName = (record: any, index: any) => {
    if (record.actionQueueType === "NEW_CONNECTION") {
      return "new-connection-highlighted-row";
    }
    if (record.actionQueueType === "CHANGE_PACKAGE") {
      return "change-package-highlighted-row";
    }
    if (
      record.actionQueueType === "REMOVE_IPTV" ||
      record.actionQueueType === "REMOVE_STATIC_IP"
    ) {
      return "remove-package-highlighted-row";
    }
    return "";
  };
  return (
    <>
      {showRejection && (
        <ActionQueueProcessRejection
          show={showRejection}
          handleClose={handleClose}
          handleReject={handleReject}
        />
      )}

      {showReOpen && activeRow && (
        <ReOpenConfirmationModal show={showReOpen} handleClose={handleReopen} />
      )}
      {showProcess && activeRow && (
        <ActionQueueProcessConfirmation
          show={showProcess}
          handleClose={handleClose}
          confirmationButton={confirmationButton}
          actionQueueType={activeRow.actionQueueType}
        />
      )}
      <div className="netwala-users-table-container">
        <Row>
          <Col span={24}>
            <Typography className="netwala-user-tables-heading">
              Action Queue
            </Typography>
            <Table
              className="netwala-users-table striped-table"
              columns={columns}
              dataSource={queues}
              pagination={false}
              rowClassName={rowClassName}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
export default ActionQueueTable;
