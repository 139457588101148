import { useEffect, useState } from "react";
import { Col, Dropdown, MenuProps, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import TailSpinLoader from "../Items/Loaders/TailSpinLoader";
import "./index.css";
import { EllipsisOutlined, MenuOutlined } from "@ant-design/icons";
import { formatDate } from "../../helpers/date";
import IPWhiteListIcon from "./../../assets/svgs/IPWhiteList.svg";
import IPTVIcon from "./../../assets/svgs/IPTVIcon.svg";
import ActionQueueProcessRejection from "../Items/ActionQueueUtils/ActionQueueRjection";
import ActionQueueProcessConfirmation from "../Items/ActionQueueUtils/ActionQueueConfirmation";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
const ActionQueueSingleComponent = () => {
  const { openNotification, contextHolder } = useMultiNotification();
  const [showProcess, setShowProcess] = useState<boolean>(false);
  const [showRejection, setShowRejection] = useState<boolean>(false);
  const actionQueueItems: MenuProps["items"] = [
    {
      key: "PROCESSED",
      label: (
        <Typography className="profile-drop-down-item">Process</Typography>
      ),
    },
    {
      key: "REJECTED",
      label: <Typography className="profile-drop-down-item">Reject</Typography>,
    },
  ];
  const navigate = useNavigate();
  const [currentItem, setCurrentItem] = useState<OperatorActionQueue | null>(
    null
  );
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>(
    "actionQueue/getPendingActionQueueForPanelManagerAndCEO"
  );

  const [
    postAcceptActionQueue,
    acceptActionQueueResponse,
    acceptActionQueueError,
    acceptActionQueueLoading,
    acceptActionQueueMessage,
    acceptActionQueueErrorCount,
  ] = useAxiosPost<AcceptActionQueueApiProp>("actionQueue/acceptActionQueue");

  const [
    deleteActionQueueInPendingApi,
    deletePendingApiResponse,
    deletePendingApiError,
    deletePendingApiLoading,
    deletePendingApiMessage,
    deletePendingApiErrorCount,
  ] = useAxiosPost<AcceptActionQueueApiProp>(
    "actionQueue/deleteItemFromProcessing"
  );

  const [
    keepActionQueueInPendingApi,
    keepPendingApiResponse,
    keepPendingApiError,
    keepPendingApiLoading,
    keepPendingApiMessage,
    keepPendingApiErrorCount,
  ] = useAxiosPost<AcceptActionQueueApiProp>(
    "actionQueue/keepActionQueueItemInProcessing"
  );

  const [
    postRejectActionQueue,
    rejectActionQueueResponse,
    rejectActionQueueError,
    rejectActionQueueLoading,
    rejectActionQueueMessage,
    rejectActionQueueErrorCount,
  ] = useAxiosPost<RejectActionQueueApiProp>("actionQueue/rejectActionQueue");
  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (apiResponse) {
      const data: any = apiResponse.responseData;
      console.log({ data });
      setCurrentItem(data);
      if (data !== null) {
        //lets keep this item in processing
        const interval = setInterval(() => {
          keepActionQueueInPendingApi({
            intPkOperatorActionQueueID: data.intPkOperatorActionQueueID,
          });
        }, 30 * 1000);
        setTimeout(() => {
          clearTimeout(interval);
          //if its still here. lets delete from processing.
          deleteActionQueueInPendingApi({
            intPkOperatorActionQueueID: data.intPkOperatorActionQueueID,
          });
        }, 15 * 60 * 1000);
      }
    }
  }, [apiResponse]);

  useEffect(() => {
    if (deletePendingApiResponse) {
      const data: any = deletePendingApiResponse.responseData;
      console.log({ deleteFromProcessingResponse: data });
      setCurrentItem(null);
    }
  }, [deletePendingApiResponse]);

  const handleMenuClick = (e: any) => {
    switch (e.key) {
      case "PROCESSED":
        setShowProcess(true);
        break;
      case "REJECTED":
        setShowRejection(true);
        break;
    }
  };
  const handleClose = (isReload: boolean) => {
    setShowProcess(false);
    setShowRejection(false);
  };
  const handleAcceptAction = (
    intPkOperatorActionQueueID: number,
    staticIP?: string
  ) => {
    postAcceptActionQueue({ intPkOperatorActionQueueID, staticIP });
  };
  const handleRejectionAction = (
    intPkOperatorActionQueueID: number,
    rejectionReason: string
  ) => {
    postRejectActionQueue({ intPkOperatorActionQueueID, rejectionReason });
  };
  const confirmationButton = (isProcess: boolean, ip?: string) => {
    setShowProcess(false);
    if (isProcess === true && currentItem !== null) {
      handleAcceptAction(currentItem.intPkOperatorActionQueueID, ip);
    }
  };
  const handleReject = (isReject: boolean, value: string) => {
    setShowRejection(false);

    if (isReject === true && currentItem !== null) {
      handleRejectionAction(currentItem.intPkOperatorActionQueueID, value);
    }
  };
  useEffect(() => {
    if (acceptActionQueueResponse !== null) {
      const { message } = acceptActionQueueResponse;
      if (message) {
        openNotification(`success`, "Success", message);
        setCurrentItem(null);
        postApiData({});
      }
    }
  }, [acceptActionQueueResponse]);
  useEffect(() => {
    if (acceptActionQueueError !== null && acceptActionQueueErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", acceptActionQueueError);
    }
  }, [acceptActionQueueError, acceptActionQueueErrorCount]);

  useEffect(() => {
    if (rejectActionQueueResponse !== null) {
      const { message } = rejectActionQueueResponse;
      if (message) {
        openNotification(`success`, "Success", message);
        setCurrentItem(null);
        postApiData({});
      }
    }
  }, [rejectActionQueueResponse]);
  useEffect(() => {
    if (rejectActionQueueError !== null && rejectActionQueueErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", rejectActionQueueError);
    }
  }, [rejectActionQueueError, rejectActionQueueErrorCount]);

  if (apiLoading || acceptActionQueueLoading || rejectActionQueueLoading) {
    return (
      <Row style={{ width: "100%" }}>
        <Col span={24}>
          <TailSpinLoader />
        </Col>
      </Row>
    );
  }
  return (
    <>
      {contextHolder}

      {showRejection && (
        <ActionQueueProcessRejection
          show={showRejection}
          handleClose={handleClose}
          handleReject={handleReject}
        />
      )}
      {showProcess && currentItem && (
        <ActionQueueProcessConfirmation
          show={showProcess}
          handleClose={handleClose}
          confirmationButton={confirmationButton}
          actionQueueType={currentItem.actionQueueType}
        />
      )}

      <Row>
        {currentItem && currentItem.intPkConnectionID ? (
          <Col className="action-queue-container" offset={6} span={12}>
            <div className="action-queue-header">
              <Typography className="action-queue-header-text">
                {currentItem.actionQueueType.split("_").join(" ")}
              </Typography>

              <Dropdown
                menu={{
                  items: actionQueueItems,
                  onClick: handleMenuClick,
                }}
                placement="bottomRight"
              >
                <MenuOutlined
                  onMouseEnter={(e) => {
                    e.preventDefault();
                    // setActiveRow(row);
                  }}
                />
              </Dropdown>
            </div>
            <div className="action-queue-user-details">
              <Typography className="action-queue-user-fullName">
                {currentItem.firstName + " " + currentItem.lastName}
              </Typography>
              <Typography className="action-queue-connection-username">
                {currentItem.username}
              </Typography>
              <Typography className="action-queue-user-phone">
                {currentItem.phoneNumber}
              </Typography>
            </div>
            <div className="single-item-container-action-queue border-bottom-action-queue">
              <Typography className="action-queue-item-label">ISP</Typography>
              <Typography className="action-queue-item-value">
                {currentItem.ISPName}
              </Typography>
            </div>
            <div className="multi-item-container-action-queue border-bottom-action-queue">
              <div>
                <Typography className="action-queue-item-label">
                  Package
                </Typography>
                <Typography
                  className="package-color-container"
                  style={{
                    color: currentItem.color,
                    background: currentItem.bgColor,
                    border: "1px solid",
                    borderColor: currentItem.borderColor,
                  }}
                >
                  {currentItem.packageName}{" "}
                </Typography>
              </div>
              <div>
                <Typography className="action-queue-item-label">
                  Vlan
                </Typography>
                <Typography className="action-queue-item-value">
                  {currentItem.vlanValue}
                </Typography>
              </div>
            </div>

            <div className="single-item-container-action-queue border-bottom-action-queue">
              <Typography className="action-queue-item-label">
                Password
              </Typography>
              <Typography className="action-queue-item-value">
                {currentItem.password}
              </Typography>
            </div>

            <div className="single-item-container-action-queue border-bottom-action-queue">
              <Typography className="action-queue-item-label">
                Address
              </Typography>
              <Typography className="action-queue-item-value">
                {currentItem.address}
              </Typography>
            </div>
            <div className="single-item-container-action-queue border-bottom-action-queue">
              <Typography className="action-queue-item-label">
                Addons
              </Typography>
              <div>
                {currentItem.totalIPTVServices > 0 ? (
                  <img src={IPTVIcon} className="cursor-pointer" />
                ) : null}
                {currentItem.totalStaticIpServices > 0 ? (
                  <img src={IPWhiteListIcon} className="cursor-pointer" />
                ) : null}
              </div>
            </div>

            <div className="single-item-container-action-queue ">
              <Typography className="action-queue-item-label">
                Created Date
              </Typography>
              <Typography className="action-queue-item-value">
                {formatDate(new Date(currentItem.createdAt))}
              </Typography>
            </div>
          </Col>
        ) : (
          <Col className="no-data-available-action-queue" offset={6} span={12}>
            <Typography className="no-data-label-action-queue">
              {" "}
              No Data Available Yet{" "}
              <NetwalaButton
                backgroundColor={ButtonProps.background.STANDARD}
                height={ButtonProps.height.STANDARD}
                width={`100px`}
                textColor={ButtonProps.color.STANDARD}
                onClick={() => {
                  setCurrentItem(null);
                  postApiData({});
                }}
                text="Refresh"
              />
            </Typography>
          </Col>
        )}
      </Row>
    </>
  );
};
export default ActionQueueSingleComponent;
