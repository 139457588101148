import { Row } from "antd";
import { TailSpin } from "react-loader-spinner";

const TailSpinLoader = () => {
  return (
    <Row
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "500px",
      }}
    >
      <TailSpin
        visible={true}
        height="80"
        width="80"
        color="#67318C"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
      />
    </Row>
  );
};
export default TailSpinLoader;
