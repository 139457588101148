import React, { useEffect, useState } from "react";
import { Col, Row, Typography } from "antd";
import "./index.css";
import useAxiosPost from "../../../hooks/useAxiosPost";
import IncomingIcon from "./../../../assets/svgs/inventoryItemsIcon/Incoming.svg";
import OutgoingIcon from "./../../../assets/svgs/inventoryItemsIcon/Outgoing.svg";
import SingleDetailsRowWithLabelAndValue from "../../connectionDetails/cards/SingleDetailsRowWithLabelAndValue";
import AddInventoryModal from "../../Modals/AddNewInventoryModal";
import RemoveInventoryModal from "../../Modals/RemoveInventoryModal";
import { formatDate } from "../../../helpers/date";
const ModelStockActivityLogsComponent: React.FC<ModalStockItemTabComponent> = ({
  intPkInventoryModelID,
}) => {
  const [stockProfile, setStockProfile] = useState<InventorySocketItem | null>(
    null
  );
  const [logs, setLogs] = useState<ActivityLog[]>([]);

  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<InventoryModalProfileApiProp>(
    "inventory/getInventoryModelProfileByModalId"
  );
  const [getLogsApi, logsApiResponse] =
    useAxiosPost<InventoryModalProfileApiProp>(
      "inventory/getInventoryStockLogs"
    );

  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setStockProfile(data);
    }
  }, [apiResponse]);
  useEffect(() => {
    if (logsApiResponse !== null) {
      const data: any = logsApiResponse.responseData;
      setLogs(data || []);
    }
  }, [logsApiResponse]);
  useEffect(() => {
    postApiData({ intPkInventoryModelID: intPkInventoryModelID });
    getLogsApi({ intPkInventoryModelID: intPkInventoryModelID });
  }, []);

  return (
    <>
      <Row className="stock-item-profile-items-container" gutter={[16, 16]}>
        {stockProfile && (
          <>
            <Col
              style={{ maxHeight: "100px" }}
              className="stock-item-brand-image-container"
              span={12}
            >
              <img
                className="stock-item-brand-image"
                src={stockProfile.brandImageUrl}
              />
            </Col>
            <Col style={{ width: "100%" }} span={12}>
              {/* Start */}
              {logs.map((item) => {
                return (
                  <div key={Math.random()} className="log-card-container">
                    <Row style={{ width: "100%" }}>
                      <Col span={12}>
                        <SingleDetailsRowWithLabelAndValue
                          value={`${item.actionEnum}`}
                          label={`Activity type`}
                          icon={
                            item.actionEnum === "ADDED"
                              ? IncomingIcon
                              : OutgoingIcon
                          }
                          isBorderBottom={false}
                        />
                      </Col>
                      <Col className="single-entity-activity-log" span={12}>
                        <Typography className="log-activity-label">
                          Activity Date & Time
                        </Typography>
                        <Typography>
                          <span className="transaction-date-value">
                            {formatDate(new Date(item.dateCreated))}
                          </span>
                        </Typography>
                      </Col>
                    </Row>
                    <Row style={{ width: "100%", marginTop: "20px" }}>
                      <Col
                        style={{ paddingLeft: "30px" }}
                        className="single-entity-activity-log"
                        span={12}
                      >
                        <Typography className="log-activity-label">
                          {item.actionEnum === "ADDED"
                            ? "Purchased From"
                            : "Sold To"}
                        </Typography>
                        <Typography className="activity-log-value">
                          {item.username ? item.username : " --------------"}
                        </Typography>
                      </Col>
                      <Col className="single-entity-activity-log" span={12}>
                        <Typography className="log-activity-label">
                          Quantity
                        </Typography>
                        <Typography className="activity-log-value">
                          {item.quantity}{" "}
                          {stockProfile.inventoryMeasurementUnit}(S)
                        </Typography>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        width: "100%",
                        marginTop: "20px",
                        paddingBottom: "30px",
                      }}
                    >
                      <Col
                        style={{ paddingLeft: "30px" }}
                        className="single-entity-activity-log"
                        span={12}
                      >
                        <Typography className="log-activity-label">
                          Price
                        </Typography>
                        <Typography className="activity-log-value">
                          RS {item.unitSalePrice}/-
                        </Typography>
                      </Col>
                      <Col className="single-entity-activity-log" span={12}>
                        <Typography className="log-activity-label">
                          Sold By
                        </Typography>
                        <Typography className="activity-log-value">
                          {item.firstName && item.lastName
                            ? item.firstName + " " + item.lastName
                            : " --------------"}
                        </Typography>
                      </Col>
                    </Row>
                  </div>
                );
              })}

              {/* End */}
            </Col>
          </>
        )}
      </Row>
    </>
  );
};
export default ModelStockActivityLogsComponent;
