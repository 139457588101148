import React, { useState } from "react";
import { Col, Row, Typography, Input } from "antd";

const { TextArea } = Input;

import NetwalaButton from "../Button/Index";
import { ButtonProps } from "../../../helpers/constants";
import NetwalaModal from "../Modal/Index";
import "./index.css";
const ActionQueueProcessRejection: React.FC<
  ProcessActionQueueRejectModalProps
> = ({ handleReject, show, handleClose }) => {
  const [rejection, setRejection] = useState<string>("");
  return (
    <>
      <NetwalaModal
        title="Reject This Action"
        headerStyle={{
          backgroundColor: "#292D8E",
          color: "#FFFFFF",
          fontSize: "16px",
          fontWeight: "400",
          height: "50px",
        }}
        open={show}
        onCancel={() => handleClose(false)}
      >
        <Row>
          <div className="queue-confirmation-message-container">
            <Typography className="queue-confirmation-message">
              Are you sure you want to Reject this action?
            </Typography>
          </div>
          <div
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              width: "100%",
              marginTop: "20px",
            }}
          >
            <TextArea
              value={rejection}
              onChange={(e) => setRejection(e.target.value)}
              rows={4}
            />
          </div>
          <div className="confirmation-action-queue-container">
            <NetwalaButton
              backgroundColor={ButtonProps.background.DANGER}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.ONE24}
              textColor={ButtonProps.color.STANDARD}
              onClick={() => {
                handleReject(true, rejection);
              }}
              text="Reject"
              borderColor="#DF3131"
            />
            <NetwalaButton
              backgroundColor={ButtonProps.background.SECONDARY}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.ONE24}
              textColor={ButtonProps.color.BLACK}
              onClick={() => {
                handleReject(false, "");
              }}
              text="Cancel"
              //   icon={<img src={PlusIcon} />}
              borderColor="#D7D7D7"
            />
          </div>
        </Row>
      </NetwalaModal>
    </>
  );
};
export default ActionQueueProcessRejection;
