import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Dropdown, Space, Button } from "antd";
import CopyToClipboard from "react-copy-to-clipboard";

import type { MenuProps } from "antd";
import "./../../SingleUser/components/cards/profile.css";
import ProfileIcon from "./../../../assets/svgs/ProfileIcons/Profile.svg";
import LocationIcon from "./../../../assets/svgs/ProfileIcons/Location.svg";
import PhoneIcon from "./../../../assets/svgs/ProfileIcons/Phone.svg";
import PackageIcon from "./../../../assets/svgs/ProfileIcons/Package.svg";
import InternetIcon from "./../../../assets/svgs/INTERNETIcon.svg";
import IPTVIcon from "./../../../assets/svgs/IPTVIcon.svg";
import StaticIPIcon from "./../../../assets/svgs/IPWhiteList.svg";
import ChangePackageIcon from "./../../../assets/svgs/ChangePackageIcon.svg";
import RechargeIcon from "./../../../assets/svgs/RechargeIcon.svg";
import ExpiryDateIcon from "./../../../assets/svgs/ExpiryDateIcon.svg";
import RechargeIconBlue from "./../../../assets/svgs/RechargeIconBlue.svg";
import KeyIcon from "./../../../assets/svgs/KeyIcon.svg";
import DiscountIcon from "./../../../assets/svgs/DiscountIcon.svg";
import PromisedTransactionIcon from "./../../../assets/svgs/PromisedTransactionIcon.svg";
import CopyIcon from "./../../../assets/svgs/CopyIcon.svg";
import EditIconBlue from "./../../../assets/svgs/EditIconBlue.svg";

import { DeleteOutlined, MenuOutlined } from "@ant-design/icons";
import IPTVModal from "../../Modals/RequestIPTVModal";
import StaticIPModal from "../../Modals/StaticIPModal";
import RechargeConnectionModal from "../../Modals/RechargeModal";
import ChangeConnectionPackageModal from "../../Modals/ChangePackageModal";
import SingleDetailsRowWithLabelAndValue from "./SingleDetailsRowWithLabelAndValue";
import {
  formatDateForJustMonthDayAndYear,
  getDifferenceOfDaysBetweenCurrentTimeAndInputDate,
} from "../../../helpers/date";
import { getUserType } from "../../../helpers/userDetails";
import UpdateConnectionFixedDiscountModal from "../../Modals/UpdateConnectionFixedDiscountModal";
import UpdateConnectionOntIDModal from "../../Modals/UpdateConnectionOntIDModal";
import UpdateExpiryDateModal from "../../Modals/UpdateExpiryDateModal";
import DeleteConnectionModal from "../../Modals/DeleteConnectionModal";
import UpdateCATVConnectionModal from "../../Modals/UpdateCATVConnectionModal";
import UpdateInternetConnectionModal from "../../Modals/UpdateInternetConnectionModal";

const ConnectionProfileDetailsLeftCard: React.FC<ConnectionDetailsCardProp> = ({
  singleConnection,
  isReload,
  settings,
}) => {
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showIPTVModal, setShowIPTVModal] = useState(false);
  const [showStaticIPModal, setShowStaticIPModal] = useState(false);
  const [showRechargeModal, setShowRechargeModal] = useState(false);
  const [showChangePackageModal, setShowChangePackageModal] = useState(false);
  const [showFixedDiscountModal, setShowFixedDiscountModal] =
    useState<boolean>(false);
  const [showUpdateOntIdModal, setShowUpdateOntIdModal] =
    useState<boolean>(false);
  const [showExpiryDateEditModal, setShowExpiryDateEditModal] =
    useState<boolean>(false);
  const handleClose = (reload: boolean) => {
    if (reload === true) {
      //lets reload the api
      isReload(true);
    }
    setShowUpdateOntIdModal(false);
    setShowIPTVModal(false);
    setShowStaticIPModal(false);
    setShowRechargeModal(false);
    setShowChangePackageModal(false);
    setShowFixedDiscountModal(false);
    setShowExpiryDateEditModal(false);
    setShowDelete(false);
    setShowUpdate(false);
  };
  const internetItems: MenuProps["items"] = [
    // {
    //   key: "IPTV",
    //   label: (
    //     <Typography className="profile-drop-down-item">
    //       <img src={IPTVIcon} /> IPTV
    //     </Typography>
    //   ),
    // },
    {
      key: "STATIC_IP",
      label: (
        <Typography className="profile-drop-down-item">
          <img src={StaticIPIcon} /> Static IP
        </Typography>
      ),
    },
    {
      key: "RECHARGE",
      label: (
        <Typography className="profile-drop-down-item">
          <img src={RechargeIcon} />
          Recharge
        </Typography>
      ),
    },
    {
      key: "CHANGE_PACKAGE",
      label: (
        <Typography className="profile-drop-down-item">
          <img src={ChangePackageIcon} />
          Change Package
        </Typography>
      ),
    },
  ];
  const catvItems: MenuProps["items"] = [
    {
      key: "RECHARGE",
      label: (
        <Typography className="profile-drop-down-item">
          <img src={RechargeIcon} />
          Recharge
        </Typography>
      ),
    },
  ];
  if (getUserType() === "CEO") {
    catvItems.push({
      key: "FIXED_DISCOUNT",
      label: (
        <Typography className="profile-drop-down-item">
          <img src={DiscountIcon} />
          Offer Fixed Discount
        </Typography>
      ),
    });
    internetItems.push({
      key: "FIXED_DISCOUNT",
      label: (
        <Typography className="profile-drop-down-item">
          <img src={DiscountIcon} />
          Offer Fixed Discount
        </Typography>
      ),
    });
    internetItems.push({
      key: "DELETE_CONNECTION",
      label: (
        <Typography className="profile-drop-down-item">
          <DeleteOutlined />
          Archive Connection
        </Typography>
      ),
    });
    catvItems.push({
      key: "DELETE_CONNECTION",
      label: (
        <Typography className="profile-drop-down-item">
          <DeleteOutlined />
          Archive Connection
        </Typography>
      ),
    });
  }
  const handleMenuClick = (e: any) => {
    switch (e.key) {
      case "IPTV":
        setShowIPTVModal(true);
        break;
      case "STATIC_IP":
        setShowStaticIPModal(true);
        break;
      case "RECHARGE":
        setShowRechargeModal(true);
        break;
      case "CHANGE_PACKAGE":
        setShowChangePackageModal(true);
        break;
      case "FIXED_DISCOUNT":
        setShowFixedDiscountModal(true);
        break;
      case "DELETE_CONNECTION":
        setShowDelete(true);
        break;
    }
  };
  const calculatePriceOfPackage = () => {
    const { sale_price, iptvDetails, staticIpDetails } = singleConnection;
    if (singleConnection.connectionType === "CATV") {
      return sale_price;
    }
    let iSalePrice = sale_price;
    if (iptvDetails) {
      iSalePrice = iSalePrice + iptvDetails.iptvSalePrice;
    }
    if (staticIpDetails) {
      iSalePrice = iSalePrice + staticIpDetails.staticIpSalePrice;
    }
    return iSalePrice;
  };
  const calculateDiscount = () => {
    const { fixedDiscountAmount, onceDiscountAmount, onceDiscountExpiry } =
      singleConnection;
    let discount = 0;
    if (fixedDiscountAmount) {
      discount = fixedDiscountAmount;
    }
    if (
      onceDiscountAmount &&
      onceDiscountExpiry &&
      new Date(onceDiscountExpiry) > new Date()
    ) {
      discount = onceDiscountAmount;
    }
    return discount;
  };
  const calculatePriceOfPackageWithDiscount = () => {
    const { sale_price, iptvDetails, staticIpDetails } = singleConnection;
    const discount = calculateDiscount();
    if (singleConnection.connectionType === "CATV") {
      return sale_price - discount;
    }
    let iSalePrice = sale_price;
    if (iptvDetails) {
      iSalePrice = iSalePrice + iptvDetails.iptvSalePrice;
    }
    if (staticIpDetails) {
      iSalePrice = iSalePrice + staticIpDetails.staticIpSalePrice;
    }
    return iSalePrice - discount;
  };
  const calculateIPTVAndStaticIpPriceOnly = () => {
    const { iptvDetails, staticIpDetails } = singleConnection;

    let iSalePrice = 0;
    if (iptvDetails) {
      iSalePrice = iSalePrice + iptvDetails.iptvSalePrice;
    }
    if (staticIpDetails) {
      iSalePrice = iSalePrice + staticIpDetails.staticIpSalePrice;
    }
    return iSalePrice;
  };

  const getNextExpiryDateForRecharge = () => {
    if (!singleConnection.expiryDate) {
      return new Date();
    }
    if (
      getDifferenceOfDaysBetweenCurrentTimeAndInputDate(
        singleConnection.expiryDate
      ) >= 1
    ) {
      //it means expiry date has been passed since three days and connection is expired from last three days
      return new Date();
    }
    return new Date(singleConnection.expiryDate);
  };
  const isShowPassword = () => {
    if (!singleConnection.password) {
      return false;
    }
    if (getUserType() === "CEO") {
      return true;
    }
    if (settings.usersPasswordViewToRman === 1) {
      return true;
    }
    return false;
  };
  return (
    <>
      {showDelete === true && (
        <DeleteConnectionModal
          handleClose={handleClose}
          show={showDelete}
          intPkConnectionID={singleConnection.intPkConnectionID}
          intPkHeadID={singleConnection.intPkHeadID}
        />
      )}
      {showExpiryDateEditModal && (
        <UpdateExpiryDateModal
          connection={singleConnection}
          show={showExpiryDateEditModal}
          handleClose={handleClose}
          lastExpiryDate={getNextExpiryDateForRecharge()}
        />
      )}
      {showUpdateOntIdModal && (
        <UpdateConnectionOntIDModal
          intPkConnectionID={singleConnection.intPkConnectionID}
          show={showUpdateOntIdModal}
          handleClose={handleClose}
          ontID={singleConnection.ontID}
        />
      )}
      {showFixedDiscountModal && (
        <UpdateConnectionFixedDiscountModal
          show={showFixedDiscountModal}
          handleClose={handleClose}
          intPkConnectionID={singleConnection.intPkConnectionID}
          discount={
            singleConnection.fixedDiscountAmount !== null
              ? singleConnection.fixedDiscountAmount
              : 0
          }
        />
      )}{" "}
      {showIPTVModal && settings && (
        <IPTVModal
          show={showIPTVModal}
          handleClose={handleClose}
          connection={singleConnection}
          settings={settings}
        />
      )}{" "}
      {showStaticIPModal && settings && (
        <StaticIPModal
          show={showStaticIPModal}
          handleClose={handleClose}
          connection={singleConnection}
          settings={settings}
        />
      )}{" "}
      {showUpdate && singleConnection.connectionType === "CATV" && (
        <UpdateCATVConnectionModal
          show={showUpdate}
          handleClose={handleClose}
          connection={singleConnection}
        />
      )}{" "}
      {showUpdate && singleConnection.connectionType === "INTERNET" && (
        <UpdateInternetConnectionModal
          show={showUpdate}
          handleClose={handleClose}
          connection={singleConnection}
        />
      )}{" "}
      {showRechargeModal && settings && (
        <RechargeConnectionModal
          show={showRechargeModal}
          handleClose={handleClose}
          connection={singleConnection}
          priceAfterDiscount={calculatePriceOfPackageWithDiscount()}
          discount={calculateDiscount()}
          lastExpiryDate={getNextExpiryDateForRecharge()}
          settings={settings}
          totalIPTVAndStaticIPPrice={calculateIPTVAndStaticIpPriceOnly()}
        />
      )}{" "}
      {showChangePackageModal && settings && (
        <ChangeConnectionPackageModal
          show={showChangePackageModal}
          handleClose={handleClose}
          connection={singleConnection}
          priceAfterDiscount={calculatePriceOfPackageWithDiscount()}
          discount={calculateDiscount()}
          lastExpiryDate={getNextExpiryDateForRecharge()}
          settings={settings}
          totalIPTVAndStaticIPPrice={calculateIPTVAndStaticIpPriceOnly()}
        />
      )}
      <Row className="user-profile-details-card-container" gutter={16}>
        <Col span={24} className="user-details-title-label-container">
          <Typography
            style={{ display: "flex" }}
            className="user-details-title-label"
          >
            Connection Details
            {getUserType() === "CEO" && (
              <img
                src={EditIconBlue}
                onClick={(e) => setShowUpdate(true)}
                className="cursor-pointer"
              />
            )}
          </Typography>

          <Dropdown
            menu={{
              items:
                singleConnection.connectionType === "INTERNET"
                  ? internetItems
                  : catvItems,
              onClick: handleMenuClick,
            }}
            placement="bottomRight"
          >
            <MenuOutlined className="cursor-pointer" />
          </Dropdown>
        </Col>
        <SingleDetailsRowWithLabelAndValue
          isGray
          value={`${singleConnection.phoneNumber}`}
          label={`${singleConnection.firstName} ${singleConnection.lastName}`}
          icon={ProfileIcon}
        />
        <SingleDetailsRowWithLabelAndValue
          value={`${singleConnection.address},${singleConnection.areaName}`}
          label="Address"
          icon={LocationIcon}
        />
        {singleConnection.connectionType === "INTERNET" ? (
          <>
            <SingleDetailsRowWithLabelAndValue
              value={`${singleConnection.packageName}`}
              label="Package"
              icon={PackageIcon}
            />

            <SingleDetailsRowWithLabelAndValue
              value={`${singleConnection.ISPName}`}
              label="ISP Name"
              icon={InternetIcon}
            />
            <SingleDetailsRowWithLabelAndValue
              // value={`${
              //   singleConnection.ontID ? singleConnection.ontID : "No ID"
              // }`}
              value={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                  className={`${
                    singleConnection.ontID === null ? "blink-label" : ""
                  }`}
                >
                  {" "}
                  {singleConnection.ontID
                    ? `${singleConnection.ontID}`
                    : "No ID Provided"}
                  <img
                    onClick={(e) => {
                      setShowUpdateOntIdModal(true);
                    }}
                    className="cursor-pointer"
                    src={EditIconBlue}
                  />
                </div>
              }
              label="Ont ID"
              icon={InternetIcon}
            />
          </>
        ) : null}
        {singleConnection.username && (
          <SingleDetailsRowWithLabelAndValue
            value={
              <div>
                {singleConnection.username}{" "}
                <CopyToClipboard
                  text={singleConnection.username || ""}
                  onCopy={() => {}}
                >
                  <img
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    src={CopyIcon}
                    className="cursor-pointer "
                  />
                </CopyToClipboard>
              </div>
            }
            label="UserName"
            icon={ProfileIcon}
            isReactNode={true}
          />
        )}
        {isShowPassword() === true && (
          <SingleDetailsRowWithLabelAndValue
            value={`${singleConnection.password}`}
            label="Password"
            icon={KeyIcon}
          />
        )}

        {singleConnection.rechargeDetails &&
          singleConnection.rechargeDetails.dateCreated && (
            <SingleDetailsRowWithLabelAndValue
              label={`Last Recharge`}
              value={`${formatDateForJustMonthDayAndYear(
                new Date(singleConnection.rechargeDetails.dateCreated)
              )}`}
              icon={RechargeIconBlue}
            />
          )}
        {getUserType() === "CEO"
          ? singleConnection.expiryDate && (
              <SingleDetailsRowWithLabelAndValue
                label={`Expiry Date`}
                // value={`${formatDateForJustMonthDayAndYear(
                //   new Date(singleConnection.expiryDate)
                // )}`}
                icon={ExpiryDateIcon}
                isBorderBottom={false}
                isReactNode={true}
                value={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    {formatDateForJustMonthDayAndYear(
                      new Date(singleConnection.expiryDate)
                    )}

                    <img
                      onClick={(e) => {
                        setShowExpiryDateEditModal(true);
                      }}
                      className="cursor-pointer"
                      src={EditIconBlue}
                    />
                  </div>
                }
              />
            )
          : singleConnection.expiryDate && (
              <SingleDetailsRowWithLabelAndValue
                label={`Expiry Date`}
                value={`${formatDateForJustMonthDayAndYear(
                  new Date(singleConnection.expiryDate)
                )}`}
                icon={ExpiryDateIcon}
                isBorderBottom={false}
              />
            )}
      </Row>
    </>
  );
};
export default ConnectionProfileDetailsLeftCard;
