import { useEffect, useState } from "react";
import { Row, Col, Typography, Alert, Button } from "antd";

import "./index.css";
import useAxiosPost, {
  authTokenString,
  baseURL,
} from "../../../../hooks/useAxiosPost";
import SingleDetailsRowWithLabelAndValue from "../../../connectionDetails/cards/SingleDetailsRowWithLabelAndValue";
import IspNameIcon from "./../../../../assets/svgs/IspIcons/IspNameIconBlue.svg";
import IspEmailIcon from "./../../../../assets/svgs/IspIcons/IspEmailIcon.svg";
import IspPhoneIcon from "./../../../../assets/svgs/IspIcons/IspPhoneIcon.svg";
import IPTVIconBlue from "./../../../../assets/svgs/IPTVIconBlue.svg";
import StaticIPIconBlue from "./../../../../assets/svgs/StaticIPIconBlue.svg";
import EditIconBlue from "./../../../../assets/svgs/EditIconBlue.svg";
import CreateUpdateStaticIPPriceModal from "../../../Modals/CreateUpdateStaticIPPriceModal";
import CreateUpdateIPTVPriceModal from "../../../Modals/CreateUpdateIPTVPriceModal";
import { getUserType } from "../../../../helpers/userDetails";
import RadialBarChart from "../../../Charts/RadialBarChart";
import TailSpinLoader from "../../../Items/Loaders/TailSpinLoader";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import NetwalaButton from "../../../Items/Button/Index";
import { ButtonProps } from "../../../../helpers/constants";
import { useNavigate } from "react-router-dom";
import { EditOutlined, UploadOutlined } from "@ant-design/icons";
import useMultiNotification from "../../../../hooks/useNotification";

interface PackageDetailsUserIndicator {
  customColorClass: string;
  value: string;
  label: string;
}
const UsersIndicatorContainer: React.FC<PackageDetailsUserIndicator> = ({
  customColorClass,
  value,
  label,
}) => {
  return (
    <div>
      <Typography className={`package-user-label ${customColorClass}`}>
        {label}
      </Typography>
      <div className={`package-user-value-box ${customColorClass}`}>
        {value}
      </div>
    </div>
  );
};
const SingleISPProfileComponent: React.FC<SingleISPProfileTabProp> = ({
  intPkIspID,
}) => {
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { openNotification, contextHolder } = useMultiNotification();

  const navigate = useNavigate();
  const [isp, setIsp] = useState<ISP | null>(null);
  const [showStaticIP, setShowStaticIP] = useState<boolean>(false);
  const [showIPTV, setShowIPTV] = useState<boolean>(false);
  const handleClose = (isReload: boolean) => {
    if (isReload === true) {
      postApiData({ intPkIspID });
    }
    setShowIPTV(false);
    setShowStaticIP(false);
  };
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<GetIspDetailsByIdApiProp>("isps/getISPByID");
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setIsp(data);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({ intPkIspID });
  }, []);
  if (apiLoading) {
    return (
      <Row style={{ width: "100%" }}>
        <Col span={24}>
          <TailSpinLoader />
        </Col>
      </Row>
    );
  }
  const handleClickEdit = () => {
    // Simulate click on hidden file input element

    const imageInput = document.getElementById("ispLogoImage");
    if (imageInput) {
      imageInput.click();
    }
  };
  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setImageFile(event.target.files?.[0] ?? null);
    if (event.target.files && event.target.files.length > 0) {
      setIsLoading(true);

      const formData = new FormData();
      formData.append("authTokenString", authTokenString.toString());
      formData.append("ispLogo", event.target.files[0]);
      formData.append("intPkIspID", intPkIspID.toString());

      try {
        const response = await fetch(`${baseURL}/isps/updateIspLogo`, {
          method: "POST",
          body: formData,
          // Note: When using FormData, do NOT set the Content-Type header
          // The browser will set it automatically, including the boundary parameter
        });

        if (response.ok) {
          const responseData = await response.json();
          openNotification(
            "success",
            "Success",
            responseData?.message || "Logo Updated Successfully"
          );
          postApiData({ intPkIspID });

          setIsLoading(false);
        } else {
          const responseData = await response.json();

          openNotification(
            "error",
            "Operation Failed",
            responseData?.message || "Server Error"
          );
          console.error("Upload failed:", responseData);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error during fetch:", error);
      }
    }
  };

  return (
    <>
      {contextHolder}
      {showStaticIP === true && isp !== null && (
        <CreateUpdateStaticIPPriceModal
          intPkIspID={isp.intPkIspID}
          price={isp.staticIPDetails?.salePrice || 0}
          show={showStaticIP}
          handleClose={handleClose}
        />
      )}
      {showIPTV === true && isp !== null && (
        <CreateUpdateIPTVPriceModal
          intPkIspID={isp.intPkIspID}
          price={isp.iptvDetails?.salePrice || 0}
          show={showIPTV}
          handleClose={handleClose}
        />
      )}
      {isp && isp.ispTypeEnum === "MIKRO_NOC" && (
        <Row style={{ width: "100%", marginTop: "50px" }}>
          <Col offset={18} span={6}>
            <NetwalaButton
              htmlType="button"
              backgroundColor={ButtonProps.background.STANDARD}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.STANDARD}
              textColor={ButtonProps.color.STANDARD}
              onClick={() => {
                navigate(`/view-isp/sync-with-mikronoc/${intPkIspID}`);
              }}
              text="Sync"
            />
          </Col>
        </Row>
      )}
      <Row gutter={[16, 16]} style={{ width: "100%" }}>
        {isp && (
          <>
            {isp.totalPendingRequests > 0 && (
              <Col style={{ marginTop: "20px" }} span={24}>
                <Alert
                  message="Pending Import Requests"
                  description={
                    <>
                      {`${isp.totalPendingRequests} import users requests are in
                      queue `}
                      <Spin />
                    </>
                  }
                  type="warning"
                  showIcon
                />
              </Col>
            )}
            <Col style={{ marginTop: "50px" }} span={12}>
              <div className="single-package-users-stats">
                <UsersIndicatorContainer
                  customColorClass="package-color-blue"
                  label="All Users"
                  value={`${isp.stats.totalIspUsers}`}
                />
                <UsersIndicatorContainer
                  customColorClass="package-color-green"
                  label="Active"
                  value={`${isp.stats.totalActiveUsers}`}
                />
                <UsersIndicatorContainer
                  customColorClass="package-color-yellow"
                  label="Expiring"
                  value={`${isp.stats.totalExpiringUsers}`}
                />
                <UsersIndicatorContainer
                  customColorClass="package-color-red"
                  label="Expired"
                  value={`${isp.stats.totalExpiredUsers}`}
                />
              </div>
            </Col>
            <Col style={{ marginTop: "50px" }} span={12}>
              <RadialBarChart
                series={[
                  isp.stats.totalIspUsers,
                  isp.stats.totalActiveUsers,
                  isp.stats.totalExpiringUsers,
                  isp.stats.totalExpiredUsers,
                ]}
                labels={[
                  "Total Users",
                  "Active Users",
                  "Expiring Users",
                  "Expired Users",
                ]}
                colors={["#6856DB", "#27D538", "#FFDE45", "#C71414"]}
              />
            </Col>
            <Col span={12}>
              <Row className="isp-profile-tab-container">
                <SingleDetailsRowWithLabelAndValue
                  value={`${isp.ISPName}`}
                  label="ISP name"
                  icon={IspNameIcon}
                />
                <SingleDetailsRowWithLabelAndValue
                  value={`${isp.ISPSupportNumber}`}
                  label="ISP Support Number"
                  icon={IspPhoneIcon}
                />
                <SingleDetailsRowWithLabelAndValue
                  value={`${isp.IspSalesNumber}`}
                  label="ISP Sales Number"
                  icon={IspPhoneIcon}
                  // isBorderBottom={false}
                />
                <SingleDetailsRowWithLabelAndValue
                  value={
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        width: "100%",
                        justifyContent: "space-around",
                      }}
                    >
                      <img
                        src={isp.url}
                        style={{ marginTop: "20px", maxHeight: "100px" }}
                      />
                      <Button
                        icon={<EditOutlined />}
                        onClick={handleClickEdit}
                      ></Button>
                      <input
                        type="file"
                        id="ispLogoImage"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{ display: "none" }}
                      />
                    </div>
                  }
                  label="Logo"
                  // icon={ProfileIcon}
                  isReactNode={true}
                  isBorderBottom={false}
                />
                {/* <Col span={24} className={`profile-card-single-row `}>
                  <div className="icon-container">
                    <img src={IPTVIconBlue} />
                  </div>
                  <div className="values-container">
                    <Typography className={`user-label`}>IPTV Price</Typography>
                    <Typography className={`user-value`}>
                      {isp.iptvDetails?.salePrice
                        ? isp.iptvDetails?.salePrice
                        : "Not Configured"}
                    </Typography>
                  </div>
                  {getUserType() === "CEO" && (
                    <div>
                      <img
                        onClick={(e) => {
                          setShowIPTV(true);
                        }}
                        className="cursor-pointer"
                        src={EditIconBlue}
                      />
                    </div>
                  )}
                </Col> */}
              </Row>
            </Col>
            <Col span={12}>
              <Row className="isp-profile-tab-container">
                <SingleDetailsRowWithLabelAndValue
                  value={`${isp.ISPSupportEmail}`}
                  label="ISP Support Email"
                  icon={IspEmailIcon}
                />
                <SingleDetailsRowWithLabelAndValue
                  value={`${isp.ISPSalesEmail}`}
                  label="ISP Sales Email"
                  icon={IspEmailIcon}
                />
                <Col span={24} className={`profile-card-single-row `}>
                  <div className="icon-container">
                    <img src={StaticIPIconBlue} />
                  </div>
                  <div className="values-container">
                    <Typography className={`user-label`}>
                      Static IP Price
                    </Typography>
                    <Typography className={`user-value`}>
                      {isp.staticIPDetails?.salePrice
                        ? isp.staticIPDetails?.salePrice
                        : "Not Configured"}
                    </Typography>
                  </div>
                  {getUserType() === "CEO" && (
                    <div>
                      <img
                        onClick={(e) => {
                          setShowStaticIP(true);
                        }}
                        className="cursor-pointer"
                        src={EditIconBlue}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
            {getUserType() === "CEO" && isp.intPkCompanyID && (
              <Col
                style={{ display: "flex", justifyContent: "center" }}
                span={24}
              >
                <NetwalaButton
                  htmlType="button"
                  backgroundColor={ButtonProps.background.STANDARD}
                  height={ButtonProps.height.STANDARD}
                  width={ButtonProps.width.STANDARD}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {
                    console.log("clicked");
                    navigate(`/update-isp/${intPkIspID}`);
                  }}
                  text="Update ISP"
                />
              </Col>
            )}
          </>
        )}
      </Row>
    </>
  );
};
export default SingleISPProfileComponent;
