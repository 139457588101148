import React, { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import BankDepositRequestFrom from "../components/deposit-requests/CreateDepositRequest";
const CreateBankDepositRequestPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("New Bank Deposit Request");
  }, [setTitle]);

  return (
    <>
      <BankDepositRequestFrom />
    </>
  );
};
export default CreateBankDepositRequestPage;
