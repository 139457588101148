import { useEffect, useState } from "react";
import { Row, Col, Typography } from "antd";
import "./../index.css";
import useAxiosPost from "../../../../../hooks/useAxiosPost";
import { useNavigate } from "react-router-dom";
const PackagesByIspComponent: React.FC<SingleISPProfileTabProp> = ({
  intPkIspID,
}) => {
  const navigate = useNavigate();
  const [packages, setPackages] = useState<PackageWithAsset[]>([]);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<PackagesAndVlansByISPApiProp>("packages/getPackagesByISP");
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setPackages(data);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({ ispId: intPkIspID });
  }, []);
  return (
    <>
      <Row gutter={[16, 16]} style={{ width: "100%", marginTop: "20px" }}>
        {packages.map((item) => {
          return (
            <Col
              onClick={(e) => {
                e.preventDefault();
                navigate(`/isps/packages/view-package/${item.intPkPackageID}`);
              }}
              key={Math.random()}
              span={4}
            >
              <div className="package-list-view-container cursor-pointer">
                <div
                  className="package-color-container"
                  style={{
                    color: item.color,
                    background: item.bgColor,
                    border: "1px solid",
                    borderColor: item.borderColor,
                  }}
                >
                  {item.packageName}
                </div>
                <Typography className="view-package-price">
                  {item.salePrice} Rs
                </Typography>
              </div>
            </Col>
          );
        })}
      </Row>
    </>
  );
};
export default PackagesByIspComponent;
