import React, { useState } from "react";
import { Col, Row, Table, Typography, Dropdown } from "antd";
import type { TableProps } from "antd";
import "./index.css";

import { formatDate } from "../../../helpers/date";
import type { MenuProps } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import RefundIcon from "./../../../assets/svgs/Refund.svg";
import RefundConfirmationModal from "../../Modals/RefundConfirmationModal";

const TransactionDetailsTable: React.FC<TransactionDetailsTable> = ({
  transactions,
  handleRefundTransaction,
  isRefunded,
}) => {
  const [showRefund, setShowRefund] = useState<boolean>(false);
  const handleMenuClick = (e: any) => {
    switch (e.key) {
      case "REFUND":
        //lets open the refund modal;
        setShowRefund(true);
        break;
    }
  };
  const refundItems: MenuProps["items"] = [
    {
      key: "REFUND",
      label: (
        <Typography className="profile-drop-down-item">
          <img src={RefundIcon} /> Refund
        </Typography>
      ),
    },
  ];
  const columns: TableProps<TransactionDetailsApiProp>["columns"] = [
    // {
    //   title: "S.No",
    //   dataIndex: "intPkTransactionID",
    //   key: "intPkTransactionID",
    //   render: (text) => <span>{text < 10 ? `0${text}` : text}</span>,
    // },
    // {
    //   title: "TRX ID",
    //   dataIndex: "intPkTransactionID",
    //   key: "intPkTransactionID",
    // },
    {
      title: "TRX Date & Time",
      dataIndex: "dateCreated",
      key: "dateCreated",
      render: (text) => (
        <span className="transaction-date-value">
          {formatDate(new Date(text))}
        </span>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Debit",
      dataIndex: "debit",
      key: "debit",
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
    },
    {
      title: "Name",
      render: (row) => (
        <span>{`${row.firstName} ${row.lastName ? row.lastName : ""}`}</span>
      ),
    },
  ];
  const handleClose = (isRefund: boolean) => {
    if (isRefund === true) {
      handleRefundTransaction(isRefund);
    }
    setShowRefund(false);
  };
  return (
    <>
      {showRefund === true && (
        <RefundConfirmationModal show={showRefund} handleClose={handleClose} />
      )}
      <div className="netwala-users-table-container">
        <Row>
          <Col span={24}>
            <Typography
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingRight: "10px",
              }}
              className="netwala-user-tables-heading"
            >
              Transactions
              {/* Number(isRefunded) === 0 && getUserType() === "CEO" && ( */}
              {Number(isRefunded) === 0 && (
                <Dropdown
                  menu={{
                    items: refundItems,
                    onClick: handleMenuClick,
                  }}
                  placement="bottomRight"
                >
                  <MoreOutlined className="cursor-pointer" />
                </Dropdown>
              )}
            </Typography>
            <Table
              className="netwala-users-table striped-table"
              columns={columns}
              dataSource={transactions}
              pagination={false}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
export default TransactionDetailsTable;
