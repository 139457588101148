import React from "react";
import { Row, Col, Typography } from "antd";
import "./transactions.css";
import { formatDate } from "../../../../helpers/date";

interface TransactionRowItemProp {
  label: string;
  value: string;
  valueClass: string;
}
const DetailsItem: React.FC<TransactionRowItemProp> = ({
  label,
  value,
  valueClass,
}) => {
  return (
    <div className="transaction-single-item-col">
      <Typography className="item-label">{label}</Typography>
      <Typography className={`${valueClass}`}>{value}</Typography>
    </div>
  );
};
const ReceiptMiniCard: React.FC<ReceiptMiniCardProp> = ({ receiptMini }) => {
  return (
    <>
      <Row className="transaction-container">
        <Col span={15}>
          <DetailsItem
            label="Transaction type"
            value={receiptMini.receiptTitle}
            valueClass="payment-purpose"
          />
        </Col>
        <Col span={5}>
          <DetailsItem
            label="Transaction Date"
            value={`${formatDate(new Date(receiptMini.receiptCreated))}`}
            valueClass="recharge-date-value"
          />
        </Col>

        <Col span={4}>
          <DetailsItem
            label="Amount"
            value={`${receiptMini.receipt_amount}`}
            valueClass="user-name-label"
          />
        </Col>
      </Row>
    </>
  );
};
export default ReceiptMiniCard;
