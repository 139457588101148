import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewBankAccounts from "../components/banks/ViewBankAccounts";
import BankAccountProfile from "../components/banks/BankAccountProfile";

const ViewBankAccountProfilePage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Bank Account Profile");
  }, [setTitle]);
  return (
    <>
      <BankAccountProfile />
    </>
  );
};
export default ViewBankAccountProfilePage;
