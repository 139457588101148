import React, { useEffect, useState } from "react";
import { Table, Select } from "antd";
import NetwalaButton from "../Button/Index";
import { ButtonProps } from "../../../helpers/constants";
import useMultiNotification from "../../../hooks/useNotification";

interface Props {
  ispPackages: string[];
  packages: Package[];
  handlePackageMapper: (mappedPackages: MappedPackagesProp[]) => void;
  alreadyMappedPackages: MappedPackagesProp[];
}

const PackageMapperTable: React.FC<Props> = ({
  ispPackages,
  packages,
  handlePackageMapper,
  alreadyMappedPackages,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();

  const [mappedPackages, setMappedPackages] = useState<
    {
      packageName: string;
      packageId: number | null;
      mappedPackageName: string;
    }[]
  >([]);
  console.log({ ispPackages, packages });
  // Initialize packageUsers state with package names and null user selections

  useEffect(() => {
    console.log({ alreadyMappedPackages });
    if (alreadyMappedPackages.length > 0) {
      setMappedPackages(alreadyMappedPackages);
    } else {
      setMappedPackages(
        ispPackages.map((pkg) => ({
          packageName: pkg,
          packageId: null,
          mappedPackageName: "",
        }))
      );
    }
  }, []);
  const handleUserChange = (index: number, packageId: number | null) => {
    const foundPackage = packages.find(
      (item) => item.intPkPackageID === packageId
    );
    const updatedMappedPackages = [...mappedPackages];
    updatedMappedPackages[index].packageId = packageId;
    updatedMappedPackages[index].mappedPackageName =
      foundPackage?.packageName || "";
    setMappedPackages(updatedMappedPackages);
  };

  const columns = [
    {
      title: "Found ISP Package",
      dataIndex: "packageName",
      key: "packageName",
    },
    {
      title: "Choose Package",
      dataIndex: "packageId",
      key: "packageId",
      render: (packageId: number | null, record: any, index: number) => (
        <Select
          value={packageId || null} // Handle null value for initial state
          style={{ width: 200 }}
          onChange={(value) => handleUserChange(index, value)}
        >
          {packages.map((user) => (
            <Select.Option
              key={user.intPkPackageID}
              value={user.intPkPackageID}
            >
              {user.packageName}
            </Select.Option>
          ))}
        </Select>
      ),
    },
  ];

  const data = mappedPackages.map((pair, index) => ({
    key: index,
    packageName: pair.packageName,
    packageId: pair.packageId,
  }));
  useEffect(() => {
    console.log({ mappedPackages });
  }, [mappedPackages]);

  return (
    <>
      {contextHolder}
      <Table columns={columns} dataSource={data} pagination={false} bordered />
      <div
        style={{
          width: "100%",
          marginTop: "20px",
          marginBottom: "20px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <NetwalaButton
          backgroundColor={ButtonProps.background.STANDARD}
          height={ButtonProps.height.STANDARD}
          width={ButtonProps.width.STANDARD}
          textColor={ButtonProps.color.STANDARD}
          onClick={() => {
            const isMapped = mappedPackages.every(
              (item) => item.packageId !== null
            );
            if (!isMapped) {
              openNotification(
                `error`,
                "Error",
                `All Packages Are not mapped yet`
              );
              return;
            }
            handlePackageMapper(mappedPackages);
          }}
          text="Save Mapping"
        />
      </div>
    </>
  );
};

export default React.memo(PackageMapperTable);
