import React, { useEffect, useState } from "react";
import { Col, Row, Typography } from "antd";
import "./index.css";
import useAxiosPost from "../../../hooks/useAxiosPost";
import StockIcon from "./../../../assets/svgs/inventoryItemsIcon/Stock.svg";
import SingleDetailsRowWithLabelAndValue from "../../connectionDetails/cards/SingleDetailsRowWithLabelAndValue";
import NetwalaButton from "../../Items/Button/Index";
import { ButtonProps } from "../../../helpers/constants";
import AddInventoryModal from "../../Modals/AddNewInventoryModal";
import RemoveInventoryModal from "../../Modals/RemoveInventoryModal";
import PlusIcon from "./../../../assets/svgs/IconsWhite/PlusIcon.svg";
import SaleInventoryModal from "../../Modals/SaleInventoryModal";
import { getUserType } from "../../../helpers/userDetails";
const SingleModalStockDataComponent: React.FC<ModalStockItemTabComponent> = ({
  intPkInventoryModelID,
}) => {
  const [stockProfile, setStockProfile] = useState<InventorySocketItem | null>(
    null
  );
  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [showSale, setShowSale] = useState<boolean>(false);
  const [showRemove, setShowRemove] = useState<boolean>(false);
  const handleClose = (isReload: boolean) => {
    if (isReload) {
      postApiData({ intPkInventoryModelID: intPkInventoryModelID });
    }
    setShowAdd(false);
    setShowRemove(false);
    setShowSale(false);
  };
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<InventoryModalProfileApiProp>(
    "inventory/getInventoryModelProfileByModalId"
  );

  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setStockProfile(data);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({ intPkInventoryModelID: intPkInventoryModelID });
  }, []);

  return (
    <>
      {showAdd && stockProfile && (
        <AddInventoryModal
          show={showAdd}
          handleClose={handleClose}
          intPkInventoryModelID={intPkInventoryModelID}
          inventoryProfile={stockProfile}
        />
      )}
      {showRemove &&
        stockProfile &&
        stockProfile.intPkInventoryStockID !== -1 && (
          <RemoveInventoryModal
            show={showRemove}
            handleClose={handleClose}
            intPkInventoryStockID={stockProfile.intPkInventoryStockID}
            quantity={stockProfile.quantity}
          />
        )}
      {showSale &&
        stockProfile &&
        stockProfile.intPkInventoryStockID !== -1 &&
        stockProfile.quantity > 0 && (
          <SaleInventoryModal
            show={showSale}
            handleClose={handleClose}
            intPkInventoryStockID={stockProfile.intPkInventoryStockID}
            quantity={stockProfile.quantity}
            inventoryProfile={stockProfile}
          />
        )}

      <Row className="stock-item-profile-items-container" gutter={[16, 16]}>
        {stockProfile && (
          <>
            <Col className="stock-item-brand-image-container" span={12}>
              <img
                className="stock-item-brand-image"
                src={stockProfile.brandImageUrl}
              />
            </Col>
            <Col className="model-profile-manage-stock-container" span={12}>
              <Row style={{ width: "100%" }}>
                <Col span={16}>
                  <div className="modal-stock-info">
                    <SingleDetailsRowWithLabelAndValue
                      value={
                        getUserType() !== "RMAN"
                          ? `${stockProfile.quantity}`
                          : "---"
                      }
                      label={`In Stock`}
                      icon={StockIcon}
                      isBorderBottom={false}
                    />
                  </div>
                </Col>
                {getUserType() === "CEO" && (
                  <Col className="model-stock-quantity-container" span={8}>
                    <Typography className="add-remove-label">
                      Add/Remove
                    </Typography>

                    <div className="add-remove-button-container">
                      <NetwalaButton
                        backgroundColor={ButtonProps.background.STANDARD}
                        height={"39px"}
                        width={"39px"}
                        textColor={ButtonProps.color.STANDARD}
                        onClick={() => {
                          setShowAdd(true);
                        }}
                        text="+"
                      />
                      <NetwalaButton
                        backgroundColor={ButtonProps.background.STANDARD}
                        height={"39px"}
                        width={"39px"}
                        textColor={ButtonProps.color.STANDARD}
                        onClick={() => {
                          if (stockProfile.quantity < 1) {
                            return;
                          }
                          setShowRemove(true);
                        }}
                        text="-"
                      />
                    </div>
                  </Col>
                )}
              </Row>
              <Row
                style={{
                  width: "100%",
                }}
              >
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "40px",
                  }}
                  span={24}
                >
                  <NetwalaButton
                    backgroundColor={ButtonProps.background.STANDARD}
                    height={ButtonProps.height.STANDARD}
                    width={ButtonProps.width.STANDARD}
                    textColor={ButtonProps.color.STANDARD}
                    onClick={() => {
                      if (stockProfile.quantity < 1) {
                        return;
                      }
                      setShowSale(true);
                    }}
                    text="Sale Inventory"
                    icon={<img src={PlusIcon} />}
                  />
                </Col>
              </Row>
            </Col>
          </>
        )}
      </Row>
    </>
  );
};
export default SingleModalStockDataComponent;
