import React from "react";
import { Col, Row, Table, Typography } from "antd";
import type { TableProps } from "antd";
import CopyIcon from "./../../../assets/svgs/CopyIcon.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import { formatDate } from "../../../helpers/date";
import "./index.css";

const PendingVouchersTable: React.FC<PendingDiscountVouchersTableProp> = ({
  vouchers,
}) => {
  const columns: TableProps<PendingDiscountVoucherProp>["columns"] = [
    {
      title: "Voucher Number",
      render: (row) => (
        <Typography
          style={{ display: "flex", alignContent: "center", gap: "10px" }}
        >
          {row.voucherNumber}
          <CopyToClipboard text={row.voucherNumber || ""} onCopy={() => {}}>
            <img
              onClick={(e) => {
                e.preventDefault();
              }}
              src={CopyIcon}
              className="cursor-pointer "
            />
          </CopyToClipboard>
        </Typography>
      ),
    },
    {
      title: "Amount",
      dataIndex: "discountAmount",
      key: "discountAmount",
    },
    {
      title: "Type",
      dataIndex: "voucherType",
      key: "voucherType",
      render: (text) => (
        <span>{text === "ONCE" ? "One Time" : "Every Month"}</span>
      ),
    },
    {
      title: "Created",
      dataIndex: "dateCreated",
      key: "dateCreated",
      render: (text) => <span>{formatDate(new Date(text))}</span>,
    },
  ];

  return (
    <>
      <div className="netwala-users-table-container">
        <Row>
          <Col span={24}>
            <Typography className="netwala-user-tables-heading">
              Unused Vouchers
            </Typography>
            <Table
              className="netwala-users-table striped-table"
              columns={columns}
              dataSource={vouchers}
              pagination={false}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
export default PendingVouchersTable;
