import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import { getUserType } from "../helpers/userDetails";
import ViewActionQueue from "../components/actionQueue/ViewActionQueue";
import CustomersComponent from "../components/Customers/Index";

const HomePage = () => {
  const { setTitle } = useContext(TitleContext);
  const role = getUserType();

  useEffect(() => {
    setTitle("Connections");
  }, [setTitle]);
  const getActiveComponent = () => {
    switch (role) {
      case "CEO":
        return <CustomersComponent />;
      case "CASHIER":
        return <CustomersComponent />;
      case "RMAN":
        return <CustomersComponent />;
      case "PANEL_MANAGER":
        return <ViewActionQueue />;
    }
    return <></>;
  };
  return (
    <>
      {getActiveComponent()}
      {/* <DashboardComponent /> */}
    </>
  );
};
export default HomePage;
