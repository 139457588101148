import React, { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewAreasComponent from "../components/areas/ViewAreas";
const AreasPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Areas");
  }, [setTitle]);

  return (
    <>
      <ViewAreasComponent />
    </>
  );
};
export default AreasPage;
