import {
  checkIfAllTheDataFollowDDMMMYYYYFormat,
  checkIfAllTheDataFollowYYYYMMDDFormat,
  checkIfItsDDMMMYYYYFormatDate,
  convertRawZalproDateToActualDate,
  formatToYYYYMMDDFromTransworldRaw,
  getTransworldFormattedDate,
} from "./date";
import _ from "lodash";
const currentDate = new Date();
export const importFormatIspTypes: NetwalaSelectOptions[] = [
  {
    value: "TRANSWORLD",
    label: "Transworld",
  },
  {
    value: "NBB",
    label: "NBB",
  },
  {
    value: "ZALPRO",
    label: "Zalpro",
  },
];
export const handleTransworldDataImportAndFormulateTheData = (data: any[]) => {
  if (data.length < 1) {
    return {
      packages: [],
      users: [],
      usersWithNoPackage: [],
      error: `No Data Found`,
    };
  }

  const headers = data[0];
  const camelCaseHeaders = convertToCamelCase(headers);
  const dataRows = data.slice(1);
  let noPackage = 0;
  const formattedData: BulkUserImportData[] = [];
  const usersWithNoPackage: BulkUserImportData[] = [];
  const formatterCheckerArray: any = [];
  dataRows.map((row) => {
    const obj: any = {};
    if (row.length > 1) {
      camelCaseHeaders.forEach((header: any, index: number) => {
        obj[header] = row[index];
      });
      formatterCheckerArray.push(obj);
    }
  });
  if (formatterCheckerArray.length < 1) {
    return {
      packages: [],
      users: [],
      usersWithNoPackage: [],
      error: `No Data Found`,
    };
  }
  // const firstObj = formatterCheckerArray.find(
  //   (obj:any) => obj.expiry !== null && obj.expiry !== ""
  // ); // formatterCheckerArray[0];
  let firstNonNullNameObject = null;
  for (const obj of formatterCheckerArray) {
    if (obj.expiry && !firstNonNullNameObject) {
      firstNonNullNameObject = obj;
      break; // Once found, break out of the loop
    }
  }
  const { expiry } = firstNonNullNameObject;
  let formatType = 0;
  const isFirstType = checkIfItsDDMMMYYYYFormatDate(expiry);
  console.log({ isFirstType });
  if (isFirstType) {
    //it means we have the first type
    const doesAllFollow = checkIfAllTheDataFollowDDMMMYYYYFormat(
      formatterCheckerArray
    );
    if (!doesAllFollow) {
      return {
        packages: [],
        users: [],
        usersWithNoPackage: [],
        error: `Unknown Expiry Date Format,please contact with support!`,
      };
    }
    formatType = 1;
  } else {
    const isSecondType = formatToYYYYMMDDFromTransworldRaw(expiry);

    if (!isSecondType) {
      return {
        packages: [],
        users: [],
        usersWithNoPackage: [],
        error: `Unknown Expiry Date Format,please contact with support!`,
      };
    }
    const doesAllFollow = checkIfAllTheDataFollowYYYYMMDDFormat(
      formatterCheckerArray
    );
    if (!doesAllFollow) {
      return {
        packages: [],
        users: [],
        usersWithNoPackage: [],
        error: `Unknown Expiry Date Format,please contact with support!`,
      };
    }
    formatType = 2;
  }
  if (formatType === 0) {
    return {
      packages: [],
      users: [],
      usersWithNoPackage: [],
      error: `Unknown Expiry Date Format,please contact with support!`,
    };
  }
  dataRows.map((row) => {
    const obj: any = {};
    if (row.length > 1) {
      camelCaseHeaders.forEach((header: any, index: number) => {
        obj[header] = row[index];
      });
      //lets build the object that we need
      //name,address,username,password,expiry,mobileNo,package,city
      const { firstName, lastName } = normalizeName(obj.name);
      const id = Math.random().toString();
      const dataObject: BulkUserImportData = {
        firstName,
        lastName,
        expiryDate: getTransworldFormattedDate(obj.expiry, formatType),
        username: obj.username,
        password: obj.username,
        phoneNumber: obj["mobileNo."],
        packageName: obj.package,
        city: obj.city,
        address: obj.address,
        expiry: obj.expiry,
        name: obj.name,
        id: id,
        intPkPackageID: null,
        mappedPackageName: "",
        balance: 0,
      };
      if (!obj.package) {
        noPackage++;
        usersWithNoPackage.push(dataObject);
      } else {
        formattedData.push(dataObject);
      }
    }
  });
  const uniquePackages = _.uniqBy(formattedData, "packageName")
    .map((item) => item.packageName)
    .filter((item) => item !== "");
  console.log({
    uniquePackages: uniquePackages,
    formattedData: formattedData,
    usersWithNoPackage: usersWithNoPackage,
  });
  return {
    packages: uniquePackages,
    users: formattedData,
    usersWithNoPackage: usersWithNoPackage,
    error: null,
  };
};
export const handleZalProDataImportAndFormulateTheData = (data: any[]) => {
  if (data.length < 1) {
    return {
      packages: [],
      users: [],
      usersWithNoPackage: [],
      error: `No Data Found`,
    };
  }

  const headers = data[0];
  const camelCaseHeaders = convertToCamelCase(headers);
  console.log({ camelCaseHeaders });
  const dataRows = data.slice(1);
  let noPackage = 0;
  const formattedData: BulkUserImportData[] = [];
  const usersWithNoPackage: BulkUserImportData[] = [];
  dataRows.map((row) => {
    const obj: any = {};
    if (row.length > 1) {
      camelCaseHeaders.forEach((header: any, index: number) => {
        obj[header] = row[index];
      });
      //lets build the object that we need
      //name,address,username,password,expiry,mobileNo,package,city
      //""
      const { firstName, lastName } = normalizeName(obj.fullName);
      const id = Math.random().toString();
      const _balance = obj.balance || 0;

      const dataObject: BulkUserImportData = {
        firstName,
        lastName,
        expiryDate: convertRawZalproDateToActualDate(
          obj.expiry ? obj.expiry : currentDate
        ),
        username: obj.username,
        password: obj.username,
        phoneNumber: obj.phone,
        packageName: obj.package,
        city: obj.city,
        address: obj.address,
        expiry: obj.expiry,
        name: obj.fullName,
        id: id,
        intPkPackageID: null,
        mappedPackageName: "",
        balance: Number(_balance) >= 0 ? _balance : 0,
      };
      if (!obj.package) {
        noPackage++;
        usersWithNoPackage.push(dataObject);
      } else {
        formattedData.push(dataObject);
      }
    }
  });
  const uniquePackages = _.uniqBy(formattedData, "packageName")
    .map((item) => item.packageName)
    .filter((item) => item !== "");
  console.log({ uniquePackages, formattedData, usersWithNoPackage });
  return {
    packages: uniquePackages,
    users: formattedData,
    usersWithNoPackage: usersWithNoPackage,
    error: null,
  };
};
export const handleNBBDataImportAndFormulateTheData = (data: any[]) => {
  if (data.length < 1) {
    return {
      packages: [],
      users: [],
      usersWithNoPackage: [],
      error: `No Data Found`,
    };
  }

  const headers = data[0];
  const camelCaseHeaders = convertToCamelCase(headers);
  const dataRows = data.slice(1);
  let noPackage = 0;
  const formattedData: BulkUserImportData[] = [];
  const usersWithNoPackage: BulkUserImportData[] = [];
  console.log({ camelCaseHeaders });
  dataRows.map((row, index) => {
    const obj: any = {};
    if (row.length > 1) {
      camelCaseHeaders.forEach((header: any, index: number) => {
        obj[header] = row[index];
      });
      const { firstName, lastName } = normalizeName(obj.fullName);
      const id = Math.random().toString();
      const dataObject: BulkUserImportData = {
        firstName,
        lastName,
        expiryDate: obj.expirationDate ? obj.expirationDate : currentDate,
        username: obj.username,
        password: obj.username,
        phoneNumber: obj.mobile,
        packageName: obj.package,
        city: obj.city,
        address: obj.address,
        expiry: obj.expirationDate,
        name: obj.fullName,
        id: id,
        intPkPackageID: null,
        mappedPackageName: "",
        balance: 0,
      };
      if (!obj.package) {
        noPackage++;
        usersWithNoPackage.push(dataObject);
      } else {
        formattedData.push(dataObject);
      }
    }
  });
  const uniquePackages = _.uniqBy(formattedData, "packageName")
    .map((item) => item.packageName)
    .filter((item) => item !== "");
  console.log({ uniquePackages, formattedData, usersWithNoPackage });
  return {
    packages: uniquePackages,
    users: formattedData,
    usersWithNoPackage: usersWithNoPackage,
    error: null,
  };
};
const convertToCamelCase = (headers: string[]) => {
  const toCamelCase = (str: string) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, ""); // Remove spaces
  };

  // Convert headers to camel case
  const camelCaseHeaders = headers.map((header: string) => toCamelCase(header));
  return camelCaseHeaders;
};
const normalizeName = (fullName: string) => {
  const names = fullName.split(" ");

  if (names.length === 1) {
    return { firstName: fullName, lastName: "" };
  } else if (names.length === 2) {
    return { firstName: names[0], lastName: names[1] };
  } else {
    const firstName = names[0];
    const lastName = names.slice(1).join(" ");
    return { firstName, lastName };
  }
};
