import { Col, Form, Input, Row, Upload, Button, message } from "antd";

import NetwalaSelect from "../Items/Select/Index";
import { useContext, useEffect, useState } from "react";
import { CameraOutlined, UploadOutlined } from "@ant-design/icons";
const { TextArea } = Input;
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import PlusIcon from "./../../assets/svgs/IconsWhite/PlusIcon.svg";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
} from "antd/lib/upload/interface";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import BalanceContext from "../../context/BalanceContext";
import { removeSpacesFromString } from "../../helpers/strings";

const CreateCompanyWithAdminComponent = () => {
  const { value, setValue } = useContext(BalanceContext);

  const navigate = useNavigate();
  const { openNotification, contextHolder } = useMultiNotification();
  const [form] = Form.useForm();

  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<CreateCompanyProp>("auth/SignUpCEOByPhone", true);

  const onFinish = (values: any) => {
    const data: CreateCompanyProp = {
      phoneNumber: values.phoneNumber,
      firstName: values.firstName,
      lastName: values.lastName,
      address: values.address,
      password: values.password,
      companyName: values.companyName,
      staffUserName: removeSpacesFromString(values.staffUserName),
      nicNumber: "StaticRandom",
    };

    postApiData(data);
  };

  const onFinishFailed = (errorInfo: any) => {};

  useEffect(() => {
    if (apiResponse !== null) {
      const { message } = apiResponse;
      if (message) {
        setValue(value + 1);
        openNotification(`success`, "Success", message);
        setTimeout(() => {
          navigate(-1);
        }, 2500);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", apiError);
    }
  }, [apiError, apiErrorCount]);

  return (
    <>
      {contextHolder}

      <Row style={{ width: "100%" }}>
        <Form
          name="createCompanyWithAdminForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          requiredMark={false}
          style={{ width: "100%" }}
          form={form}
        >
          {/* First */}
          {/* " "password":"password123", 
          "address":"Lahore", "nicNumber"
           */}
          <Row gutter={16}>
            {/* Company Name */}
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Company Name</span>
                }
                name="companyName"
                rules={[
                  {
                    required: true,
                    message: "Please enter Company Name",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="Test Company"
                />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
            {/* First */}
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">First Name</span>
                }
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please enter First Name",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="John"
                />
              </Form.Item>
            </Col>
            {/* Last */}
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Last Name</span>
                }
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please enter Last Name",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="Doe"
                />
              </Form.Item>
            </Col>
            {/* Username */}

            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">UserName</span>}
                name="staffUserName"
                rules={[
                  {
                    required: true,
                    message: "Please enter UserName",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="03001234567"
                />
              </Form.Item>
            </Col>
            {/* Password */}
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">Password</span>}
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter Password",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="password"
                />
              </Form.Item>
            </Col>
            {/* Phone */}
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Phone Number</span>
                }
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter Phone Number",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="03001234567"
                />
              </Form.Item>
            </Col>
            {/* Address */}
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">Address</span>}
                name="address"
                rules={[
                  {
                    required: true,
                    message: "Please enter Address",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="House No."
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              style={{ display: "flex", justifyContent: "center" }}
              span={24}
            >
              <Form.Item>
                <NetwalaButton
                  htmlType="submit"
                  backgroundColor={ButtonProps.background.STANDARD}
                  height={ButtonProps.height.STANDARD}
                  width={ButtonProps.width.STANDARD}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {
                    console.log("clicked");
                  }}
                  text="Create New Company "
                  icon={<img src={PlusIcon} />}
                  disabled={apiLoading}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Row>
    </>
  );
};
export default CreateCompanyWithAdminComponent;
