import React, { useEffect, useState } from "react";
import { Row, Col, Typography } from "antd";
import "./comingSoon.css";
import ComingSoon from "./../../assets/svgs/ComingSoon.svg";
const ComingSoonComponent = () => {
  return (
    <>
      <Row style={{ width: "100%" }}>
        <Col span={24} className="coming-soon-container">
          <Typography className="coming-soon-heading">Coming Soon</Typography>
          <img src={ComingSoon} />
          <Typography className="coming-soon-para">
            We are working in this feature and this will available for you soon.
          </Typography>
        </Col>
      </Row>
    </>
  );
};
export default ComingSoonComponent;
