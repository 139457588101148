import { useEffect, useState } from "react";
import { Row, Col, Typography, Form, Input, Card, Select, Button } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import useMultiNotification from "../../../hooks/useNotification";
import useAxiosPost from "../../../hooks/useAxiosPost";
import NetwalaButton from "../../Items/Button/Index";
import { ButtonProps } from "../../../helpers/constants";
import InventoryItemTypesTable from "../../Items/Table/InventoryItemTypesTable";
import AddOrUpdateInventoryItemTypesModal from "../../Modals/AddOrUpdateInventoryItemTypesModal";

const ViewInventoryItemTypesComponent = () => {
  const navigate = useNavigate();

  const { openNotification, contextHolder } = useMultiNotification();
  const [show, setShow] = useState<boolean>(false);
  const { intPkInventoryItemID } = useParams();
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<InventoryItemTypesByInventoryItemIDApiProp>(
    "superAdmin/getInventoryItemTypesByInventoryItemId"
  );
  const [itemTypes, setItemTypes] = useState<InventoryItemTypeProp[]>([]);
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setItemTypes(data || []);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({ intPkInventoryItemID: Number(intPkInventoryItemID) });
  }, []);
  const handleClose = (isReload: boolean) => {
    setShow(false);
    if (isReload === true) {
      postApiData({ intPkInventoryItemID: Number(intPkInventoryItemID) });
    }
  };
  const reloadApi = () => {
    postApiData({ intPkInventoryItemID: Number(intPkInventoryItemID) });
  };
  return (
    <>
      {contextHolder}

      {show === true && (
        <AddOrUpdateInventoryItemTypesModal
          itemType={null}
          show={show}
          handleClose={handleClose}
          url={`addNewInventoryItemType`}
          intPkInventoryItemID={Number(intPkInventoryItemID)}
        />
      )}
      <Row style={{ marginBottom: "20px" }} gutter={[16, 16]}>
        <Col offset={18} span={6}>
          <NetwalaButton
            backgroundColor={ButtonProps.background.STANDARD}
            height={ButtonProps.height.STANDARD}
            width={ButtonProps.width.STANDARD}
            textColor={ButtonProps.color.STANDARD}
            onClick={() => {
              setShow(true);
            }}
            text="Create New Item Type"
          />
        </Col>
      </Row>
      <Row style={{ width: "100%" }} gutter={30}>
        <Col span={24}>
          <InventoryItemTypesTable
            isLoading={apiLoading}
            itemTypes={itemTypes}
            reloadApi={reloadApi}
          />
        </Col>
      </Row>
    </>
  );
};
export default ViewInventoryItemTypesComponent;
