import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import UpdatePackageForISPComponent from "../components/isps/SingleIspProfile/components/packages/UpdatePackage";

const UpdatePackagePage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Update Package");
  }, [setTitle]);
  return (
    <>
      <UpdatePackageForISPComponent />
    </>
  );
};
export default UpdatePackagePage;
