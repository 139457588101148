import React, { useState } from "react";

import { Col, Row, Table, Typography } from "antd";
import type { TableProps } from "antd";

import "./index.css";

import TailSpinLoader from "../Loaders/TailSpinLoader";
import ViewReceiptIcon from "./../../../assets/svgs/ViewReceiptIcon.svg";

import { formatDate } from "../../../helpers/date";
import ViewReceiptModal from "../../Modals/ViewReceiptModal";

const RecentExpensesTable: React.FC<RecentExpensesTable> = ({
  expenses,
  isLoading,
  title,
  isCEOView,
}) => {
  const [showSlipModal, setShowSlipModal] = useState<boolean>(false);
  const [intPkResourceFileID, setIntPkResourceFileID] = useState<number | null>(
    null
  );
  const handleCloseSliPModal = (isReload: boolean) => {
    setIntPkResourceFileID(null);
    setShowSlipModal(true);
  };
  const columns: TableProps<RecentExpensesProp>["columns"] = [
    // {
    //   title: "S.No",
    //   dataIndex: "intPkTransactionHeadID",
    //   key: "intPkTransactionHeadID",
    //   render: (text) => <span>{text < 10 ? `0${text}` : text}</span>,
    // },
    {
      title: "TRX Date & Time",
      dataIndex: "dateCreated",
      key: "dateCreated",
      render: (text) => (
        <span className="transaction-date-value">
          {formatDate(new Date(text))}
        </span>
      ),
    },
    {
      title: "Type",
      dataIndex: "expenseTag",
      key: "expenseTag",
    },

    {
      title: "View Receipt",
      render: (row) => (
        <img
          onClick={(e) => {
            // console.log(row);
            e.preventDefault();
            setShowSlipModal(true);
            setIntPkResourceFileID(row.intPkResourceFileID);
          }}
          src={ViewReceiptIcon}
          className="cursor-pointer"
        />
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => <span className="out-amount-label"> -{text}</span>,
    },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
    },
  ];
  if (isCEOView) {
    columns.push({
      title: "Staff Name",
      render: (row) => <span>{row.firstName + " " + row.lastName}</span>,
    });
  }
  return (
    <>
      {showSlipModal === true && intPkResourceFileID !== null && (
        <ViewReceiptModal
          show={showSlipModal}
          handleClose={handleCloseSliPModal}
          intPkResourceFileID={intPkResourceFileID}
        />
      )}

      <div style={{ width: "100%" }} className="netwala-users-table-container">
        {isLoading === true ? (
          <TailSpinLoader />
        ) : (
          <Row>
            <Col span={24}>
              <div>
                <Typography className="netwala-user-tables-heading">
                  {title}
                </Typography>
              </div>
              <Table
                className="netwala-users-table striped-table"
                columns={columns}
                dataSource={expenses}
                pagination={false}
              />
            </Col>
            <Col className="view-more-container" span={24}>
              <Typography className="cursor-pointer">
                View More&gt;&gt;
              </Typography>
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};
export default RecentExpensesTable;
