import React from "react";
import NetwalaModal from "../Items/Modal/Index";
import { Col, Row, Typography } from "antd";
import "./styles/confirmInternetPackage.css";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";

const ConfirmInventorySaleModal: React.FC<ConfirmInventorySaleProp> = ({
  handleConfirmation,
  confirmationData,
  apiLoading,
}) => {
  return (
    <>
      <Row>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            gap: "20px",
            alignItems: "center",
            paddingLeft: "20px",
            paddingBottom: "30px",
          }}
          span={24}
        >
          <Row className="confirm-connection-top-row">
            <Col offset={2} span={10}>
              <Typography className="confirmation-connection-label">
                User Details
              </Typography>
              <Typography className="confirmation-connection-value">
                {confirmationData.username}
              </Typography>
            </Col>
          </Row>
          <Row className="confirmation-connection-header-blue">
            <Col offset={2} span={10}>
              <Typography className="confirmation-connection-label">
                Item Details
              </Typography>
            </Col>
          </Row>
          <Row className="confirmation-connection-header-values">
            <Col offset={2} span={10}>
              <Typography className="confirmation-connection-label-blue">
                Item Name
              </Typography>
            </Col>
            <Col offset={4} span={8}>
              <Typography className="confirmation-connection-value">
                {confirmationData.itemName}
              </Typography>
            </Col>
            <Col offset={2} span={10}>
              <Typography className="confirmation-connection-label-blue">
                Item Type
              </Typography>
            </Col>
            <Col offset={4} span={8}>
              <Typography className="confirmation-connection-value">
                {confirmationData.itemType}
              </Typography>
            </Col>

            <Col offset={2} span={10}>
              <Typography className="confirmation-connection-label-blue">
                Quantity
              </Typography>
            </Col>
            <Col offset={4} span={8}>
              <Typography className="confirmation-connection-value">
                {confirmationData.quantity}{" "}
              </Typography>
            </Col>
          </Row>

          <Row className="confirmation-connection-header-blue">
            <Col offset={2} span={10}>
              <Typography className="confirmation-connection-label">
                Payment Details
              </Typography>
            </Col>
          </Row>
          <Row className="confirmation-connection-header-values">
            <Col offset={2} span={10}>
              <Typography className="confirmation-connection-label-blue">
                Unit Price
              </Typography>
            </Col>
            <Col offset={4} span={8}>
              <Typography className="confirmation-connection-value">
                {confirmationData.unitPrice}{" "}
              </Typography>
            </Col>
            <Col offset={2} span={10}>
              <Typography className="confirmation-connection-label-blue">
                Total Price
              </Typography>
            </Col>
            <Col offset={4} span={8}>
              <Typography className="confirmation-connection-value">
                {confirmationData.totalPrice}{" "}
              </Typography>
            </Col>
          </Row>

          <div className="total-payment-details-confirmation-connection-container">
            <Typography className="total-amount-receipt-label">
              Total Amount
            </Typography>{" "}
            <Typography className="total-amount-receipt-value">
              {confirmationData.totalPrice}{" "}
            </Typography>
          </div>

          <div className="confirmation-action-queue-container">
            <NetwalaButton
              backgroundColor={ButtonProps.background.DANGER}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.ONE24}
              textColor={ButtonProps.color.STANDARD}
              onClick={() => handleConfirmation(true)}
              text="Confirm"
              borderColor="#DF3131"
              disabled={apiLoading}
            />
            <NetwalaButton
              backgroundColor={ButtonProps.background.SECONDARY}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.ONE24}
              textColor={ButtonProps.color.BLACK}
              onClick={() => handleConfirmation(false)}
              disabled={apiLoading}
              text="Cancel"
              //   icon={<img src={PlusIcon} />}
              borderColor="#D7D7D7"
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ConfirmInventorySaleModal;
