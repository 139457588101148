import React, { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewSingleStaffComponent from "../components/staff/singleStaff/ViewSingleStaff";
import { getUserType } from "../helpers/userDetails";
import ViewSingleStaffBasic from "../components/staff/singleStaff/ViewSingleStaffBasic";
const StaffProfilePage = () => {
  const { setTitle } = useContext(TitleContext);
  const role = getUserType();

  useEffect(() => {
    setTitle("Staff Profile");
  }, [setTitle]);
  const getActiveComponent = () => {
    switch (role) {
      case "CEO":
        return <ViewSingleStaffComponent />;
      case "CASHIER":
        return <ViewSingleStaffBasic />;
    }
    return <></>;
  };
  return <>{getActiveComponent()}</>;
};
export default StaffProfilePage;
