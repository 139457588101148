import React from "react";
import "./styles.css";

const ZipUnzipAppPrivacyPolicyPage = () => {
  return (
    <div className="policy-page">
      <header className="privacy-policy-main-heading">
        <h1>Privacy Policy for Rar Extractor - Zip Unzip App</h1>
      </header>
      <section className="policy-section">
        <h2>Introduction</h2>
        <p>
          This Privacy Policy describes how we collects, uses, and discloses
          your information in connection with your use of our Rar Extractor -
          Zip Unzip application.
        </p>
      </section>
      <section className="policy-section">
        <h2>Information We Collect</h2>
        <p>We collect two main types of information through the App:</p>
        <p>
          Device Information: This may include basic information about your
          device, such as its model, operating system version, and unique
          identifiers. This information helps us understand how you interact
          with the App and improve its performance.
        </p>
        <p>
          Files and Data: The App requires access to your device's storage to
          allow you to select compressed files (like RAR and ZIP) for
          extraction. We do not access or store the actual content of the files
          you extract.
        </p>
      </section>

      <section className="policy-section">
        <h2>How We Use Your Information</h2>
        <p>We use the information we collect for the following purposes:</p>
        <p>
          To provide the App functionality: Device information is used to ensure
          the App functions properly on your device and to optimize the
          extraction process.
        </p>
        <p>
          App Analytics (Anonymized): We may use anonymized data on App usage to
          analyze trends and improve the App's features and functionality.
        </p>
      </section>

      <section className="policy-section">
        <h2>Security</h2>
        <p>
          We take reasonable steps to protect the information we collect from
          loss, misuse, and unauthorized access.
        </p>
        <p>
          We do not access or store the actual content of the files you extract
          through the App.
        </p>
      </section>

      <section className="policy-section">
        <h2>Children's Privacy</h2>
        <p>
          Our App is not directed to children under the age of 13. We do not
          knowingly collect personal information from children under 13. If you
          are a parent or guardian and you believe your child has provided us
          with personal information, please contact us.
        </p>
      </section>

      <section className="policy-section">
        <h2>Changes to This Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on the App.
        </p>
      </section>
      <section className="policy-section">
        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Policy, please contact us at
          info@zipunzipfiles.com
        </p>
      </section>
      <footer className="privacy-policy-footer">
        <p>Last Updated: March 1, 2024</p>
      </footer>
    </div>
  );
};

export default ZipUnzipAppPrivacyPolicyPage;
