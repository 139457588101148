import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewInventoryModelStockDetailsComponent from "../components/inventory/ViewInventoryModelStockDetails";

const ViewInventoryModelStockPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Stock Details");
  }, [setTitle]);
  return (
    <>
      <ViewInventoryModelStockDetailsComponent />
    </>
  );
};
export default ViewInventoryModelStockPage;
