import { Col, Form, Input, Row, Upload, Button, message } from "antd";

import NetwalaSelect from "../Items/Select/Index";
import { useContext, useEffect, useState } from "react";
import { CameraOutlined, UploadOutlined } from "@ant-design/icons";
const { TextArea } = Input;
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import PlusIcon from "./../../assets/svgs/IconsWhite/PlusIcon.svg";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
} from "antd/lib/upload/interface";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import BalanceContext from "../../context/BalanceContext";

const CreateCATVForm = () => {
  const { value, setValue } = useContext(BalanceContext);

  const navigate = useNavigate();
  const { openNotification, contextHolder } = useMultiNotification();
  const { intPkHeadID } = useParams();
  const [form] = Form.useForm();
  const [
    getAreasApi,
    areasApiResponse,
    areasApiError,
    areasApiLoading,
    areasApiMessage,
    areasApiErrorCount,
  ] = useAxiosPost<ResponseProp>("areas/getAreasByOwner");
  const [
    getSubAreasApi,
    subAreasApiResponse,
    subAreasApiError,
    subAreasApiLoading,
    subAreasApiMessage,
    subAreasApiErrorCount,
  ] = useAxiosPost<SubAreasByAreaIDApiProp>("areas/getSubAreasByAreaID");
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<CreateCATVConnectionApiProp>(
    "connections/createCATVConnection",
    true
  );

  const onFinish = (values: any) => {
    const dateFormat = "YYYY-MM-DD";
    const currentDate = new Date(); // Get the current date
    currentDate.setMonth(currentDate.getMonth() + 1); // Add one month
    const defaultDate = moment(new Date(currentDate))
      .format(dateFormat)
      .toString();
    console.log({ amountPaid: values.amountPaid });
    const dataToSend: CreateCATVConnectionApiProp = {
      areaId: values.subArea ? values.subArea : values.area,
      address: values.address,
      sale_price: values.sale_price,
      expiryDate: defaultDate,
      voucherNumber: values.voucherNumber,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      amountPaid: values.amountPaid,
    };
    // console.log({ dataToSend, values });
    postApiData(dataToSend);
  };
  const [salePrice, setSalePrice] = useState<number>(0);
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedSubArea, setSelectedSubArea] = useState<null | string>(null);
  const [areas, setAreas] = useState<NetwalaSelectOptions[]>([]);
  const [subAreas, setSubAreas] = useState<NetwalaSelectOptions[]>([]);

  useEffect(() => {
    getAreasApi({});
  }, []);
  useEffect(() => {
    if (areasApiResponse !== null) {
      const data: any = areasApiResponse.responseData;
      const areas = data.map((item: any): NetwalaSelectOptions => {
        return {
          value: item.intPkAreaID,
          label: item.areaName,
        };
      });

      setAreas(areas);
    }
  }, [areasApiResponse]);
  useEffect(() => {
    if (subAreasApiResponse !== null) {
      const data: any = subAreasApiResponse.responseData;
      const subAreas = data.map((item: any): NetwalaSelectOptions => {
        return {
          value: item.intPkAreaID,
          label: item.areaName,
        };
      });

      setSubAreas(subAreas);
    }
  }, [subAreasApiResponse]);

  const onFinishFailed = (errorInfo: any) => {};
  const handleAreaChange = (value: string) => {
    setSelectedSubArea(null);
    form.setFieldsValue({ subArea: undefined });

    getSubAreasApi({ intPkAreaID: Number(value) });
  };
  useEffect(() => {
    if (apiResponse !== null) {
      const { message } = apiResponse;
      if (message) {
        setValue(value + 1);
        openNotification(`success`, "Success", message);
        setTimeout(() => {
          navigate(-1);
        }, 2500);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", apiError);
    }
  }, [apiError, apiErrorCount]);
  useEffect(() => {
    form.setFieldsValue({ amountPaid: salePrice });
  }, [salePrice]);
  return (
    <>
      {contextHolder}

      <Row style={{ width: "100%" }}>
        <Form
          name="createUserForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          requiredMark={false}
          style={{ width: "100%" }}
          form={form}
        >
          {/* First */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">First Name</span>
                }
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please enter First Name",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="John"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Last Name</span>
                }
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please enter Last Name",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="Doe"
                />
              </Form.Item>
            </Col>
          </Row>
          {/* Second */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Phone Number</span>
                }
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter Phone number",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="03001235467"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">Address</span>}
                name="address"
                rules={[
                  {
                    required: true,
                    message: "Please enter Address",
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="House No."
                />
              </Form.Item>
            </Col>
          </Row>
          {/* Third */}

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">Area</span>}
                name="area"
                rules={[
                  {
                    required: true,
                    message: "Please Select Area",
                  },
                ]}
              >
                <NetwalaSelect
                  value={selectedArea}
                  onChange={handleAreaChange}
                  size="large"
                  height="45px"
                  options={areas}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<span className="create-user-form-label">Sub Area</span>}
                name="subArea"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please enter Sub Area",
                //   },
                // ]}
              >
                <NetwalaSelect
                  value={selectedSubArea}
                  size="large"
                  height="45px"
                  options={subAreas}
                />
              </Form.Item>
            </Col>
          </Row>
          {/* Fourth */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Sale Price</span>
                }
                name="sale_price"
                rules={[
                  {
                    required: true,
                    message: "Please enter Sale Price",
                  },
                  {
                    validator: (_, value) =>
                      value > 0
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("Sale Price must be greater than 0")
                          ),
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="300"
                  type="number"
                  value={salePrice}
                  onChange={(e) => setSalePrice(Number(e.target.value))}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Voucher Number</span>
                }
                name="voucherNumber"
              >
                <Input
                  className="user-input-general-height"
                  placeholder="ABCDEFG21"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={
                  <span className="create-user-form-label">Amount Paid</span>
                }
                name="amountPaid"
                rules={[
                  {
                    required: true,
                    message: "Please enter Amount Paid",
                  },
                  {
                    validator: (_, value) =>
                      value >= 0
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(
                              "Amount Paid must be greater than or equal to zero"
                            )
                          ),
                  },
                ]}
              >
                <Input
                  className="user-input-general-height"
                  placeholder="300"
                  type="number"
                  value={salePrice}
                  onChange={(e) => setSalePrice(Number(e.target.value))}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col
              style={{ display: "flex", justifyContent: "center" }}
              span={24}
            >
              <Form.Item>
                <NetwalaButton
                  htmlType="submit"
                  backgroundColor={ButtonProps.background.STANDARD}
                  height={ButtonProps.height.STANDARD}
                  width={ButtonProps.width.STANDARD}
                  textColor={ButtonProps.color.STANDARD}
                  onClick={() => {
                    console.log("clicked");
                  }}
                  text="Create CATV "
                  icon={<img src={PlusIcon} />}
                  disabled={apiLoading}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Row>
    </>
  );
};
export default CreateCATVForm;
