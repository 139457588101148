import React, { useState, CSSProperties, useEffect } from "react";

import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize,
} from "react-papaparse";
import { Row, Col, Typography, Upload, Button, Alert } from "antd";

import "./index.css";
import useAxiosPost, {
  authTokenString,
} from "../../../../../hooks/useAxiosPost";
import { useNavigate } from "react-router-dom";
import NetwalaSelect from "../../../../Items/Select/Index";
import {
  handleNBBDataImportAndFormulateTheData,
  handleTransworldDataImportAndFormulateTheData,
  handleZalProDataImportAndFormulateTheData,
  importFormatIspTypes,
} from "../../../../../helpers/bulkUsers";
import BulkUsersImportTable from "../../../../Items/Table/BulkUsersImportDataTable";
import PackageMapperTable from "../../../../Items/Table/PackageMapperTable";
import NetwalaButton from "../../../../Items/Button/Index";
import { ButtonProps } from "../../../../../helpers/constants";
import useMultiNotification from "../../../../../hooks/useNotification";
import ImportUserConfirmationModal from "../../../../Modals/ImportUserConfirmationModal";
import CreatePackageOnTheGoModalComponent from "../../../../Modals/CreatePackageOnTheGoModal";
import PlusIcon from "./../../../../../assets/svgs/IconsWhite/PlusIcon.svg";
import BackButtonLong from "./../../../../../assets/svgs/BackButtonLong.svg";
import { socket } from "../../../../../socketService";
import TailSpinLoader from "../../../../Items/Loaders/TailSpinLoader";

const GREY = "#CCC";
const GREY_LIGHT = "rgba(255, 255, 255, 0.4)";
const DEFAULT_REMOVE_HOVER_COLOR = "#A01919";
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
);
const GREY_DIM = "#686868";

const styles = {
  zone: {
    cursor: "pointer",
    alignItems: "center",
    border: `2px dashed ${GREY}`,
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    padding: 20,
  } as CSSProperties,
  file: {
    background: "linear-gradient(to bottom, #EEE, #DDD)",
    borderRadius: 20,
    display: "flex",
    height: 120,
    width: 120,
    position: "relative",
    zIndex: 10,
    flexDirection: "column",
    justifyContent: "center",
  } as CSSProperties,
  info: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 10,
  } as CSSProperties,
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: "0.5em",
    justifyContent: "center",
    display: "flex",
  } as CSSProperties,
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: "0.5em",
  } as CSSProperties,
  progressBar: {
    bottom: 14,
    position: "absolute",
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
  } as CSSProperties,
  zoneHover: {
    borderColor: GREY_DIM,
  } as CSSProperties,
  default: {
    borderColor: GREY,
  } as CSSProperties,
  remove: {
    height: 23,
    position: "absolute",
    right: 6,
    top: 6,
    width: 23,
  } as CSSProperties,
};

const ISPBulkUserImport: React.FC<SingleISPProfileTabProp> = ({
  intPkIspID,
}) => {
  const { openNotification, contextHolder } = useMultiNotification();
  socket;
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR
  );
  const [expiryDateError, setExpiryDateError] = useState<string | null>(null);
  const [selectedFormat, setSelectedFormat] = useState<string>("");
  const [isSelectorDisabled, setIsSelectorDisabled] = useState<boolean>(false);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const [ispPackages, setIspPackages] = useState<string[]>([]);
  const [users, setUsers] = useState<BulkUserImportData[]>([]);
  const [chunks, setChunks] = useState<BulkUserImportData[][]>([]);
  const [totalChunks, setTotalChunks] = useState<number>(0);
  const [chunkSize, setChunkSize] = useState<number>(250);
  const [show, setShow] = useState<boolean>(false);
  const [showCreatePackage, setShowCreatePackage] = useState<boolean>(false);
  const [usersWithNoPackage, setUsersWithNoPackage] = useState<
    BulkUserImportData[]
  >([]);
  const [activeStep, setActiveStep] = useState<number>(1);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPackagesMapped, setIsPackagesMapped] = useState<boolean>(false);
  const navigate = useNavigate();
  const [packages, setPackages] = useState<PackageWithAsset[]>([]);
  const [mappedPackages, setMappedPackages] = useState<MappedPackagesProp[]>(
    []
  );
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<PackagesAndVlansByISPApiProp>("packages/getPackagesByISP");
  const [
    createNewBulkRequestApi,
    bulkRequestApiResponse,
    bulkRequestApiError,
    bulkRequestApiLoading,
    bulkRequestApiMessage,
    bulkRequestApiErrorCount,
  ] = useAxiosPost<CreateNewBulkRequestApiProp>("isps/createBulkImportRequest");

  const [
    bulkImportUsersByIspApi,
    usersImportRequestApiResponse,
    usersImportRequestApiError,
    usersImportRequestApiLoading,
    usersImportRequestApiMessage,
    usersImportRequestApiErrorCount,
  ] = useAxiosPost<BulkImportUsersApiRequest>("isps/bulkImportUser");
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setPackages(data);
    }
  }, [apiResponse]);

  useEffect(() => {
    postApiData({ ispId: intPkIspID });
    socket.emit(
      "join",
      {
        userName: localStorage.getItem("username") || "client",
        client: "web",
      },
      () => {}
    );
  }, []);
  const handleSelectedFormat = (value: string) => {
    setSelectedFormat(value);
  };
  const handleUploadRawData = (data: any[], _selectedFormat: string) => {
    setIsSelectorDisabled(true);
    switch (selectedFormat) {
      case "TRANSWORLD":
        {
          const result = handleTransworldDataImportAndFormulateTheData(data);
          const { users, packages, usersWithNoPackage, error } = result;
          if (error !== null) {
            setExpiryDateError(error);
            return;
          }
          setIsDataLoaded(true);
          setUsers(users);
          setIspPackages(packages);
          setUsersWithNoPackage(usersWithNoPackage);
        }
        break;
      case "ZALPRO":
        {
          const result = handleZalProDataImportAndFormulateTheData(data);
          const { users, packages, usersWithNoPackage, error } = result;
          if (error !== null) {
            setExpiryDateError(error);
            return;
          }
          setIsDataLoaded(true);
          setUsers(users);
          setIspPackages(packages);
          setUsersWithNoPackage(usersWithNoPackage);
        }
        break;
      case "NBB":
        {
          const result = handleNBBDataImportAndFormulateTheData(data);

          const { users, packages, usersWithNoPackage, error } = result;
          if (error !== null) {
            setExpiryDateError(error);
            return;
          }
          setIsDataLoaded(true);
          setUsers(users);
          setIspPackages(packages);
          setUsersWithNoPackage(usersWithNoPackage);
        }
        break;
    }
  };
  const handleUserSkip = (id: string) => {
    console.log({ id });
    const updatedUsers = users.filter((item) => item.id !== id);

    console.log({ updatedUsers });
    setUsers(updatedUsers);
  };
  const handlePackageMapper = (mappedData: MappedPackagesProp[]) => {
    console.log(mappedData);
    setMappedPackages(mappedData);
    const updatedUsers = users.map((user) => {
      const matchingPackage = mappedData.find(
        (pkg) => pkg.packageName === user.packageName
      );
      if (matchingPackage) {
        user.intPkPackageID = matchingPackage.packageId;
        user.mappedPackageName = matchingPackage.mappedPackageName;
      }
      return user;
    });
    setUsers(updatedUsers);
    setIsPackagesMapped(true);
    setActiveStep(2);
  };
  const chunkData = (_users: BulkUserImportData[]) => {
    const chunks = [];
    for (let i = 0; i < _users.length; i += chunkSize) {
      chunks.push(_users.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const handleBulkImportApi = () => {
    console.log({ usersBeforeUpload: users });
    socket.emit("uploadBulkUsersData", {
      users: users,
      intPkIspID: Number(intPkIspID),
      authToken: authTokenString,
    });
    setIsLoading(true);
    return;

    // const chunks = chunkData(users);
    // setChunks(chunks);

    // setTotalChunks(chunks.length);
    // setIsLoading(true);
    // createNewBulkRequestApi({
    //   intPkIspID,
    //   totalChunks: chunks.length,
    // });
  };
  useEffect(() => {
    if (bulkRequestApiError !== null && bulkRequestApiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", bulkRequestApiError);
    }
  }, [bulkRequestApiError, bulkRequestApiErrorCount]);

  useEffect(() => {
    if (
      usersImportRequestApiError !== null &&
      usersImportRequestApiErrorCount !== 0
    ) {
      openNotification(`error`, "Operation Failed", usersImportRequestApiError);
    }
  }, [usersImportRequestApiError, usersImportRequestApiErrorCount]);

  useEffect(() => {
    if (bulkRequestApiResponse !== null) {
      const data: any = bulkRequestApiResponse.responseData;
      const id = data.id;
      console.log({ id });

      if (id) {
        //lets send the data
        for (let i = 0; i < chunks.length; i++) {
          bulkImportUsersByIspApi({
            intPkIspID,
            requestId: id,
            chunkNumber: i + 1,
            users: chunks[i],
          });
        }
      }
    }
  }, [bulkRequestApiResponse]);

  useEffect(() => {
    if (usersImportRequestApiResponse !== null) {
      const data: any = usersImportRequestApiResponse.responseData;
      const { message } = usersImportRequestApiResponse;
      if (data.isRequestCompleted === true && message) {
        console.log({ data, message });
        openNotification(`success`, "Success", message);
        navigate("/");
      }
    }
  }, [usersImportRequestApiResponse]);
  const handleClose = (isSelected: boolean) => {
    setShow(false);
    if (isSelected === true) {
      console.log({ users });
      handleBulkImportApi();
    }
  };
  const handlePackageCreateClose = (isReload: boolean) => {
    if (isReload) {
      postApiData({ ispId: intPkIspID });
    }
    setShowCreatePackage(false);
  };
  useEffect(() => {
    socket.on(`errorUploadingUsers`, (data) => {
      console.log({ data });
      openNotification(
        `error`,
        "Operation Failed",
        data.message || "Error While Uploading"
      );
      setIsLoading(false);
    });
    socket.on(`bulkUserUploaded`, (data) => {
      console.log({ data });
      openNotification(
        `success`,
        "Success",
        data.message || "Users Uploaded Successfully"
      );
      setTimeout(() => {
        navigate("/");
      }, 2500);
    });
  }, []);

  return (
    <>
      <Row
        gutter={[32, 32]}
        style={{ width: "100%", marginBottom: "20px", marginTop: "20px" }}
      >
        <Col offset={18} span={6}>
          <NetwalaButton
            backgroundColor={ButtonProps.background.STANDARD}
            height={ButtonProps.height.STANDARD}
            width={ButtonProps.width.STANDARD}
            textColor={ButtonProps.color.STANDARD}
            icon={<img src={PlusIcon} />}
            onClick={() => {
              setShowCreatePackage(true);
            }}
            text="Create Package"
          />
        </Col>
      </Row>
      {show && (
        <ImportUserConfirmationModal show={show} handleClose={handleClose} />
      )}

      {showCreatePackage && (
        <CreatePackageOnTheGoModalComponent
          show={showCreatePackage}
          handleClose={handlePackageCreateClose}
          intPkIspID={intPkIspID}
        />
      )}
      {contextHolder}
      <Row gutter={[16, 16]} style={{ width: "100%", marginTop: "20px" }}>
        <Col offset={6} span={12}>
          <label className="bulk-user-import-label">Choose ISP Format</label>
          <NetwalaSelect
            value={selectedFormat}
            onChange={handleSelectedFormat}
            size="large"
            height="45px"
            options={importFormatIspTypes}
            disabled={isSelectorDisabled}
          />
        </Col>
        {selectedFormat && (
          <Col offset={6} span={12}>
            <CSVReader
              onUploadAccepted={(results: any) => {
                console.log("---------------------------");
                console.log(results);
                handleUploadRawData(results.data, selectedFormat);
                console.log("---------------------------");
                setZoneHover(false);
              }}
              onDragOver={(event: DragEvent) => {
                event.preventDefault();
                setZoneHover(true);
              }}
              onDragLeave={(event: DragEvent) => {
                event.preventDefault();
                setZoneHover(false);
              }}
            >
              {({
                getRootProps,
                acceptedFile,
                ProgressBar,
                getRemoveFileProps,
                Remove,
              }: any) => (
                <>
                  <div
                    {...getRootProps()}
                    style={Object.assign(
                      {},
                      styles.zone,
                      zoneHover && styles.zoneHover
                    )}
                  >
                    {acceptedFile ? (
                      <>
                        <div style={styles.file}>
                          <div style={styles.info}>
                            <span style={styles.size}>
                              {formatFileSize(acceptedFile.size)}
                            </span>
                            <span style={styles.name}>{acceptedFile.name}</span>
                          </div>
                          <div style={styles.progressBar}>
                            <ProgressBar />
                          </div>
                          <div
                            {...getRemoveFileProps()}
                            style={styles.remove}
                            onMouseOver={(event: Event) => {
                              event.preventDefault();
                              setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                            }}
                            onMouseOut={(event: Event) => {
                              event.preventDefault();
                              setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                            }}
                          >
                            <Remove color={removeHoverColor} />
                          </div>
                        </div>
                      </>
                    ) : (
                      "Drop CSV file here or click to upload"
                    )}
                  </div>
                </>
              )}
            </CSVReader>
          </Col>
        )}
        {expiryDateError !== null && (
          <Col offset={6} span={12}>
            <Alert
              message="Error"
              description={expiryDateError}
              type="error"
              showIcon
            />
          </Col>
        )}
        {activeStep > 1 && (
          <Col span={24}>
            <NetwalaButton
              backgroundColor={ButtonProps.background.STANDARD}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.STANDARD}
              textColor={ButtonProps.color.STANDARD}
              icon={<img src={BackButtonLong} />}
              onClick={() => {
                setActiveStep((activeStep) => activeStep - 1);
              }}
              text="Back"
            />
          </Col>
        )}
        {selectedFormat && isDataLoaded && (
          <>
            {activeStep === 1 && (
              <Col span={24}>
                <PackageMapperTable
                  packages={packages}
                  ispPackages={ispPackages}
                  handlePackageMapper={handlePackageMapper}
                  alreadyMappedPackages={mappedPackages}
                />
              </Col>
            )}
            {isPackagesMapped && activeStep === 2 && (
              <Col span={24}>
                {isLoading === true ? (
                  <TailSpinLoader />
                ) : (
                  <BulkUsersImportTable
                    users={users}
                    handleUserSkip={handleUserSkip}
                  />
                )}
                <div
                  style={{
                    width: "100%",
                    marginTop: "20px",
                    marginBottom: "20px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <NetwalaButton
                    backgroundColor={ButtonProps.background.STANDARD}
                    height={ButtonProps.height.STANDARD}
                    width={ButtonProps.width.STANDARD}
                    textColor={ButtonProps.color.STANDARD}
                    onClick={() => {
                      setShow(true);
                    }}
                    disabled={isLoading}
                    text="Upload Users"
                  />
                </div>
              </Col>
            )}
          </>
        )}
      </Row>
    </>
  );
};
export default ISPBulkUserImport;
