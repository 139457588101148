import { Col, Row, Typography } from "antd";
import React from "react";
import "./index.css";
const NetwalaTabs: React.FC<NetwalaTabsComponentProp> = ({
  colSpan,
  tabs,
  handleTabSwitch,
}) => {
  return (
    <div className="netwala-tabs-main">
      <Row className="netwala-tabs-container">
        {tabs.map((item: NetwalaTabItemsProp) => {
          return item.isActive === true ? (
            <Col
              onClick={() => {
                handleTabSwitch(item.id);
              }}
              key={Math.random()}
              className="netwala-tab-active-single"
              span={colSpan}
            >
              <Typography className="netwala-tab-text">{item.text}</Typography>
            </Col>
          ) : (
            <Col
              key={Math.random()}
              className="netwala-tab-single"
              span={colSpan}
              onClick={() => {
                handleTabSwitch(item.id);
              }}
            >
              <Typography className="netwala-tab-text">{item.text}</Typography>
            </Col>
          );
        })}
        {/* <Col className="netwala-tab-active-single" span={colSpan}>
          <Typography className="netwala-tab-text">Hamza</Typography>
        </Col>
        <Col className="netwala-tab-single" span={colSpan}>
          <Typography className="netwala-tab-text">Hamza</Typography>
        </Col>
        <Col className="netwala-tab-active-single" span={colSpan}>
          <Typography className="netwala-tab-text">Hamza</Typography>
        </Col> */}
      </Row>
    </div>
  );
};
export default NetwalaTabs;
