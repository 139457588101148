import React from "react";
import { Typography } from "antd";
import "./index.css";
interface CashInHandContainerProp {
  label: string;
  value: string;
}
const CashInHandContainer: React.FC<CashInHandContainerProp> = ({
  label,
  value,
}) => {
  return (
    <div className="cash-in-hand-container">
      <Typography className="cash-in-hand-label">{label}</Typography>
      <Typography className="cash-in-hand-value">{value}</Typography>
    </div>
  );
};
export default CashInHandContainer;
