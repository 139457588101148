import { Col, Form, Input, Row, Upload, Button, message } from "antd";
import { useEffect, useState } from "react";
import { CameraOutlined, DeleteOutlined } from "@ant-design/icons";

import { useNavigate, useParams } from "react-router-dom";
import useMultiNotification from "../../../../../hooks/useNotification";
import NetwalaButton from "../../../../Items/Button/Index";
import { ButtonProps } from "../../../../../helpers/constants";
import PlusIcon from "./../../../../../assets/svgs/IconsWhite/PlusIcon.svg";
import NetwalaSelect from "../../../../Items/Select/Index";
import ChoosePackageIconModal from "../../../../Modals/ChoosePackageIconModal";
import useAxiosPost from "../../../../../hooks/useAxiosPost";
import PackagePriceConfirmationModal from "../../../../Modals/PackagePriceConfirmationModal";
import ChoosePackageColorModal from "../../../../Modals/ChoosePackageColorModal";
import PackageUpdateConfirmationModal from "../../../../Modals/PackageUpdateConfirmationModal";
const UpdatePackageForISPComponent = () => {
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<UpdatePackageDetailApiProp>(
    "packages/updatePackageDetails",
    true
  );
  const { intPkPackageID } = useParams();
  const [packageDetails, setPackageDetails] = useState<PackageDetails | null>(
    null
  );
  const [updatePackageData, setUpdatePackageData] =
    useState<UpdatePackageDetailApiProp | null>(null);
  const [getPackageById, packageDetailsApiResponse] =
    useAxiosPost<SinglePackageDetailsApiProp>("packages/getPackageById");
  const { openNotification, contextHolder } = useMultiNotification();
  const [show, setShow] = useState<boolean>(false);
  const [showPriceUpdate, setShowPriceUpdate] = useState<boolean>(false);
  const [showUpdateConfirmation, setShowUpdateConfirmation] =
    useState<boolean>(false);
  const [iconId, setIconId] = useState<number | null>(null);
  const [url, setUrl] = useState<string | null>(null);
  const [packageName, setPackageName] = useState<string>("");
  const [selectedColor, setSelectedColor] = useState<any>(null);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const onFinish = (values: any) => {
    if (!packageDetails) {
      return;
    }
    if (selectedColor === null) {
      openNotification("error", "Error", `No Color Selected For Package`);
      return;
    }
    //  let { costPrice } = values;
    //  if (!costPrice) {
    //    costPrice = 0;
    //  } else if (costPrice < 0) {
    //    openNotification("error", "Error", `Cost Price Must be 0 or more`);
    //    return;
    //  }
    const dataToSend: UpdatePackageDetailApiProp = {
      intPkPackageID: packageDetails.intPkPackageID,
      intPkIspID: Number(packageDetails.intPkIspID),
      // intPkResourceFileID: iconId,
      packageName: values.packageName,
      billingMethod: "MONTHLY",
      costPrice: 0,
      salePrice: Number(values.salePrice),
      durationDays: 30,
      connectionsPriceUpdate: false,
      intPkPackageColorID: selectedColor.intPkPackageColorID,
    };
    setUpdatePackageData(dataToSend);
    setShowUpdateConfirmation(true);
    // if (Number(packageDetails.salePrice) !== Number(values.salePrice)) {
    //   setUpdatePackageData(dataToSend);
    //   setShowPriceUpdate(true);
    // } else {
    //   postApiData(dataToSend);
    // }
  };
  const [selectedBillingMethod, setSelectedBillingMethod] =
    useState<string>("");
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const handleClose = (isReload: boolean) => {
    setShow(false);
    if (isReload === false) {
      setIconId(null);
    }
  };
  const handleSelectIconId = (id: number) => {
    setIconId(id);
  };
  useEffect(() => {
    if (apiResponse !== null) {
      const { message } = apiResponse;
      if (message) {
        openNotification(`success`, "Success", message);
        setTimeout(() => {
          navigate(-1);
        }, 3000);
      }
    }
  }, [apiResponse]);
  useEffect(() => {
    if (apiError !== null && apiErrorCount !== 0) {
      openNotification(`error`, "Operation Failed", apiError);
    }
  }, [apiError, apiErrorCount]);
  useEffect(() => {
    getPackageById({ packageId: Number(intPkPackageID) });
  }, []);
  useEffect(() => {
    if (packageDetailsApiResponse !== null) {
      const packageData: any = packageDetailsApiResponse.responseData;
      setPackageDetails(packageData);
      setPackageName(packageData.packageName);
      setSelectedColor(packageData);
      setSelectedBillingMethod(packageData.billingMethod);
      setUrl(packageData.url);
    }
  }, [packageDetailsApiResponse]);
  const selectIcon = (data: any) => {
    setUrl(data.url);
  };
  const handleConfirmationOfPackagePrice = (userConsent: boolean) => {
    setShowPriceUpdate(false);
    if (!updatePackageData) {
      return;
    }
    const dataToSend: UpdatePackageDetailApiProp = {
      ...updatePackageData,
      connectionsPriceUpdate: userConsent,
    };
    postApiData(dataToSend);
  };
  const handleSelectedColor = (selectedColor: any) => {
    setSelectedColor(selectedColor);
  };
  const handleUpdateConfirmation = (userConsent: boolean) => {
    setShowUpdateConfirmation(false);
    if (!updatePackageData) {
      return;
    }
    if (userConsent === true) {
      postApiData(updatePackageData);
    }
  };
  return (
    <>
      {contextHolder}
      {show && (
        <ChoosePackageColorModal
          handleSelectedColor={handleSelectedColor}
          show={show}
          handleClose={handleClose}
          packageName={form.getFieldValue("packageName")}
        />
      )}
      {showPriceUpdate && (
        <PackagePriceConfirmationModal
          show={showPriceUpdate}
          handleClose={handleConfirmationOfPackagePrice}
        />
      )}
      {showUpdateConfirmation && (
        <PackageUpdateConfirmationModal
          show={showUpdateConfirmation}
          handleClose={handleUpdateConfirmation}
        />
      )}
      <Row style={{ width: "100%" }}>
        {packageDetails && (
          <Form
            name="updatePackageForm"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            requiredMark={false}
            style={{ width: "100%" }}
            form={form}
            initialValues={{
              packageName: packageDetails.packageName,
              costPrice: packageDetails.costPrice,
              salePrice: packageDetails.salePrice,
              billingMethod: packageDetails.billingMethod,
              durationDays: packageDetails.durationDays,
              ISPName: packageDetails.ISPName,
            }}
          >
            {/* First */}
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={
                    <span className="create-user-form-label">Package Name</span>
                  }
                  name="packageName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Package Name",
                    },
                  ]}
                >
                  <Input
                    className="user-input-general-height"
                    placeholder="DT-1MB"
                    value={packageName}
                    disabled={
                      packageDetails.ispTypeEnum === "STANDARD" ? false : true
                    }
                    onChange={(e) => setPackageName(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
                span={12}
              >
                <Button
                  style={{ marginTop: "20px", height: "45px" }}
                  onClick={(e) => {
                    if (!form.getFieldValue("packageName")) {
                      return;
                    }
                    e.preventDefault();
                    setShow(true);
                  }}
                  // onClick={showModal}
                  icon={<CameraOutlined />}
                >
                  Choose Package Color
                </Button>
                {packageName && selectedColor !== null && (
                  <div
                    className="package-color-container"
                    style={{
                      background: selectedColor.bgColor,
                      border: "1px solid",
                      borderColor: selectedColor.borderColor,
                      color: selectedColor.color,
                      maxHeight: "20px",
                    }}
                  >
                    {packageName}
                  </div>
                )}
              </Col>

              {/* <Col span={12}>
                <Button
                  style={{ marginTop: "20px", height: "45px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setShow(true);
                  }}
                  // onClick={showModal}
                  icon={<CameraOutlined />}
                >
                  Choose Package Icon
                </Button>
              </Col> */}
            </Row>
            {/* Second */}
            <Row gutter={16}>
              {/* <Col span={12}>
                <Form.Item
                  label={
                    <span className="create-user-form-label">Cost Price</span>
                  }
                  name="costPrice"
                >
                  <Input
                    type="number"
                    className="user-input-general-height"
                    placeholder="50"
                  />
                </Form.Item>
              </Col> */}
              <Col span={12}>
                <Form.Item
                  label={<span className="create-user-form-label">ISP</span>}
                  name="ISPName"
                >
                  <Input
                    className="user-input-general-height"
                    value={packageDetails.ISPName}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <span className="create-user-form-label">Sale Price</span>
                  }
                  name="salePrice"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Sale Price",
                    },
                    {
                      validator: (_, value) =>
                        value >= 0
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error("Cost Price Must be greater than 0")
                            ),
                    },
                  ]}
                >
                  <Input
                    type="number"
                    defaultValue={0}
                    className="user-input-general-height"
                    placeholder="100"
                  />
                </Form.Item>
              </Col>
            </Row>
            {/* Third */}
            {/* <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={
                    <span className="create-user-form-label">
                      Billing Method
                    </span>
                  }
                  name="billingMethod"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Billing Method",
                    },
                  ]}
                >
                  <NetwalaSelect
                    value={selectedBillingMethod}
                    size="large"
                    height="45px"
                    onChange={(e) => setSelectedBillingMethod(e)}
                    options={[
                      { value: "DAYS", label: "DAYS" },
                      { value: "MONTHLY", label: "MONTHLY" },
                    ]}
                  />
                </Form.Item>
              </Col>
              {selectedBillingMethod === "DAYS" && (
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className="create-user-form-label">
                        Duration Days
                      </span>
                    }
                    name="durationDays"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Duration Days",
                      },
                      {
                        validator: (_, value) =>
                          value >= 0
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error("Duration Days Must be more than 0")
                              ),
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      className="user-input-general-height"
                      placeholder="30"
                    />
                  </Form.Item>
                </Col>
              )}
            </Row> */}
            {/* <Row gutter={16}>
              <Col span={12}>
                <span className="create-user-form-label">Picture</span>
                <div className="update-package-image-container">
                  {url && (
                    <div style={{ display: "flex" }}>
                      <img src={url} />
                      <Button
                        style={{
                          marginTop: "20px",
                          marginRight: "5px",
                          height: "45px",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          setUrl(null);
                          setIconId(null);
                        }}
                        // onClick={showModal}
                        icon={<DeleteOutlined />}
                      ></Button>
                    </div>
                  )}
                  <Button
                    style={{
                      marginTop: "20px",
                      marginRight: "5px",
                      marginLeft: "5px",
                      height: "45px",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setShow(true);
                    }}
                    // onClick={showModal}
                    icon={<CameraOutlined />}
                  >
                    Choose Package Icon
                  </Button>
                </div>
              </Col>
            </Row> */}
            {/* Final */}
            <Row>
              <Col
                style={{ display: "flex", justifyContent: "center" }}
                span={24}
              >
                <Form.Item>
                  <NetwalaButton
                    htmlType="submit"
                    backgroundColor={ButtonProps.background.STANDARD}
                    height={ButtonProps.height.STANDARD}
                    width={ButtonProps.width.STANDARD}
                    textColor={ButtonProps.color.STANDARD}
                    onClick={() => {
                      console.log("clicked");
                    }}
                    text="Update Package"
                    // icon={<img src={PlusIcon} />}
                    disabled={apiLoading}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Row>
    </>
  );
};
export default UpdatePackageForISPComponent;
