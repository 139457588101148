import { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import ViewBankDepositRequests from "../components/deposit-requests/ViewBankDepositRequests";

const ViewBankDepositRequestsPage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Deposit Requests");
  }, [setTitle]);
  return (
    <>
      <ViewBankDepositRequests />
    </>
  );
};
export default ViewBankDepositRequestsPage;
