import React, { useContext, useEffect, useState } from "react";
import { Typography, Dropdown } from "antd";
import SettingsIcon from "./../../assets/svgs/NavBarIcons/SettingsIcon.svg";
import SearchIcon from "./../../assets/svgs/NavBarIcons/SearchIcon.svg";
import NotificationsIcon from "./../../assets/svgs/NavBarIcons/NotificationsIcon.svg";
import ArrowDown from "./../../assets/svgs/ArrowDown.svg";
import CompanySettingsIcon from "./../../assets/svgs/CompanySettingsIcon.svg";
import ProfileIconBlue from "./../../assets/svgs/ProfileIconBlue.svg";
import KeyIcon from "./../../assets/svgs/KeyIcon.svg";
import LogoutIcon from "./../../assets/svgs/LogoutIcon.svg";

import type { MenuProps } from "antd";
import { useNavigate } from "react-router-dom";
import { getUserType, handleLogout } from "../../helpers/userDetails";
import useAxiosPost from "../../hooks/useAxiosPost";
import CashInHandContainer from "../Items/CashAvaialble/Index";
import BalanceContext from "../../context/BalanceContext";

const navItems: NavItem[] = [
  { label: "Settings", url: "/settings", icon: SettingsIcon },
  { label: "Search", url: "/search", icon: SearchIcon },
  { label: "Notification", url: "notifications", icon: NotificationsIcon },
];

const TopNavBar = () => {
  const { value, setValue } = useContext(BalanceContext);

  const [staff, setStaffProfile] = useState<StaffListType | null>(null);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("auth/getMyProfile");
  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setStaffProfile(data);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({});
  }, []);
  useEffect(() => {
    if (value > 0) {
      postApiData({});
    }
  }, [value]);
  const navigate = useNavigate();
  const connectionOptionItems: MenuProps["items"] = [
    {
      key: "PROFILE",
      label: (
        <Typography className="profile-drop-down-item">
          <img src={ProfileIconBlue} />
          User Profile
        </Typography>
      ),
    },
    {
      key: "CHANGE_PASSWORD",
      label: (
        <Typography className="profile-drop-down-item">
          <img src={KeyIcon} />
          Change Password
        </Typography>
      ),
    },
    {
      key: "LOGOUT",
      label: (
        <Typography className="profile-drop-down-item">
          <img src={LogoutIcon} />
          Logout
        </Typography>
      ),
    },
  ];
  if (getUserType() === "CEO") {
    connectionOptionItems.splice(2, 0, {
      key: "COMPANY_SETTINGS",
      label: (
        <Typography className="profile-drop-down-item">
          <img src={CompanySettingsIcon} />
          Company Settings
        </Typography>
      ),
    });
  }
  const handleMenuClick = (e: any) => {
    if (e.key === "PROFILE") {
      navigate("/auth/profile");
    } else if (e.key === "COMPANY_SETTINGS") {
      navigate(`/auth/company-settings`);
    } else if (e.key === "CHANGE_PASSWORD") {
      navigate(`/auth/update-password`);
    } else if (e.key === "LOGOUT") {
      handleLogout();
    } else if (e.key === "LOGOUT") {
      handleLogout();
    }
  };
  const showName = (firstName: string, lastName: string, role: string) => {
    const fullName = `${firstName} ${lastName}`;
    if (fullName.length > 13) {
      return `${firstName} (${role})`;
    }
    return `${fullName} (${role})`;
  };
  return (
    <>
      <div className="top-nav-bar-items-container">
        {staff && ["RMAN", "CASHIER"].includes(staff.headType) && (
          <div style={{ marginRight: "40px" }}>
            <CashInHandContainer
              value={`${staff.userBalance.cashAvailable}`}
              label="Balance:"
            />
          </div>
        )}
        {navItems.map((item: NavItem) => {
          return (
            <div key={Math.random()} className="top-nav-bar-item">
              {<img src={item.icon} />}
            </div>
          );
        })}

        {/* <Select
          className="top-nav-select-control"
          size={"middle"}
          defaultValue="Hamza Arshad"
          //   onChange={handleChange}
          style={{ width: 200 }}
          options={options}
        /> */}
        <Dropdown
          menu={{
            items: connectionOptionItems,

            onClick: handleMenuClick,
          }}
          placement="bottomLeft"
        >
          <div className="user-profile-options-container">
            <div className="user-profile-container-with-profile-icon">
              <img src={ProfileIconBlue} />{" "}
              <Typography className="user-name-options-label">
                {staff &&
                  showName(staff.firstName, staff.lastName, staff.headType)}
              </Typography>{" "}
            </div>
            <img className="profile-arrow-down" src={ArrowDown} />
          </div>
        </Dropdown>
      </div>
    </>
  );
};

export default TopNavBar;
