import React, { useContext, useEffect } from "react";
import TitleContext from "../context/TitleContext";
import AddExpenseForm from "../components/expenses/addExpenseForm";
const AddExpensePage = () => {
  const { setTitle } = useContext(TitleContext);

  useEffect(() => {
    setTitle("Add Expense");
  }, [setTitle]);

  return (
    <>
      <AddExpenseForm />
    </>
  );
};
export default AddExpensePage;
