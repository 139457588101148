import { useEffect, useState } from "react";
import { Col, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import useAxiosPost from "../../hooks/useAxiosPost";
import useMultiNotification from "../../hooks/useNotification";
import "./index.css";
import NetwalaButton from "../Items/Button/Index";
import { ButtonProps } from "../../helpers/constants";
import CreateDealerModal from "../Modals/CreateDealerModal";
import CompanyDealersTable from "../Items/Table/CompanyDealersTable";
import { getUserType } from "../../helpers/userDetails";
const ViewDealersComponent = () => {
  const { openNotification, contextHolder } = useMultiNotification();
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(false);
  const [dealers, setDealers] = useState<CompanyDealer[]>([]);
  const [
    postApiData,
    apiResponse,
    apiError,
    apiLoading,
    apiMessage,
    apiErrorCount,
  ] = useAxiosPost<ResponseProp>("users/getCompanyDealers");

  useEffect(() => {
    if (apiResponse !== null) {
      const data: any = apiResponse.responseData;
      setDealers(data || []);
    }
  }, [apiResponse]);
  useEffect(() => {
    postApiData({});
  }, []);
  const handleClose = (isReload: boolean) => {
    if (isReload === true) {
      postApiData({});
    }
    setShow(false);
  };
  const reloadApi = () => {
    postApiData({});
  };
  return (
    <>
      {contextHolder}
      {show === true && (
        <CreateDealerModal show={show} handleClose={handleClose} />
      )}
      <Row style={{ width: "100%" }} gutter={[16, 16]}>
        <Col span={6} offset={18} style={{ marginBottom: "20px" }}>
          {getUserType() === "CEO" && (
            <NetwalaButton
              backgroundColor={ButtonProps.background.STANDARD}
              height={ButtonProps.height.STANDARD}
              width={ButtonProps.width.STANDARD}
              textColor={ButtonProps.color.STANDARD}
              onClick={() => {
                setShow(true);
              }}
              text="Create New Dealer"
            />
          )}
        </Col>
        <Col span={24}>
          <CompanyDealersTable dealers={dealers} reloadApi={reloadApi} />
        </Col>
      </Row>
    </>
  );
};
export default ViewDealersComponent;
//updateDealerProfile
