import React, { useState, ReactNode } from "react";
import BalanceContext from "./BalanceContext";

interface BalanceReloadProviderProps {
  children: ReactNode;
}

const BalanceReloadProvider: React.FC<BalanceReloadProviderProps> = ({
  children,
}) => {
  const [value, setValue] = useState<number>(0);
  const incrementValue = () => {
    setValue((prevValue) => prevValue + 1);
  };
  return (
    <BalanceContext.Provider value={{ value, setValue: incrementValue }}>
      {children}
    </BalanceContext.Provider>
  );
};

export default BalanceReloadProvider;
